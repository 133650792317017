import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose, pathStrOr, projectAs } from 'utils/fp'
import withFormContext, {
  ValidatedFormInputPropTypes,
} from 'core/components/validatedForm/withFormContext'
import MultiSelect from 'core/components/MultiSelect'
import SingleSelect from 'core/components/SingleSelect'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { cloudProviderRegionDetailsSelector } from '../selectors'
import { listCloudProviderRegionDetails } from '../new-actions'

const AwsAvailabilityZoneChooser = forwardRef(
  (
    {
      cloudProviderId,
      cloudProviderRegionId,
      allowMultiSelect,
      values,
      id,
      required,
      ...rest
    }: Props,
    ref,
  ) => {
    const { loading: loadingCloudProviderRegionDetails } = useListAction(
      listCloudProviderRegionDetails,
      {
        params: { cloudProviderId, cloudProviderRegionId },
      },
    )

    const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
      cloudProviderId,
      cloudProviderRegionId,
    })

    const regions = useMemo(() => {
      const azs = pathStrOr([], '0.azs', regionDetails)
      return projectAs({ label: 'ZoneName', value: 'ZoneName' }, azs)
    }, [regionDetails])

    const SelectComponent = allowMultiSelect ? MultiSelect : SingleSelect
    const componentProps: any = {}
    if (!allowMultiSelect && values.length === 1) componentProps.value = values[0]

    return (
      <SelectComponent
        className="validatedFormInput"
        label="Availability Zones"
        options={regions}
        values={values.azs}
        loading={loadingCloudProviderRegionDetails}
        required={required}
        {...componentProps}
        {...rest}
      />
    )
  },
)

AwsAvailabilityZoneChooser.propTypes = {
  id: PropTypes.string.isRequired,
  cloudProviderId: PropTypes.string,
  cloudProviderRegionId: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  ...ValidatedFormInputPropTypes,
}
interface Props {
  id: string
  info: string
  cloudProviderId: string
  cloudProviderRegionId: string
  initialValue?: string | number
  updateWizard?: boolean
  values: any
  type: string
  onChange: any
  required?: boolean
  allowMultiSelect: boolean
}
AwsAvailabilityZoneChooser.displayName = 'AwsAvailabilityZoneChooser'

export default compose(withFormContext)(AwsAvailabilityZoneChooser)
