import React, { useMemo } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import PieGraph, { PieDataEntry } from 'core/components/graphs/PieGraph'
import useListAction from 'core/hooks/useListAction'
import { listPods } from 'k8s/components/pods/new-actions'
import { podsSelector } from 'k8s/components/pods/selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listClusterAddons, listClusterVersions } from '../clusters/cluster-addons/new-actions'
import {
  clusterAddonsSelector,
  clusterVersionsSelector,
} from '../clusters/cluster-addons/selectors'
import { getClusterAddonHealthStatus } from '../clusters/cluster-addons/helpers'
import clsx from 'clsx'
import { getCloudProviderLabel } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { listCronjobs } from 'k8s/components/cronjobs/actions'
import { cronjobSelector } from 'k8s/components/cronjobs/selectors'
import { formatDate } from 'utils/misc'
import { EtcdBackupTypes } from '../clusters/aws/capi/CapiEtcdBackupFields'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useParams from 'core/hooks/useParams'
import { allKey } from 'app/constants'
import { useSelector } from 'react-redux'
import ClusterVersionIcon from '../clusters/cluster-cells/ClusterVersionIcon'
import SimpleLink from 'core/components/SimpleLink'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { getClusterDetailsPath, isImportedCluster, isCapiCluster } from '../clusters/helpers'
import { routes } from 'core/utils/routes'
import { ClusterTypes } from '../clusters/model'
import { Upgrading, UpgradeFailed } from '../clusters/cluster-cells/ClusterVersionStatus'
import { isTransientStatus } from '../clusters/ClusterStatusUtils'

export interface StyleProps {
  hasAddonErrors: boolean
  healthStatus: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    padding: 16,
    display: 'grid',
    gap: 16,
  },
  header: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    paddingLeft: 8,
  },
  star: {
    color: theme.components.badge.warning.color,
  },
  info: {
    padding: 16,
    background: theme.components.frame.accentBackground,
    display: 'grid',
    gridTemplateColumns: '1fr 1px 1fr',
    gap: 24,
  },
  divider: {
    height: '100%',
    background: theme.components.card.border,
  },
  backup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  stats: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto',
  },
  inline: {
    display: 'inline',
  },
  healthCircle: {
    '&:before': {
      content: '""',
      height: 12,
      width: 12,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: 6,
      backgroundColor: ({ healthStatus }) =>
        healthStatus === 'Unhealthy'
          ? theme.components.graph.fadedError
          : theme.components.graph.fadedSuccess,
    },
  },
  addonCircle: {
    '&:before': {
      content: '""',
      height: 12,
      width: 12,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: 6,
      backgroundColor: ({ hasAddonErrors }) =>
        hasAddonErrors ? theme.components.graph.fadedError : theme.components.graph.fadedSuccess,
    },
  },
}))

const useKubernetesVersionStyles = makeStyles<Theme>((theme) => ({
  upgradingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    alignItems: 'baseline',
    marginTop: theme.spacing(0.5),
  },
  spinner: {
    color: theme.palette.blue.main,
  },
  upgradeFailedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    alignItems: 'baseline',
    marginTop: theme.spacing(0.5),
  },
  failedIcon: {
    color: theme.palette.red.main,
    marginRight: theme.spacing(1),
  },
}))

const noPieData = [{ name: 'unknown', value: 1, color: 'tray' }]

const nodeHealthStatus = ({ status }) => {
  if (status === 'converging') {
    return status
  }
  return status === 'disconnected' ? 'unknown' : status === 'ok' ? 'healthy' : 'unhealthy'
}

const podHealthStatus = (pod) => {
  const phase = pod?.status?.phase
  if (['Running', 'Succeeded'].includes(phase)) {
    return 'healthy'
  }
  if (phase === 'Failed') {
    return 'unhealthy'
  }
  return 'warning'
}

const getClusterHealth = (cluster) => {
  if (cluster?.clusterType === 'normal') {
    return cluster?.healthStatus === 'unhealthy' ? 'Unhealthy' : 'Healthy'
  } else if (cluster?.clusterType === 'imported') {
    if (
      cluster?.status?.phase === 'Failing' ||
      !cluster?.ecoInstalled ||
      cluster?.ecoStatus === 'errored' ||
      cluster?.ecoStatus === 'offline'
    ) {
      return 'Unhealthy'
    }
    return 'Healthy'
  } else if (cluster?.clusterType === 'capi') {
    return cluster?.phase === 'Provisioned' ? 'Healthy' : 'Unhealthy'
  }
  return 'Unhealthy'
}

const cloudProviderLabel = (cluster) => {
  if (cluster?.clusterType === 'capi') {
    return getCloudProviderLabel(cluster.infrastructureType) || cluster.infrastructureType
  } else {
    return getCloudProviderLabel(cluster.cloudProviderType) || cluster.cloudProviderType
  }
}

const getUpgradeAvailable = (cluster) => {
  if (isImportedCluster(cluster)) return false

  if (isCapiCluster(cluster)) {
    const { canUpgrade, phase, infrastructureReady, controlPlaneReady } = cluster
    return (
      canUpgrade &&
      !isTransientStatus((phase || '').toLowerCase()) &&
      infrastructureReady &&
      controlPlaneReady
    )
  } else {
    const { canUpgrade } = cluster
    return canUpgrade
  }
}

const getContinueUpgrade = (cluster) => {
  if (isImportedCluster(cluster) || isCapiCluster(cluster)) return false

  const { taskStatus, upgradingTo } = cluster
  return !isTransientStatus(taskStatus) && upgradingTo
}

const KubernetesVersionCell = ({ cluster }) => {
  const classes = useKubernetesVersionStyles()
  const { loading } = useListAction(listClusterVersions)
  const clusterVersions = useSelector(clusterVersionsSelector)

  const version =
    cluster?.kubeRoleVersion || cluster?.kubeVersion || cluster?.controlPlane?.k8sVersion

  const upgrading = cluster?.upgrading
  const upgradeFailed = cluster?.upgradeFailed
  const upgradeAvailable = getUpgradeAvailable(cluster)
  const continueUpgrade = getContinueUpgrade(cluster)

  const upgradingRoute = isCapiCluster(cluster)
    ? routes.cluster.managed.capi.upgradeProgress.path({
        id: cluster?.uuid,
        jobId: cluster?.currentUpgradeJob?.id,
      })
    : routes.cluster.managed.qbert.detail.path({
        tab: 'node-health',
        id: cluster?.uuid,
      })

  const upgradeAvailableRoute = isCapiCluster(cluster)
    ? routes.cluster.managed.capi.details.path({
        id: cluster?.uuid,
      })
    : routes.cluster.managed.qbert.upgrade.path({ id: cluster?.uuid })

  const upgradeFailedRoute = isCapiCluster(cluster)
    ? routes.cluster.managed.capi.details.path({
        id: cluster?.uuid,
      })
    : routes.cluster.managed.qbert.upgrade.path({ id: cluster?.uuid })

  return (
    <>
      {upgrading ? (
        <div className={classes.upgradingContainer}>
          <Upgrading upgradingRoute={upgradingRoute} />
        </div>
      ) : (
        <>
          <Text variant="caption1">{version}</Text>
          {upgradeFailed ? (
            <div className={classes.upgradeFailedContainer}>
              <UpgradeFailed
                upgradeFailed={upgradeFailed}
                upgradeFailedRoute={upgradeFailedRoute}
              />
            </div>
          ) : (
            <div className={classes.upgradeFailedContainer}>
              <ClusterVersionIcon
                version={version}
                clusterVersions={clusterVersions}
                showIconText={true}
              />
              {upgradeAvailable && (
                <SimpleLink src={upgradeAvailableRoute}>
                  {continueUpgrade ? 'Continue Upgrade' : 'Upgrade'}
                </SimpleLink>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

const clusterTypeLabel = {
  [ClusterTypes.Normal]: 'Qbert',
  [ClusterTypes.Imported]: 'Imported',
  [ClusterTypes.Capi]: 'CAPI',
}

// etcd backup for Imported Clusters is currently not supported
const etcdCronjobNames = [
  EtcdBackupTypes.Daily,
  EtcdBackupTypes.Interval,
  'etcd-backup',
  'etcd-backup-with-interval',
  'etcd-backup-with-timestamp',
]

const compareDates = (d1, d2) => {
  const date1 = new Date(d1)
  const date2 = new Date(d2)
  return date1 <= date2 ? 1 : -1
}

const defaultParams = {
  clusterId: null,
  namespace: allKey,
}

export default function DefaultClusterCard({ cluster }) {
  const { allParams: params } = useGlobalParams(useParams, defaultParams)

  const { loading: loadingCronjobs } = useListAction(listCronjobs, {
    params: { clusterId: cluster?.uuid, namespace: 'kube-system' },
  })
  const cronjobs = useSelectorWithParams(cronjobSelector, {
    clusterId: cluster?.uuid,
    namespace: 'kube-system',
    useGlobalParams: false,
  })

  const lastScheduleTimesArray = useMemo(() => {
    const etcdCronjobs = cronjobs.filter((x) => etcdCronjobNames.includes(x.name))

    return etcdCronjobs
      .map((x) => x?.status?.lastScheduleTime)
      .filter(Boolean)
      .sort(compareDates)
  }, [cronjobs])

  const lastScheduleTime =
    lastScheduleTimesArray.length > 0 ? formatDate(lastScheduleTimesArray[0]) : undefined

  const { loading: loadingPods } = useListAction(listPods, {
    params: {
      clusterId: params?.clusterId || cluster?.uuid,
      namespace: params?.namespace || cluster?.namespace,
    },
  })
  const pods = useSelectorWithParams(podsSelector, {
    clusterId: cluster?.uuid,
    useGlobalParams: false,
  })

  const { loading: loadingClusterAddons } = useListAction(listClusterAddons, {
    params: { clusterId: cluster?.uuid },
  })
  const addons = useSelectorWithParams(clusterAddonsSelector, { clusterId: cluster?.uuid })
  const numErrorAddons = useMemo(() => {
    return addons?.filter((addon) => {
      return getClusterAddonHealthStatus(addon) === 'Error'
    }).length
  }, [addons])

  const healthStatus = getClusterHealth(cluster)

  const classes = useStyles({ hasAddonErrors: !!numErrorAddons, healthStatus })

  const nodeGraphData = useMemo(() => {
    if (!cluster?.nodes?.length) {
      return noPieData
    }
    return [
      {
        name: 'healthy',
        value: cluster?.nodes?.filter((node) => nodeHealthStatus(node) === 'healthy').length || 0,
        color: 'fadedSuccess',
      },
      {
        name: 'unknown',
        value: cluster?.nodes?.filter((node) => nodeHealthStatus(node) === 'unknown').length || 0,
        color: 'fadedWarning',
      },
      {
        name: 'converging',
        value:
          cluster?.nodes?.filter((node) => nodeHealthStatus(node) === 'converging').length || 0,
        color: 'fadedDanger',
      },
      {
        name: 'unhealthy',
        value: cluster?.nodes?.filter((node) => nodeHealthStatus(node) === 'unhealthy').length || 0,
        color: 'fadedError',
      },
    ]
  }, [cluster]) as PieDataEntry[]

  const podGraphData = useMemo(() => {
    if (!pods?.length) {
      return noPieData
    }
    return [
      {
        name: 'healthy',
        value: pods?.filter((pod) => podHealthStatus(pod) === 'healthy').length || 0,
        color: 'fadedSuccess',
      },
      {
        name: 'warning',
        value: pods?.filter((pod) => podHealthStatus(pod) === 'warning').length || 0,
        color: 'fadedWarning',
      },
      {
        name: 'unhealthy',
        value: pods?.filter((pod) => podHealthStatus(pod) === 'unhealthy').length || 0,
        color: 'fadedError',
      },
    ]
  }, [pods]) as PieDataEntry[]

  const { currentPluginId } = usePluginRouter()
  const routePathPods = currentPluginId === 'kubevirt' ? 'kubevirtPods' : 'pods'

  const nodesUrl = useMemo(() => {
    return getClusterDetailsPath({
      clusterId: cluster?.uuid,
      clusterType: cluster?.clusterType,
      params: { tab: cluster?.clusterType === ClusterTypes.Normal ? 'nodes' : 'node-groups' },
      currentPluginId,
    })
  }, [cluster?.clusterType, cluster?.uuid])

  const clusterDetailsUrl = useMemo(() => {
    return getClusterDetailsPath({
      clusterId: cluster?.uuid,
      clusterType: cluster?.clusterType,
      currentPluginId,
    })
  }, [cluster?.clusterType, cluster?.uuid])

  const clusterDetailsAddonsUrl = useMemo(() => {
    return getClusterDetailsPath({
      clusterId: cluster?.uuid,
      clusterType: cluster?.clusterType,
      // tab is called addons for Normal clusters and addOns for Capi clusters
      params: { tab: cluster?.clusterType === ClusterTypes.Normal ? 'addons' : 'addOns' },
      currentPluginId,
    })
  }, [cluster?.clusterType, cluster?.uuid])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>
          <SimpleLink src={clusterDetailsUrl}>
            <Text variant="subtitle2">{cluster.name}</Text>
          </SimpleLink>
          <Text variant="body2">{cloudProviderLabel(cluster)}</Text>
        </div>
        <div>
          <FontAwesomeIcon className={classes.star} size="md" solid>
            star
          </FontAwesomeIcon>
        </div>
      </div>
      <div className={classes.info}>
        <div>
          <Text variant="body2">Management API</Text>
          <Text variant="caption1">{clusterTypeLabel[cluster?.clusterType]}</Text>
        </div>
        <div className={classes.divider} />
        <div>
          <Text variant="body2">Kubernetes Version</Text>
          <KubernetesVersionCell cluster={cluster} />
        </div>
      </div>
      {!!lastScheduleTime && (
        <div className={classes.backup}>
          <Text variant="body2">Last Backup Time:</Text>
          <Text variant="caption1">{lastScheduleTime}</Text>
        </div>
      )}
      <div className={classes.stats}>
        <div>
          <SimpleLink src={nodesUrl}>
            <Text variant="caption1" className={classes.inline}>
              Nodes
            </Text>{' '}
            <Text variant="body2" className={classes.inline}>
              ({cluster?.nodes?.length || 0})
            </Text>
          </SimpleLink>
          <PieGraph data={nodeGraphData} arcWidth={14} sideLength={66} />
        </div>
        <div>
          {loadingPods ? (
            <FontAwesomeIcon spin>sync</FontAwesomeIcon>
          ) : (
            <>
              <SimpleLink src={routes[routePathPods].list.path({ clusterId: cluster?.uuid })}>
                <Text variant="caption1" className={classes.inline}>
                  Pods
                </Text>{' '}
                <Text variant="body2" className={classes.inline}>
                  ({pods?.length})
                </Text>
              </SimpleLink>
              <PieGraph data={podGraphData} arcWidth={14} sideLength={66} />
            </>
          )}
        </div>
        <div>
          <div>
            <Text variant="body2" className={clsx(classes.inline, classes.healthCircle)}>
              Cluster health
            </Text>{' '}
            <Text variant="caption1" className={classes.inline}>
              {healthStatus}
            </Text>
          </div>
          <div>
            <SimpleLink src={clusterDetailsAddonsUrl}>
              <Text variant="body2" className={clsx(classes.inline, classes.addonCircle)}>
                Add-ons health
              </Text>{' '}
              <Text variant="caption1" className={classes.inline}>
                {!!numErrorAddons ? `${numErrorAddons} issues` : 'No issues'}
              </Text>
            </SimpleLink>
          </div>
        </div>
      </div>
    </div>
  )
}
