import Card from 'core/elements/card'
import React, { useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import ClusterStatusSpan from '../../../ClusterStatusSpan'
import { getFieldsForCard } from 'core/components/InfoPanel'
import { castBoolToStr, formatDate } from 'utils/misc'
import SubnetConfigsTableFields from './SubnetConfigsTableFields'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { both } from 'ramda'
import { renderAvailabilityZones, renderCidrRanges } from '../view-helpers'
import { SubsectionHeader, TableRows, TableRow } from '../PropertyCardTable'
import { renderResourceLabels } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import Divider from 'core/elements/Divider'

const renderReadyStatus = (ready) => {
  const status = ready ? 'ok' : 'fail'
  return <ClusterStatusSpan status={status}>{ready ? 'True' : 'False'}</ClusterStatusSpan>
}

const isAwsCluster = (cluster) => cluster?.infrastructureType === CloudProviders.Aws
const hasRoleIdentityRef = (cluster) =>
  cluster?.infrastructure?.identityRef?.kind === 'AWSClusterRoleIdentity'

const standardPropertyColumns = [
  { id: 'name', title: 'Cluster Name', required: true },
  {
    id: 'infrastructureReady',
    title: 'InfrastructureReady',
    render: renderReadyStatus,
    required: true,
  },
  {
    id: 'controlPlaneReady',
    title: 'ControlPlane Ready',
    render: renderReadyStatus,
    required: true,
  },
  {
    id: 'infrastructure.awsAssumeRole',
    title: 'AWS Assume Role',
    conditions: both(isAwsCluster, hasRoleIdentityRef),
    render: (value) => castBoolToStr()(value),
  },
  {
    id: 'infrastructure.cloudProviderName',
    title: 'Cloud Provider',
    conditions: isAwsCluster,
  },
  {
    id: 'infrastructure.roleArn',
    title: 'Assume Role Target ARN',
    conditions: both(isAwsCluster, hasRoleIdentityRef),
  },
  {
    id: 'podsCidrBlocks',
    title: 'Cluster Pod CIDR Range',
    render: renderCidrRanges,
    required: true,
  },
  {
    id: 'servicesCidrBlocks',
    title: 'Cluster Service CIDR Range',
    render: renderCidrRanges,
    required: true,
  },
  { id: 'infrastructure.region', title: 'Region', condition: isAwsCluster, required: true },
  { id: 'infrastructure.sshKey', title: 'SSH Key', condition: isAwsCluster, required: true },
  {
    id: 'creationTimestamp',
    title: 'Created At',
    render: (value) => formatDate(value),
    required: true,
  },
]

const vpcConfigColumns = [
  { id: 'vpcId', title: 'VPC ID', required: true },
  {
    id: 'vpcCidrBlock',
    title: 'VPC CIDR Block',
    render: renderCidrRanges,
    required: true,
  },
  {
    id: 'internetGatewayId',
    title: 'Internet Gateway ID',
    required: true,
  },
  {
    id: 'availabilityZones',
    title: 'Availability Zones',
    render: renderAvailabilityZones,
    required: true,
  },
]

const loadBalancerConfigColumns = [
  { id: 'name', title: 'API Server Load Balancer Type', required: true }, // Not sure if this field is correct
  {
    id: 'scheme',
    title: 'Scheme',
    required: true,
  },
  {
    id: 'crossZoneLoadBalancing',
    title: 'Cross Zone Load Balancing',
    render: (value) => castBoolToStr()(value),
    required: true,
  },
]

export default function ClusterPropertiesCard({ cluster }) {
  const classes = useStyles()
  const isAwsCluster = cluster?.infrastructureType === CloudProviders.Aws

  const generalPropertyFields = useMemo(() => getFieldsForCard(standardPropertyColumns, cluster), [
    cluster,
  ])

  const vpcConfigFields = useMemo(
    () => getFieldsForCard(vpcConfigColumns, cluster?.infrastructure || {}),
    [cluster?.infrastructure],
  )

  const loadBalancerFields = useMemo(
    () =>
      getFieldsForCard(
        loadBalancerConfigColumns,
        cluster?.infrastructure?.controlPlaneLoadBalancer,
      ),
    [cluster?.infrastructure?.controlPlaneLoadBalancer],
  )

  return (
    <Card className={classes.card} title="Properties">
      <div className={classes.table}>
        <TableRows fields={generalPropertyFields} />

        {/* ControlPlane Endpoint Rows */}
        <Text variant="caption1">Control Plane Endpoint</Text>
        <TableRow
          label="Host"
          value={cluster?.controlPlaneEndpointHost}
          labelClassName={classes.indentedLabel}
        />
        <TableRow
          label="Port"
          value={cluster?.controlPlaneEndpointPort}
          labelClassName={classes.indentedLabel}
        />

        {isAwsCluster && (
          <>
            {/* VPC Config Rows */}

            <TableRows fields={vpcConfigFields} />

            <SubnetConfigsTableFields subnetConfigs={cluster?.infrastructure?.subnetConfigs} />

            {/* Load Balancer Config Rows */}
            <SubsectionHeader title="AWS Load Balancer Configuration" />
            <TableRows fields={loadBalancerFields} />
            <Divider className={classes.divider}></Divider>
            {/* Tags */}
            {!!cluster?.infrastructure?.tags && (
              <>
                <Text variant="caption1">Tags</Text>
                {renderResourceLabels({ separator: '=', type: 'panel', ellipsisAt: 80 })(
                  cluster?.infrastructure?.tags,
                )}
              </>
            )}
          </>
        )}
      </div>
    </Card>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    height: 'max-content',
  },
  table: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  indentedLabel: {
    marginLeft: '8px',
  },
  subsection: {
    marginTop: theme.spacing(2),
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(1, 0),
  },
}))
