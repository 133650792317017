import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { IEventSelector } from '../../../../model'

export const capiClusterEventsSelector: AppSelector<IEventSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.CapiEvents>(DataKeys.CapiEvents),
  (rawEvents) => {
    return rawEvents.map((event) => {
      return {
        ...event,
        id: event?.metadata?.uid,
        name: event?.metadata?.name,
      }
    })
  },
)
