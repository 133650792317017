import { makeStyles } from '@material-ui/styles'
import { ClusterCloudPlatforms } from 'app/constants'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Theme from 'core/themes/model'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isNilOrEmpty } from 'utils/fp'
import ClustersChecklists from './ClustersChecklists'
import { toggleRegion } from './ImportEKSClusterPage'
import RegionsChecklist from './RegionsChecklist'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  regionsAndClusters: {
    display: 'flex',
  },
  regions: {
    minWidth: 200,
  },
  clusters: {
    flexGrow: 1,
  },
}))

interface Props {
  providerType: ClusterCloudPlatforms
  wizardContext: any
  setWizardContext: any
  onNext: any
}

const RegionsAndClustersSelection = ({
  providerType,
  wizardContext,
  setWizardContext,
  onNext,
}: Props) => {
  const classes = useStyles()
  const validatorRef = useRef(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    if (isNilOrEmpty(wizardContext.selectedClusters)) {
      setErrorMessage('No clusters have been selected')
      return false
    }
    return true
  }, [wizardContext])

  useEffect(() => {
    onNext(submitStep)
  }, [submitStep])

  return (
    <ValidatedForm
      classes={{ root: classes.validatedFormContainer }}
      initialValues={wizardContext}
      onSubmit={setWizardContext}
      triggerSubmit={setupValidator}
      elevated={false}
    >
      <FormFieldSection title="Select Regions & Clusters">
        <div className={classes.regionsAndClusters}>
          <RegionsChecklist
            cloudProviderId={wizardContext.cloudProviderId}
            onChange={(value) => toggleRegion(value, wizardContext, setWizardContext)}
            value={wizardContext.regions}
            className={classes.regions}
            clusters={wizardContext.clusterList}
          />
          <ClustersChecklists
            cloudProviderId={wizardContext.cloudProviderId}
            onChange={(value, region) =>
              setWizardContext({
                selectedClusters: { ...wizardContext.selectedClusters, [region]: value },
              })
            }
            value={wizardContext.selectedClusters}
            selectedRegions={wizardContext.regions}
            className={classes.clusters}
            stack={providerType}
            onClustersLoad={(clusters) => setWizardContext({ clusterList: clusters })}
            toggleRegion={(value) => toggleRegion(value, wizardContext, setWizardContext)}
          />
        </div>
      </FormFieldSection>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </ValidatedForm>
  )
}

export default RegionsAndClustersSelection
