import { customValidator } from 'core/utils/fieldValidators'
import * as IpAddress from 'ip-address'
import { NetworkStackTypes } from '../constants'

const getIpValidator = (type = NetworkStackTypes.IPv4) =>
  ({
    [NetworkStackTypes.IPv4]: {
      cls: IpAddress.Address4,
      blockSize: [20, 32],
      subnetMask: [0, 32],
    },
    [NetworkStackTypes.IPv6]: {
      cls: IpAddress.Address6,
      blockSize: [116, 128],
      subnetMask: [0, 128],
    },
  }[type])

const isValidBlockSize = (size, stack) => {
  const {
    blockSize: [lowerBound, upperBound],
  } = getIpValidator(stack)
  return size >= lowerBound && size <= upperBound
}
const isValidSubnetMask = (size, stack) => {
  const {
    subnetMask: [lowerBound, upperBound],
  } = getIpValidator(stack)
  return size >= lowerBound && size <= upperBound
}

const IPValidator = customValidator((value, formValues) => {
  const { cls: IPValidatorCls } = getIpValidator(formValues.networkStack)
  return IPValidatorCls.isValid(value)
}, 'Invalid IP address provided')

const cidrIndependenceValidator = customValidator((value, formValues) => {
  const { cls: IPValidatorCls } = getIpValidator(formValues.networkStack)
  const containersIPInstance = new IPValidatorCls(formValues.containersCidr)
  const servicesIPInstance = new IPValidatorCls(value)

  return (
    servicesIPInstance.isInSubnet(containersIPInstance) === false &&
    containersIPInstance.isInSubnet(servicesIPInstance) === false
  )
}, 'Services CIDR must be an independent subnet of Containers CIDR')

const ipv6BlockSizeValidator = customValidator((value, formValues) => {
  const blockSize = parseInt(value)
  return isValidBlockSize(blockSize, formValues.networkStack)
}, 'Block Size must be in the range 116 -> 128')

const ipv4BlockSizeValidator = customValidator((value, formValues) => {
  const blockSize = parseInt(value)
  return isValidBlockSize(blockSize, formValues.networkStack)
}, 'Block Size must be in the range 20 -> 32')

const ipv6SubnetMaskSizeValidator = customValidator((value, formValues) => {
  const subnetMask = parseInt(`${value}`.split('/')[1])
  return isValidSubnetMask(subnetMask, formValues.networkStack)
}, 'Subnet Mask must be in the range 116 -> 128')

const ipv4SubnetMaskSizeValidator = customValidator((value, formValues) => {
  const subnetMask = parseInt(`${value}`.split('/')[1])
  return isValidSubnetMask(subnetMask, formValues.networkStack)
}, 'Subnet Mask must be in the range 20 -> 32')

const calicoBlockSizeCIDRValidator = customValidator((value, formValues) => {
  const cidrRange = formValues.containersCidr || formValues.clusterPodCidr
  const blockSize = parseInt(`${cidrRange}`.split('/')[1])
  if (!isValidSubnetMask(blockSize, formValues.networkStack)) {
    return false
  }
  return value >= blockSize
}, 'Calico Block Size must not conflict with the Container CIDR subnet mask')

const secondaryCIDRBlockValidator = customValidator((value, formValues) => {
  const { cls: IPValidatorCls } = getIpValidator(formValues.networkStack)
  const ipInstance = new IPValidatorCls(value)
  return (
    ipInstance.isInSubnet(new IPValidatorCls('100.64.0.0/10')) === true ||
    ipInstance.isInSubnet(new IPValidatorCls('198.19.0.0/16')) === true
  )
}, 'Must be within the 100.64.0.0/10 or 198.19.0.0/16 range.')

export const ipValidators = {
  [NetworkStackTypes.IPv4]: {
    cidrIndependenceValidator,
    calicoBlockSizeCIDRValidator,
    subnetMaskSizeValidator: ipv4SubnetMaskSizeValidator,
    ipValidator: IPValidator,
    blockSizeValidator: ipv4BlockSizeValidator,
    secondaryCIDRBlockValidator,
  },
  [NetworkStackTypes.IPv6]: {
    cidrIndependenceValidator,
    calicoBlockSizeCIDRValidator,
    subnetMaskSizeValidator: ipv6SubnetMaskSizeValidator,
    ipValidator: IPValidator,
    blockSizeValidator: ipv6BlockSizeValidator,
    secondaryCIDRBlockValidator,
  },
}
export const arnAwsValidator = customValidator((value) => {
  const regex = /^(arn:(?:aws|aws-cn|aws-us-gov):[\w\d-]+:[\w\d-]*:\d{0,12}:[\w\d-]*\/?[\w\d-]*)(\/.*)?.*$/
  return value.match(regex)
}, 'Invalid AWS ARN Value')

export const ipv4CidrValidator = customValidator((value) => {
  if (!value) return true
  const regex = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/
  return value.match(regex)
}, 'Invalid IP/CIDR  Notation')

// This is not a comprehensive validator, using this because ssh-pub-key-validation stopped working
export const sshKeyValidator = customValidator((value) => {
  const regex = /^ssh-rsa\s+[A-Za-z0-9+/]+[=]{0,3}(\s+.+)?\s*$/
  return value.match(regex)
}, 'You must enter a valid SSH key')
