import React, { useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import Grid from 'core/elements/grid'
import { castFuzzyBool } from 'utils/misc'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import { partition, sort } from 'ramda'
import Badge from 'core/elements/badge'
import PieUsageWidget, { PieLegend } from 'core/components/widgets/PieUsageWidget'
import PieGraph, { PieDataEntry } from 'core/components/graphs/PieGraph'
import Badges from 'core/elements/badge/Badges'
import StatusCondViewToggleButtonGroup from './StatusCondViewToggleButtonGroup'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import ClusterStatusSpan from '../../ClusterStatusSpan'
import { isNilOrEmpty } from 'utils/fp'
import { IClusterStatus } from '../../model'
import { sortStatusCondsBySeverity } from '../helpers'

const getPieChartData = (conditions = []) => {
  const basePieData = {
    True: {
      name: 'True',
      color: 'fadedSuccess',
      value: 0,
    },
    False: {
      name: 'False',
      color: 'fadedError',
      value: 0,
    },
  }
  const data = conditions?.reduce((acc, condition) => {
    const status = condition.status
    acc[status]['value'] += 1
    return acc
  }, basePieData)
  return [...Object.values(data)]
}

const StatusConditionCardHeader = ({ resourceType, handleViewChange }) => {
  const classes = useStyles({})
  return (
    <div className={classes.cardHeader}>
      <Text variant="body1">
        <b>{resourceType}</b>
        {` Status Conditions`}
      </Text>
      <StatusCondViewToggleButtonGroup handleChange={handleViewChange} />
    </div>
  )
}

const StatusOverview = ({ pieChartData, trueBadges, falseBadges }) => {
  const classes = useStyles({})
  return (
    <>
      <PieUsageWidget
        data={pieChartData}
        className={classes.pieWidget}
        showPercent={false}
        arcWidth={45}
        sideLength={175}
      />
      <div className={classes.info}>
        <div>
          <Text variant="subtitle2">True</Text>
          <div className={classes.badges}>
            <Badges values={trueBadges} variant="success" maxVisible={5} bold />
          </div>
        </div>
        <div>
          <Text variant="subtitle2">False</Text>
          <div className={classes.badges}>
            <Badges values={falseBadges} variant="error" maxVisible={5} bold />
          </div>
        </div>
      </div>
    </>
  )
}

const conditionsFilterFn = (condition) => !!castFuzzyBool(condition.status)

const StatusCell = ({ value }) => {
  let status: IClusterStatus = 'unknown'
  switch (value) {
    case 'True':
      status = 'ok'
      break
    case 'False':
      status = 'fail'
      break
    default:
      status = 'unknown'
      break
  }
  return <ClusterStatusSpan status={status}>{value}</ClusterStatusSpan>
}
const cellFormatFn = (value) => (isNilOrEmpty(value) ? '-' : value)

export const statusConditionColumns = [
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
    CellComponent: StatusCell,
  },
  { key: 'severity', label: 'Severity', formatFn: cellFormatFn },
  {
    key: 'lastTransitionTime',
    label: 'Last Transition Time',
    CellComponent: DateAndTime,
  },
  { key: 'reason', label: 'Reason', formatFn: cellFormatFn },
  { key: 'message', label: 'Message', formatFn: cellFormatFn },
]

export default function StatusConditionsCard({ resourceType, conditions = [] }) {
  const [activeView, setActiveView] = useState('detailed')
  const classes = useStyles({ activeView })

  const handleViewChange = (view) => {
    setActiveView(view)
  }

  const header = (
    <StatusConditionCardHeader resourceType={resourceType} handleViewChange={handleViewChange} />
  )
  const [trueConditions, falseConditions] = useMemo(
    () => partition(conditionsFilterFn, conditions),
    [conditions],
  )
  const sortedConditions = useMemo(
    () => [...sort(sortStatusCondsBySeverity, falseConditions), ...trueConditions],
    [falseConditions, trueConditions],
  )

  const trueBadges = useMemo(
    () =>
      trueConditions.map(({ type, lastTransitionTime }) => ({
        text: type,
        tooltipText: `Last Transition Time: ${lastTransitionTime}`,
      })),
    [trueConditions],
  )
  const falseBadges = useMemo(
    () =>
      falseConditions.map(({ type, lastTransitionTime }) => ({
        text: type,
        tooltipText: `Last Transition Time: ${lastTransitionTime}`,
      })),
    [falseConditions],
  )
  const pieChartData = useMemo(() => getPieChartData(conditions) as PieDataEntry[], [conditions])
  return (
    <Card className={classes.card} title={header} withCustomBody>
      <div className={classes.cardBody}>
        {activeView === 'overview' && (
          <StatusOverview
            pieChartData={pieChartData}
            trueBadges={trueBadges}
            falseBadges={falseBadges}
          />
        )}
        {activeView === 'detailed' && (
          <Grid
            uniqueIdentifier="type"
            data={sortedConditions}
            columns={statusConditionColumns}
            rowsPerPage={5}
            showResultsPerPageDropdown={false}
            disableToolbar
          />
        )}
      </div>
    </Card>
  )
}

interface StyleProps {
  activeView?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {},
  cardHeader: {
    padding: '16px 32px',
    borderBottom: `1px solid ${theme.components.card.border}`,
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
  },
  cardBody: {
    display: 'grid',
    gridTemplateColumns: ({ activeView }) =>
      activeView === 'overview' ? 'max-content 1fr' : '1fr',
    gridGap: theme.spacing(5),
    padding: ({ activeView }) => (activeView === 'overview' ? '32px' : '0px'),
    '& .grid-container': {
      border: 0,
    },
    '& .paginationControls': {
      width: 'max-content',
    },
  },
  info: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, minmax(55px, max-content))',
    gridGap: theme.spacing(3),
  },
  badges: {
    display: 'flex',
    flexFlow: 'wrap',
    maxWidth: 'max-content',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  pieWidget: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
    '& .pieLegend': {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
    },
  },
}))
