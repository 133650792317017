import React, { useMemo } from 'react'
import { pathStrOr } from 'utils/fp'
import { uniq } from 'ramda'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listCloudProviderRegionDetails } from '../../cloudProviders/new-actions'
import { cloudProviderRegionDetailsSelector } from '../../cloudProviders/selectors'
interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
}

export default function AzureResourceGroupPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })
  // Azure might have more than 1 virtualNetwork with the same resourceGroup be sure to use 'uniq'
  const items = useMemo<{ label: string; value: string }[]>(
    () =>
      uniq(
        pathStrOr([], '0.virtualNetworks', regionDetails).map((x) => ({
          label: x.resourceGroup,
          value: x.resourceGroup,
        })),
      ),
    [regionDetails],
  )

  return <AsyncDropdown loading={loadingCloudProviderRegionDetails} items={items} {...rest} />
}
