import React from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'

export default function ClusterTemplatesField({ items, onChange }) {
  return (
    <DropdownField
      DropdownComponent={AsyncDropdown}
      id="template"
      label="Cluster Template"
      items={items}
      onChange={onChange}
    />
  )
}
