import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import Picklist from 'core/elements/dropdown/AsyncDropdown'

const operatingSystemOptions = [
  { label: 'Ubuntu', value: 'ubuntu' },
  { label: 'CentOS', value: 'centos' },
]

interface Props {
  id?: string
  onChange?: (value) => void
  options?: any[]
  value?: string
}

const OperatingSystemField = ({
  id = 'ami',
  onChange,
  value,
  options = operatingSystemOptions,
}: Props) => (
  <PicklistField
    DropdownComponent={Picklist}
    id={id}
    label="Operating System"
    items={options}
    value={value}
    tooltip="Operating System / AMI"
    onChange={onChange}
    required
  />
)

export default OperatingSystemField
