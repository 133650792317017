import React, { FC } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import ExternalLink from 'core/components/ExternalLink'
import {
  ImportedClusterSelector,
  importedClusterStatusMap,
} from 'app/plugins/infrastructure/components/importedClusters/model'
import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { clientStoreKey } from 'core/client/clientReducers'
import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import Alert from 'core/components/Alert'
import Tooltip from 'core/elements/tooltip'
import Card from 'core/elements/card'

const getGrafanaUrl = (host, cluster) =>
  `${host}/k8s/v1/clusters/${cluster.uuid}/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:grafana-ui:80/proxy/`

const HeaderCard: FC<{ title: string; cluster: ImportedClusterSelector }> = ({
  title,
  cluster,
  children,
}) => {
  const classes = useStyles()
  const baseEndpoint = useSelector(pathOr('', [clientStoreKey, 'endpoints', 'qbert'])).match(
    /(.*?)\/qbert/,
  )[1]

  const phase = cluster?.status?.phase
  const hasApiServer = cluster?.status?.controlPlaneEndpoint
  return (
    <Card
      className={classes.headerCard}
      footer={
        cluster?.hasPrometheus && !cluster?.isPrivateVpc ? (
          <footer className={classes.footer}>
            <ExternalLink
              icon="arrow-up-right-from-square"
              className={classes.grafanaLink}
              url={getGrafanaUrl(baseEndpoint, cluster)}
            >
              View Grafana
            </ExternalLink>
          </footer>
        ) : null
      }
      withCustomFooter
    >
      <header className={classes.header}>
        <Tooltip message={title}>
          <Text variant="subtitle1" component="h1">
            {title}
          </Text>
        </Tooltip>
      </header>
      <article className={classes.article}>
        <Text variant="body2" className={classes.uppercase}>
          {cluster?.providerType} - {cluster?.kubeVersion}
        </Text>
        <Text variant="body2">{cluster?.vpcText}</Text>
        <Text variant="body2" className={classes.capitalize}>
          {cluster.region}
        </Text>
      </article>
      {cluster?.isPrivateVpc && (
        <Alert
          title="The cluster's API server is private"
          message="All actions are disabled and workload information is not available."
        />
      )}
      {!cluster?.isPrivateVpc && (
        <div className={classes.overview}>
          <Text variant="caption1">Overview</Text>
          <ClusterStatusSpan
            title={phase}
            variant="header"
            status={importedClusterStatusMap[phase]}
            iconStatus
            rootClassName={classes.statusContainer}
            className={classes.status}
            iconPosition={'right'}
          >
            {phase}
          </ClusterStatusSpan>
          <ClusterStatusSpan
            title="Api Server Connected"
            variant="header"
            status={!cluster?.ecoInstalled ? 'unknown' : hasApiServer ? 'ok' : 'error'}
            iconStatus
            rootClassName={classes.statusContainer}
            className={classes.status}
            iconPosition={'right'}
          >
            API Server Connected
          </ClusterStatusSpan>
        </div>
      )}
    </Card>
  )
}

export default HeaderCard

const useStyles = makeStyles<Theme>((theme) => ({
  headerCard: {
    width: 424,
    minHeight: 210,
    marginBottom: theme.spacing(3),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  article: {
    display: 'grid',
    gridGap: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  footer: {
    padding: '24px 32px',
    borderTop: `1px solid ${theme.components.card.border}`,
  },
  grafanaLink: {
    ...theme.typography.body2,
    marginTop: 4,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  overview: {
    display: 'grid',
    margin: theme.spacing(1, 0),
    '& > span': {
      marginBottom: theme.spacing(1),
    },
  },
  statusContainer: {
    '& > div': {
      width: '100%',
    },
  },
  status: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
}))
