import React, { useMemo, useEffect } from 'react'
import { pick, prop, isNil, both, always, not } from 'ramda'
import { castBoolToStr } from 'utils/misc'
import NodeDeAuthDialog from './NodeDeAuthDialog'
import RemoteSupportDialog from 'app/plugins/infrastructure/components/nodes/RemoteSupportDialog'
import { listTablePrefs, allKey, TablePrefsParams } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import NodeAuthDialog from './NodeAuthDialog'
import { INodesSelector } from './model'
import NodeForceRemoveDialog from './NodeForceRemoveDialog'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { sessionStoreKey, SessionState } from 'core/session/sessionReducers'
import ListContainer from 'core/containers/ListContainer'
import DocumentMeta from 'core/components/DocumentMeta'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listK8sNodes, listNodes } from './new-actions'
import { makeParamsNodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import { useAppSelector } from 'app/store'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import NodeDetailCell from 'app/plugins/infrastructure/components/nodes/node-cells/NodeDetailCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import NodeStatusCell from 'app/plugins/infrastructure/components/nodes/node-cells/NodeStatusCell'
import { NodeHealthStatusCell } from 'app/plugins/infrastructure/components/nodes/node-cells/NodeHealthStatusCell'
import NodeApiHealthCell from 'app/plugins/infrastructure/components/nodes/node-cells/NodeApiHealthCell'
import K8sNodeStatusCell from 'app/plugins/infrastructure/components/nodes/node-cells/K8sNodeStatusCell'
import LogsCell from 'app/plugins/infrastructure/components/nodes/node-cells/LogsCell'
import NetworkInterfacesCell from 'app/plugins/infrastructure/components/nodes/node-cells/NetworkInterfacesCell'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import UUIDCell from 'app/plugins/infrastructure/components/common/cells/UUIDCell'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NodesStatePicklist from 'app/plugins/infrastructure/components/nodes/NodesStatePicklist'
import NodeRolesPicklist from 'app/plugins/infrastructure/components/nodes/NodeRolesPicklist'
import InferActionParams from 'core/actions/InferActionParams'
import { listCapiHosts } from '../clusters/capi/details/overview/hosts/actions'
import { makeParamsCapiHostsSelector } from '../clusters/capi/details/overview/hosts/selectors'
import { ICapiHostsSelector } from '../clusters/capi/details/overview/hosts/model'
import { AwsClusterTypes } from '../clusters/capi/model'
import { ClusterAddon } from '../clusters/cluster-addons/model'
import { isUnassignedNode } from './helpers'

type ModelDataKey = DataKeys.Nodes
type SelectorModel = INodesSelector & ICapiHostsSelector
type ActionParams = InferActionParams<typeof listNodes>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  state: string
  role: string
  clusterId: string
}

const filterEksClusters = (clusters) =>
  clusters?.filter((cluster) => cluster?.infrastructureType !== AwsClusterTypes.EKS)

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'uuid',
    label: 'UUID',
    tooltip:
      'This is the unique ID generated by PMK for this node. You will need this ID to perform any API based operations with PMK',
    CellComponent: UUIDCell,
    display: false,
  } as GridViewColumn<SelectorModel, 'uuid'>,
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: NodeDetailCell,
  },
  {
    key: 'clusterName',
    label: 'Cluster',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterUuid, clusterName, clusterId }) =>
        clusterUuid
          ? routes.cluster.managed.qbert.detail.path({ id: clusterUuid })
          : clusterName
          ? routes.cluster.managed.capi.details.path({ id: clusterId })
          : null,
    }),
  } as GridViewColumn<SelectorModel, 'clusterName'>,
  {
    key: 'isMaster',
    label: 'Role',
    width: 'small',
    formatFn: (isMaster, node) =>
      isNil(node.uuid) || isUnassignedNode(node) ? '' : isMaster ? 'Master' : 'Worker',
  } as GridViewColumn<SelectorModel, 'isMaster'>,
  { key: 'connectionStatus', label: 'Connection status', CellComponent: NodeStatusCell },
  {
    key: 'platform9ComponentsStatus',
    label: 'Platform9 Components',
    CellComponent: NodeHealthStatusCell,
  },
  { key: 'apiResponding', label: 'API Server Health', CellComponent: NodeApiHealthCell },
  {
    key: 'ready',
    label: 'K8s Node Status',
    CellComponent: K8sNodeStatusCell,
    display: isAdmin(),
  } as GridViewColumn<SelectorModel, 'ready'>,
  { key: 'logs', label: 'Logs', CellComponent: LogsCell } as GridViewColumn<SelectorModel, 'logs'>,
  { key: 'nodeKubeVersion', label: 'Node Kube Version' },
  { key: 'primaryIp', label: 'Network Interfaces', CellComponent: NetworkInterfacesCell },
  { key: 'usage', label: 'Resource Utilization', CellComponent: StatsCell } as GridViewColumn<
    SelectorModel,
    'resourceUtilization'
  >,
  { key: 'operatingSystem', label: 'Operating System' },
  { key: 'isSpotInstance', label: 'Spot Instance?', display: false, formatFn: castBoolToStr() },
  {
    key: 'assignedRoles',
    label: 'Assigned Roles',
    formatFn: (roles) => (roles ? roles.join(', ') : '-'),
  } as GridViewColumn<SelectorModel, 'assignedRoles'>,
]

const batchActions: GridBatchActionSpec<SelectorModel>[] = [
  {
    cond: isAdmin,
    icon: 'headset',
    label: 'Remote Support',
    BatchActionButton: getGridDialogButton(RemoteSupportDialog),
  },
  {
    cond: ([node]) => !node.isAuthorized,
    icon: 'plus-circle',
    label: 'Authorize',
    BatchActionButton: getGridDialogButton(NodeAuthDialog),
  },
  {
    cond: ([node]) => node.isAuthorized && !node.clusterUuid,
    icon: 'trash-alt',
    label: 'Deauthorize',
    BatchActionButton: getGridDialogButton(NodeDeAuthDialog, null, ([cluster], isDisabled) => ({
      tooltip: isDisabled
        ? "You can only de-authorize a node when it's not associated with any cluster. Detach the node from the cluster first."
        : null,
    })),
  },
  {
    cond: ([node]) => node.isAuthorized && node.cloudProviderType === CloudProviders.BareOS,
    icon: 'minus-hexagon',
    label: 'Force Remove',
    BatchActionButton: getGridDialogButton(
      NodeForceRemoveDialog,
      null,
      ([cluster], isDisabled) => ({
        tooltip: isDisabled
          ? 'Force removal of a node is only available for BareOS cluster nodes.'
          : null,
      }),
    ),
  },
]

const searchTargets = ['name', 'uuid']

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Nodes', listTablePrefs)
const columnsOrder = ['uuid', 'name', 'clusterName']

const defaultParams: Params = {
  state: allKey,
  role: allKey,
  clusterId: allKey,
}
const nodesSelector = makeParamsNodesSelector()
const capiHostsSelector = makeParamsCapiHostsSelector()
const requiredParams: Array<keyof ActionParams> = ['clusterId']

export default function NodesListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)

  const {
    message: loadingK8NodesMessage,
    loading: loadingK8Nodes,
    reload: reloadK8sNodes,
  } = useListAction(listK8sNodes)
  const { message, loading, reload } = useListAction(listNodes, {
    params,
    requiredParams,
  })
  const { loading: loadingCapiHosts } = useListAction(listCapiHosts)
  const nodes = useSelectorWithParams(nodesSelector, params)
  const hosts = useSelectorWithParams(capiHostsSelector, params)

  useEffect(() => {
    // Without this, hard refreshing on this page, the k8s nodes will not be loaded properly
    // due to how the action pulls clusters from store
    reloadK8sNodes(true)
  }, [])

  const data = useMemo(() => [...nodes, ...hosts], [nodes, hosts])
  const filters = useMemo(
    () => [
      {
        columnKey: 'clusterUuid',
        FilterComponent: (props) => (
          <ClusterPicklist
            {...props}
            showNone
            noneLabel="No Cluster"
            filterFn={filterEksClusters}
          />
        ),
        initialValue: allKey,
        onChange: getParamsUpdater('clusterId'),
        controlled: true,
      } as GridFilterSpec<SelectorModel, Params, 'clusterUuid', 'clusterUuid', string, string>,
      {
        columnKey: 'isAuthorized',
        FilterComponent: NodesStatePicklist,
        onChange: getParamsUpdater('state'),
        controlled: true,
      } as GridFilterSpec<SelectorModel, Params, 'isAuthorized', 'state'>,
      {
        columnKey: 'isMaster',
        FilterComponent: NodeRolesPicklist,
        onChange: getParamsUpdater('role'),
        controlled: true,
      } as GridFilterSpec<SelectorModel, Params, 'isMaster', 'role'>,
    ],
    [],
  )
  const { features } = useAppSelector<SessionState>(prop(sessionStoreKey))
  return (
    <>
      <DocumentMeta title="Clusters" />
      <ListContainer<ModelDataKey, any>
        dataKey={DataKeys.Nodes}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading || loadingK8Nodes || loadingCapiHosts}
        loadingMessage={message || loadingK8NodesMessage}
        onRefresh={reload}
        data={data}
        columns={columns}
        filters={filters}
        addUrl={
          features?.experimental?.airgapped
            ? routes.nodes.addAirgapped.path()
            : routes.nodes.add.path()
        }
        addCond={isAdmin}
        addText="Onboard a Node"
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        columnsOrder={columnsOrder}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
