import React from 'react'
import TextField from 'core/components/validatedForm/TextField'
import { sshKeyValidator } from './validators'

export default ({
  wizardContext,
  setWizardContext,
  validations = [sshKeyValidator],
  required = true,
}) => (
  <TextField
    id="sshKey"
    value={wizardContext.sshKey}
    onChange={(value) => setWizardContext({ sshKey: value })}
    label="Public SSH key"
    info="Copy/paste your SSH public key"
    compact
    validations={wizardContext.sshKey || required ? validations : []}
    required={required}
  />
)
