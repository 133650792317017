// AWSManagedControlPlane Constants
export const apiServerLogsInfo = 'Logs pertaining to API requests to the cluster.'
export const auditLogsInfo = 'Logs pertaining to cluster access via the Kubernetes API.'
export const authenticatorLogsInfo = 'Logs pertaining to authentication requests into the cluster.'
export const controllerManagerLogsInfo = 'Logs pertaining to state of cluster controllers.'
export const schedulerLogsInfo = 'Logs pertaining to scheduling decisions.'

// NodeletControlPlane constants
export const publicMasterNodeFieldInfo = `Enabling this will put master nodes in public subnets of 
  the VPC and assign an elastic IP for the master nodes so that master nodes are publicly 
  accessible and users can ssh into them`

export const publicCidrInfo = `Determines the traffic that can reach your endpoint. By default, your
public endpoint is accessible from anywhere on the internet (0.0.0.0/0). If connecting from  
behind a firewall, you'll need the IP address range used by the client computers.`
export const cidrBlockInfo = `Secondary CIDR Block is the additional CIDR range to use for pod IPs. 
Must be within the 100.64.0.0/10 or 198.19.0.0/16 range.`

export const eksNodeGroupTypeInfo = 'Creates an EKS Managed Node Group'
export const amiVersionInfo = `AMI version defines the desired AMI release version. If no version number
  is supplied, then the latest version for the Kubernetes version will be used.`
export const makeNodesPublicTooltipDescription = `Enabling this will put worker nodes of this node group
  in public subnets of the VPC and assign an elastic IP for the nodes so that the nodes are 
  publicly accessible and users can ssh into them.`
export const taintsTooltipDescription = `Taints added here will be applied on creation to each node which 
  is part of this node group.`
export const labelsTooltipDescription = `Labels added here will be applied on creation to each node which
  is part of this node group.`

// only supporting k8s version 1.24 from PMK 5.9 onwards for CAPI clusters | https://platform9.atlassian.net/browse/UX-2753
export const CapiSupportedK8sVersions = ['v1.24', '1.24.7']
