import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

export const nodeletConfigActions = ActionsSet.make<DataKeys.NodeletConfigs>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.NodeletConfigs],
  cacheKey: DataKeys.NodeletConfigs,
})

export const listNodeletConfigs = nodeletConfigActions.add(
  new ListAction<DataKeys.NodeletConfigs>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get Nodelet Configs')
    return sunpike.getNodeletConfigs()
  }),
)

export const updateNodeletConfig = nodeletConfigActions.add(
  new UpdateAction<DataKeys.NodeletConfigs, { namespace: string; name: string; body: unknown }>(
    async ({ namespace, name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update Nodelet Config', { namespace, name, body })
      return sunpike.updateNodeletConfig(namespace, name, body)
    },
  ),
)

export const nodeletConfigTemplateActions = ActionsSet.make<DataKeys.NodeletConfigTemplates>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.NodeletConfigTemplates],
  cacheKey: DataKeys.NodeletConfigTemplates,
})

export const listNodeletConfigTemplates = nodeletConfigTemplateActions.add(
  new ListAction<DataKeys.NodeletConfigTemplates>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get Nodelet Config Templates')
    return sunpike.getNodeletConfigTemplates()
  }),
)

export const updateNodeletConfigTemplate = nodeletConfigTemplateActions.add(
  new UpdateAction<
    DataKeys.NodeletConfigTemplates,
    { namespace: string; name: string; body: unknown }
  >(async ({ namespace, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Nodelet Config', { namespace, name, body })
    return sunpike.updateNodeletConfigTemplate(namespace, name, body)
  }),
)

export const eksConfigActions = ActionsSet.make<DataKeys.EksConfigs>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.EksConfigs],
  cacheKey: DataKeys.EksConfigs,
})

export const listEksConfigs = eksConfigActions.add(
  new ListAction<DataKeys.EksConfigs>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get EKS Configs')
    return sunpike.getEksConfigs()
  }),
)

export const updateEksConfig = eksConfigActions.add(
  new UpdateAction<DataKeys.EksConfigs, { namespace: string; name: string; body: unknown }>(
    async ({ namespace, name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update EKS Config', { namespace, name, body })
      return sunpike.updateEksConfig(namespace, name, body)
    },
  ),
)

export const eksConfigTemplatesActions = ActionsSet.make<DataKeys.EksConfigTemplates>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.EksConfigTemplates],
  cacheKey: DataKeys.EksConfigTemplates,
})

export const listEksConfigTemplates = eksConfigTemplatesActions.add(
  new ListAction<DataKeys.EksConfigTemplates>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get EKS Config Templates')
    return sunpike.getEksConfigTemplates()
  }),
)

export const updateEksConfigTemplate = eksConfigTemplatesActions.add(
  new UpdateAction<DataKeys.EksConfigTemplates, { namespace: string; name: string; body: unknown }>(
    async ({ namespace, name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update EKS Config Template', { namespace, name, body })
      return sunpike.updateEksConfigTemplate(namespace, name, body)
    },
  ),
)
