import React from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

export default function BareOsAddOnBulletList() {
  const classes = useStyles()
  const addons = [
    'Prometheus',
    'Grafana',
    'Metal LB for Service Type Load Balancer (Optional)',
    'Multi-Master Virtual IP using VRRP (Optional)',
  ]

  return (
    <>
      <Text variant="subtitle2">{'Add Ons'}</Text>
      {addons.map((addon) => {
        return (
          <div key={addon} className={classes.container}>
            <FontAwesomeIcon className={classes.icon}>{'check'}</FontAwesomeIcon>
            <Text variant="body2">{addon}</Text>
          </div>
        )
      })}
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    color: theme.palette.secondary.contrastText,
    flex: `0 0 20px`,
    width: 20,
    height: 20,
    fontSize: 12,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
    gap: theme.spacing(1),
    margin: theme.spacing(2, 0),
  },
}))
