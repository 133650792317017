export interface ICapiAwsClusterSelector extends CapiAwsCluster {
  uuid: CapiAwsCluster['metadata']['uid']
  name: CapiAwsCluster['metadata']['name']
  namespace: CapiAwsCluster['metadata']['namespace']
  creationTimestamp: CapiAwsCluster['metadata']['creationTimestamp']
  vpcId: CapiAwsCluster['spec']['network']['vpc']['id']
  vpcCidrBlock: CapiAwsCluster['spec']['network']['vpc']['cidrBlock']
  internetGatewayId: CapiAwsCluster['spec']['network']['vpc']['internetGatewayId']
  controlPlaneLoadBalancer: CapiAwsCluster['spec']['controlPlaneLoadBalancer']
  identityRef: CapiAwsCluster['spec']['identityRef']
  region: CapiAwsCluster['spec']['region']
  sshKey: CapiAwsCluster['spec']['sshKeyName']
  ready: CapiAwsCluster['status']['ready']
  subnets: CapiAwsCluster['spec']['network']['subnets']
  subnetConfigs: SubnetConfigs
  availabilityZones: string[]
  awsAssumeRole: boolean
  cloudProviderName: string
  roleArn: string
  tags: CapiAwsCluster['spec']['additionalTags']
  resources: any[]
}

export interface SubnetConfigs {
  [availabilityZone: string]: SubnetType
}

interface SubnetType {
  publicSubnets?: Subnet[]
  privateSubnets?: Subnet[]
  cloudProviderName: string
}

export interface GetAwsClustersReponse {
  apiVersion: string
  items: CapiAwsCluster[]
  kind: string
  metadata: GetAwsClustersReponseMetadata
}

export interface CapiAwsCluster {
  apiVersion: string
  kind: string
  metadata: AWSClusterMetadata
  spec: Spec
  status: Status
}

export interface AWSClusterMetadata {
  creationTimestamp: string
  finalizers: string[]
  generation: number
  labels: Record<string, string>
  name: string
  namespace: string
  ownerReferences: OwnerReference[]
  resourceVersion: string
  selfLink: string
  uid: string
}
export interface OwnerReference {
  apiVersion: string
  blockOwnerDeletion: boolean
  controller: boolean
  kind: string
  name: string
  uid: string
}

export interface Spec {
  additionalTags: AdditionalTags
  bastion: Bastion
  controlPlaneEndpoint: ControlPlaneEndpoint
  controlPlaneLoadBalancer: ControlPlaneLoadBalancer
  identityRef: IdentityRef
  network: Network
  region: string
  sshKeyName: string
}

export interface AdditionalTags {
  ClusterName: string
}

export interface Bastion {
  allowedCIDRBlocks: string[]
  enabled: boolean
}

export interface ControlPlaneEndpoint {
  host: string
  port: number
}

export interface ControlPlaneLoadBalancer {
  crossZoneLoadBalancing: boolean
  healthCheckProtocol: string
  scheme: string
}

export interface IdentityRef {
  kind: string
  name: string
}

export interface Network {
  cni: Cni
  subnets: Subnet[]
  vpc: Vpc
}

export interface Cni {
  cniIngressRules: CniIngressRule[]
}

export interface CniIngressRule {
  description: string
  fromPort: number
  protocol: string
  toPort: number
}

export interface Subnet {
  availabilityZone: string
  cidrBlock: string
  id: string
  isPublic: boolean
  tags: { [key: string]: string }
}

export interface Vpc {
  availabilityZoneSelection: string
  availabilityZoneUsageLimit: number
  cidrBlock: string
  id: string
  internetGatewayId: string
  tags: Tags
}

export interface Tags {
  ClusterName: string
  Name: string
  'sigs.k8s.io/cluster-api-provider-aws/cluster/pmk-aws-1': string
  'sigs.k8s.io/cluster-api-provider-aws/role': string
}

export interface Status {
  conditions: Condition[]
  ready: boolean
}

export interface Condition {
  lastTransitionTime: string
  message?: string
  reason?: string
  severity?: string
  status: string
  type: string
}

export interface GetAwsClustersReponseMetadata {
  continue: string
  resourceVersion: string
  selfLink: string
}

export enum CapiOperation {
  Create = 'Create',
  Update = 'Update',
  PartialUpdate = 'PartialUpdate',
}

export enum CapiResourceKind {
  Cluster = 'Cluster',
  AWSCluster = 'AWSCluster',
  NodeletControlPlane = 'NodeletControlPlane',
  AWSManagedControlPlane = 'AWSManagedControlPlane',
  AWSMachineTemplate = 'AWSMachineTemplate',
  //   AWSClusterControllerIdentity = 'AWSClusterControllerIdentity',
  AWSClusterRoleIdentity = 'AWSClusterRoleIdentity',
  //   AWSClusterStaticIdentity = 'AWSClusterStaticIdentity',
  MachineDeployment = 'MachineDeployment',
  MachinePool = 'MachinePool',
  AWSMachinePool = 'AWSMachinePool',
  AWSManagedMachinePool = 'AWSManagedMachinePool',
  NodeletConfig = 'NodeletConfig',
  NodeletConfigTemplate = 'NodeletConfigTemplate',
  EksConfig = 'EKSConfig',
  EksConfigTemplate = 'EKSConfigTemplate',
  ClusterAddon = 'ClusterAddon',
}
