import React from 'react'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import {
  ImportedClusterSelector,
  IEditImportedClusterPageTabs,
  IAgentStatus,
} from 'app/plugins/infrastructure/components/importedClusters/model'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import { capitalizeString } from 'utils/misc'

const getIconColor = (status: IAgentStatus, theme: Theme) =>
  ({
    connected: theme.components.graph.success,
    errored: theme.components.graph.error,
    offline: theme.components.graph.unknown,
  }[status] || theme.components.graph.unknown)

const useStyles = makeStyles<Theme, { status?: IAgentStatus; installed: boolean }>((theme) => ({
  ecoStatus: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: 6,
  },
  icon: {
    color: ({ status, installed }) => getIconColor(!installed ? 'errored' : status, theme),
    position: 'relative',
    top: 1,
  },
}))

// @todo use ClusterStatus or a Badge?
export const EcoStatus = ({ status, installed, cluster }) => {
  const classes = useStyles({ status, installed })
  return (
    <Text className={classes.ecoStatus} variant="body2" component="p">
      <FontAwesomeIcon className={classes.icon} size="sm" solid>
        circle
      </FontAwesomeIcon>
      {installed ? (
        capitalizeString(status)
      ) : (
        <SimpleLink
          src={routes.cluster.imported.edit.path({
            id: cluster?.uuid,
            tab: IEditImportedClusterPageTabs.ECO,
          })}
        >
          Install ECO
        </SimpleLink>
      )}
    </Text>
  )
}

export default function EcoStatusCell({
  value: installed,
  item: cluster,
}: GridCellProps<ImportedClusterSelector>) {
  return <EcoStatus status={cluster.ecoStatus} installed={installed} cluster={cluster} />
}
