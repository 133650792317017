import React from 'react'
import { pathStrOr } from 'utils/fp'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { loadAwsRegionInfo } from '../../../../cloudProviders/new-actions'
import useListAction from 'core/hooks/useListAction'
import { awsRegionInfoSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import DataKeys from 'k8s/DataKeys'
import { useSelector } from 'react-redux'

// @todo fix these typings
interface Props {
  azs: unknown[]
  identityName: string
  roleArn: string
  region: string
}

export default function CapiAwsVpcPicklist({
  azs,
  identityName,
  roleArn,
  region,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading } = useListAction<DataKeys.AwsRegionInfo>(loadAwsRegionInfo, {
    params: { identityName, region, roleArn },
  })
  const regionInfo = useSelector(awsRegionInfoSelector)
  const vpcs = pathStrOr([], '0.vpcs', regionInfo)

  const hasAllAzs = (vpc) => {
    const azsInVpc = pathStrOr([], 'Subnets', vpc).map((x) => x.AvailabilityZone)
    return azs.every((az) => azsInVpc.includes(az))
  }

  const toOption = (vpc) => ({ label: `${vpc.VpcName}-${vpc.CidrBlock}`, value: vpc.VpcId })

  const options = vpcs.filter(hasAllAzs).map(toOption)

  return <AsyncDropdown {...rest} loading={loading} items={options} />
}
