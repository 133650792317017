import React, { useCallback, useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import RadioFields from 'core/components/validatedForm/radio-fields'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { NodeUpdateStrategyTypes } from './CapiMachineDeploymentNodeUpdateStrategies'
import { validators } from 'core/utils/fieldValidators'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'

const maxUnavailableValueTypeOptions = [
  { label: 'Count', value: NodeUpdateStrategyTypes.Count },
  { label: 'Percentage', value: NodeUpdateStrategyTypes.Percentage },
]

const labels = {
  count: 'Max Unavailable Nodes - Count',
  percentage: 'Max Unavailable Nodes - Percentage',
}

const getLabel = (updateStrategyType, value) =>
  `${labels[value]} ${updateStrategyType === value ? '*' : ''}`

interface Props {
  workerNodeGroup: any
  onChange: (fieldId: string) => (value: Record<string, unknown>) => void
  // Full page form or modal form
  containerType?: 'form' | 'modal'
}

export default function CapiAwsManagedMachinePoolNodeUpdateStrategies({
  workerNodeGroup,
  onChange,
  containerType = 'form',
}: Props) {
  const classes = useStyles()
  const { id, updateConfig } = workerNodeGroup
  const [updateStrategyType, setUpdateStrategyType] = useState(
    workerNodeGroup?.maxUnavailableType || NodeUpdateStrategyTypes.Count,
  )

  const handleChanges = useCallback(
    (field) => (value) => {
      if (field === 'maxUnavailableType') {
        const key =
          value === NodeUpdateStrategyTypes.Percentage
            ? 'maxUnavailablePercentage'
            : 'maxUnavailable'
        onChange('updateConfig')({
          [key]: updateConfig.maxUnavailablePercentage || updateConfig.maxUnavailable,
          maxUnavailable: null,
        })
      } else {
        onChange('updateConfig')({ [field]: value, maxUnavailabe: null })
      }
    },
    [onChange],
  )

  const strategies = useMemo(
    () => [
      {
        label: getLabel(updateStrategyType, NodeUpdateStrategyTypes.Count),
        value: NodeUpdateStrategyTypes.Count,
        extraContent: (
          <TextField
            id={`${id}-maxUnavailableNodes`}
            placeholder="Count"
            value={updateConfig?.maxUnavailable}
            onChange={(value) => handleChanges('maxUnavailable')(value)}
            type="number"
            disabled={updateStrategyType === NodeUpdateStrategyTypes.Percentage}
            required={updateStrategyType === NodeUpdateStrategyTypes.Count}
            info="Number of nodes that can be updated in parallel"
            min="0"
            max="100"
            validations={[validators.rangeValue(1, 100)]}
          />
        ),
      },
      {
        label: getLabel(updateStrategyType, NodeUpdateStrategyTypes.Percentage),
        value: NodeUpdateStrategyTypes.Percentage,
        extraContent: (
          <TextField
            // NOTE: maxUnavailablePrecentage is not a typo. It's the API that misspelled percentage
            id={`${id}-maxUnavailablePrecentage`}
            placeholder="Percentage"
            value={updateConfig?.maxUnavailablePrecentage}
            onChange={(value) => handleChanges('maxUnavailablePrecentage')(value)}
            disabled={updateStrategyType === NodeUpdateStrategyTypes.Count}
            required={updateStrategyType === NodeUpdateStrategyTypes.Percentage}
            type="number"
            info="Percentage of nodes that can be updated in parallel"
            min="0"
            max="100"
            step="10"
            validations={[validators.rangeValue(1, 100)]}
          />
        ),
      },
    ],
    [updateStrategyType, updateConfig],
  )

  return (
    <>
      {containerType === 'form' ? (
        <>
          <Text variant="caption1">Nodes Update Strategy</Text>
          <RadioFields
            id={`${id}-updateStrategyType`}
            className={classes.radioFields}
            value={updateStrategyType}
            options={strategies}
            onChange={(value: NodeUpdateStrategyTypes) => {
              setUpdateStrategyType(value)
            }}
          />{' '}
        </>
      ) : (
        <>
          <DropdownField
            DropdownComponent={Dropdown}
            id="maxUnavailableType"
            label="Max Unavailable - Type"
            value={updateStrategyType}
            items={maxUnavailableValueTypeOptions}
            onChange={(value: NodeUpdateStrategyTypes) => {
              setUpdateStrategyType(value)
              handleChanges('maxUnavailableType')(value)
            }}
          />
          {updateStrategyType === NodeUpdateStrategyTypes.Percentage && (
            <TextField
              // NOTE: maxUnavailablePrecentage is not a typo. It's the API that misspelled percentage
              id="maxUnavailablePrecentage"
              label="Max Unavailable - Value"
              icon={updateStrategyType === NodeUpdateStrategyTypes.Percentage ? 'percent' : null}
              value={updateConfig?.maxUnavailablePrecentage}
              onChange={(value) => handleChanges('maxUnavailablePrecentage')(value)}
              type="number"
              info="Percentage of nodes that can be updated in parallel"
              min="0"
              max="100"
              step="10"
              validations={[validators.rangeValue(1, 100)]}
              required
            />
          )}
          {updateStrategyType === NodeUpdateStrategyTypes.Count && (
            <TextField
              id="maxUnavailable"
              label="Max Unavailable - Value"
              value={updateConfig?.maxUnavailable}
              onChange={(value) => handleChanges('maxUnavailable')(value)}
              info="Number of nodes that can be updated in parallel"
              min="0"
              max="100"
              validations={[validators.rangeValue(1, 100)]}
              type="number"
              required
            />
          )}
        </>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  radioFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    alignItems: 'center',
    gridGap: theme.spacing(3),
  },
}))
