import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { CloudProviderRequirementTD } from 'k8s/components/common/CloudProviderRequirementTD'

export default function AwsMinimumPermissions() {
  const classes = useStyles()

  return (
    <>
      <Text variant="subtitle2">Minimum Permissions Required</Text>
      <table className={classes.table}>
        <tbody>
          <tr>
            <CloudProviderRequirementTD text="ELB Management" />
            <CloudProviderRequirementTD text="EC2 Instance Management" />
          </tr>
          <tr>
            <CloudProviderRequirementTD text="Route 53 DNS Configuration" />
            <CloudProviderRequirementTD text="EBS Volume Management" />
          </tr>
          <tr>
            <CloudProviderRequirementTD text="Access to 2 or more Availability Zones within the region" />
            <CloudProviderRequirementTD text="VPC Management" />
          </tr>
        </tbody>
      </table>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    minWidth: '100%',
    tableLayout: 'fixed',
    borderSpacing: 0,
    marginTop: theme.spacing(2),
    borderColor: theme.components.selectableCard.border,
    borderStyle: 'solid',
    borderWidth: '0 1px  1px  1px',
    borderRadius: 4,

    '& tr td': {
      padding: theme.spacing(1),
      border: 0,
      borderColor: theme.components.selectableCard.border,
      borderStyle: 'solid',
      '&:first-child': {
        borderTopWidth: '1px',
        borderRightWidth: '1px',
      },
      '&:last-child': {
        borderTopWidth: '1px',
      },
    },
  },
}))
