import { UserPreferences } from 'app/constants'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { getDeleteConfirmText } from 'core/components/CRUDListContainer'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { omit } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { emptyObj } from 'utils/fp'
import { deleteCloudProvider } from './new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'

const DeleteCloudProviderDialog = ({ rows: [cloudProvider], onClose }) => {
  const { update: deleteFn, updating: deletingCloudProvider } = useUpdateAction(deleteCloudProvider)

  const { prefs, fetchUserDefaults, updateUserDefaults } = useScopedPreferences('defaults')
  const cloudProviderDefaults = prefs?.[UserPreferences.CloudProviders] || emptyObj

  const deleteConfirmText = useMemo(() => getDeleteConfirmText([cloudProvider]), [cloudProvider])

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  const handleSubmit = async () => {
    await deleteFn(cloudProvider)
    if (cloudProviderDefaults[cloudProvider.uuid] !== undefined) {
      updateUserDefaults(
        UserPreferences.CloudProviders,
        omit([cloudProvider.uuid], cloudProviderDefaults),
        true,
      )
    }
    onClose(true)
  }

  return (
    <ConfirmationDialog
      text={deleteConfirmText}
      onConfirm={handleSubmit}
      onCancel={onClose}
      loading={deletingCloudProvider}
      open
    />
  )
}

export default DeleteCloudProviderDialog
