import React from 'react'
import Card from 'core/elements/card'
import PieUsageWidget from 'core/components/widgets/PieUsageWidget'
import { getClusterAddonHealthStatus } from '../helpers'
import { PieDataEntry } from 'core/components/graphs/PieGraph'

const getData = (addons = []) => {
  const basePieData = {
    Healthy: {
      name: 'Healthy',
      color: 'fadedSuccess',
      value: 0,
    },
    Installing: {
      name: 'Installing',
      color: 'fadedWarning',
      value: 0,
    },
    Error: {
      name: 'Error',
      color: 'fadedError',
      value: 0,
    },
    Unknown: {
      name: 'Unknown',
      color: 'unknown',
      value: 0,
    },
  }
  const data = addons?.reduce((acc, addon) => {
    const status = getClusterAddonHealthStatus(addon)
    acc[status]['value'] += 1
    return acc
  }, basePieData)
  return [...Object.values(data)]
}

export default function AddonHealthStatusesCard(
  { addons, title = 'Add-ons Health Statuses' },
  compact = false,
) {
  const pieData = getData(addons) as PieDataEntry[]

  return compact ? (
    <PieUsageWidget data={pieData} primary={'Healthy'} />
  ) : (
    <Card title={title}>
      <PieUsageWidget data={pieData} primary={'Healthy'} />
    </Card>
  )
}
