import React from 'react'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import CodeBlock from 'core/components/CodeBlock'

const TagsField = ({
  info = 'Add tag metadata to this cluster',
  blacklistedTags = [],
  hideTitle = false,
  onChange = null,
}) => (
  <KeyValuesField
    id="tags"
    // label="Tags"
    info={info}
    blacklistedTags={blacklistedTags}
    hideTitle={hideTitle}
    addLabel="Add Tag"
    onChange={onChange}
  />
)

export default TagsField

export const FormattedTags = ({ tags }) => {
  const elems = tags.map((tag) => (
    <CodeBlock key={tag.key}>
      {tag.key} : {tag?.value}
    </CodeBlock>
  ))
  return <>{!tags.length ? '-' : elems}</>
}
