import React from 'react'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import SimpleLink from 'core/components/SimpleLink'
import ClusterVersionIcon from './ClusterVersionIcon'

export const Upgrading = ({ upgradingRoute }) => {
  const classes = useStyles()
  return (
    <>
      <FontAwesomeIcon size="md" className={classes.spinner} spin>
        circle-notch
      </FontAwesomeIcon>
      <Text variant="body2">Upgrading</Text>
      <SimpleLink src={upgradingRoute}>See more</SimpleLink>
    </>
  )
}

export const UpgradeFailed = ({ version = undefined, upgradeFailed, upgradeFailedRoute }) => {
  const classes = useStyles()
  return (
    <>
      <Tooltip message={`Upgrade Failed`}>
        <FontAwesomeIcon
          solid
          className={clsx(classes.icon, {
            upgradeFailed,
          })}
        >
          xmark-circle
        </FontAwesomeIcon>
      </Tooltip>
      {!!version && <Text variant="body2">{version}</Text>}
      <SimpleLink src={upgradeFailedRoute}>Retry</SimpleLink>
    </>
  )
}

export default function ClusterVersionStatus({
  version,
  clusterVersions,
  upgrading = false,
  upgradingRoute,
  upgradeAvailable = false,
  upgradeAvailableRoute,
  upgradeFailed = false,
  upgradeFailedRoute,
  continueUpgrade = false,
}) {
  const classes = useStyles()

  return (
    <>
      {upgrading ? (
        <div className={classes.upgradingContainer}>
          <Upgrading upgradingRoute={upgradingRoute} />
        </div>
      ) : (
        <>
          {upgradeFailed ? (
            <div className={classes.versionContainer}>
              <UpgradeFailed
                version={version}
                upgradeFailed={upgradeFailed}
                upgradeFailedRoute={upgradeFailedRoute}
              />
            </div>
          ) : (
            <div className={classes.versionContainer}>
              <ClusterVersionIcon version={version} clusterVersions={clusterVersions} />
              <Text variant="body2">{version}</Text>
              {upgradeAvailable && (
                <SimpleLink src={upgradeAvailableRoute}>
                  {continueUpgrade ? 'Continue Upgrade' : 'Upgrade'}
                </SimpleLink>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  versionContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content 1fr',
    gridGap: theme.spacing(1),
  },
  upgradingContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  spinner: {
    color: theme.palette.blue.main,
  },
  container: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  icon: {
    '&.upgradeAvailable': {
      color: theme.palette.blue.main,
    },
    '&.upgradeFailed': {
      color: theme.palette.red.main,
    },
  },
}))
