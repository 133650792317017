import { pathJoin } from 'utils/misc'
import { CalicoDetectionTypes } from './form-components/CalicoNetworkFields'
import store from 'app/store'
import { qbertEndpointSelector } from 'app/plugins/infrastructure/components/common/selectors'
import { preferencesStoreKey } from 'core/session/preferencesReducers'

export const getCalicoDetectionMethod = ({ calicoDetectionMethod, calicoDetectionMethodValue }) => {
  if (calicoDetectionMethodValue) return `${calicoDetectionMethod}=${calicoDetectionMethodValue}`
  return calicoDetectionMethod
}

export const getScopedQbertEndpoint = (qbertEndpoint) => {
  const state = store.getState()
  const currentTenantId =
    state?.[preferencesStoreKey]?.[state?.session?.username]?.root?.currentTenant
  return pathJoin(qbertEndpoint, currentTenantId)
}

export const getScopedClusterProxyEndpoint = (qbertEndpoint, cluster) =>
  `${getScopedQbertEndpoint(qbertEndpoint)}/clusters/${cluster.uuid}/k8sapi/api/v1`

export const getK8sDashboardLinkFromVersion = (qbertEndpoint, cluster) => {
  return `${getScopedClusterProxyEndpoint(
    qbertEndpoint,
    cluster,
  )}/namespaces/kubernetes-dashboard/services/https:kubernetes-dashboard:443/proxy/`
}
