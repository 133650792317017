import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterDomainPicklist from 'app/plugins/infrastructure/components/clusters/ClusterDomainPicklist'
import AwsClusterSshKeyPicklist from 'app/plugins/infrastructure/components/clusters/aws/AwsClusterSshKeyPicklist'
import Info from 'core/components/validatedForm/Info'
import ExternalLink from 'core/components/ExternalLink'
import { awsPrerequisitesLink, awsRoute53HelpLink } from 'k8s/links'
import { pathStrOr } from 'utils/fp'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { getIcon, getIconClass, RegionAvailability, setCloudProviderDefaults } from '../helpers'
import Button from 'core/elements/button'
import { CloudProviders, CloudDefaults } from '../model'
import CloudProviderRegionField from 'app/plugins/infrastructure/components/clusters/form-components/CloudProviderRegionField'
import SshKeyPicklist from 'app/plugins/infrastructure/components/clusters/form-components/SshKeyPicklist'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { loadCloudProviderDetails, loadCloudProviderRegionDetails } from '../new-actions'
import { cloudProviderDetailsSelector } from '../selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginTop: theme.spacing(3),
  },
  info: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  spaceRight: {
    marginRight: theme.spacing(4),
  },
  checkIcon: {
    color: theme.components.graph.success,
    marginRight: theme.spacing(1),
    alignSelf: 'center',
  },
  timesIcon: {
    color: theme.components.graph.error,
    marginRight: theme.spacing(1),
    alignSelf: 'center',
  },
  smallLink: {
    marginTop: '6px',
  },
  selectionArea: {
    display: 'grid',
    gap: 8,
  },
  setDefaultButton: {
    alignSelf: 'center',
    justifySelf: 'flex-start',
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  updateUserDefaults: any
  cloudDefaults: any
  showDefaultButtons: boolean
}

const getRoute53String = (classes, loading, regionId, available) =>
  loading || !regionId
    ? 'Select a region to validate Route53 Configuration'
    : available
    ? 'Route 53 Domains Available'
    : 'Route 53 Domains Unavailable'

const getSshKeyString = (classes, loading, regionId, available) =>
  loading || !regionId
    ? 'Select a Region to validate SSH Key availability'
    : available
    ? 'SSH Keys Detected'
    : 'No SSH Keys Detected'

const Route53Availability = ({ domains, loading, regionId }) => {
  const classes = useStyles({})
  const available = domains?.length

  return (
    <div className={classes.info}>
      <FontAwesomeIcon className={getIconClass(classes, loading, regionId, available)} solid>
        {getIcon(classes, loading, regionId, available)}
      </FontAwesomeIcon>
      <Text variant="body2" className={classes.spaceRight}>
        {getRoute53String(classes, loading, regionId, available)}
      </Text>
    </div>
  )
}

const SshKeyAvailability = ({ keypairs, loading, regionId }) => {
  const classes = useStyles({})
  const available = keypairs?.length

  return (
    <div className={classes.info}>
      <FontAwesomeIcon className={getIconClass(classes, loading, regionId, available)} solid>
        {getIcon(classes, loading, regionId, available)}
      </FontAwesomeIcon>
      <Text variant="body2" className={classes.spaceRight}>
        {getSshKeyString(classes, loading, regionId, available)}
      </Text>
    </div>
  )
}

const AwsCloudProviderVerification = ({
  wizardContext,
  setWizardContext,
  updateUserDefaults,
  cloudDefaults,
  showDefaultButtons,
}: Props) => {
  const classes = useStyles({})
  const [details, setDetails] = useState({})

  const { loading: regionsLoading } = useListAction(loadCloudProviderDetails, {
    params: {
      cloudProviderId: wizardContext.cloudProviderId,
    },
    requiredParams: ['cloudProviderId'],
  })
  const regions = useSelectorWithParams(cloudProviderDetailsSelector, {
    cloudProviderId: wizardContext.cloudProviderId,
  })

  const { update: loadRegions, updating: loading } = useUpdateAction(loadCloudProviderRegionDetails)

  useEffect(() => {
    const fetchRegionDetails = async () => {
      const { response: regionDetails } = await loadRegions({
        cloudProviderId: wizardContext.cloudProviderId,
        cloudProviderRegionId: wizardContext.region,
      })
      setDetails(regionDetails)
    }

    if (wizardContext.region && wizardContext.cloudProviderId) {
      fetchRegionDetails()
    }
  }, [wizardContext.region, wizardContext.cloudProviderId])

  const domains = pathStrOr([], 'domains', details)
  const keypairs = pathStrOr([], 'keyPairs', details)

  const handleSetUserDefault = (values) =>
    setCloudProviderDefaults(
      wizardContext.cloudProviderId,
      cloudDefaults,
      values,
      updateUserDefaults,
    )

  return (
    <>
      <FormFieldSection
        title="AWS Region Availability"
        className={classes.section}
        link={
          <ExternalLink textVariant="caption2" url={awsPrerequisitesLink}>
            AWS Help
          </ExternalLink>
        }
      >
        <Text variant="body2">
          Platform9 deploys Kubernetes clusters into specified AWS Regions.
        </Text>
        <div className={classes.selectionArea}>
          <CloudProviderRegionField
            cloudProviderType={CloudProviders.Aws}
            onChange={(value) => setWizardContext({ region: value })}
            wizardContext={wizardContext}
            values={wizardContext}
          />
          {showDefaultButtons && (
            <Button
              className={classes.setDefaultButton}
              disabled={
                !wizardContext.region ||
                cloudDefaults[CloudDefaults.Region] === wizardContext.region
              }
              onClick={() =>
                handleSetUserDefault({
                  [CloudDefaults.Region]: wizardContext.region,
                  [CloudDefaults.RegionLabel]: wizardContext.regionLabel,
                })
              }
            >
              Set As Default
            </Button>
          )}
          {wizardContext.cloudProviderId && !regionsLoading && (
            <RegionAvailability classes={classes} regions={regions} />
          )}
        </div>
      </FormFieldSection>
      <FormFieldSection
        title="AWS Native Clusters: Route53 Domains (Optional)"
        className={classes.section}
        link={
          <ExternalLink textVariant="caption2" url={awsPrerequisitesLink}>
            AWS Help
          </ExternalLink>
        }
      >
        <Text variant="body2">
          Platform9 can utilize Route53 Domains to route traffic to AWS Native Clusters. Route53 is
          optional and not required for EKS.
        </Text>
        <div className={classes.selectionArea}>
          <PicklistField
            DropdownComponent={ClusterDomainPicklist}
            id="awsDomain"
            label="Route 53 Domain"
            cloudProviderId={wizardContext.cloudProviderId}
            cloudProviderRegionId={wizardContext.region}
            disabled={!(wizardContext.cloudProviderId && wizardContext.region)}
            onChange={(value) =>
              setWizardContext({ awsDomain: value.domainId, awsDomainLabel: 'Route 53 Domain' })
            }
          />
          {showDefaultButtons && (
            <Button
              className={classes.setDefaultButton}
              disabled={
                !wizardContext.awsDomain ||
                cloudDefaults[CloudDefaults.Domain] === wizardContext.awsDomain
              }
              onClick={() =>
                handleSetUserDefault({
                  [CloudDefaults.Domain]: wizardContext.awsDomain,
                  [CloudDefaults.DomainLabel]: wizardContext.awsDomainLabel,
                })
              }
            >
              Set As Default
            </Button>
          )}
          <Route53Availability
            loading={loading}
            regionId={wizardContext.region}
            domains={domains}
          />
        </div>
        {wizardContext.region && !loading && !domains.length && (
          <Info>
            <div>No registered Route53 Domains have been detected.</div>
            <div>Please register at least one domain with AWS Route53 to use Platform9.</div>
            <ExternalLink className={classes.smallLink} url={awsRoute53HelpLink}>
              <Text variant="caption2" className={classes.smallLink}>
                How to register a Route 53 domain
              </Text>
            </ExternalLink>
          </Info>
        )}
      </FormFieldSection>
      <FormFieldSection
        title="AWS Native Clusters: SSH Keys"
        className={classes.section}
        link={
          <ExternalLink textVariant="caption2" url={awsPrerequisitesLink}>
            AWS Help
          </ExternalLink>
        }
      >
        <Text variant="body2">
          When building AWS Native Clusters, SSH Keys are required to access and configure EC2
          Instances. SSH Keys are not required to Manage EKS Clusters.
        </Text>
        <div className={classes.selectionArea}>
          <SshKeyPicklist
            dropdownComponent={AwsClusterSshKeyPicklist}
            values={wizardContext}
            info=""
            wizardContext={wizardContext}
            setWizardContext={setWizardContext}
            required={false}
          />
          {showDefaultButtons && (
            <Button
              className={classes.setDefaultButton}
              disabled={
                !wizardContext.sshKey ||
                cloudDefaults[CloudDefaults.SshKey] === wizardContext.sshKey
              }
              onClick={() => handleSetUserDefault({ [CloudDefaults.SshKey]: wizardContext.sshKey })}
            >
              Set As Default
            </Button>
          )}
          <SshKeyAvailability
            loading={loading}
            regionId={wizardContext.region}
            keypairs={keypairs}
          />
        </div>
      </FormFieldSection>
    </>
  )
}

export default AwsCloudProviderVerification
