import React, { useCallback, useState } from 'react'
import ExternalLink from 'core/components/ExternalLink'
import Checkbox from 'core/components/validatedForm/CheckboxField'
import useDataUpdater from 'core/hooks/useDataUpdater'
import { updateRemoteSupport } from 'app/plugins/infrastructure/components/common/actions'
import { remoteMonitoringDocsLink } from 'k8s/links'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import Text from 'core/elements/Text'
import ModalForm from 'core/elements/modal/ModalForm'

export default function RemoteSupportDialog({
  rows: [node],
  onClose,
}: GridDialogProps<INodesSelector>) {
  const [updateNode, updatingNode] = useDataUpdater(updateRemoteSupport, onClose)
  const [enableSupport, setEnableSupport] = useState(node.combined.supportRole)
  const handleSubmit = useCallback(
    async ({ enableSupport }) => {
      // If no change, just close the modal
      if (enableSupport === node.combined.supportRole) {
        return onClose()
      }
      await updateNode({ id: node.uuid, enableSupport })
    },
    [node],
  )

  const initialValues = { enableSupport }

  return (
    <ModalForm
      open
      onClose={onClose}
      panel="dialog"
      title="Configure Advanced Remote Support"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitting={updatingNode}
    >
      <Text variant="body2">
        You may enable or disable Advanced Remote Support on this node. Please refer to the
        following <ExternalLink url={remoteMonitoringDocsLink}>article</ExternalLink> for more
        information.
      </Text>
      <br />
      <Checkbox
        id="enableSupport"
        onChange={() => setEnableSupport(!enableSupport)}
        label="Enable Advanced Remote Support"
      />
    </ModalForm>
  )
}
