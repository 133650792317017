import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

export const awsClusterRoleIdentityActions = ActionsSet.make<DataKeys.AwsClusterRoleIdentities>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.AwsClusterRoleIdentities],
  cacheKey: DataKeys.AwsClusterRoleIdentities,
})

export const listAwsClusterRoleIdentities = awsClusterRoleIdentityActions.add(
  new ListAction<DataKeys.AwsClusterRoleIdentities>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get CAPI AWSClusterStaticIdentity', params)
    return sunpike.getAwsClusterRoleIdentities()
  }),
)

export const updateAwsClusterRoleIdentities = awsClusterRoleIdentityActions.add(
  new UpdateAction<DataKeys.AwsClusterRoleIdentities, { name: string; body: unknown }>(
    async ({ name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update AWSClusterStaticIdentity', { name, body })
      return sunpike.updateAwsClusterRoleIdentity(name, body)
    },
  ),
)

export const awsClusterStaticIdentityActions = ActionsSet.make<DataKeys.AwsClusterStaticIdentities>(
  {
    uniqueIdentifier: 'metadata.uid',
    entityName: entityNamesByKey[DataKeys.AwsClusterStaticIdentities],
    cacheKey: DataKeys.AwsClusterStaticIdentities,
  },
)

export const listAwsClusterStaticIdentities = awsClusterStaticIdentityActions.add(
  new ListAction<DataKeys.AwsClusterStaticIdentities>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get CAPI AWSClusterStaticIdentity', params)
    return sunpike.getAwsClusterStaticIdentities()
  }),
)

export const updateAwsClusterStaticIdentities = awsClusterStaticIdentityActions.add(
  new UpdateAction<DataKeys.AwsClusterStaticIdentities, { name: string; body: unknown }>(
    async ({ name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update AWSClusterStaticIdentity', { name, body })
      return sunpike.updateAwsClusterStaticIdentity(name, body)
    },
  ),
)
