import { memoize } from 'utils/misc'

export const generateRegionSummaryFields = memoize((clustersByRegion = {}) => {
  const toReturn = {
    regionFormattedNames: {},
    regionCalloutFields: [],
  }
  for (const id of Object.keys(clustersByRegion)) {
    toReturn.regionFormattedNames[id] = id
    toReturn.regionCalloutFields.push(`selectedClusters.${id}`)
  }
  return toReturn
})
