import React, { useMemo, useState } from 'react'
import { pathStrOr, projectAs } from 'utils/fp'
import { propEq } from 'ramda'
import MultiDropdown from 'core/elements/dropdown/MultiDropdown'
import useListAction from 'core/hooks/useListAction'
import { loadAwsRegionInfo } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { awsRegionInfoSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import DropdownField from 'core/components/validatedForm/DropdownField'
import { useSelector } from 'react-redux'
import DataKeys from 'k8s/DataKeys'
import Text from 'core/elements/Text'

// @todo fix these typings
interface Props {
  label: string
  az: string
  type: string
  identityName: string
  roleArn: string
  region: string
  vpcId: string
  onChange: (value: string[]) => void
}

/* Displays multiple picklists to allow the user to specify a subnet for each AZ in the VPC */
export default function CapiAwsSubnetsMultiDropdown({
  az,
  label,
  type,
  identityName,
  roleArn,
  region,
  onChange,
  vpcId,
  ...rest
}: Props) {
  if (!vpcId) return null
  const { loading } = useListAction<DataKeys.AwsRegionInfo>(loadAwsRegionInfo, {
    params: { identityName, region, roleArn },
  })

  const regionInfo = useSelector(awsRegionInfoSelector)
  const subnets = useMemo(() => {
    const vpc = pathStrOr([], '0.vpcs', regionInfo).find(propEq('VpcId', vpcId))

    if (!vpc) return []
    const isPublic = type === 'public'
    const subnets = pathStrOr([], 'Subnets', vpc)
    const options = subnets.filter(
      (x) => x.MapPublicIpOnLaunch === isPublic && az === x.AvailabilityZone,
    )
    return projectAs({ label: 'SubnetName', value: 'SubnetId' }, options)
  }, [regionInfo, vpcId, az])

  return (
    <>
      <DropdownField
        DropdownComponent={MultiDropdown}
        name={`Availability Zone (${type}): ${az}`}
        key={`subnet-${type}-${az}-${vpcId}`}
        id={`subnet-${type}-${az}-${vpcId}`}
        label={label}
        items={subnets}
        onChange={onChange}
        loading={loading}
        enableSearch
        {...rest}
      />
    </>
  )
}
