import { useSelector } from 'react-redux'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { prop } from 'ramda'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import useListAction from 'core/hooks/useListAction'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'

import {
  checkNodesForClockDrift,
  clockDriftErrorMessage,
} from 'app/plugins/infrastructure/components/nodes/helpers'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import { sessionStoreKey, SessionState } from 'core/session/sessionReducers'
import ClusterHostChooser from './bareos/ClusterHostChooser'
import { RootState } from 'app/store'

interface AddNodeStepProps {
  wizardContext: any
  setWizardContext: any
  onNext: any
  title: string
  nodeFieldId: string
  nodeSelection?: 'single' | 'multiple'
  nodeFilterFn: (node: INodesSelector) => boolean
  nodeValidations: any[]
  isSingleNodeCluster: boolean
  pollForNodes?: boolean
  required?: boolean
  airgapped?: boolean
}

const AddNodeStep = ({
  wizardContext,
  setWizardContext,
  onNext,
  title,
  nodeFieldId,
  nodeSelection = 'single',
  nodeFilterFn,
  nodeValidations,
  isSingleNodeCluster,
  pollForNodes = true,
  required = false,
}: AddNodeStepProps) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const validatorRef = useRef(null)

  useListAction(listNodes)
  const allNodes = useSelector(nodesSelector)

  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }

    const selectedNodeUuids = wizardContext[nodeFieldId] || []
    const hasClockDrift = checkNodesForClockDrift(selectedNodeUuids, allNodes)
    if (hasClockDrift) {
      setErrorMessage(clockDriftErrorMessage)
      return false
    }
    setErrorMessage(null)
    return true
  }, [wizardContext])

  const { features } = useSelector<RootState, SessionState>(prop(sessionStoreKey))

  useEffect(() => {
    onNext(submitStep)
  }, [submitStep])

  return (
    <ValidatedForm
      fullWidth
      initialValues={wizardContext}
      onSubmit={setWizardContext}
      triggerSubmit={setupValidator}
      elevated={false}
      // title={title}
      // link={
      //   <ExternalLink textVariant="caption2" url={pmkCliOverviewLink}>
      //     Not Seeing Any Nodes?
      //   </ExternalLink>
      // }
    >
      <ClusterHostChooser
        selection={nodeSelection}
        id={nodeFieldId}
        filterFn={nodeFilterFn}
        validations={nodeValidations}
        isSingleNodeCluster={isSingleNodeCluster}
        pollForNodes={pollForNodes}
        airgapped={features?.experimental?.airgapped}
        onChange={(value) => setWizardContext({ [nodeFieldId]: value })}
        value={wizardContext[nodeFieldId]}
        required={required}
        title={title}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </ValidatedForm>
  )
}

export default AddNodeStep
