import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import Accordion from 'core/components/accordion/Accordion'

import Dropdown from 'core/elements/dropdown'
import { isEmpty } from 'ramda'
import { projectAs } from 'utils/fp'
import { SubnetConfigs } from '../../../aws/capi/model'
import { TableRow } from '../PropertyCardTable'

const Subnets = ({ type, subnets = [] }) => {
  const classes = useStyles()
  const [selectedSubnetId, setSelectedSubnetId] = useState(null)
  const subnetsById = useMemo(
    () =>
      subnets.reduce((accum, subnet) => {
        const id = subnet.id
        if (id) {
          accum[id] = subnet
        }
        return accum
      }, {}),
    [subnets],
  )

  const options = useMemo(() => {
    return [...projectAs({ label: 'id', value: 'id' }, subnets)]
  }, [subnets])

  useEffect(() => {
    if (isEmpty(subnets)) return
    setSelectedSubnetId(subnets[0]?.id)
  }, [subnets])

  return (
    <div className={classes.subnetFields}>
      <div className={classes.subnetId}>
        <Text variant="caption1" component="p">
          {type} Subnets
        </Text>
        <Dropdown value={selectedSubnetId} items={options} onChange={setSelectedSubnetId} />
      </div>
      <TableRow label="Route Table ID" value={subnetsById[selectedSubnetId]?.routeTableId} />
      <TableRow label="NAT Gateway ID" value={subnetsById[selectedSubnetId]?.natGatewayId} />
    </div>
  )
}

interface SubnetConfigsTableFieldsProps {
  subnetConfigs: SubnetConfigs
}

export default function SubnetConfigsTableFields({
  subnetConfigs = {},
}: SubnetConfigsTableFieldsProps) {
  const classes = useStyles()
  const renderTitle = useCallback(
    (availabilityZone) => (
      <Text variant="body2">
        <b>Subnet Config </b>
        {`(${availabilityZone})`}
      </Text>
    ),
    [],
  )
  return (
    <>
      {Object.entries(subnetConfigs).map(
        ([availabilityZone, { publicSubnets, privateSubnets }]) => {
          return (
            <Accordion
              key={availabilityZone}
              id={availabilityZone}
              title={renderTitle(availabilityZone)}
              className={classes.accordian}
            >
              <Subnets type="Public" subnets={publicSubnets} />
              <Subnets type="Private" subnets={privateSubnets} />
            </Accordion>
          )
        },
      )}
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  accordion: {
    '& .accordionTopBar': {
      backgroundColor: 'unset',
      borderTop: 'unset',
      padding: 'unset',
      borderBottom: `1px solid ${theme.components.card.border}`,
      paddingBottom: '12px',
    },
  },
  subnetFields: {
    display: 'grid',
    gridGap: theme.spacing(2),
    margin: theme.spacing(3, 0),
  },
  subnetId: {
    display: 'grid',
    gridTemplateColumns: '275px 1fr',
  },
}))
