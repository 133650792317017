import React, { useState } from 'react'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import MetalLbModePicklist, { MetalLbModes } from 'k8s/components/common/MetalLbModePicklist'
import ExternalLink from 'core/components/ExternalLink'
import { applicationLoadBalancer } from 'k8s/links'
import { ipValidators } from '../form-components/validators'
import withFormContext from 'core/components/validatedForm/withFormContext'
import uuid from 'uuid'
import { metal3FieldId } from './Metal3'
import { metalLbInfo } from 'app/plugins/infrastructure/components/clusters/constants'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'
import RadioFields from 'core/components/validatedForm/radio-fields'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import jsYaml from 'js-yaml'

enum MetalLbInputMethods {
  Form = 'form',
  Yaml = 'yaml',
}

const metalLbInputMethodOptions = [
  { label: 'Create Default Layer2 mode MetalLB deployment', value: MetalLbInputMethods.Form },
  {
    label: 'Advanced Configuration (Upload/Paste MetalLB Custom Resource Yamls)',
    value: MetalLbInputMethods.Yaml,
  },
]

export const metalLbFieldId = 'enableMetallb'

export const parseMetalLbCidr = (metalLbCidr) => {
  if (!metalLbCidr) return []
  const ranges = metalLbCidr.split(',')
  return ranges.map((range) => {
    const [start, end] = range.split('-')
    return { key: start, value: end, id: uuid.v4() }
  })
}

const handleMetallbChange = (value, wizardContext) => ({
  [metalLbFieldId]: value,
  privileged: value ? true : wizardContext?.privileged,
})

const MetalLbField = ({ label = 'Enable MetalLB', wizardContext, setWizardContext }) => {
  return (
    <CheckboxField
      id={metalLbFieldId}
      label={label}
      onChange={(value) => setWizardContext(handleMetallbChange(value, wizardContext))}
      value={wizardContext[metalLbFieldId]}
      info={metalLbInfo}
    />
  )
}

export const MetalLbLayer2Field = ({
  wizardContext,
  setWizardContext,
  useToggleSwitch = false,
}) => (
  <>
    {useToggleSwitch ? (
      <ToggleSwitchField
        id={metalLbFieldId}
        label="Deploy MetalLB"
        onChange={(value) => setWizardContext(handleMetallbChange(value, wizardContext))}
        info={metalLbInfo}
        infoPlacement={{ align: topMiddle.align, offset: topMiddle.offset }}
        value={wizardContext[metal3FieldId] ? true : wizardContext[metalLbFieldId]}
      />
    ) : (
      <CheckboxField
        id={metalLbFieldId}
        label="Deploy MetalLB - Layer 2 Mode"
        onChange={(value) => setWizardContext(handleMetallbChange(value, wizardContext))}
        value={wizardContext[metal3FieldId] ? true : wizardContext[metalLbFieldId]}
        info={metalLbInfo}
      />
    )}
  </>
)
export const MetalLbAddonLayer2Field = () => (
  <MetalLbAddonCard>
    <MetalLbCidrField id="MetalLbAddonLayer2Field" />
  </MetalLbAddonCard>
)

export const MetalLbAddonCreationField = (params) => {
  const [inputMethod, setInputMethod] = useState(
    params?.values?.metalLbInputMethod || MetalLbInputMethods.Form,
  )
  return (
    <MetalLbAddonCard>
      <RadioFields
        id="metalLbInputMethod"
        options={metalLbInputMethodOptions}
        value={inputMethod}
        //@ts-ignore
        onChange={(value) => setInputMethod(value)}
      />
      {inputMethod === MetalLbInputMethods.Form && <MetalLbCidrField id="base64EncMetallbConfig" />}
      {inputMethod === MetalLbInputMethods.Yaml && <MetalLbYamlField id="base64EncMetallbConfig" />}
    </MetalLbAddonCard>
  )
}

const handleMetallbCidrChange = (valuesArr, setFieldValue) => {
  const addresses = valuesArr.map(({ key, value }) => `${key}-${value}`)

  const obj1 = {
    apiVersion: 'metallb.io/v1beta1',
    kind: 'IPAddressPool',
    metadata: {
      creationTimestamp: null,
      name: 'default',
      namespace: 'metallb-system',
    },
    spec: {
      addresses: addresses,
    },
    status: {},
  }

  const obj2 = {
    apiVersion: 'metallb.io/v1beta1',
    kind: 'L2Advertisement',
    metadata: {
      creationTimestamp: null,
      name: 'l2advertisement1',
      namespace: 'metallb-system',
    },
    spec: {
      ipAddressPools: ['default'],
    },
    status: {},
  }

  let obj = ''
  try {
    const yaml1 = jsYaml.dump(obj1)
    const yaml2 = jsYaml.dump(obj2)
    obj = yaml1 + '---\n' + yaml2
  } catch (e) {
    console.error('Error parsing YAML', e)
  }

  setFieldValue('base64EncMetallbConfig')(obj)
}

const MetalLbYamlField = withFormContext(({ values, setFieldValue }) => {
  return (
    <>
      <TextFileDrop
        onChange={setFieldValue('base64EncMetallbConfig')}
        fileTypes={['.yaml', '.yml']}
      />
      <CodeMirror
        id="base64EncMetallbConfig"
        label="MetalLB Config YAML"
        value={values.base64EncMetallbConfig as string}
        onChange={setFieldValue('base64EncMetallbConfig')}
      />
    </>
  )
})

export const MetalLbCidrField = withFormContext(({ values, setFieldValue }) => {
  return (
    <KeyValuesField
      required
      id="MetallbIpRange"
      label="Address Pool Range"
      keyLabel="Start Address"
      valueLabel="End Address"
      value={values?.MetallbIpRange}
      onChange={(value) => handleMetallbCidrChange(value, setFieldValue)}
    />
  )
})

export const MetalLbAddonField = ({ values }) => (
  <MetalLbAddonCard>
    <PicklistField
      DropdownComponent={MetalLbModePicklist}
      required
      id="metallbMode"
      label="Metal Lb Mode"
    />
    {values.metallbMode === MetalLbModes.Layer2Mode && <MetalLbCidrField id="MetalLbAddonField" />}
    {values.metallbMode === MetalLbModes.BGPMode && (
      <>
        <TextField id="metallbRouterIp" label="Router IP Address" required />
        <TextField id="metallbRouterAS" label="Router's AS Number" required />
        <TextField id="metallbAS" label="AS Number For MetalLb" required />
        <TextField
          id="metallbCidr"
          label="Address Pool CIDR"
          // info="Network CIDR from which Kubernetes allocates IP addresses to containers.
          // This CIDR shouldn't overlap with the VPC CIDR. A /16 CIDR enables 256 nodes."
          required
          validations={[ipValidators?.[values.networkStack]?.ipValidator]}
        />
      </>
    )}
  </MetalLbAddonCard>
)

export const MetalLbAddonCard = ({ children }) => {
  return (
    <FormFieldSection
      title="MetalLB Configuration"
      link={
        <ExternalLink textVariant="caption2" url={applicationLoadBalancer}>
          MetalLB Help
        </ExternalLink>
      }
      info={{
        title: 'MetalLB is a software load balancer.',
        children: (
          <>
            <p>
              - Ensure firewalls are configured to allow ingress and egress traffic for the virtual
              IP
            </p>
            <p>- Ensure the Address Pool is a reserved Network IP Range to avoid IP Conflicts</p>
          </>
        ),
      }}
    >
      {children}
    </FormFieldSection>
  )
}

export default MetalLbField
