import React from 'react'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import AirCtlWalkthrough from './AirCtlWalkthrough'

const OnboardAirgappedNodePage = () => (
  <Tabs route={routes.nodes.addAirgapped}>
    <Tab value="airCtl" label="AirCTL CLI">
      <AirCtlWalkthrough />
    </Tab>
  </Tabs>
)

export default OnboardAirgappedNodePage
