import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Progress from 'core/components/progress/Progress'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import StatusConditionsCard from '../StatusConditionsCard'
import { downloadYamlsZipFile, convertResourcesToYamls } from '../helpers'
import Button from 'core/elements/button'
import ControlPlanePropertiesCard from './ControlPlanePropertiesCard'
import InfoHeader from 'k8s/components/common/entity/info-header'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateAwsManagedControlPlane } from '../../control-plane/aws-managed-control-plane/actions'
import { updateNodeletControlPlane } from '../../control-plane/nodelet-control-plane/actions'
import YamlCard from '../YamlCard'
import { ICapiClusterSelector } from '../../model'
import { getCodeMirrorYamlValidations } from '../../helpers'
import {
  updateAwsClusterRoleIdentities,
  updateAwsClusterStaticIdentities,
} from '../../identities/actions'
import {
  createAwsMachineTemplate,
  deleteAwsMachineTemplate,
} from '../../aws-machine-templates/actions'
import { cleanResource } from '../../../aws/capi/capiUtils'
import EditEksControlPlaneModal from './EditEksControlPlaneModal'
import EditAwsControlPlaneModal from './EditAwsControlPlaneModal'
import { ClusterCloudPlatforms } from 'app/constants'
import { CapiResourceKind } from 'app/plugins/infrastructure/components/clusters/aws/capi/model'

interface CapiControlPlanePageProps {
  cluster: ICapiClusterSelector
  resources: unknown[]
  loading: boolean
}
interface Yamls {
  [kind: string]: string
}

export default function CapiControlPlanePage({
  cluster,
  loading,
  resources,
}: CapiControlPlanePageProps) {
  const classes = useStyles()
  const { controlPlane } = cluster || {}
  const [yamls, setYamls] = useState<Yamls>({})
  const [machineTemplate, setMachineTemplate] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)

  const { update: updateNodeletCp, updating: updatingNodeletCp } = useUpdateAction(
    updateNodeletControlPlane,
  )
  const { update: updateAwsManagedCp, updating: updatingAwsManagedCp } = useUpdateAction(
    updateAwsManagedControlPlane,
  )
  const { update: updateRoleIdentity, updating: updatingRoleIdentity } = useUpdateAction(
    updateAwsClusterRoleIdentities,
  )
  const { update: updateStaticIdentity, updating: updatingStaticIdentity } = useUpdateAction(
    updateAwsClusterStaticIdentities,
  )
  const { update: createMachineTemplate, updating: creatingMachineTemplate } = useUpdateAction(
    createAwsMachineTemplate,
  )
  const { update: deleteMachineTemplate, updating: deletingMachineTemplate } = useUpdateAction(
    deleteAwsMachineTemplate,
  )

  const controlPlaneKind = controlPlane?.kind

  useEffect(() => {
    setYamls(convertResourcesToYamls(resources))
  }, [resources])

  useEffect(() => {
    if (!controlPlane?.resources) return
    setMachineTemplate(
      controlPlane.resources.find((r) => r.kind === CapiResourceKind.AWSMachineTemplate),
    )
  }, [controlPlane])

  const downloadYamls = useCallback(() => {
    downloadYamlsZipFile(yamls, `${cluster?.name}-control-plane`)
  }, [yamls])

  const replicaInfoColumns = useMemo(() => {
    if (controlPlane?.kind !== 'NodeletControlPlane') return []
    const replicas = controlPlane?.replicas || {}
    return [
      { label: 'Desired Replicas', value: replicas?.desired },
      { label: 'Ready Replicas', value: replicas?.ready },
      { label: 'Updated Replicas', value: replicas?.updated },
      { label: 'Unavailable Replicas', value: replicas?.unavailable },
    ]
  }, [controlPlane?.kind, controlPlane?.replicas])

  const updateFnMap = useMemo(
    () => ({
      NodeletControlPlane: updateNodeletCp,
      AWSManagedControlPlane: updateAwsManagedCp,
      AWSClusterRoleIdentity: updateRoleIdentity,
      AWSClusterStaticIdentity: updateStaticIdentity,
      AWSMachineTemplate: createMachineTemplate,
    }),
    [],
  )

  const handleYamlUpdate = useCallback(
    async ({ kind, namespace, name, updatedYaml }) => {
      if (kind === 'AWSMachineTemplate') {
        await deleteMachineTemplate({ namespace, name })
      }
      const updateFn = updateFnMap[kind]
      if (!updateFn) return
      const body =
        kind === 'AWSMachineTemplate'
          ? cleanResource(['metadata.creationTimestamp', 'metadata.resourceVersion'])(updatedYaml)
          : updatedYaml
      return updateFn({
        namespace,
        name,
        body,
      })
    },
    [updateFnMap],
  )

  const EditModal = useMemo(() => {
    return cluster?.infrastructureType === ClusterCloudPlatforms.EKS ? (
      <EditEksControlPlaneModal
        open
        onClose={() => setShowEditModal(false)}
        controlPlane={controlPlane}
      />
    ) : (
      <EditAwsControlPlaneModal
        open
        onClose={() => setShowEditModal(false)}
        controlPlane={controlPlane}
        machineTemplate={machineTemplate}
        region={cluster?.infrastructure?.region}
      />
    )
  }, [
    cluster?.infrastructureType,
    cluster?.infrastructure?.cloudProviderName,
    cluster?.infrastructure?.region,
    controlPlane,
    machineTemplate,
  ])

  const updating =
    updatingNodeletCp ||
    updatingAwsManagedCp ||
    updatingRoleIdentity ||
    updatingStaticIdentity ||
    creatingMachineTemplate ||
    deletingMachineTemplate

  return (
    <>
      {showEditModal && EditModal}
      <Progress loading={loading || updating} overlay>
        <div className={classes.infraPage}>
          <div className={classes.columns}>
            <StatusConditionsCard
              resourceType={controlPlaneKind}
              conditions={controlPlane?.status?.conditions}
            />
          </div>
          <div className={classes.buttons}>
            <div>
              <Button variant="secondary" icon="edit" onClick={() => setShowEditModal(true)}>
                Edit
              </Button>
            </div>
            <div>
              {/* Todo: Add this button once the docs have been completed */}
              {/* <Button variant="secondary" icon="external-link">
            Read YAML Docs
          </Button> */}
              <Button variant="secondary" icon="download" onClick={downloadYamls}>
                Download YAMLs
              </Button>
            </div>
          </div>
          <div className={classes.columns}>
            <div>
              {controlPlane?.kind === 'NodeletControlPlane' && (
                <InfoHeader
                  columns={replicaInfoColumns}
                  className={classes.replicasInfoBar}
                  labelTextVariant="caption1"
                />
              )}
              <ControlPlanePropertiesCard
                controlPlane={controlPlane}
                machineTemplate={machineTemplate}
              />
            </div>

            <div className={classes.yamls}>
              {Object.entries(yamls).map(([kind, yaml]) => (
                <YamlCard
                  key={kind}
                  kind={kind}
                  yaml={yaml}
                  open={kind === 'NodeletControlPlane' || kind === 'AWSManagedControlPlane'}
                  updateYamlFn={handleYamlUpdate}
                  yamlValidations={getCodeMirrorYamlValidations(kind)}
                />
              ))}
            </div>
          </div>
        </div>
      </Progress>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  infraPage: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 0, 1, 0),
    gap: theme.spacing(1),
    minWidth: `${626 * 2 + 16}px`,
    maxWidth: `${774 * 2 + 16}px`,
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(626px, 774px))',
    gridGap: theme.spacing(2),
  },
  yamls: {
    display: 'grid',
    gridTemplateColumns: 'minmax(626px, 774px)',
    gridAutoRows: 'max-content',
    gridGap: theme.spacing(2),
  },
  replicasInfoBar: {
    display: 'grid',
    gridAutoColumns: 'minmax(50px, 1fr)',
    gridGap: '0px',
    height: 'max-content',
    marginBottom: theme.spacing(1),
    marginTop: '0px',
    '& .info': {
      padding: theme.spacing(2),
      margin: '0px',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
    },
    '& .label': {
      color: theme.palette.grey[500],
    },
    '& .value': {
      fontSize: '37px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '54px',
      letterSpacing: '-0.37px',
    },
  },
}))
