import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

export const capiHostActions = ActionsSet.make<DataKeys.CapiHosts>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.CapiHosts],
  cacheKey: DataKeys.CapiHosts,
  cache: false,
})

export const listCapiHosts = capiHostActions.add(
  new ListAction<DataKeys.CapiHosts, { clusterName: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get CAPI Host', params)
    const response = await sunpike.getHostsForCapiCluster(params?.clusterName)
    return response?.items
  }),
)
