import React from 'react'
import { compareVersions } from 'k8s/util/helpers'
import { MetalLbAddonCreationField, MetalLbCidrField } from '../MetalLbField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'

// Note: prop is called base64EncMetallbConfig, but the config the UI provides
// should not be base64 encoded
export default function MetalLbParamFields({
  setFieldValue,
  addonVersion = undefined,
  action = undefined,
}) {
  const isUpdatedVersion = compareVersions(addonVersion, '0.13.2') >= 0
  const showCidrField = addonVersion && !isUpdatedVersion
  return (
    <>
      {action === 'Enable' && <MetalLbAddonCreationField />}
      {action === 'Edit' && (
        <>
          {showCidrField && <MetalLbCidrField id="MetallbIpRange" />}
          <CodeMirror
            id="base64EncMetallbConfig"
            label="MetalLB Config YAML"
            onChange={setFieldValue('base64EncMetallbConfig')}
          />
        </>
      )}
    </>
  )
}
