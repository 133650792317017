import PicklistField from 'core/components/validatedForm/DropdownField'
import React from 'react'

const defaultLabel = 'Worker Node Instance Type'

const WorkerNodeInstanceTypeField = ({
  dropdownComponent,
  values,
  label = defaultLabel,
  onChange = undefined,
  ...rest
}) => (
  <PicklistField
    DropdownComponent={dropdownComponent}
    disabled={!(values.cloudProviderId && values.region)}
    id="workerFlavor"
    onChange={onChange}
    label={label}
    cloudProviderId={values.cloudProviderId}
    cloudProviderRegionId={values.region}
    tooltip="Choose an instance type used by worker nodes."
    enableSearch
    required
    {...rest}
  />
)

export default WorkerNodeInstanceTypeField
