import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import { CapiVpcTypes, CidrBlock, Subnet } from '../../../model'
import Row from 'core/containers/Row'
import { concat, filter, take, update } from 'ramda'
import { Reducer } from 'react'
import { ipv4CidrValidator } from '../../../form-components/validators'
import { Netmask } from 'netmask'

interface Props {
  wizardContext: any
  setWizardContext: any
  az: string
  getNextPublicCidr: () => string
  getNextPrivateCidr: () => string
}

interface PmkManagedSubnetsReducerPayload {
  previousCount?: number
  currentCount?: number
  index?: number
  cidrBlockValue?: string
  az?: string
  isPublic?: boolean
}
interface PmkManagedSubnetsReducerAction {
  type: 'add' | 'remove' | 'update'
  payload: PmkManagedSubnetsReducerPayload
}

function getDefaultSubnetCidrBlock(isPublic: boolean, az: string, cidrBlock: string): Subnet {
  return {
    cidrBlock: cidrBlock,
    isPublic: isPublic,
    availabilityZone: az,
  }
}

type PmkManagedSubnetsReducer = Reducer<Array<Subnet>, PmkManagedSubnetsReducerAction>

function subnetsReducer(state, { type, payload }: PmkManagedSubnetsReducerAction) {
  const { index, previousCount, currentCount, az, isPublic, cidrBlockValue } = payload

  switch (type) {
    case 'add': {
      const newSubnets = Array(currentCount - previousCount).fill(
        getDefaultSubnetCidrBlock(isPublic, az, cidrBlockValue),
      )
      return [...state, ...newSubnets]
    }
    case 'update': {
      return [
        ...update(
          index,
          getDefaultSubnetCidrBlock(isPublic, az, cidrBlockValue),
          filter((subnet: Subnet) => subnet.isPublic === isPublic, state),
        ),
        ...filter((subnet: Subnet) => subnet.isPublic !== isPublic, state),
      ]
    }
    case 'remove': {
      const { currentCount, isPublic } = payload
      return concat(
        take(
          currentCount,
          filter((subnet: Subnet) => subnet.isPublic === isPublic, state),
        ),
        filter((subnet: Subnet) => subnet.isPublic !== isPublic, state),
      )
    }

    default:
      throw new Error()
  }
}

export default function CapiPmkManagedSubnets({
  wizardContext,
  setWizardContext,
  az,
  getNextPublicCidr,
  getNextPrivateCidr,
}: Props) {
  const classes = useStyles()
  const vpcType: CapiVpcTypes = wizardContext?.vpcType

  const [privateSubnetsCount, setPrivateSubnetsCount] = useState(1)
  const [publicSubnetsCount, setPublicSubnetsCount] = useState(1)
  const initalState = useMemo(
    () => [
      getDefaultSubnetCidrBlock(true, az, getNextPublicCidr()),
      getDefaultSubnetCidrBlock(false, az, getNextPrivateCidr()),
    ],
    [],
  )

  const [subnets, dispatch] = useReducer<PmkManagedSubnetsReducer>(subnetsReducer, initalState)

  useEffect(() => {
    const existingSubnets = filter(
      (subnet: Subnet) => subnet.availabilityZone !== az,
      wizardContext?.subnets,
    )
    setWizardContext({ subnets: [...existingSubnets, ...subnets] })
  }, [subnets, az, setWizardContext])

  const onPrivateSubnetsCountChange = useCallback(
    (count) => {
      if (count < privateSubnetsCount) {
        dispatch({
          type: 'remove',
          payload: { previousCount: privateSubnetsCount, currentCount: count, az, isPublic: false },
        })
      } else if (count > privateSubnetsCount) {
        const cidrBlockValue = getNextPrivateCidr()
        dispatch({
          type: 'add',
          payload: {
            previousCount: privateSubnetsCount,
            currentCount: count,
            cidrBlockValue,
            az,
            isPublic: false,
          },
        })
      }
      setPrivateSubnetsCount(count)
    },
    [dispatch, az, privateSubnetsCount],
  )
  const onPublicSubnetsCountChange = useCallback(
    (count) => {
      if (count < publicSubnetsCount) {
        dispatch({
          type: 'remove',
          payload: { previousCount: publicSubnetsCount, currentCount: count, az, isPublic: true },
        })
      } else if (count > publicSubnetsCount) {
        const cidrBlockValue = getNextPublicCidr()
        dispatch({
          type: 'add',
          payload: {
            previousCount: publicSubnetsCount,
            currentCount: count,
            cidrBlockValue,
            az,
            isPublic: true,
          },
        })
      }

      setPublicSubnetsCount(count)
    },
    [dispatch, az, publicSubnetsCount],
  )

  // Todo: add debouncing here
  const onSubnetCidrValueChange = useCallback(
    (value, index, isPublic) => {
      dispatch({
        type: 'update',
        payload: {
          cidrBlockValue: value,
          index,
          isPublic,
          az,
        },
      })
    },
    [dispatch, az],
  )
  const isPublicSubnet = (subnet: Subnet) => subnet.isPublic
  const isPrivateSubnet = (subnet: Subnet) => !subnet.isPublic

  if (!vpcType || !az) return null
  return (
    <div className={classes.root}>
      <Row minItemWidth="300" gap={24}>
        {/* Number of Private Subnets */}
        <TextField
          id={`noOfPrivateSubnetsFor-${az}`}
          className={classes.numberOfSubnets}
          label="Number of Private Subnets"
          type="number"
          min="1"
          max="2" // max 2 private subnets for aws
          value={privateSubnetsCount}
          onChange={onPrivateSubnetsCountChange}
          required
        />
        {/* Number of Public Subnets */}
        <TextField
          id={`noOfPublicSubnetsFor-${az}`}
          className={classes.numberOfSubnets}
          label="Number of Public Subnets"
          type="number"
          min="1"
          max="1" // max 1 public subnets for aws
          value={publicSubnetsCount}
          onChange={onPublicSubnetsCountChange}
          required
        />
      </Row>
      <br />
      {/* <Text className={classes.cidrText} variant="caption1">
        Private Subnets CIDR
      </Text> */}
      <div className={classes.cidrContainer}>
        {subnets &&
          subnets.filter(isPrivateSubnet).map((subnet, index) => {
            const cidrBlock: CidrBlock = new Netmask(subnet?.cidrBlock)
            return (
              <TextField
                id={`private-${index}-${az}`}
                key={`private-${index}-${az}`}
                label="Private Subnets CIDR"
                info={`IP Range : ${cidrBlock.base} - ${cidrBlock.broadcast}, Count : ${cidrBlock.size}`}
                value={subnet?.cidrBlock}
                validations={[ipv4CidrValidator]}
                onChange={(value) => onSubnetCidrValueChange(value, index, false)}
                required
              ></TextField>
            )
          })}
      </div>
      <br />
      {/* <Text className={classes.cidrText} variant="caption1">
        Public Subnets CIDR
      </Text> */}
      <div className={classes.cidrContainer}>
        {subnets &&
          subnets.filter(isPublicSubnet).map((subnet, index) => {
            const cidrBlock: CidrBlock = new Netmask(subnet?.cidrBlock)
            return (
              <TextField
                id={`public-${index}-${az}`}
                key={`public-${index}-${az}`}
                value={subnet?.cidrBlock}
                info={` IP Range : ${cidrBlock.base} -  ${cidrBlock.broadcast}, Count : ${cidrBlock.size} `}
                label="Public Subnets CIDR"
                validations={[ipv4CidrValidator]}
                onChange={(value) => onSubnetCidrValueChange(value, index, true)}
                required
              ></TextField>
            )
          })}
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  numberOfSubnets: {
    // maxWidth: 300,
  },
  cidrContainer: {
    marginTop: theme.spacing(1),
    display: 'grid',
    minWidth: 400,
    maxWidth: 800,
    // justifyContent: 'end',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(2),
  },
}))
