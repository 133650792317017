import React from 'react'
import Text from 'core/elements/Text'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ensureArray, isNilOrEmpty } from 'utils/fp'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

interface CidrRangeProps {
  label: string
  value: string | string[]
}

interface BaseCidrRangesCellProps<T> {
  dataFn: (item: T) => CidrRangeProps[]
}

interface CidrRangesCellProps<T, V> extends GridCellProps<T, V>, BaseCidrRangesCellProps<T> {}

const useStyles = makeStyles((theme: Theme) => ({
  cidrRange: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: '2px',
  },
}))

const CidrRanges = ({ label, value = [] }: CidrRangeProps) => {
  const classes = useStyles()
  const ipAddresses = ensureArray(value)

  return (
    <div className={classes.cidrRange}>
      <Text variant="body2">{`${label} -`}</Text>

      <div>
        {ipAddresses.map((ip) => (
          <Text key={ip} variant="body2">
            {ip}
          </Text>
        ))}
      </div>
    </div>
  )
}

export default function CidrRangesCell<T, V = unknown>({
  item: cluster = {} as T,
  dataFn,
}: CidrRangesCellProps<T, V>) {
  const values = dataFn(cluster)
  return (
    <div>
      {values.map(({ label, value }) =>
        isNilOrEmpty(value) ? null : <CidrRanges key={label} label={label} value={value} />,
      )}
    </div>
  )
}

export function createCidrRangesCell<T, V = unknown>({ dataFn }: BaseCidrRangesCellProps<T>) {
  return (props: GridCellProps<T, V>) => {
    return <CidrRangesCell<T, V> {...props} dataFn={dataFn} />
  }
}
