import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CapiPmkManagedSubnets from './CapiPmkManagedSubnets'
import CapiSelfManagedSubnets from './CapiSelfManagedSubnets'
import CapiSpec from '../CapiSpec'
import { CapiVpcTypes } from '../../../model'
import Accordion from 'core/components/accordion/Accordion'
import Text from 'core/elements/Text'

interface Props {
  wizardContext: any
  setWizardContext: any
  getNextPublicCidr: () => string
  getNextPrivateCidr: () => string
}

export default function CapiSubnetConfig({
  wizardContext,
  setWizardContext,
  getNextPublicCidr,
  getNextPrivateCidr,
}: Props) {
  const classes = useStyles()
  const azs: string[] = wizardContext?.azs
  const vpcType: CapiVpcTypes = wizardContext?.vpcType

  return (
    <CapiSpec className={classes.root} title="Subnet Config" fullWidth>
      {azs?.length ? (
        azs.map((az) => (
          <Accordion id={`${vpcType}- ${az}`} key={`${vpcType}- ${az}`} title={az}>
            {vpcType === CapiVpcTypes.SelfManaged && (
              <CapiSelfManagedSubnets
                az={az}
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
              ></CapiSelfManagedSubnets>
            )}
            {vpcType === CapiVpcTypes.PmkManaged && (
              <CapiPmkManagedSubnets
                az={az}
                getNextPublicCidr={getNextPublicCidr}
                getNextPrivateCidr={getNextPrivateCidr}
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
              ></CapiPmkManagedSubnets>
            )}
          </Accordion>
        ))
      ) : (
        <Text className={classes.label} variant="caption2">
          Select the Availability Zone(s) to proceed
        </Text>
      )}
    </CapiSpec>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  label: {
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
}))
