import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import { listEksAmiTypes } from './actions'
import { useSelector } from 'react-redux'
import { eksAmiTypesSelector } from './selectors'
import { useMemo } from 'react'

interface Props {
  identityName: string
}

export default function EksAmiTypePicklist({
  identityName,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading } = useListAction(listEksAmiTypes, { params: { identityName } })
  const eksAmiTypes = useSelector(eksAmiTypesSelector)

  const items = useMemo(
    () =>
      eksAmiTypes.map((eksAmiType) => ({
        label: `${eksAmiType?.spec?.description} (${eksAmiType?.metadata?.name})`,
        value: eksAmiType?.metadata?.name,
      })),
    [eksAmiTypes],
  )

  return <AsyncDropdown loading={loading} items={items} {...rest} />
}
