import { StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { Phase } from '../../machine-deployment/model'

export const getPhaseStatus = (phase): StatusCellModel => {
  switch (phase) {
    case Phase.Running:
      return { variant: 'success', label: Phase.Running }

    case Phase.Failed:
      return { variant: 'error', label: Phase.Failed }

    case Phase.ScalingUp:
      return { variant: 'warning', label: Phase.ScalingUp }

    case Phase.ScalingDown:
      return { variant: 'warning', label: Phase.ScalingDown }

    case Phase.Deleting:
      return { variant: 'error', label: Phase.Deleting }

    default:
      return { variant: 'unknown', label: Phase.Unknown }
  }
}
