import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withTooltip from 'core/elements/tooltip/withTooltip'
import { CapiVpcTypes } from '../../../model'

interface Props {
  onChange: (value: string) => void
  tooltip?: string
  value: string
  awsAssumeRole: boolean
}

export default withTooltip<Props>(function AwsCapiVpcTypePicklist({ awsAssumeRole, ...rest }) {
  const options = useMemo(
    () =>
      awsAssumeRole
        ? [{ label: 'PMK Managed', value: CapiVpcTypes.PmkManaged }]
        : [
            { label: 'Self Managed', value: CapiVpcTypes.SelfManaged },
            { label: 'PMK Managed', value: CapiVpcTypes.PmkManaged },
          ],
    [awsAssumeRole],
  )

  return <Dropdown label="VPC Type" items={options} {...rest} />
})
