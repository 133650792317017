import React, { useCallback } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import WizardStep from 'core/components/wizard/WizardStep'
import CapiClusterSpec from './CapiClusterSpec'
import CapiVpcConfig from './capi-vpc/CapiVpcConfig'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CapiSubnetConfig from './capi-vpc/CapiSubnetConfig'
import CapiAwsLoadBalancerConfig from './capi-vpc/CapiAwsLoadBalancerConfig'
import CapiSpec from './CapiSpec'
import TagsField from '../../form-components/TagsField'
import { isNilOrEmpty, keyValueArrToObj } from 'utils/fp'
import cluster from './schema/default/cluster.json'
import eksCluster from './schema/eks/eks-cluster.json'
import awsManagedControlPlane from './schema/eks/aws-managed-control-plane.json'
import awsCluster from './schema/default/aws-cluster.json'
import awsClusterRoleIdentity from './schema/default/aws-cluster-role-identity.json'
import { YamlTemplate } from 'core/components/wizard/YamlTemplates'
import { AwsClusterTypes } from '../../capi/model'
import { Netmask } from 'netmask'

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
  isEksCluster?: boolean
}

const yamlTemplates: YamlTemplate[] = [
  { title: 'Cluster', schema: cluster, hide: (values) => values.type !== AwsClusterTypes.AWS },
  {
    title: 'AWSCluster',
    schema: awsCluster,
    hide: (values) => values.type !== AwsClusterTypes.AWS,
  },
  { title: 'Cluster', schema: eksCluster, hide: (values) => values.type !== AwsClusterTypes.EKS },
  {
    title: 'AWSManagedControlPlane',
    schema: awsManagedControlPlane,
    hide: (values) => values.type !== AwsClusterTypes.EKS,
  },
  {
    title: 'AWSClusterRoleIdentity',
    schema: awsClusterRoleIdentity,
    hide: (values) => !values.awsAssumeRole,
  },
]

const getNextValidCidr = (isPublic, publicCidr = '10.0.0.0/20', privateCidr = '10.0.128.0/20') => {
  let nextCidr = isPublic ? publicCidr : privateCidr
  return (): string => {
    const block = new Netmask(nextCidr)
    nextCidr = block.next().toString()
    return block.toString()
  }
}

let getNextPublicCidr = getNextValidCidr(true)
let getNextPrivateCidr = getNextValidCidr(true)

export default function CapiInfrastructureWizardStep({
  wizardContext,
  setWizardContext,
  onNext,
  isEksCluster = false,
  ...rest
}: Props) {
  const classes = useStyles()

  const handleNext = useCallback(
    (values) => {
      // This is a CIDR  generation reset meant to be done when clicking back to this step, hence this handler
      getNextPublicCidr = getNextValidCidr(true)
      getNextPrivateCidr = getNextValidCidr(false)
      onNext(values)
    },
    [onNext],
  )
  return (
    <WizardStep
      stepId="infrastructureStep"
      label="Infrastructure"
      yamlTemplates={yamlTemplates}
      showSummaryLabels={false}
      showSummaryYamls
      //    onNext={awsClusterTracking.wZStepOne(trackingFields)} //TODO:CAPI-> Add tracking for capi
      {...rest}
    >
      <ValidatedForm
        fullWidth
        initialValues={wizardContext}
        elevated={false}
        onSubmit={setWizardContext}
        triggerSubmit={handleNext}
      >
        {({ values }) => (
          <div className={classes.root}>
            {/* Cluster Spec */}
            <CapiClusterSpec
              values={values}
              setWizardContext={setWizardContext}
              wizardContext={wizardContext}
            />
            <hr className={classes.divider} />
            {/* VPC Config */}
            <CapiVpcConfig
              setWizardContext={setWizardContext}
              wizardContext={wizardContext}
              isEksCluster={isEksCluster}
            />
            {/* Subnet Config */}
            <CapiSubnetConfig
              getNextPublicCidr={getNextPublicCidr}
              getNextPrivateCidr={getNextPrivateCidr}
              setWizardContext={setWizardContext}
              wizardContext={wizardContext}
            />
            {!isEksCluster && (
              <>
                <hr className={classes.divider} />
                {/* AWS Load Balancer Configuration */}
                <CapiAwsLoadBalancerConfig
                  values={values}
                  setWizardContext={setWizardContext}
                  wizardContext={wizardContext}
                />
                <hr className={classes.divider} />
                <CapiSpec title="Tags (Additional)">
                  <TagsField
                    hideTitle
                    onChange={(value) =>
                      setWizardContext({
                        additionalTags: isNilOrEmpty(value) ? null : keyValueArrToObj(value),
                      })
                    }
                  />
                </CapiSpec>
              </>
            )}
          </div>
        )}
      </ValidatedForm>
    </WizardStep>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(1),
    height: '100%',
    alignContent: 'flex-start',
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(3, 0),
  },
}))
