import React from 'react'
import { routes } from 'core/utils/routes'
import DeleteCloudProviderDialog from '../delete-cloud-provider-dialog'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { ArrayElement } from 'core/actions/Action'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { cloudProvidersSelector, makeCloudProvidersSelector } from '../selectors'
import { listCloudProviders } from '../new-actions'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import getGridDialogButton, {
  GridDialogProps,
} from 'core/elements/grid/helpers/getGridDialogButton'
import { pick } from 'ramda'
import { CloudProviders, CloudProviderRouteNames } from '../model'
import {
  cloudProvidersListTableColumns,
  deleteCloudProviderTooltipText,
} from '../list-table-helpers'
import UpdateCloudProviderPage from '../UpdateCloudProviderPage'

type SelectorModel = ArrayElement<ReturnType<typeof cloudProvidersSelector>>

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>(
  'Google Cloud Providers',
  listTablePrefs,
)

const searchTargets = ['name', 'descriptiveType']

export const columns: GridViewColumn<SelectorModel>[] = cloudProvidersListTableColumns

const batchActions = [
  {
    cond: ([cloudProvider]) =>
      cloudProvider?.type !== 'local' && cloudProvider?.importedClusters?.length === 0,
    label: 'Delete',
    icon: 'trash-alt',
    BatchActionButton: getGridDialogButton<SelectorModel, GridDialogProps<SelectorModel>>(
      DeleteCloudProviderDialog,
      {},
      ([cloudProvider]) => ({
        tooltip:
          cloudProvider?.importedClusters?.length !== 0 ? deleteCloudProviderTooltipText : '',
      }),
    ),
  },
]

const googleCloudProvidersSelector = makeCloudProvidersSelector({ type: CloudProviders.Gcp })

export default function GoogleCloudProvidersListPage() {
  const { params, getParamsUpdater } = usePrefParams({})
  const { message, loading, reload } = useListAction(listCloudProviders)
  const data = useSelector(googleCloudProvidersSelector)

  return (
    <>
      <DocumentMeta title="Google Cloud Providers" />
      <UpdateCloudProviderPage />
      <ListContainer<DataKeys.CloudProviders, SelectorModel>
        dataKey={DataKeys.CloudProviders}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.cloudProviders.add.path({ provider: CloudProviderRouteNames.Google })}
        addText="Add Cloud Provider"
        editUrl={(_, id) => routes.cloudProviders.google.edit.path({ id })}
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        multiSelection={false}
        label="Google Cloud Providers"
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
