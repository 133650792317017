import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import createContextLoader from 'core/helpers/createContextLoader'
import createCRUDActions from 'core/helpers/createCRUDActions'
import listFnWithDependencies from 'core/helpers/list-with-dependencies'
import { getEtcdBackupPayload } from 'app/plugins/infrastructure/components/clusters/helpers'
import {
  clustersSelector,
  makeClusterEventsSelector,
  makeParamsClustersSelector,
} from 'app/plugins/infrastructure/components/clusters/selectors'
import { loadResMgrHosts } from 'app/plugins/infrastructure/components/common/actions'
import { loadNodes } from 'app/plugins/infrastructure/components/nodes/actions'
import { ActionDataKeys } from 'k8s/DataKeys'
import { mergeLeft, pick, propEq } from 'ramda'
import { adjustWith, updateWith } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import { monitoringAddonActions } from './cluster-addons/actions'
import {
  createBareOSCluster,
  createAwsCluster,
  createAzureCluster,
  upgradeNodesCluster,
} from 'app/plugins/infrastructure/components/clusters/newActions'

// DEPRECATED!! Please use newActions.ts instead

const { qbert } = ApiClient.getInstance()

export const clusterActions = createCRUDActions(ActionDataKeys.Clusters, {
  listFn: listFnWithDependencies(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get clusters')
    const clusters = await qbert.getClusters()
    return clusters.map((cluster) => {
      return {
        ...cluster,
        baseUrl: qbert.clusterBaseUrl(cluster.uuid),
      }
    })
  }, [monitoringAddonActions.list, loadNodes, loadResMgrHosts]),
  createFn: (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to create cluster', params)
    if (params.clusterType === 'aws') {
      return createAwsCluster(params)
    }
    if (params.clusterType === 'azure') {
      return createAzureCluster(params)
    }
    if (params.clusterType === 'local') {
      return createBareOSCluster(params)
    }
  },
  updateFn: async ({ clusterId, ...params }) => {
    const updateableParams = [
      'tags',
      'enableProfileAgent',
      'numWorkers',
      'numMinWorkers',
      'numMaxWorkers',
    ]

    const body = pick(updateableParams, params)
    if (params.etcdBackup) {
      body.etcdBackup = getEtcdBackupPayload('etcdBackup', params)
    }

    Bugsnag.leaveBreadcrumb('Attempting to update cluster', { clusterId, ...body })

    await qbert.updateCluster(clusterId, body)
    trackEvent('Update Cluster', { clusterId })

    // Doing this will help update the table, but the cache remains incorrect...
    // Same issue regarding cache applies to anything else updated this function
    // body.etcdBackupEnabled = !!body.etcdBackup
    clusterActions.invalidateCache()
    return body
  },
  deleteFn: async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster', { clusterId })
    await qbert.deleteCluster(clusterId)
    // Delete cluster Segment tracking is done in ClusterDeleteDialog.tsx because that code
    // has more context about the cluster name, etc.
  },
  customOperations: {
    scaleCluster: async ({ cluster, numSpotWorkers, numWorkers, spotPrice }, prevItems) => {
      const body = {
        numWorkers,
        numSpotWorkers: numSpotWorkers || 0,
        spotPrice: spotPrice || 0.001,
        spotWorkerFlavor: cluster.cloudProperties.workerFlavor,
      }
      Bugsnag.leaveBreadcrumb('Attempting to scale cluster', { clusterId: cluster.uuid, ...body })
      await qbert.updateCluster(cluster.uuid, body)
      trackEvent('Scale Cluster', { clusterUuid: cluster.uuid, numSpotWorkers, numWorkers })

      // Update the cluster in the cache
      return updateWith(
        propEq('uuid', cluster.uuid),
        {
          ...cluster,
          numWorkers,
        },
        prevItems,
      )
    },
    upgradeClusterNodes: async (formData, prevItems) => {
      Bugsnag.leaveBreadcrumb('Attempting to upgrade cluster', {
        clusterId: formData.clusterId,
      })
      const updatedCluster = await upgradeNodesCluster(formData)
      return adjustWith(propEq('uuid', formData.clusterId), mergeLeft(updatedCluster), prevItems)
    },
    updateTag: async ({ cluster, key, val }, prevItems) => {
      const body = {
        tags: { ...(cluster.tags || {}), [key]: val },
      }
      Bugsnag.leaveBreadcrumb('Attempting to update cluster tag', {
        clusterId: cluster.uuid,
        ...body,
      })
      await qbert.updateCluster(cluster.uuid, body)
      trackEvent('Cluster Tag Update', { clusterId: cluster.uuid })
      return updateWith(
        propEq('uuid', cluster.uuid),
        {
          ...cluster,
          ...body,
        },
        prevItems,
      )
    },
    attachNodes: async ({ cluster, nodes }, prevItems) => {
      Bugsnag.leaveBreadcrumb('Attempting to attach nodes to cluster', {
        clusterId: cluster.uuid,
        numNodes: (nodes || []).length,
      })
      await qbert.attachNodes(cluster.uuid, nodes)
      trackEvent('Cluster Attach Nodes', {
        numNodes: (nodes || []).length,
        clusterUuid: cluster.uuid,
      })
      return prevItems
    },
    detachNodes: async ({ cluster, nodes }, prevItems) => {
      Bugsnag.leaveBreadcrumb('Attempting to detach nodes from cluster', {
        clusterId: cluster.uuid,
        numNodes: (nodes || []).length,
      })
      await qbert.detachNodes(cluster.uuid, nodes)
      trackEvent('Cluster Detach Nodes', {
        numNodes: (nodes || []).length,
        clusterUuid: cluster.uuid,
      })
      return prevItems
    },
  },
  uniqueIdentifier: 'uuid',
  selector: clustersSelector,
  selectorCreator: makeParamsClustersSelector,
})

export const loadSupportedRoleVersions = createContextLoader(
  ActionDataKeys.SupportedRoleVersions,
  async () => {
    const response = await qbert.getK8sSupportedRoleVersions()
    return response.roles
  },
  {
    uniqueIdentifier: 'uuid',
    cache: false,
  },
)

export const clusterEventsActions = createCRUDActions(ActionDataKeys.Events, {
  listFn: async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster events', { clusterId })
    try {
      return qbert.getClusterEvents(clusterId)
    } catch {
      return []
    }
  },
  deleteFn: async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster event', {
      clusterId,
      namespace,
      name,
    })
    await qbert.deleteClusterEvent(clusterId, namespace, name)
    trackEvent('Delete Cluster Event', { clusterId, namespace, name })
  },
  service: 'qbert',
  entity: ActionDataKeys.Events,
  uniqueIdentifier: 'id',
  indexBy: ['clusterId'],
  selectorCreator: makeClusterEventsSelector,
})
