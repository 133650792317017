import ActionsSet from 'core/actions/ActionsSet'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import UpdateAction from 'core/actions/UpdateAction'
import Bugsnag from 'utils/bugsnag'
import { trackEvent } from 'utils/tracking'
import ListAction from 'core/actions/ListAction'

const { resMgr } = ApiClient.getInstance()

const resMgrHostActions = ActionsSet.make<DataKeys.ResMgrHosts>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.ResMgrHosts,
  cacheKey: DataKeys.ResMgrHosts,
})
export const listResMgrHosts = resMgrHostActions.add(
  new ListAction<DataKeys.ResMgrHosts>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to load ResMgr hosts')
    return resMgr.getHosts()
  }),
)
// We only need to have it support updating remoteSupport
export const updateResMgrHost = resMgrHostActions.add(
  new UpdateAction<DataKeys.ResMgrHosts, { id: string; enableSupport: boolean }>(
    async ({ id, enableSupport }) => {
      const supportRoleName = 'pf9-support'
      Bugsnag.leaveBreadcrumb('Attempting to update remote support', { id, enableSupport })
      trackEvent('Remote Support Update', { id, enableSupport })
      if (enableSupport) {
        await resMgr.addRole(id, supportRoleName, {})
      } else {
        await resMgr.removeRole(id, supportRoleName)
      }
    },
  ),
)
