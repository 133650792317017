import { ICloudProvidersSelector } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { cloudProvidersSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import Accordion from 'core/components/accordion/Accordion'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { propEq, find } from 'ramda'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

function getCloudProvider(cloudProviderId): ICloudProvidersSelector {
  const cloudProviders = useSelector(cloudProvidersSelector)
  return useMemo(() => cloudProviders.find(propEq('uuid', cloudProviderId)), [
    cloudProviders,
    cloudProviderId,
  ])
}

const columns = [
  { id: 'name', label: 'Cluster Name' },
  { id: 'cloudProviderId', label: 'AWS Provider', render: (value) => getCloudProvider(value).name },
  { id: 'clusterPodCidr', label: 'Cluster Pod CIDR Range' },
  { id: 'clusterServiceCidr', label: 'Cluster Pod CIDR Range' },
  { id: 'region', label: 'Region' },
  { id: 'sshKey', label: 'SSH Key' },
]

export default function CapiClusterSpecReview({ wizardContext, open }) {
  return (
    <Accordion id="capiSpecReview" title="Cluster Spec" open={open}>
      <FormReviewTable data={wizardContext} columns={columns} />
    </Accordion>
  )
}
