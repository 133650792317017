import React, { useContext, useEffect } from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { handleSetUpgradeStrategy } from '../UpgradeClusterPage'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { IconInfo } from 'core/components/validatedForm/Info'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import { partition } from 'ramda'
import TransferList from 'core/elements/TransferList'
import { WizardContext } from 'core/components/wizard/Wizard'
import Theme from 'core/themes/model'

export enum BatchUpgradeFieldTypes {
  UpgradedNodes = 'upgradedNodes',
  ToBeUpgradedNodes = 'toBeUpgradedNodes',
  BatchUpgradeNodes = 'batchUpgradeNodes',
}

const useStyles = makeStyles<Theme>((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
  },
}))

const filterByNodeVersion = (targetVersion) => (node) => {
  return node.actualKubeRoleVersion === targetVersion
}

const AdvancedBatchUpgradeField = ({ wizardContext, setWizardContext }) => (
  <CheckboxField
    id="advancedBatchUpgrade"
    label="Advanced: Batch Upgrade"
    onChange={(value) => setWizardContext(handleSetUpgradeStrategy(value, 'advancedBatchUpgrade'))}
    value={wizardContext?.advancedBatchUpgrade}
    info={`
      The selected worked nodes will be upgraded in parallel.
      Worker nodes can be manually upgraded in distinct batches.
    `}
  />
)

export const AdvancedBatchUpgradeAddonField = ({ values, defineField, setFieldValue }) => {
  const {
    wizardContext,
    setWizardContext,
  }: { wizardContext: any; setWizardContext: any } = useContext(WizardContext) as any

  const classes = useStyles()

  useEffect(() => {
    const [upgradedNodes, toBeUpgradedNodes] = partition(
      filterByNodeVersion(wizardContext.upgradeVersion),
      wizardContext.workerNodes,
    )
    setWizardContext({
      [BatchUpgradeFieldTypes.UpgradedNodes]: upgradedNodes,
      [BatchUpgradeFieldTypes.ToBeUpgradedNodes]: toBeUpgradedNodes,
    })
  }, [wizardContext.workerNodes, wizardContext.upgradeVersion])

  const updateBatchUpgradeNodes = (selectedNodes) => {
    setWizardContext({
      [BatchUpgradeFieldTypes.BatchUpgradeNodes]: selectedNodes,
    })
  }

  const excludedNodes =
    (wizardContext?.toBeUpgradedNodes?.length || 0) -
    (wizardContext?.batchUpgradeNodes?.length || 0)

  return (
    <FormFieldSection title="Batch Upgrade Strategy">
      <IconInfo
        icon="info-circle"
        title={`
          Select the nodes to upgrade in parallel, any
          remaining nodes must be upgraded in separate batches.
        `}
        spacer={false}
      >
        <Text className={classes.text} variant="body2">
          <b>Total Worker Nodes :</b>
          {wizardContext?.workerNodes?.length}
        </Text>
        <Text className={classes.text} variant="body2">
          <b>Upgraded Worker Nodes :</b>
          {wizardContext?.upgradedNodes?.length}
        </Text>

        <Text className={classes.text} variant="body2">
          <b>Nodes Excluded from Batch :</b>
          {excludedNodes}
        </Text>
      </IconInfo>
      {wizardContext?.toBeUpgradedNodes && (
        <TransferList list={wizardContext?.toBeUpgradedNodes} onUpdate={updateBatchUpgradeNodes} />
      )}
    </FormFieldSection>
  )
}

export default AdvancedBatchUpgradeField
