import React from 'react'
import { NodeState } from './model'
import Dropdown from 'core/elements/dropdown'

const options = [
  { label: 'Authorized', value: NodeState.Authorized },
  { label: 'Unauthorized', value: NodeState.Unauthorized },
]
const NodesStatePicklist = ({ onChange, ...rest }) => {
  return (
    <Dropdown
      {...rest}
      compact
      showAll
      label="State"
      name="state"
      onChange={onChange}
      items={options}
    />
  )
}

export default NodesStatePicklist
