import React, { Reducer, useCallback, useEffect, useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { CapiVpcTypes, Subnet } from '../../../model'
import Row from 'core/containers/Row'
import CapiAwsSubnetsMultiDropdown from './CapiAwsSubnetsMultiDropdown'
import { propEq, filter, mergeLeft } from 'ramda'
import { adjustWith } from 'utils/fp'
import PicklistField from 'core/components/validatedForm/DropdownField'
import CapiAwsRouteTablePicklist from './CapiAwsRouteTablePicklist'
import CapiAwsNatGatewayPicklist from './CapiAwsNatGatewayPicklist'

interface Props {
  wizardContext: any
  setWizardContext: any
  az: string
}

interface SelfManagedSubnetsReducerPayload {
  subnetsToAdd?: string[]
  subnetsToRemove?: string[]
  az?: string
  isPublic?: boolean
  subnetId?: string
  routeTableId?: string
  natGatewayId?: string
  propName?: 'routeTableId' | 'natGatewayId'
}
interface SelfManagedSubnetsReducerAction {
  type: 'add' | 'remove' | 'update'
  payload: SelfManagedSubnetsReducerPayload
}

type SelfManagedSubnetsReducer = Reducer<Array<Subnet>, SelfManagedSubnetsReducerAction>

function subnetsReducer(state, { type, payload }: SelfManagedSubnetsReducerAction) {
  switch (type) {
    case 'add': {
      const { subnetsToAdd, az, isPublic } = payload
      const subnets: Subnet[] = subnetsToAdd.map((subnetsId) => {
        return { id: subnetsId, isPublic: isPublic, availabilityZone: az }
      })
      return [...state, ...subnets]
    }
    case 'update': {
      const { natGatewayId, routeTableId, subnetId, propName } = payload
      const propValue = natGatewayId ? natGatewayId : routeTableId
      return adjustWith(propEq('id', subnetId), mergeLeft({ [propName]: propValue }), state)
    }
    case 'remove': {
      const { subnetsToRemove } = payload
      const subnets: Subnet[] = state.filter(
        (subnet: Subnet) => !subnetsToRemove.includes(subnet.id),
      )
      return [...subnets]
    }
    default:
      throw new Error()
  }
}

export default function CapiSelfManagedSubnets({ wizardContext, setWizardContext, az }: Props) {
  const classes = useStyles()
  const vpcType: CapiVpcTypes = wizardContext?.vpcType
  const vpcId: CapiVpcTypes = wizardContext?.vpc?.id

  const [privateSubnets, setPrivateSubnets] = useState([])
  const [publicSubnets, setPublicSubnets] = useState([])

  const [subnets, dispatch] = useReducer<SelfManagedSubnetsReducer>(subnetsReducer, [])
  useEffect(() => {
    const existingSubnets = filter(
      (subnet: Subnet) => subnet.availabilityZone !== az,
      wizardContext?.subnets,
    )
    setWizardContext({ subnets: [...existingSubnets, ...subnets] })
  }, [subnets, az, setWizardContext])

  const onPublicSubnetSelect = useCallback(
    (subnetsIds: string[], az: string) => {
      if (!subnetsIds) return

      const subnetsToAdd = filter(
        (subnetId: string) => !publicSubnets.includes(subnetId),
        subnetsIds,
      )
      const subnetsToRemove = filter(
        (subnetId: string) => !subnetsIds.includes(subnetId),
        publicSubnets,
      )

      if (subnetsToAdd?.length) {
        dispatch({ type: 'add', payload: { subnetsToAdd, az, isPublic: true } })
      } else if (subnetsToRemove?.length) {
        dispatch({ type: 'remove', payload: { subnetsToRemove } })
      }
      setPublicSubnets(subnetsIds)
    },
    [publicSubnets, dispatch],
  )

  const onPrivateSubnetSelect = useCallback(
    (subnetsIds) => {
      if (!subnetsIds) return

      const subnetsToAdd = filter(
        (subnetId: string) => !privateSubnets.includes(subnetId),
        subnetsIds,
      )
      const subnetsToRemove = filter(
        (subnetId: string) => !subnetsIds.includes(subnetId),
        privateSubnets,
      )

      if (subnetsToAdd?.length) {
        dispatch({ type: 'add', payload: { subnetsToAdd, az, isPublic: false } })
      } else if (subnetsToRemove?.length) {
        dispatch({ type: 'remove', payload: { subnetsToRemove } })
      }
      setPrivateSubnets(subnetsIds)
    },
    [privateSubnets, dispatch],
  )

  const onRouteTableIdChange = useCallback(
    (subnetId: string, routeTableId: string) => {
      dispatch({ type: 'update', payload: { routeTableId, subnetId, propName: 'routeTableId' } })
    },
    [dispatch],
  )

  const onNatGatewayIdChange = useCallback(
    (subnetId: string, natGatewayId: string) => {
      dispatch({ type: 'update', payload: { natGatewayId, subnetId, propName: 'natGatewayId' } })
    },
    [dispatch],
  )
  if (!vpcType || !az || !vpcId) return null
  return (
    <>
      {/* Public Subnets */}
      <br />
      <Row minItemWidth="300" gap={24} key={`row-${vpcType}-${az}-${vpcId}-public`}>
        <CapiAwsSubnetsMultiDropdown
          type="public"
          identityName={wizardContext.provider}
          roleArn={wizardContext.assumeRoleTargetArn}
          region={wizardContext.region}
          onChange={(subnetIds) => onPublicSubnetSelect(subnetIds, az)}
          vpcId={vpcId}
          az={az}
          label="Public Subnets"
        />
      </Row>
      {publicSubnets?.map((subnet) => (
        <div
          className={classes.subnetsContainer}
          key={`container-${vpcType}-${az}-${subnet}-${vpcId}`}
        >
          <Text
            key={`subnetText-${vpcType}-${az}-${subnet}`}
            className={classes.subnetIdText}
            variant="caption1"
          >
            {subnet}
          </Text>
          <Row minItemWidth="300" gap={24} key={`row-${vpcType}-${az}-${subnet}-${vpcId}`}>
            {/* Route Table Id */}
            <PicklistField
              DropdownComponent={CapiAwsRouteTablePicklist}
              id={`routeTableId-${vpcType}-${az}-${subnet}-${vpcId}`}
              key={`routeTableId-${vpcType}-${az}-${subnet}-${vpcId}`}
              label="Route Table Id"
              onChange={(value) => onRouteTableIdChange(subnet, value)}
              required
              identityName={wizardContext.provider}
              roleArn={wizardContext.assumeRoleTargetArn}
              region={wizardContext?.region}
              disabled={!wizardContext?.vpc?.id}
              vpcId={wizardContext?.vpc?.id}
              selectFirst
            />
            {/* NAT Gateway Id  */}
            <PicklistField
              DropdownComponent={CapiAwsNatGatewayPicklist}
              id={`natGatewayId-${vpcType}-${az}-${subnet}-${vpcId}`}
              key={`natGatewayId-${vpcType}-${az}-${subnet}-${vpcId}`}
              label="NAT Gateway Id"
              onChange={(value) => onNatGatewayIdChange(subnet, value)}
              required
              identityName={wizardContext.provider}
              roleArn={wizardContext.assumeRoleTargetArn}
              region={wizardContext?.region}
              disabled={!wizardContext?.vpc?.id}
              vpcId={wizardContext?.vpc?.id}
              selectFirst
            />
          </Row>
        </div>
      ))}
      <hr className={classes.divider} />

      {/* Private Subnets */}
      <Row minItemWidth="300" gap={24} key={`row-${vpcType}-${az}-${vpcId}-private`}>
        <CapiAwsSubnetsMultiDropdown
          type="private"
          identityName={wizardContext.provider}
          roleArn={wizardContext.assumeRoleTargetArn}
          region={wizardContext.region}
          onChange={onPrivateSubnetSelect}
          vpcId={vpcId}
          az={az}
          label="Private Subnets"
        />
      </Row>
      {privateSubnets?.map((subnet) => (
        <div
          className={classes.subnetsContainer}
          key={`container-${vpcType}-${az}-${subnet}-${vpcId}`}
        >
          <Text key={`subnetText-${vpcType}-${az}-${subnet}`} variant="caption1">
            {subnet}
          </Text>
          <Row minItemWidth="300" gap={24} key={`row-${vpcType}-${az}-${subnet}-${vpcId}`}>
            {/* Route Table Id */}
            <PicklistField
              DropdownComponent={CapiAwsRouteTablePicklist}
              id={`routeTableId-${vpcType}-${az}-${subnet}-${vpcId}`}
              key={`routeTableId-${vpcType}-${az}-${subnet}-${vpcId}`}
              label="Route Table Id"
              onChange={(value) => onRouteTableIdChange(subnet, value)}
              required
              identityName={wizardContext.provider}
              roleArn={wizardContext.assumeRoleTargetArn}
              region={wizardContext?.region}
              disabled={!wizardContext?.vpc?.id}
              vpcId={wizardContext?.vpc?.id}
              selectFirst
            />
          </Row>
        </div>
      ))}
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  subnetsContainer: {
    display: 'grid',
    gap: theme.spacing(1),
    // flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(3, 0),
  },
}))
