import React, { useCallback, useEffect, useRef, useState } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import WizardStep from 'core/components/wizard/WizardStep'
import Divider from 'core/elements/Divider'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import WorkerNodeGroupFields from '../AwsWorkerNodeGroupFields'
import uuid from 'uuid'
import { YamlTemplate } from 'core/components/wizard/YamlTemplates'
import machineDeployment from './schema/default/machine-deployment.json'
import machinePool from './schema/default/machine-pool.json'
import awsMachinePool from './schema/default/aws-machine-pool.json'
import awsMachineTemplate from './schema/default/worker-node-aws-machine-template.json'
import nodeletConfigTemplate from './schema/default/nodelet-config-template.json'
import nodeletConfig from './schema/default/nodelet-config.json'
import eksConfig from './schema/eks/eks-config.json'
import eksConfigTemplate from './schema/eks/eks-config-template.json'
import awsManagedMachinePool from './schema/eks/aws-managed-machine-pool.json'
import { CancellableDebounceFn, debounce } from 'utils/async'
import { AwsClusterTypes, ConfigTypes, NodeGroupTypeLabels, NodeGroupTypes } from '../../capi/model'

export const initialAwsWorkerNodeGroup = {
  type: NodeGroupTypes.MachineDeployment,
  machinePoolType: NodeGroupTypes.AwsMachinePool,
  availabilityZones: [],
  publicIP: false,
  cas: null,
  spotMarketOptions: null,
  rootVolume: {
    size: 50,
    type: 'gp3',
  },
  mpRootVolume: {
    size: 150, // AWSMachinePool don't have the non-root volume Spec, thus a bigger root volume (150GB) needs to be set
    type: 'gp3',
  },
  nonRootVolumes: [
    {
      size: 100,
      type: 'gp3',
      deviceName: '/dev/xvdb',
    },
  ],
  rollingUpdate: {
    maxUnavailable: 0,
    maxSurge: 1,
  },
  refreshPreferences: {
    minHealthyPercentage: 90,
    strategy: 'Rolling',
  },
  updateConfig: {
    maxUnavailable: 1,
  },
  scaling: {
    minSize: 1,
    maxSize: 3,
  },
  capacityType: 'onDemand',
  nodeUpdateStrategy: 'count',
  nodeCount: 3,
  ami: 'ubuntu',
  taints: [],
  amiType: 'AL2_x86_64',
  instanceType: 't2.medium',
  wgVersion: '1.23.7',
}

export const initialEksWorkerNodeGroup = {
  type: NodeGroupTypes.MachineDeployment,
  machinePoolType: NodeGroupTypes.AwsMachinePool,
  availabilityZones: [],
  cas: null,
  spotMarketOptions: null,
  rollingUpdate: {
    maxUnavailable: 0,
    maxSurge: 1,
  },
  refreshPreferences: {
    minHealthyPercentage: 90,
    strategy: 'Rolling',
  },
  updateConfig: {
    maxUnavailable: 1,
  },
  scaling: {
    minSize: 1,
    maxSize: 3,
  },
  capacityType: 'onDemand',
  nodeUpdateStrategy: 'count',
  nodeCount: 3,
  ami: 'ubuntu',
  amiType: 'AL2_x86_64',
  instanceType: 't2.medium',
  wgVersion: '1.23.7',
}

export const defaultYamlTemplates: YamlTemplate[] = [
  {
    title: NodeGroupTypes.MachineDeployment,
    schema: machineDeployment,
    hide: (values) => values.type !== NodeGroupTypes.MachineDeployment,
  },
  {
    title: NodeGroupTypeLabels.MachinePool,
    schema: machinePool,
    hide: (values) => values.type !== NodeGroupTypes.MachinePool,
  },
  {
    title: NodeGroupTypes.AwsMachinePool,
    schema: awsMachinePool,
    hide: (values) =>
      values.type !== NodeGroupTypes.MachinePool ||
      values.machinePoolType !== NodeGroupTypes.AwsMachinePool,
  },
  {
    title: NodeGroupTypes.AwsManagedMachinePool,
    schema: awsManagedMachinePool,
    hide: (values) =>
      values.type !== NodeGroupTypes.MachinePool ||
      values.machinePoolType !== NodeGroupTypes.AwsManagedMachinePool,
  },
  {
    title: NodeGroupTypes.AwsMachineTemplate,
    schema: awsMachineTemplate,
    hide: (values) => values.type !== NodeGroupTypes.MachineDeployment,
  },
  {
    title: ConfigTypes.NodeletConfigTemplate,
    schema: nodeletConfigTemplate,
    hide: (values) =>
      values.clusterType !== AwsClusterTypes.AWS ||
      values.type !== NodeGroupTypes.MachineDeployment,
  },
  {
    title: ConfigTypes.NodeletConfig,
    schema: nodeletConfig,
    hide: (values) =>
      values.clusterType !== AwsClusterTypes.AWS || values.type !== NodeGroupTypes.MachinePool,
  },
  {
    title: ConfigTypes.EksConfigTemplate,
    schema: eksConfigTemplate,
    hide: (values) =>
      values.clusterType !== AwsClusterTypes.EKS ||
      values.type !== NodeGroupTypes.MachineDeployment,
  },
  {
    title: ConfigTypes.EksConfig,
    schema: eksConfig,
    hide: (values) =>
      values.clusterType !== AwsClusterTypes.EKS || values.type !== NodeGroupTypes.MachinePool,
  },
]

const MemoizedWorkerNodeGroupFields = React.memo(WorkerNodeGroupFields)

const DeleteNodeGroupButton = ({ id, handleDelete }) => {
  const classes = useStyles()
  return (
    <button type="button" className={classes.button} onClick={() => handleDelete(id)}>
      <FontAwesomeIcon className={classes.buttonIcon}>minus-circle</FontAwesomeIcon>
      <Text variant="body2">Delete Node Group</Text>
    </button>
  )
}

const createNewWorkerNodeGroup = (isEksCluster) => {
  return {
    ...(isEksCluster ? initialEksWorkerNodeGroup : initialAwsWorkerNodeGroup),
    id: uuid.v4(),
  }
}

export function WorkerNodeGroupsFields({
  workerNodeGroups,
  deleteNodeGroup = null,
  wizardContext,
  handleChange,
  isEksCluster,
  addNewNodeGroup = null,
  baseIdx = 0,
}) {
  const classes = useStyles()
  return (
    <>
      {workerNodeGroups.map((nodeGroup, idx) => (
        <div key={nodeGroup.id} className={classes.nodeGroupSection}>
          {idx > 0 && <DeleteNodeGroupButton id={nodeGroup.id} handleDelete={deleteNodeGroup} />}
          <MemoizedWorkerNodeGroupFields
            wizardContext={wizardContext}
            values={nodeGroup}
            onChange={handleChange}
            isEksCluster={isEksCluster}
            index={baseIdx + idx}
          />
          <Divider className={classes.divider} />
        </div>
      ))}
      {addNewNodeGroup && (
        <button type="button" className={classes.button} onClick={addNewNodeGroup}>
          <FontAwesomeIcon className={classes.buttonIcon}>plus-circle</FontAwesomeIcon>
          <Text variant="body2">Add New Node Group</Text>
        </button>
      )}
    </>
  )
}

export default function CapiWorkerNodeGroupsWizardStep({
  wizardContext,
  setWizardContext,
  onNext,
  isEksCluster = false,
}) {
  const classes = useStyles()
  const debounceRef = useRef<CancellableDebounceFn>()
  const [workerNodeGroups, setWorkerNodeGroups] = useState(
    wizardContext.workerNodeGroups || [createNewWorkerNodeGroup(isEksCluster)],
  )
  const [yamlTemplates, setYamlTemplates] = useState(defaultYamlTemplates)

  useEffect(() => {
    debouncedUpdateYamlTemplates(workerNodeGroups, defaultYamlTemplates)
    setWizardContext({ workerNodeGroups })
  }, [workerNodeGroups])

  const debouncedUpdateYamlTemplates = useCallback(
    (workerNodeGroups, yamlTemplates: YamlTemplate[] = []) => {
      debounceRef.current?.cancel()
      debounceRef.current = debounce(() => {
        setYamlTemplates(
          workerNodeGroups // updating the custom values for each node group in yaml templates
            .map((nodeGroup) => {
              const setValues = () => nodeGroup
              return yamlTemplates.map((yamlTemplate) => {
                return { ...yamlTemplate, setValues }
              })
            })
            .flat(),
        )
      }, 500)
      debounceRef.current()
    },
    [workerNodeGroups],
  )

  useEffect(() => {
    // Cancel debouncing when component gets unmounted
    return () => debounceRef.current?.cancel()
  }, [])

  const addNewNodeGroup = useCallback(() => {
    setWorkerNodeGroups((prevGroups) => [...prevGroups, createNewWorkerNodeGroup(isEksCluster)])
  }, [isEksCluster])

  const deleteNodeGroup = useCallback((nodeGroupId) => {
    setWorkerNodeGroups((prevGroups) => prevGroups.filter((group) => group.id !== nodeGroupId))
  }, [])

  const handleChange = useCallback((updatedGroup) => {
    setWorkerNodeGroups((prevGroups) =>
      prevGroups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group)),
    )
  }, [])

  return (
    <WizardStep
      stepId="workerNodeGroups"
      label="Worker Node Groups"
      yamlTemplates={yamlTemplates}
      // Todo: Write tracking fn
      // onNext={awsClusterTracking.wZStepTwo(trackingFields)}
    >
      <ValidatedForm
        classes={{ root: classes.validatedFormContainer }}
        fullWidth
        initialValues={wizardContext}
        triggerSubmit={onNext}
        elevated={false}
      >
        <WorkerNodeGroupsFields
          workerNodeGroups={workerNodeGroups}
          deleteNodeGroup={deleteNodeGroup}
          wizardContext={wizardContext}
          handleChange={handleChange}
          isEksCluster={isEksCluster}
          addNewNodeGroup={addNewNodeGroup}
        />
      </ValidatedForm>
    </WizardStep>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  nodeGroupSection: {
    display: 'grid',
    gridGap: theme.spacing(3.5),
  },
  button: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[700],
    outline: 0,
    padding: 0,
    background: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonIcon: {
    marginRight: theme.spacing(2),
    fontWeight: 900,
    color: theme.palette.blue.main,
  },
  divider: {
    margin: theme.spacing(0.5, 0),
  },
}))
