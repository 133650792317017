import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

interface Props {
  ready: number
  notReady: number
  unknown: number
  numNodes: number
}

const useStyles = makeStyles<Theme>((theme) => ({
  ul: {
    padding: theme.spacing(1),
    margin: 0,
    listStyleType: 'none',
  },
}))

export default function ClusterStatusTooltip({ ready, notReady, unknown, numNodes }: Props) {
  const classes = useStyles()
  return (
    <ul className={classes.ul}>
      {ready > 0 && <li>Ready: {`${ready}/${numNodes}`}</li>}
      {notReady > 0 && <li>Not Ready: {`${notReady}/${numNodes}`}</li>}
      {unknown > 0 && <li>Unknown: {`${unknown}/${numNodes}`}</li>}
    </ul>
  )
}
