import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import getDataSelector from 'core/utils/getDataSelector'
import { cacheActions } from 'core/caching/cacheReducers'
import store from 'app/store'
import { ensureArray } from 'utils/fp'

const { dispatch } = store

const { qbert, sunpike } = ApiClient.getInstance()

const cloudProvidersSelector = getDataSelector(DataKeys.CloudProviders)

export const cloudProviderActions = ActionsSet.make<DataKeys.CloudProviders>({
  uniqueIdentifier: 'uuid',
  entityName: entityNamesByKey.CloudProviders,
  cacheKey: DataKeys.CloudProviders,
})

export const listCloudProviders = cloudProviderActions.add(
  new ListAction<DataKeys.CloudProviders>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get cloud providers')
    return qbert.getCloudProviders()
  }),
)

export const createCloudProvider = cloudProviderActions.add(
  new CreateAction<DataKeys.CloudProviders>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to create cloud provider', params)
    const result = await qbert.createCloudProvider(params)
    trackEvent('Create Cloud Provider', {
      cloud_provider_name: params.name,
      cloud_provider_type: params.type,
    })
    return result
  }),
)

export const updateCloudProvider = cloudProviderActions.add(
  new UpdateAction<DataKeys.CloudProviders, { uuid: string }>(async ({ uuid, ...data }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update cloud provider', { clusterId: uuid, ...data })
    const result = await qbert.updateCloudProvider(uuid, data)
    trackEvent('Update Cloud Provider', { uuid })
  }),
)

export const deleteCloudProvider = cloudProviderActions.add(
  new DeleteAction<DataKeys.CloudProviders, { uuid: string; name: string; type: string }>(
    async ({ uuid, name, type }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete cloud provider', {
        clusterId: uuid,
        name,
        type,
      })
      const result = await qbert.deleteCloudProvider(uuid)
      trackEvent('Delete Cloud Provider', {
        uuid,
        cloud_provider_name: name,
        cloud_provider_type: type,
      })
    },
  ),
)

const cloudProviderDetailActions = ActionsSet.make<DataKeys.CloudProviderDetails>({
  uniqueIdentifier: 'RegionName',
  indexBy: 'cloudProviderId',
  entityName: entityNamesByKey.CloudProviderDetails,
  cacheKey: DataKeys.CloudProviderDetails,
})

export const loadCloudProviderDetails = cloudProviderDetailActions.add(
  new ListAction<DataKeys.CloudProviderDetails, { cloudProviderId: string }>(
    async ({ cloudProviderId }) => {
      Bugsnag.leaveBreadcrumb('Attempting to load cloud provider details', { cloudProviderId })
      const response = await qbert.getCloudProviderDetails(cloudProviderId)
      return response.Regions
    },
  ),
)

export const loadAwsRegionInfo = new ListAction<
  DataKeys.AwsRegionInfo,
  { identityName: string; region: string; roleArn: string }
>(
  async (params) => {
    const { identityName, region, roleArn } = params
    Bugsnag.leaveBreadcrumb('Attempting to load aws region details', params)
    return await sunpike.getAwsRegionInfo(identityName, region, roleArn)
  },
  {
    cache: true,
    uniqueIdentifier: 'metadata.uid',
    indexBy: ['identityName', 'region'],
    entityName: entityNamesByKey[DataKeys.AwsRegionInfo],
    cacheKey: DataKeys.AwsRegionInfo,
  },
)

const cloudProviderRegionDetailActions = ActionsSet.make<DataKeys.CloudProviderRegionDetails>({
  uniqueIdentifier: ['cloudProviderId', 'cloudProviderRegionId'],
  indexBy: ['cloudProviderId', 'cloudProviderRegionId'],
  entityName: entityNamesByKey.CloudProviderRegionDetails,
  cacheKey: DataKeys.CloudProviderRegionDetails,
})
export const listCloudProviderRegionDetails = cloudProviderRegionDetailActions.add(
  new ListAction<
    DataKeys.CloudProviderRegionDetails,
    { cloudProviderId: string; cloudProviderRegionId: string }
  >(async ({ cloudProviderId, cloudProviderRegionId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to load cloud provide region details', {
      cloudProviderId,
      cloudProviderRegionId,
    })
    const regions = await qbert.getCloudProviderRegionDetails(
      cloudProviderId,
      cloudProviderRegionId,
    )
    // TODO: Update this  with a GetOneAction which  will handle an object repsonse
    // Here, converted the single object to an array as the current useListAction is only meant to work with array responses.
    return ensureArray(regions)
  }),
)

export const loadCloudProviderRegionDetails = cloudProviderRegionDetailActions.add(
  new CustomAction<
    DataKeys.CloudProviderRegionDetails,
    { cloudProviderId: string; cloudProviderRegionId: string }
  >(
    'loadRegionDetails',
    async ({ cloudProviderId, cloudProviderRegionId }) => {
      Bugsnag.leaveBreadcrumb('Attempting to load cloud provide region details', {
        cloudProviderId,
        cloudProviderRegionId,
      })
      // This API returns an object, not an array
      return qbert.getCloudProviderRegionDetails(cloudProviderId, cloudProviderRegionId)
    },
    (result, { cloudProviderId, cloudProviderRegionId }) => {
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: ['cloudProviderId', 'cloudProviderRegionId'],
          cacheKey: DataKeys.CloudProviderRegionDetails,
          params: { cloudProviderId, cloudProviderRegionId },
          item: result,
        }),
      )
    },
  ),
  // TODO: During implementation, make sure this works properly
)
