import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { getDeleteConfirmText } from 'core/components/CRUDListContainer'
import React, { useState, useMemo } from 'react'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteMachineDeployment } from '../../machine-deployment/actions'
import { deleteMachinePool } from '../../machine-pool/actions'
import { NodeGroupTypes } from '../../model'
import { deleteAwsMachineTemplate } from '../../aws-machine-templates/actions'

const DeleteNodeGroupsDialog = ({ rows: [nodeGroup], onClose }) => {
  const [error, setError] = useState(null)
  const {
    update: deleteMachineDeploymentFn,
    updating: deletingMachineDeployment,
  } = useUpdateAction(deleteMachineDeployment)

  const { update: deleteMachinePoolFn, updating: deletingMachinePool } = useUpdateAction(
    deleteMachinePool,
  )
  const {
    update: deleteAwsMachineTemplateFn,
    updating: deletingAwsMachineTemplate,
  } = useUpdateAction(deleteAwsMachineTemplate)

  const deleteConfirmText = useMemo(() => getDeleteConfirmText([nodeGroup]), [nodeGroup])

  const handleSubmit = async () => {
    if (!nodeGroup?.kind) return
    try {
      if (nodeGroup?.kind === NodeGroupTypes.MachineDeployment) {
        await deleteMachineDeploymentFn(nodeGroup)
        await deleteAwsMachineTemplateFn(nodeGroup)
      } else if (nodeGroup?.kind === NodeGroupTypes.MachinePool) {
        await deleteMachinePoolFn(nodeGroup)
      }
      onClose(true)
    } catch (err) {
      console.error(err)
      setError({ title: 'Error Deleting Node Group', message: err?.message })
    }
  }

  return (
    <ConfirmationDialog
      text={deleteConfirmText}
      onConfirm={handleSubmit}
      onCancel={onClose}
      error={error}
      loading={deletingMachineDeployment || deletingMachinePool || deletingAwsMachineTemplate}
      open
    />
  )
}

export default DeleteNodeGroupsDialog
