import React, { useContext } from 'react'
import { ValidatedFormContext } from 'core/components/validatedForm/ValidatedForm'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import AzureAutoscalerParamFields from './param-fields/AzureAutoscalerParamFields'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { azureAutoscalerInfo } from 'app/plugins/infrastructure/components/clusters/constants'

export const azureAutoscalingFieldId = 'enableCAS'

export default function AzureAutoScalingField({ wizardContext, setWizardContext, disabled }) {
  return (
    <CheckboxField
      id={azureAutoscalingFieldId}
      label="Enable Auto Scaling"
      info={azureAutoscalerInfo}
      disabled={disabled}
      value={wizardContext[azureAutoscalingFieldId]}
      onChange={(value) => setWizardContext({ [azureAutoscalingFieldId]: value })}
    />
  )
}

export const AzureAutoScalingAddonFields = () => {
  const { setFieldValue } = useContext(ValidatedFormContext) as any
  return (
    <FormFieldSection title={'Auto Scaling Configurations'}>
      <AzureAutoscalerParamFields setFieldValue={setFieldValue} />
    </FormFieldSection>
  )
}
