import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterPicklistDefault from 'k8s/components/common/ClusterPicklist'

const ClusterPicklist: any = ClusterPicklistDefault

export default function ClusterPicklistField({ onChange, filterFn }) {
  return (
    <PicklistField
      DropdownComponent={ClusterPicklist}
      id="clusterId"
      label=""
      onChange={onChange}
      selectFirst={false}
      compact={false}
      filterFn={filterFn}
      required
    />
  )
}
