import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { EKSClusterVersion, IEksVersionsSelector } from './model'

export const eksVersionsSelector = createSharedSelector(
  getDataSelector<DataKeys.EksVersions>(DataKeys.EksVersions),
  (versions: EKSClusterVersion[]): IEksVersionsSelector[] => {
    return versions.map((version) => ({
      ...version,
      deprecated: version.spec?.deprecated,
      name: version.metadata?.name,
    }))
  },
)
