import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'

const { keystone } = ApiClient.getInstance()

const regionActions = ActionsSet.make<DataKeys.Regions>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.Regions,
  cacheKey: DataKeys.Regions,
  cache: false,
})

export const listRegions = regionActions.add(
  new ListAction<DataKeys.Regions>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to load Regions')
    return keystone.getRegions()
  }),
)
