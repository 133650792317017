import React, { useMemo } from 'react'
import { loadCloudProviderDetails } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import Checkbox from 'core/elements/input/Checkbox'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import useListAction from 'core/hooks/useListAction'
import { cloudProviderDetailsSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))

interface Props {
  cloudProviderId: string
  onChange: any
  value: string[]
  className: string
  clusters?: any
}

const RegionsChecklist = ({ cloudProviderId, onChange, value, className, clusters }: Props) => {
  const classes = useStyles()

  const handleChange = (e, region) => {
    if (onChange) {
      onChange(region)
    }
  }
  const { loading } = useListAction(loadCloudProviderDetails, {
    params: { cloudProviderId },
    requiredParams: ['cloudProviderId'],
  })
  const details = useSelectorWithParams(cloudProviderDetailsSelector, { cloudProviderId })

  const regionCount = useMemo(() => {
    // AKS & GKE both need this, uses cluster.location
    if (clusters) {
      return clusters.reduce((accum, cluster) => {
        return {
          ...accum,
          [cluster.location]: cluster?.clusters?.length,
        }
      }, {})
    }
    return null
  }, [clusters])

  const regions = useMemo(() => {
    const regionList = details
      .map((r) => {
        return r.RegionName
      })
      .sort()
    if (regionCount) {
      // There is no region list for GKE... determine from clusters
      if (!regionList.length) {
        return Object.keys(regionCount).sort()
      }
      // Want to trim down region list for AKS
      return regionList.filter((region) => regionCount[region])
    }
    return regionList
  }, [details, regionCount])

  return (
    <div className={clsx(classes.nowrap, className)}>
      <Text variant="caption1" className={classes.title}>
        Regions
      </Text>
      {loading ? (
        <Text variant="body2">
          <FontAwesomeIcon spin>sync</FontAwesomeIcon> Loading regions...
        </Text>
      ) : regions?.length ? (
        <>
          {regions.map((region) => (
            <Checkbox
              key={region}
              label={regionCount ? `${region} (${regionCount[region]})` : region}
              onChange={(e) => handleChange(e, region)}
              checked={value.includes(region)}
            />
          ))}
        </>
      ) : (
        <Text variant="body2">No regions available</Text>
      )}
    </div>
  )
}

export default RegionsChecklist
