import Accordion from 'core/components/accordion/Accordion'
import FormReviewTable from 'core/components/validatedForm/review-table'
import Divider from 'core/elements/Divider'
import Text from 'core/elements/Text'
import React, { useMemo } from 'react'
import { getPMKMangedSubnetReviewData, getSelfMangedSubnetReviewData } from '../capiUtils'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { CapiVpcTypes, Subnet } from '../../../model'
import SelfMangedSubnetReview from './SelfMangedSubnetReview'

const columns = [
  {
    id: 'vpcType',
    label: 'VPC Type',
    render: (value) => (value === CapiVpcTypes.PmkManaged ? 'PMK Managed' : 'Self Managed'),
  },
  { id: 'vpc.cidrBlock', label: 'VPC CIDR Block' },
  { id: 'azs', label: 'Availability Zones' },
]

const selfManagedVpcColumns = [
  { id: 'vpcId', label: 'VPC ID' },
  { id: 'internetGatewayId:', label: 'Internet Gateway ID' },
]

const pmkManagedSubnetConfigColumns = [
  { id: 'publicSubnetsCount', label: 'No of Public Subnets' },
  { id: 'privateSubnetsCount', label: 'No of Private Subnets' },
  { id: 'publicSubnetsCidr', label: 'Public Subnets CIDR' },
  { id: 'privateSubnetsCidr', label: 'Private Subnets CIDR' },
]

export default function CapiVpcConfigReview({ wizardContext, open }) {
  const vpcType: CapiVpcTypes = wizardContext?.vpcType

  const getReviewData =
    vpcType === CapiVpcTypes.SelfManaged
      ? getSelfMangedSubnetReviewData
      : getPMKMangedSubnetReviewData
  const data = useMemo(() => getReviewData(wizardContext?.subnets), [
    wizardContext?.subnets,
    getReviewData,
  ])

  const vpcColumns =
    vpcType === CapiVpcTypes.SelfManaged ? [...columns, ...selfManagedVpcColumns] : columns

  const isPublicSubnet = (subnet: Subnet) => subnet.isPublic
  const isPrivateSubnet = (subnet: Subnet) => !subnet.isPublic

  const classes = useStyles()
  return (
    <Accordion open={open} id="vpcConfigReview" title="VPC Configuration">
      <>
        <FormReviewTable data={wizardContext} columns={vpcColumns} />
        <Text variant="caption1" className={classes.title}>
          Subnet Config
        </Text>
        {data &&
          vpcType === CapiVpcTypes.PmkManaged &&
          Object.keys(data).map((key: string) => (
            <>
              <Divider className={classes.divider} />
              <Text className={classes.title} key={`${key}-title`} variant="caption1">
                {key}
              </Text>
              <FormReviewTable
                key={`${key}-table`}
                data={data[key]}
                columns={pmkManagedSubnetConfigColumns}
              />
            </>
          ))}

        {data &&
          vpcType === CapiVpcTypes.SelfManaged &&
          Object.keys(data).map((key: string) => (
            <>
              <Divider className={classes.divider} />
              <Text key={`${key}-title`} className={classes.title} variant="caption1">
                {key}
              </Text>
              <SelfMangedSubnetReview
                isPublic={true}
                items={data[key].filter(isPublicSubnet)}
              ></SelfMangedSubnetReview>
              <SelfMangedSubnetReview
                isPublic={false}
                items={data[key].filter(isPrivateSubnet)}
              ></SelfMangedSubnetReview>
            </>
          ))}
      </>
    </Accordion>
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  divider: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  title: {
    marginLeft: theme.spacing(1),
  },
}))
