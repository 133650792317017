import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { assocPath } from 'ramda'
import { INodeletControlPlaneSelector } from './model'
import { awsMachineTemplatesByNamespaceAndNameSelector } from '../../aws-machine-templates/selectors'
import { EtcdBackupTypes } from '../../../aws/capi/CapiEtcdBackupFields'

const getMachineTemplateResource = (machineTemplateRef, awsMachineTemplatesByNamespaceAndName) => {
  const { namespace, name } = machineTemplateRef || {}
  return awsMachineTemplatesByNamespaceAndName?.[namespace]?.[name]
}

export const nodeletControlPlanesSelector: AppSelector<INodeletControlPlaneSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.NodeletControlPlanes>(DataKeys.NodeletControlPlanes),
  awsMachineTemplatesByNamespaceAndNameSelector,
  (
    rawNodeletControlPlanes,
    awsMachineTemplatesByNamespaceAndName,
  ): INodeletControlPlaneSelector[] => {
    return rawNodeletControlPlanes.map((controlPlane) => {
      const etcdBackup = controlPlane?.spec?.etcdBackup || []
      const etcdDailyBackup = etcdBackup.find((b) => b.name === EtcdBackupTypes.Daily)
      const etcdIntervalBackup = etcdBackup.find((b) => b.name === EtcdBackupTypes.Interval)
      const machineTemplateInfraRef = controlPlane?.spec?.machineTemplate?.infrastructureRef
      const machineTemplateResource = getMachineTemplateResource(
        machineTemplateInfraRef,
        awsMachineTemplatesByNamespaceAndName,
      )
      const machineTemplateResources = machineTemplateResource?.resources || []

      return {
        ...controlPlane,
        uuid: controlPlane.metadata?.uid,
        name: controlPlane.metadata?.name,
        clusterName: controlPlane.metadata?.labels?.['cluster.x-k8s.io/cluster-name'],
        namespace: controlPlane.metadata?.namespace,
        creationTimestamp: controlPlane.metadata?.creationTimestamp,
        privileged: controlPlane.spec?.apiserver?.privileged,
        version: controlPlane.spec?.version,
        replicas: {
          total: controlPlane.status?.replicas,
          ready: controlPlane.status?.readyReplicas,
          unavailable: controlPlane.status?.unavailableReplicas,
          updated: controlPlane.status?.updatedReplicas,
          desired: controlPlane.spec?.replicas,
        },
        cniPlugin: !!controlPlane.spec?.cni?.calico
          ? 'Calico'
          : !!controlPlane.spec?.cni?.flannel
          ? 'Flannel'
          : null,
        allowWorkloadsOnMaster: controlPlane.spec?.schedulable,
        machineTemplate: controlPlane.spec?.machineTemplate,
        route53: controlPlane?.spec?.dns?.route53,
        httpProxy: controlPlane?.spec?.machineTemplate?.nodeletConfigSpec?.httpProxy,
        calico: controlPlane?.spec?.cni?.calico,
        flannel: controlPlane?.spec?.cni?.flannel,
        etcdBackup,
        etcdDailyBackup,
        etcdIntervalBackup,
        etcdBackupStatus: controlPlane.status?.etcdBackupStatus || [],
        k8sVersion: controlPlane.spec?.version,
        machineTemplateResource,
        resources: [controlPlane, ...machineTemplateResources].filter((r) => !isNilOrEmpty(r)),
      }
    })
  },
)

type NodeletControlPlanesByNamespaceAndNameSelectorModel = {
  [namespace: string]: {
    [name: string]: INodeletControlPlaneSelector
  }
}

export const nodeletControlPlanesByNamespaceAndNameSelector = createSharedSelector(
  nodeletControlPlanesSelector,
  (controlPlanes): NodeletControlPlanesByNamespaceAndNameSelectorModel =>
    controlPlanes.reduce((accum, controlPlane) => {
      const { namespace, name } = controlPlane
      return assocPath([namespace, name], controlPlane, accum)
    }, emptyObj),
)
