import Accordion from 'core/components/accordion/Accordion'
import FormReviewTable from 'core/components/validatedForm/review-table'
import React from 'react'
import { ApiServerLoadBalancerTypeLabels, SchemeTypeLabels } from '../../../model'

const columns = [
  {
    id: 'apiServerLoadBalancerType',
    label: 'API Server Load Balancer Type',
    render: (value) => ApiServerLoadBalancerTypeLabels[value],
  },
  {
    id: 'scheme',
    label: 'Scheme',
    render: (value) => SchemeTypeLabels[value],
  },
  {
    id: 'crossZoneLoadBalancing',
    label: 'Cross Zone Load Balancing',
    render: (value) => (value ? 'True' : 'False'),
  },
]

export default function CapiAdvancedLoadBalancerConfigReview({ wizardContext, open }) {
  return (
    <Accordion open={open} id="awsLbConfig" title="AWS Load Balancer Configuration">
      <FormReviewTable data={wizardContext?.controlPlaneLoadBalancer ?? {}} columns={columns} />
    </Accordion>
  )
}
