import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { assocPath } from 'ramda'
import { ICapiAwsClusterSelector } from './model'
import {
  getCloudProviderName,
  getIdentityResource,
  getRoleArn,
  getSubnetConfigs,
} from '../../capi/helpers'

export const capiAwsClustersSelector: AppSelector<ICapiAwsClusterSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.CapiAwsClusters>(DataKeys.CapiAwsClusters),
  getDataSelector<DataKeys.AwsClusterRoleIdentities>(DataKeys.AwsClusterRoleIdentities),
  getDataSelector<DataKeys.AwsClusterStaticIdentities>(DataKeys.AwsClusterStaticIdentities),
  (
    rawAwsCapiClusters,
    rawAwsClusterRoleIdentities,
    rawAwsClusterStaticIdentities,
  ): ICapiAwsClusterSelector[] => {
    return rawAwsCapiClusters.map((cluster) => {
      const subnets = cluster.spec?.network?.subnets
      const subnetConfigs = getSubnetConfigs(cluster?.spec?.network?.subnets)
      const identityRef = cluster?.spec?.identityRef
      const identityResource = getIdentityResource(
        identityRef,
        rawAwsClusterRoleIdentities,
        rawAwsClusterStaticIdentities,
      )
      return {
        ...cluster,
        uuid: cluster.metadata?.uid,
        name: cluster.metadata?.name,
        namespace: cluster.metadata?.namespace,
        creationTimestamp: cluster.metadata?.creationTimestamp,
        vpcId: cluster?.spec?.network?.vpc?.id,
        vpcCidrBlock: cluster.spec?.network?.vpc?.cidrBlock,
        internetGatewayId: cluster?.spec?.network?.vpc?.internetGatewayId,
        controlPlaneLoadBalancer: cluster.spec?.controlPlaneLoadBalancer,
        region: cluster.spec?.region,
        sshKey: cluster.spec?.sshKeyName,
        ready: cluster.status?.ready,
        subnets,
        subnetConfigs,
        identityRef,
        availabilityZones: Object.keys(subnetConfigs),
        cloudProviderName: getCloudProviderName(identityRef?.name),
        awsAssumeRole: identityRef?.kind === 'AWSClusterRoleIdentity',
        roleArn:
          identityRef?.kind === 'AWSClusterRoleIdentity'
            ? getRoleArn(identityRef?.name, rawAwsClusterRoleIdentities)
            : undefined,
        tags: cluster.spec?.additionalTags,
        resources: [cluster, identityResource].filter((r) => !isNilOrEmpty(r)),
      }
    })
  },
)

type CapiAwsClustersByNamespaceAndNameSelectorModel = {
  [namespace: string]: {
    [name: string]: ICapiAwsClusterSelector
  }
}

export const capiAwsClustersByNamespaceAndNameSelector = createSharedSelector(
  capiAwsClustersSelector,
  (capiClusters): CapiAwsClustersByNamespaceAndNameSelectorModel =>
    capiClusters.reduce((accum, cluster) => {
      const { namespace, name } = cluster
      return assocPath([namespace, name], cluster, accum)
    }, emptyObj),
)
