import { createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from '../combinedClusters/selectors'
import { nodesSelector } from '../nodes/selectors'
import { pathEq } from 'ramda'
import {
  getLegacyClusterIssues,
  getImportedClusterIssues,
  getCapiClusterIssues,
  getNodeIssues,
} from './helpers'

interface Issue {
  type: string
  status: string
  message: string
  link: string
  resourceType: string
  resourceName: string
  id: string
}

// Todo: may need to add capi clusters
export const insightIssuesSelector = createSharedSelector(
  allClustersSelector,
  nodesSelector,
  (allClusters, nodes): Issue[] => {
    const clusters = allClusters.filter(pathEq(['clusterType'], 'normal'))
    const importedClusters = allClusters.filter(pathEq(['clusterType'], 'imported'))
    const capiClusters = allClusters.filter(pathEq(['clusterType'], 'capi'))

    const clusterIssues = getLegacyClusterIssues(clusters)
    const importedClusterIssues = getImportedClusterIssues(importedClusters)
    const capiClusterIssues = getCapiClusterIssues(capiClusters)
    const nodeIssues = getNodeIssues(nodes)

    return [...clusterIssues, ...importedClusterIssues, ...capiClusterIssues, ...nodeIssues]
  },
)
