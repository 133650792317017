import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

export const awsMachinePoolActions = ActionsSet.make<DataKeys.AwsMachinePools>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.AwsMachinePools],
  cacheKey: DataKeys.AwsMachinePools,
})

export const listAwsMachinePools = awsMachinePoolActions.add(
  new ListAction<DataKeys.AwsMachinePools>(async ({ clusterName }: { clusterName: string }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get AWS  Machine Pools', { clusterName })
    return sunpike.getAwsMachinePools(clusterName)
  }),
)

export const updateAwsMachinePool = awsMachinePoolActions.add(
  new UpdateAction<DataKeys.AwsMachinePools, { namespace: string; name: string; body: unknown }>(
    async ({ namespace, name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update AWS Machine Pool', {
        namespace,
        name,
        body,
      })
      return sunpike.updateAwsMachinePool(namespace, name, body)
    },
  ),
)
