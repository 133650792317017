import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import UpdateAction from 'core/actions/UpdateAction'

const { sunpike } = ApiClient.getInstance()

export const awsManagedControlPlaneActions = ActionsSet.make<DataKeys.AwsManagedControlPlanes>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.AwsManagedControlPlanes],
  cacheKey: DataKeys.AwsManagedControlPlanes,
})

export const listAwsManagedControlPlanes = awsManagedControlPlaneActions.add(
  new ListAction<DataKeys.AwsManagedControlPlanes>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get CAPI AWS Managed Control Planes', params)
    return sunpike.getAwsManagedControlPlanes()
  }),
)

export const updateAwsManagedControlPlane = awsManagedControlPlaneActions.add(
  new UpdateAction<
    DataKeys.AwsManagedControlPlanes,
    { namespace: string; name: string; body: unknown; updateMethod: 'PATCH' | 'PUT' }
  >(async ({ namespace, name, body, updateMethod = 'PUT' }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update CAPI AWS Managed Control Plane', {
      namespace,
      name,
      body,
    })
    const updateFn =
      updateMethod === 'PUT'
        ? sunpike.updateAwsManagedControlPlane
        : sunpike.patchAwsManagedControlPlane
    return updateFn(namespace, name, body)
  }),
)
