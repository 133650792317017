import Card from 'core/elements/card'
import useParams from 'core/hooks/useParams'
import { identity } from 'ramda'
import React, { useEffect } from 'react'
import { ICapiClusterSelector } from '../../model'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Divider from 'core/elements/Divider'
import Progress from 'core/components/progress/Progress'
import { CapiResourceKind } from 'app/plugins/infrastructure/components/clusters/aws/capi/model'
import useFetchApi from 'core/hooks/useFetchApi'
import ApiClient from 'api-client/ApiClient'
import { PrometheusResponse } from './model'
import CardHeader from 'core/elements/card/CardHeader'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import moment from 'moment'
import ResourceUsageGraph from 'app/plugins/infrastructure/components/common/ResourceUsageGraph'

const { sunpike } = ApiClient.getInstance()

const timestampSteps = {
  // for use in moment.add
  '48.h': [4, 'h'],
  '24.h': [2, 'h'],
  '12.h': [1, 'h'],
  '6.h': [45, 'm'],
}
const items = [
  { label: '48 Hours', value: '48.h' },
  { label: '24 Hours', value: '24.h' },
  { label: '12 Hours', value: '12.h' },
  { label: '6 Hours', value: '6.h' },
]

const makeQObj = (query) => ({ query: `ui:${query}` })
const makeQSumObj = (query) => ({ query: `sum(ui:${query})` })

const totalCPUCores = makeQObj('cluster_cpu_total')
const currentCPUUsage = makeQObj('cluster_cpu_usage:sum_rate5m')
const totalCPURequests = makeQObj('cluster_cpu_requests')

const totalMEMAvailable = makeQObj('cluster_mem_available:sum')
const currentMEMUsage = makeQObj('cluster_mem_usage:sum')
const totalMEMRequests = makeQObj('cluster_mem_requests')

const availStorage = makeQSumObj('cluster_storage_available')
const usedStorage = makeQSumObj('cluster_storage_usage')

function makeTsQuery(chartTime, base) {
  const end = moment().unix()
  const [number, period] = chartTime.split('.')
  // @ts-ignore not sure how to fix TS complaining about moment
  const start = moment
    .unix(end)
    .subtract(number, period)
    .unix()
  const step = timestampSteps[chartTime].join('')
  return { ...base, start, end, step }
}

function getValueFromPrometheusResponse(
  items: PrometheusResponse['result'] = [],
  parser = identity as any,
) {
  if (!items.length) {
    return 0
  }
  if (items.length === 1) {
    return parser(items[0].value[1])
  }
  return items.map((item) => parser(item.value[1]))
}

function getPreciseValue(
  usage = 0,
  unit = '',
  valueConverter = identity as any, //todo:fix typings
  precision = 1,
) {
  return `${valueConverter(usage).toFixed(precision)} ${unit}`
}

export default function CapiClusterOverviewResources({
  cluster,
}: {
  cluster: ICapiClusterSelector
}) {
  const { params, getParamsUpdater } = useParams({
    chartTime: '48.h',
  })

  useEffect(() => {
    reloadCpuUsageTSItems()
    reloadCpuCoreItems()
    reloadCpuUsageItems()
    reloadCpuRequestItems()
    reloadMemAvailableItems()
    reloadMemUsageItems()
    reloadMemUsageTSItems()
    reloadMemRequestItems()
    reloadStorageUsedItems()
    reloadStorageAvailableItems()
    reloadStorageAvailableTSItems()
  }, [params.chartTime])

  const {
    items: cpuUsageTSItems = {},
    reload: reloadCpuUsageTSItems,
    loading: loadingCpuUsageTSItems,
  } = useFetchApi(
    sunpike.queryCCPrometheus,
    makeTsQuery(params.chartTime, currentCPUUsage),
    cluster,
    'query_range',
  )

  const {
    items: cpuCoreItems,
    reload: reloadCpuCoreItems,
    loading: loadingCpuCoreItems,
  } = useFetchApi(sunpike.queryCCPrometheus, totalCPUCores, cluster)
  const {
    items: cpuUsageItems,
    reload: reloadCpuUsageItems,
    loading: loadingCpuUsageItems,
  } = useFetchApi(sunpike.queryCCPrometheus, currentCPUUsage, cluster)
  const {
    items: cpuRequestItems,
    reload: reloadCpuRequestItems,
    loading: loadingCpuRequestItems,
  } = useFetchApi(sunpike.queryCCPrometheus, totalCPURequests, cluster)
  const {
    items: memAvailableItems,
    reload: reloadMemAvailableItems,
    loading: loadingMemAvailableItems,
  } = useFetchApi(sunpike.queryCCPrometheus, totalMEMAvailable, cluster)
  const {
    items: memUsageItems,
    reload: reloadMemUsageItems,
    loading: loadingMemUsageItems,
  } = useFetchApi(sunpike.queryCCPrometheus, currentMEMUsage, cluster)
  const {
    items: memUsageTSItems = {},
    reload: reloadMemUsageTSItems,
    loading: loadingMemUsageTSItems,
  } = useFetchApi(
    sunpike.queryCCPrometheus,
    makeTsQuery(params.chartTime, currentMEMUsage),
    cluster,
    'query_range',
  )
  const {
    items: memRequestItems,
    reload: reloadMemRequestItems,
    loading: loadingMemRequestItems,
  } = useFetchApi(sunpike.queryCCPrometheus, totalMEMRequests, cluster)

  const {
    items: storageUsedItems,
    reload: reloadStorageUsedItems,
    loading: loadingStorageUsedItems,
  } = useFetchApi(sunpike.queryCCPrometheus, usedStorage, cluster)
  const {
    items: storageAvailableItems,
    reload: reloadStorageAvailableItems,
    loading: loadingStorageAvailableItems,
  } = useFetchApi(sunpike.queryCCPrometheus, availStorage, cluster)
  const {
    items: storageAvailableTSItems = {},
    reload: reloadStorageAvailableTSItems,
    loading: loadingStorageAvailableTSItems,
  } = useFetchApi(
    sunpike.queryCCPrometheus,
    makeTsQuery(params.chartTime, usedStorage),
    cluster,
    'query_range',
  )

  const loadingSomething =
    loadingCpuUsageTSItems ||
    loadingCpuCoreItems ||
    loadingCpuUsageItems ||
    loadingCpuRequestItems ||
    loadingMemAvailableItems ||
    loadingMemUsageItems ||
    loadingMemUsageTSItems ||
    loadingMemRequestItems ||
    loadingStorageUsedItems ||
    loadingStorageAvailableItems ||
    loadingStorageAvailableTSItems

  const cpuCores = getValueFromPrometheusResponse(cpuCoreItems, parseFloat)
  const cpuUsage = getValueFromPrometheusResponse(cpuUsageItems, parseFloat)
  const cpuRequests = getValueFromPrometheusResponse(cpuRequestItems, parseFloat)
  const memAvailable = getValueFromPrometheusResponse(memAvailableItems, parseFloat)
  const memUsage = getValueFromPrometheusResponse(memUsageItems, parseFloat)
  const memRequests = getValueFromPrometheusResponse(memRequestItems, parseFloat)
  const storageUsed = getValueFromPrometheusResponse(storageUsedItems, parseFloat)
  const storageAvailable = getValueFromPrometheusResponse(storageAvailableItems, parseFloat)

  // const toMHz = (value: number) => value * 1024
  const btToGb = (value: number) => value / 1024 / 1024 / 1024

  const classes = useStyles()
  const ResourceBlock = ({ title, value }) => (
    <div className={classes.resourcesColumn}>
      <Text variant="caption1" className={classes.contentTitle}>
        {title}
      </Text>
      <Text variant="caption1" className={classes.contentText}>
        {value}
      </Text>
    </div>
  )

  const CPU = () => (
    <>
      <Text variant="caption1" className={classes.title}>
        CPU
      </Text>
      <div className={classes.resourcesContainer}>
        <ResourceBlock title="Used" value={getPreciseValue(cpuUsage, 'CPUs', undefined, 2)} />
        <ResourceBlock
          title="Available"
          value={getPreciseValue(cpuCores - cpuUsage, 'CPUs', undefined, 2)}
        />
        <ResourceBlock
          title="Requests"
          value={getPreciseValue(cpuRequests, 'CPUs', undefined, 2)}
        />
        <ResourceUsageGraph data={cpuUsageTSItems?.[0]?.values} name="CPU Used" />
      </div>
    </>
  )
  const Memory = () => (
    <>
      <Text variant="caption1" className={classes.title}>
        Memory
      </Text>
      <div className={classes.resourcesContainer}>
        <ResourceBlock title="Used" value={getPreciseValue(memUsage, 'Gi', btToGb, 2)} />
        <ResourceBlock title="Available" value={getPreciseValue(memAvailable, 'Gi', btToGb, 2)} />
        <ResourceBlock title="Requests" value={getPreciseValue(memRequests, 'Gi', btToGb, 2)} />
        <ResourceUsageGraph
          data={memUsageTSItems?.[0]?.values}
          name="Memory Used"
          valueTransformer={btToGb}
        />
      </div>
    </>
  )
  const Storage = () => (
    <>
      <Text variant="caption1" className={classes.title}>
        Storage
      </Text>
      <div className={classes.resourcesContainer}>
        <ResourceBlock title="Used" value={getPreciseValue(storageUsed, 'Gi', btToGb, 0)} />
        <ResourceBlock
          title="Available"
          value={getPreciseValue(storageAvailable, 'Gi', btToGb, 0)}
        />
        <div />
        <ResourceUsageGraph
          data={storageAvailableTSItems?.[0]?.values}
          name="Storage Used"
          valueTransformer={btToGb}
        />
      </div>
    </>
  )
  if (!cluster || !cluster?.uuid) {
    return null
  }
  return (
    <Progress loading={loadingSomething} overlay>
      <Card
        title={
          <CardHeader className={classes.cardHeader}>
            <p>Resources</p>
            <AsyncDropdown
              selectFirst
              compact
              items={items}
              onChange={getParamsUpdater('chartTime')}
              value={params.chartTime}
            />
          </CardHeader>
        }
        className={classes.container}
      >
        <CPU />
        <Divider className={classes.divider} />
        <Memory />
        <Divider className={classes.divider} />
        <Storage />
      </Card>
    </Progress>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  cardHeader: {
    display: 'grid',
    gridAutoFlow: 'column',
  },
  container: {
    maxWidth: 800,
    height: 500,
  },
  resourcesContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr 1fr 1fr 25rem',
  },
  title: { marginLeft: theme.spacing(3) },
  summaryContent: {
    display: 'flex',
    background: theme.components.frame.accentBackground,
  },
  resourcesColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    margin: '18px 16px 0px 24px',
  },
  contentTitle: {
    color: theme.components.typography.passive,
  },
  barSubtitle: {
    width: 320,
    textAlign: 'right',
    color: theme.components.typography.passive,
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  emptyText: {
    marginTop: '25%', // center the text
    marginLeft: '40%',
  },
}))
