import React, { useCallback } from 'react'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { detachNodes } from '../clusters/newActions'
import { deauthNode } from './new-actions'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'

export default function NodeForceRemoveDialog({
  rows: [node],
  onClose,
}: GridDialogProps<INodesSelector>) {
  const { update: detach, updating: isDetaching, error: detachError } = useUpdateAction(detachNodes)
  const { update: deauth, updating: deAuthorizing, error: deauthError, reset } = useUpdateAction(
    deauthNode,
  )
  const handleSubmit = useCallback(async () => {
    if (node.clusterUuid) {
      const { success: detachSuccess } = await detach({
        cluster: { uuid: node?.clusterUuid },
        nodes: [node.uuid],
      })

      if (!detachSuccess) {
        return
      }

      if (detachSuccess) {
        const { success: deauthSuccess } = await deauth({ node })
        if (!deauthSuccess) {
          return
        }
        reset()
        onClose()
      }
    } else {
      const { success: deauthSuccess } = await deauth({ node })
      if (!deauthSuccess) {
        return
      }
      reset()
      onClose()
    }
  }, [deauth, detach])

  return (
    <ConfirmationDialog
      title="Node Force Remove"
      error={detachError || deauthError}
      text={
        <>
          You are about to de-authorize the node {node?.name} ({node?.primaryIp}).
          <br />
          <br />
          {node.clusterUuid && (
            <>
              Force Removal of the Node will detach the node from its associated cluster{' '}
              <b>({node.clusterName})</b> and deauthorize it from Platform9.
              {!!node?.isMaster && (
                <>
                  <br />
                  <br />
                  <b>
                    The selected node is a master, and removing it might lead to etcd quorum loss
                    and cluster being non-functional.
                  </b>
                </>
              )}
              <br />
              <br />
              Are you sure you want to force remove this node?
            </>
          )}
        </>
      }
      confirmText="Remove Node"
      loading={isDetaching || deAuthorizing}
      open
      onCancel={onClose}
      onConfirm={handleSubmit}
    />
  )
}
