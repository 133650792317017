import React, { useMemo, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import Row from 'core/containers/Row'
import { mergeRight } from 'ramda'
import { validators } from 'core/utils/fieldValidators'

export const enum NodeUpdateStrategyTypes {
  Count = 'count',
  Percentage = 'percentage',
}
const rollingUpdateTypeOptions = [
  { label: 'Count', value: NodeUpdateStrategyTypes.Count },
  { label: 'Percentage', value: NodeUpdateStrategyTypes.Percentage },
]

const convertToPercentageStr = (value: string | number) => {
  if (!value) return value
  return value.toString() + '%'
}

// Rolling Update 'count' should be integer and 'percentage' should be string as per the crd
const formatValue = (valueType: NodeUpdateStrategyTypes, value: string | number) => {
  return valueType === NodeUpdateStrategyTypes.Count
    ? Number(typeof value === 'string' ? value.replace('%', '') : value)
    : convertToPercentageStr(value)
}

const parseValue = (value: string | number) => {
  return typeof value === 'string' ? value.replace('%', '') : value
}

const nodeUpdateStrategyField = (
  <DropdownField
    DropdownComponent={Dropdown}
    id="nodesUpdateStrategy"
    label="Strategy"
    value="rollingUpdate"
    disabled
    items={[{ label: 'Rolling Update', value: 'rollingUpdate' }]}
  />
)

interface Props {
  workerNodeGroup: any
  onChange: (fieldId: string) => (value: Record<string, unknown>) => void
  // Full page form or modal form
  containerType?: 'form' | 'modal'
}

export default function CapiMachineDeploymentNodeUpdateStrategies({
  workerNodeGroup,
  onChange,
  containerType = 'form',
}: Props) {
  const classes = useStyles()
  const [maxSurgeType, setMaxSurgeType] = useState(
    workerNodeGroup?.maxSurgeType || NodeUpdateStrategyTypes.Count,
  )
  const [maxUnavailableType, setMaxUnavailableType] = useState(
    workerNodeGroup?.maxUnavailableType || NodeUpdateStrategyTypes.Count,
  )

  const { rollingUpdate } = workerNodeGroup

  useEffect(() => {
    if (!workerNodeGroup?.maxSurgeType || workerNodeGroup.maxSurgeType === maxSurgeType) return
    setMaxSurgeType(workerNodeGroup.maxSurgeType)
  }, [workerNodeGroup?.maxSurgeType])

  useEffect(() => {
    if (
      !workerNodeGroup?.maxUnavailableType ||
      workerNodeGroup.maxUnavailableType === maxUnavailableType
    )
      return
    setMaxUnavailableType(workerNodeGroup.maxUnavailableType)
  }, [workerNodeGroup?.maxUnavailableType])

  const maxSurgeFields = useMemo(
    () => (
      <div className={classes.fields}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="maxSurgeType"
          label="Max Surge - Type"
          value={maxSurgeType}
          items={rollingUpdateTypeOptions}
          onChange={(value) => {
            setMaxSurgeType(value),
              onChange('rollingUpdate')(
                mergeRight(rollingUpdate, {
                  maxSurge: formatValue(value, rollingUpdate?.maxSurge),
                }),
              )
          }}
        />
        <TextField
          id="maxSurgeValue"
          label="Max Surge - Value"
          icon={maxSurgeType === NodeUpdateStrategyTypes.Percentage ? 'percent' : null}
          value={parseValue(rollingUpdate?.maxSurge)}
          validations={[validators.rangeValue(1, 100)]}
          onChange={(value) => {
            onChange('rollingUpdate')(
              mergeRight(rollingUpdate, {
                maxSurge: formatValue(maxSurgeType, value),
              }),
            )
          }}
          required
        />
      </div>
    ),
    [maxSurgeType, rollingUpdate, onChange],
  )

  const maxUnavailableFields = useMemo(
    () => (
      <div className={classes.fields}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="maxUnavailableType"
          label="Max Unavailable - Type"
          value={maxUnavailableType}
          items={rollingUpdateTypeOptions}
          onChange={(value) => {
            setMaxUnavailableType(value),
              onChange('rollingUpdate')(
                mergeRight(rollingUpdate, {
                  maxUnavailable: formatValue(value, rollingUpdate?.maxUnavailable),
                }),
              )
          }}
        />
        <TextField
          id="maxUnavailableValue"
          label="Max Unavailable - Value"
          icon={maxUnavailableType === NodeUpdateStrategyTypes.Percentage ? 'percent' : null}
          validations={[validators.rangeValue(0, 100)]}
          value={parseValue(rollingUpdate?.maxUnavailable)}
          onChange={(value) => {
            onChange('rollingUpdate')(
              mergeRight(rollingUpdate, {
                maxUnavailable: formatValue(maxUnavailableType, value),
              }),
            )
          }}
          required
        />
      </div>
    ),
    [maxUnavailableType, rollingUpdate, onChange],
  )

  return (
    <>
      {containerType === 'form' ? (
        <>
          <Text variant="caption1">Nodes Update Strategy</Text>
          <Row minItemWidth="300">{nodeUpdateStrategyField}</Row>
          <Row minItemWidth="300">{maxSurgeFields}</Row>
          <Row minItemWidth="300">{maxUnavailableFields}</Row>
        </>
      ) : (
        <>
          {nodeUpdateStrategyField}
          <div className={classes.updateFieldsContainer}>
            {maxSurgeFields}
            {maxUnavailableFields}
          </div>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  updateFieldsContainer: {
    display: 'grid',
    gridGap: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  fields: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))
