import store from 'app/store'
import { makeParamsAllClustersSelector } from './selectors'
import { CombinedClusterSelector } from './model'
import { ClusterParams } from 'app/plugins/infrastructure/components/common/model'
import { isNilOrEmpty, ensureArray } from 'utils/fp'
import { allKey } from 'app/constants'

const allClustersSelector = makeParamsAllClustersSelector()

export default function parseClusterIdsFromParams<P extends ClusterParams>(params: P): string[] {
  const state = store.getState()
  const { clusterId } = params
  if (isNilOrEmpty(clusterId) || clusterId === allKey) {
    const allClusters: CombinedClusterSelector[] = allClustersSelector(state, params)
    return allClusters.map((cluster) => cluster.uuid)
  }
  return ensureArray(clusterId)
}
