import React, { useMemo } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { compareVersions } from 'k8s/util/helpers'

const items = [
  { label: 'Docker', value: 'docker' },
  { label: 'Containerd', value: 'containerd' },
]

interface Props {
  kubeRoleVersion?: string
}

export default function ContainerRuntimePicklist({
  onChange,
  name = 'containerRuntime',
  label = 'Container Runtime',
  kubeRoleVersion,
  disabled,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const isDisabled = useMemo(() => {
    if (!kubeRoleVersion) {
      return false
    }
    if (compareVersions(kubeRoleVersion, '1.21') < 0) {
      onChange('docker')
      return true
    }
    if (compareVersions(kubeRoleVersion, '1.24') >= 0) {
      onChange('containerd')
      return true
    }
    return false
  }, [kubeRoleVersion])

  return (
    <AsyncDropdown
      name={name}
      label={label}
      onChange={onChange}
      items={items}
      showAll={false}
      disabled={isDisabled || disabled}
      {...rest}
    />
  )
}
