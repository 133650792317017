import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalLink from 'core/components/ExternalLink'
import { googlePrerequisitesLink } from 'k8s/links'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { RegionAvailability } from '../helpers'
import { CloudProviders } from '../model'
import CloudProviderRegionField from 'app/plugins/infrastructure/components/clusters/form-components/CloudProviderRegionField'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { loadCloudProviderDetails } from '../new-actions'
import { cloudProviderDetailsSelector } from '../selectors'

const useStyles = makeStyles((theme: Theme) => ({
  spaceRight: {
    marginRight: theme.spacing(4),
  },
  checkIcon: {
    color: theme.components.graph.success,
    marginRight: theme.spacing(1),
  },
  timesIcon: {
    color: theme.components.graph.error,
    marginRight: theme.spacing(1),
  },
  selectionArea: {
    display: 'grid',
    gap: 8,
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
}

const GoogleCloudProviderVerification = ({ wizardContext, setWizardContext }: Props) => {
  const classes = useStyles({})

  const { loading: regionsLoading } = useListAction(loadCloudProviderDetails, {
    params: {
      cloudProviderId: wizardContext.cloudProviderId,
    },
    requiredParams: ['cloudProviderId'],
  })
  const regions = useSelectorWithParams(cloudProviderDetailsSelector, {
    cloudProviderId: wizardContext.cloudProviderId,
  })

  // Regions expected to return empty, not sure if it may change in the future
  if (!regions.length) {
    return null
  }

  return (
    <>
      <FormFieldSection
        title="Google Region Availability"
        link={
          <ExternalLink textVariant="caption2" url={googlePrerequisitesLink}>
            Google Help
          </ExternalLink>
        }
      >
        <Text variant="body2">
          Platform9 deploys Kubernetes clusters into specified Google Regions.
        </Text>
        <div className={classes.selectionArea}>
          <CloudProviderRegionField
            cloudProviderType={CloudProviders.Gcp}
            onChange={(value) => setWizardContext({ region: value })}
            values={wizardContext}
            wizardContext={wizardContext}
          />
          {wizardContext.cloudProviderId && !regionsLoading && (
            <RegionAvailability classes={classes} regions={regions} />
          )}
        </div>
      </FormFieldSection>
    </>
  )
}

export default GoogleCloudProviderVerification
