import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

import AlarmsListPage from 'k8s/components/alarms/AlarmsListPage'
import EnableMonitoringCard from './enable-monitoring-card'

export default function ClusterAlarms({ cluster }) {
  const classes = useStyles()
  if (!cluster || !cluster?.uuid) {
    // we only render this component if a cluster exists so
    // this check is more or less just a sanity check
    return null
  }
  if (!cluster.hasPrometheus) {
    return <EnableMonitoringCard cluster={cluster} />
  }
  return (
    <section className={classes.clusterAlarms}>
      <AlarmsListPage clusterId={cluster.uuid} />
    </section>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  clusterAlarms: {
    paddingTop: theme.spacing(2),
  },
}))
