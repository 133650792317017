import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import createCRUDActions from 'core/helpers/createCRUDActions'
import DataKeys from 'k8s/DataKeys'
import { getClusterAddonBody } from './helpers'
import { makeClusterAddonsSelector, monitoringAddonsSelector } from './selectors'
import createContextLoader from 'core/helpers/createContextLoader'
import { ClusterAddonType } from './model'

const { qbert } = ApiClient.getInstance()

export const monitoringAddonActions = createCRUDActions(DataKeys.MonitoringAddons, {
  listFn: async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get monitoring addons')
    return qbert.getAllClusterAddons('type=monitoring')
  },
  uniqueIdentifier: 'metadata.uid',
  selector: monitoringAddonsSelector,
})

export const clusterAddonActions = createCRUDActions(DataKeys.ClusterAddons, {
  listFn: async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster addons')
    return qbert.getClusterAddons(clusterId)
  },
  createFn: async ({ clusterId, type, overrideParams, version }) => {
    const body = getClusterAddonBody({ clusterId, type, overrideParams, version })
    const result = await qbert.addClusterAddon(body)
    if (type === ClusterAddonType.Monitoring) {
      monitoringAddonActions.list()
    }
    return result
  },
  updateFn: async ({ clusterId, type, name, overrideParams, version }) => {
    const body = getClusterAddonBody({ clusterId, type, overrideParams, version })
    const result = await qbert.editClusterAddon(name, body)
    if (type === ClusterAddonType.Monitoring) {
      monitoringAddonActions.list()
    }
    return result
  },
  deleteFn: async ({ name, type }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster addon', { addon: name, type })
    await qbert.deleteClusterAddon(name)
    if (type === ClusterAddonType.Monitoring) {
      monitoringAddonActions.list()
    }
  },
  uniqueIdentifier: ['spec.clusterID', 'spec.type'],
  indexBy: 'clusterId',
  selectorCreator: makeClusterAddonsSelector,
})

export const loadAddonVersions = createContextLoader(
  DataKeys.AddonVersions,
  async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get addon versions')
    return qbert.getAddonVersions(clusterId)
  },
  {
    uniqueIdentifier: 'metadata.id',
    indexBy: 'clusterId',
  },
)
