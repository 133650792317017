import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ClusterConnectionStatus } from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import React from 'react'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'

import { ClusterTypes } from '../model'

export default function ConnectionStatusCell({
  item: cluster,
}: GridCellProps<CombinedClusterSelector>) {
  if (cluster?.clusterType === ClusterTypes.Capi) {
    return null
  }
  return (
    <ClusterConnectionStatus
      iconStatus={cluster.connectionStatus === 'converging'}
      cluster={cluster}
    />
  )
}
