import ConfirmationDialog from 'core/components/ConfirmationDialog'
import useScopedPreferences from 'core/session/useScopedPreferences'
import React from 'react'
import Text from 'core/elements/Text'
import { capitalizeString } from 'utils/misc'
import { switchCase } from 'utils/fp'
import { UserPreferences } from 'app/constants'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'

const getDefaultsValue = (defaultsKey, item) =>
  switchCase(
    {
      [UserPreferences.Cluster]: { uuid: item.uuid },
      [UserPreferences.Namespace]: {
        [item.clusterId]: { namespace: item.name },
      },
    },
    {},
  )(defaultsKey)

interface Props<T> extends GridDialogProps<T> {
  defaultsKey: UserPreferences
  type: string
}

export default function SetAsDefaultDialog<T extends { name: string }>({
  rows,
  onClose,
  defaultsKey,
  type = '',
}: Props<T>) {
  const item = rows.length >= 1 ? rows[0] : null
  const { updateUserDefaults } = useScopedPreferences('defaults')

  const handleSubmit = () => {
    if (!item || !defaultsKey) return
    updateUserDefaults(defaultsKey, getDefaultsValue(defaultsKey, item))
    onClose()
  }
  return (
    <ConfirmationDialog
      title={`Set Default ${capitalizeString(type)}`}
      text={<Text variant="body1">{`Set ${item?.name} as the default ${type}?`}</Text>}
      open
      onCancel={onClose}
      onConfirm={handleSubmit}
    />
  )
}
