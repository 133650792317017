import React from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import Badge from 'core/elements/badge'
import SimpleLink from 'core/components/SimpleLink'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Progress from 'core/components/progress/Progress'

const useStyles = makeStyles((theme: Theme) => ({
  issues: {
    background: theme.components.table.background,
    border: `1px solid ${theme.components.table.border}`,
    borderRadius: 4,
  },
  issuesHeader: {
    padding: '16px 24px',
    borderBottom: `1px solid ${theme.components.table.border}`,
  },
  issuesTableContainer: {
    height: 317,
    background: theme.components.table.background,
  },
  issuesTable: {
    maxHeight: '100%',
    overflowY: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    alignContent: 'start',
    gap: '1px 0px',
    background: theme.components.table.border,
  },
  issuesRow: {
    display: 'contents',
  },
  issuesColumn: {
    padding: '8px 16px',
    background: theme.components.table.background,
    display: 'grid',
    alignContent: 'center',
    '&:first-child': {
      paddingLeft: '24px',
    },
    '&:last-child': {
      paddingRight: '24px',
    },
  },
  passiveText: {
    color: theme.components.typography.passive,
  },
  noIssues: {
    display: 'grid',
    alignContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  check: {
    color: theme.components.badge.success.color,
    marginBottom: 8,
  },
}))

export default function IssuesTable({ issues, loading }) {
  const classes = useStyles()
  return (
    <div className={classes.issues}>
      <Progress inline loading={loading}>
        {issues.length > 0 ? (
          <>
            <div className={classes.issuesHeader}>
              <Text variant="subtitle2">Active Issues</Text>
            </div>
            <div className={classes.issuesTableContainer}>
              <div className={classes.issuesTable}>
                {issues.map((issue) => {
                  return (
                    <div key={issue.id} className={classes.issuesRow}>
                      <div className={classes.issuesColumn}>
                        <Badge variant={issue.type} text={issue.status} />
                      </div>
                      <div className={classes.issuesColumn}>
                        <Text variant="body2">{issue.message}</Text>
                      </div>
                      <div className={classes.issuesColumn}>
                        <Text variant="body2">
                          <SimpleLink src={issue.link}>{issue.resourceName}</SimpleLink>
                        </Text>
                        <Text variant="body2" className={classes.passiveText}>
                          {issue.resourceType}
                        </Text>
                      </div>
                      <div className={classes.issuesColumn}>
                        <SimpleLink src={issue.link}>
                          <FontAwesomeIcon size="md">angle-right</FontAwesomeIcon>
                        </SimpleLink>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        ) : (
          <div className={classes.noIssues}>
            <div>
              <FontAwesomeIcon solid size="3x" className={classes.check}>
                circle-check
              </FontAwesomeIcon>
            </div>
            <Text variant="h4">No issues found</Text>
            <Text variant="body1">Active infrastructure issues will show up here</Text>
          </div>
        )}
      </Progress>
    </div>
  )
}
