import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import { nodeApiServerHealthStatusFields } from 'app/plugins/infrastructure/components/nodes/helpers'
import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'

export default function NodeApiHealthCell({ item: node }: GridCellProps<INodesSelector>) {
  if (!node.isMaster) {
    return null
  }
  const status = node.api_responding ? 'online' : 'offline'
  const fields = nodeApiServerHealthStatusFields[status]

  return (
    <ClusterStatusSpan title={fields.message} status={fields.clusterStatus}>
      {fields.label}
    </ClusterStatusSpan>
  )
}
