import React from 'react'
import Dropdown from 'core/elements/dropdown'
import DropdownField from 'core/components/validatedForm/DropdownField'
import { NodeGroupTypes } from '../capi/model'

const options = [
  { label: 'Machine Deployment (Default)', value: NodeGroupTypes.MachineDeployment },
  { label: 'Machine Pool', value: NodeGroupTypes.MachinePool },
]

export default function WorkerNodeGroupTypesPicklist({
  id,
  value,
  onChange,
  required = true,
  info,
}) {
  return (
    <DropdownField
      DropdownComponent={Dropdown}
      id={id}
      label="Type"
      items={options}
      value={value}
      onChange={onChange}
      required={required}
      tooltip={info}
    />
  )
}
