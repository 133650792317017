import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { assocPath } from 'ramda'
import {
  getCloudProviderName,
  getIdentityResource,
  getRoleArn,
  getSubnetConfigs,
} from '../../helpers'
import { IAwsManagedControlPlaneSelector } from './model'

const getEndpointAccessLabel = (endpointAccess) => {
  if (endpointAccess.private && endpointAccess.public) {
    return 'Private + Public'
  } else if (endpointAccess.public) {
    return 'Public'
  } else if (endpointAccess.private) {
    return 'Private'
  }
  return null
}

export const awsManagedControlPlanesSelector: AppSelector<IAwsManagedControlPlaneSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.AwsManagedControlPlanes>(DataKeys.AwsManagedControlPlanes),
  getDataSelector<DataKeys.AwsClusterRoleIdentities>(DataKeys.AwsClusterRoleIdentities),
  getDataSelector<DataKeys.AwsClusterStaticIdentities>(DataKeys.AwsClusterStaticIdentities),
  (
    rawAwsManagedControlPlanes,
    rawAwsClusterRoleIdentities,
    rawAwsClusterStaticIdentities,
  ): IAwsManagedControlPlaneSelector[] => {
    return rawAwsManagedControlPlanes.map((controlPlane) => {
      const subnetConfigs = getSubnetConfigs(controlPlane?.spec?.network?.subnets)
      const identityRef = controlPlane?.spec?.identityRef
      const identityResource = getIdentityResource(
        identityRef,
        rawAwsClusterRoleIdentities,
        rawAwsClusterStaticIdentities,
      )
      const endpointAccess = controlPlane?.spec?.endpointAccess || {}
      return {
        ...controlPlane,
        uuid: controlPlane.metadata?.uid,
        name: controlPlane.metadata?.name,
        namespace: controlPlane.metadata?.namespace,
        creationTimestamp: controlPlane.metadata?.creationTimestamp,
        disableVPCCNI: controlPlane.spec?.disableVPCCNI,
        identityRef,
        logging: controlPlane.spec?.logging,
        secondaryCidrBlock: controlPlane.spec?.secondaryCidrBlock,
        sshKey: controlPlane.spec?.sshKeyName,
        version: controlPlane.spec?.version,
        conditions: controlPlane.status?.conditions,
        availabilityZones: Object.keys(subnetConfigs),
        cloudProviderName: getCloudProviderName(identityRef?.name),
        roleArn:
          identityRef?.kind === 'AWSClusterRoleIdentity'
            ? getRoleArn(identityRef?.name, rawAwsClusterRoleIdentities)
            : undefined,
        endpointAccess,
        endpointAccessLabel: getEndpointAccessLabel(endpointAccess),
        subnetConfigs,
        awsAssumeRoleEnabled: identityRef?.kind === 'AWSClusterRoleIdentity',
        vpcId: controlPlane.spec?.network?.vpc?.id,
        vpcCidrBlock: controlPlane.spec?.network?.vpc?.cidrBlock,
        region: controlPlane.spec?.region,
        tags: controlPlane.spec?.additionalTags,
        replicas: {},
        etcdBackupStatus: [],
        resources: [controlPlane, identityResource].filter((r) => !isNilOrEmpty(r)),
        addons: controlPlane?.spec?.addons,
      }
    })
  },
)

type AwsManagedControlPlanesByNamespaceAndNameSelectorModel = {
  [namespace: string]: {
    [name: string]: any
  }
}

export const awsManagedControlPlanesByNamespaceAndNameSelector = createSharedSelector(
  awsManagedControlPlanesSelector,
  (controlPlanes): AwsManagedControlPlanesByNamespaceAndNameSelectorModel =>
    controlPlanes.reduce((accum, controlPlane) => {
      const { namespace, name } = controlPlane
      return assocPath([namespace, name], controlPlane, accum)
    }, emptyObj),
)
