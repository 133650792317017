import React, { useState } from 'react'
import Button from 'core/elements/button'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import UploadGoogleJson from './UploadGoogleJson'
import Text from 'core/elements/Text'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import TextField from 'core/components/validatedForm/TextField'
import {
  cloudProviderValidator,
  jsonValidator,
  requiredValidator,
} from 'core/utils/fieldValidators'
import Info from 'core/components/validatedForm/Info'
import CheckCloudAccessDialog from '../check-cloud-access-dialog'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'

const useStyles = makeStyles((theme: Theme) => ({
  inCardSubmit: {
    marginTop: theme.spacing(2.5),
  },
  fullWidth: {
    width: '100% !important',
  },
  wizardLabel: {
    margin: theme.spacing(1, 0),
  },
  info: {
    marginBottom: theme.spacing(4),
  },
  downloadIcon: {
    marginRight: theme.spacing(0.5),
  },
}))

const customCodeMirrorOptions = {
  mode: 'json',
}

const codeMirrorValidations = [requiredValidator, jsonValidator]

interface Props {
  wizardContext: any
  setWizardContext: any
  showSubmitInCard: boolean
  updateWizard: boolean
  errorMessage: string
  showInfo?: boolean
}

const GoogleCloudProviderFields = ({
  wizardContext,
  setWizardContext,
  showSubmitInCard = false,
  updateWizard = false,
  errorMessage = '',
  showInfo = true,
}: Props) => {
  const { info, inCardSubmit, fullWidth, wizardLabel, downloadIcon } = useStyles({})
  const [showCloudAccessDialog, setShowCloudAccessDialog] = useState(false)

  return (
    <>
      {showCloudAccessDialog && (
        <CheckCloudAccessDialog onClose={() => setShowCloudAccessDialog(false)} />
      )}
      {showInfo && (
        <Info expanded={false} className={info}>
          <div>
            To manage GKE clusters, the following requirements must be met, otherwise Platform9 will
            not be able to import and manage those GKE Clusters. A service account within your
            Google Cloud project with the following roles must be created:
          </div>
          <ul>
            <li>Compute Viewer: roles/compute.viewer</li>
            <li>Project Viewer: roles/viewer</li>
            <li>Kubernetes Engine Admin: roles/container.admin</li>
            <li>Service Account User: roles/iam.serviceAccountUser</li>
          </ul>
          <Button
            type="button"
            variant="secondary"
            icon="download"
            onClick={() => setShowCloudAccessDialog(true)}
          >
            Check Cloud Access
          </Button>
        </Info>
      )}
      <FormFieldSection title="Create Google Cloud Provider">
        <TextField
          id="name"
          label="Cloud Provider Name"
          onChange={(value) => setWizardContext({ name: value })}
          value={wizardContext.name}
          info="Name for Cloud Provider"
          disabled={updateWizard}
          validations={[cloudProviderValidator]}
          required
        />
        <CodeMirror
          variant="light"
          label={
            <>
              <Text variant="caption1" className={wizardLabel}>
                Cloud Provider JSON
              </Text>
              <Text variant="body2">Copy/Paste or upload your JSON key below.</Text>
            </>
          }
          id="json"
          options={customCodeMirrorOptions}
          onChange={(value) => setWizardContext({ json: value })}
          value={wizardContext.json}
          className={fullWidth}
          validations={codeMirrorValidations}
        />
        <UploadGoogleJson onChange={(value) => setWizardContext({ json: value })} />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </FormFieldSection>
      {showSubmitInCard && (
        <div className={inCardSubmit}>
          <Button disabled type="submit">
            Update Cloud Provider
          </Button>
        </div>
      )}
    </>
  )
}

export default GoogleCloudProviderFields
