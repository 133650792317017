import React, { useMemo } from 'react'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const clusterVersionIconMap = {
  EarlyAccess: {
    icon: 'info-circle',
    iconText: 'Early Access',
    tooltipText: 'Early Access',
  },
  Active: {
    icon: 'check-circle',
    iconText: 'Active',
    tooltipText: null,
  },
  Supported: {
    icon: 'check-circle',
    iconText: 'Supported',
    tooltipText: null,
  },
  Deprecated: {
    icon: 'exclamation-circle',
    iconText: 'Deprecated',
    tooltipText: 'Deprecated',
  },
  EndOfLife: {
    icon: 'exclamation-circle',
    iconText: 'End Of Life',
    tooltipText: 'End Of Life',
  },
}

export default function ClusterVersionIcon({ version, clusterVersions, showIconText = false }) {
  const classes = useStyles()

  const cleanVersion = version?.length >= 1 && version[0] === 'v' ? version.slice(1) : version

  const clusterVersion = useMemo(
    () => clusterVersions.find((clusterVersion) => clusterVersion.version === cleanVersion),
    [version, clusterVersions],
  )

  const earlyAccessVersion = clusterVersion?.phase === 'EarlyAccess'
  const activeVersion = clusterVersion?.phase === 'Active'
  const supportedVersion = clusterVersion?.phase === 'Supported'
  const deprecatedVersion = clusterVersion?.phase === 'Deprecated'
  const endOfLifeVersion = clusterVersion?.phase === 'EndOfLife'

  const { icon, iconText, tooltipText } = clusterVersionIconMap[clusterVersion?.phase] || {}

  return (
    <div className={classes.container}>
      <Tooltip message={tooltipText}>
        <FontAwesomeIcon
          solid
          className={clsx(classes.icon, {
            earlyAccessVersion,
            deprecatedVersion,
            activeVersion,
            supportedVersion,
            endOfLifeVersion,
          })}
        >
          {icon}
        </FontAwesomeIcon>
      </Tooltip>
      {showIconText && <Text variant="body2">{iconText}</Text>}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  icon: {
    '&.earlyAccessVersion': {
      color: theme.palette.blue.main,
    },
    '&.activeVersion': {
      color: theme.palette.green.main,
    },
    '&.supportedVersion': {
      color: theme.palette.green.main,
    },
    '&.deprecatedVersion': {
      color: theme.palette.yellow.main,
    },
    '&.endOfLifeVersion': {
      color: theme.palette.red.main,
    },
  },
}))
