import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

export const capiAwsClusterActions = ActionsSet.make<DataKeys.CapiAwsClusters>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.CapiAwsClusters],
  cacheKey: DataKeys.CapiAwsClusters,
})

export const listCapiAwsClusters = capiAwsClusterActions.add(
  new ListAction<DataKeys.CapiAwsClusters>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get CAPI AWSCluster', params)
    return sunpike.getCapiAwsClusters()
  }),
)

export const updateCapiAwsCluster = capiAwsClusterActions.add(
  new UpdateAction<
    DataKeys.CapiAwsClusters,
    { namespace: string; name: string; body: unknown; updateMethod: 'PATCH' | 'PUT' }
  >(async ({ namespace, name, body, updateMethod = 'PUT' }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update CAPI AWSCluster', { namespace, name, body })
    const updateFn =
      updateMethod === 'PUT' ? sunpike.updateCapiAwsCluster : sunpike.partiallyUpdateCapiAwsCluster
    return updateFn(namespace, name, body)
  }),
)
