import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

export const listEksAmiTypes = new ListAction<DataKeys.EksAmiTypes, { identityName: string }>(
  async ({ identityName }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get EKS AMI Types')
    return sunpike.getEksAmiTypes(identityName)
  },
  {
    uniqueIdentifier: 'metadata.name',
    entityName: entityNamesByKey[DataKeys.EksAmiTypes],
    cacheKey: DataKeys.EksAmiTypes,
  },
)
