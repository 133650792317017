import React, { useEffect, useMemo, useState } from 'react'
import { loadCloudProviderDetails } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { discoverExternalClusters } from './actions'
import { indexBy, prop } from 'ramda'
import ListTableField from 'core/components/validatedForm/ListTableField'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { ClusterCloudPlatforms } from 'app/constants'
import { ensureArray } from 'utils/fp'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { cloudProviderDetailsSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'

const renderImported = (_, { pf9Registered }) =>
  pf9Registered ? <Text variant="caption2">Imported</Text> : null

const columns = {
  [ClusterCloudPlatforms.EKS]: [
    { id: 'name', label: 'Name' },
    { id: 'id', label: 'ID' },
    { id: 'vpc', label: 'VPC' },
    { id: 'imported', label: '', render: renderImported },
  ],
  [ClusterCloudPlatforms.AKS]: [
    { id: 'name', label: 'Name' },
    { id: 'id', label: 'ID' },
    { id: 'vpc', label: 'VPC' },
    { id: 'imported', label: '', render: renderImported },
  ],
  [ClusterCloudPlatforms.GKE]: [
    { id: 'name', label: 'Name' },
    { id: 'zone', label: 'Zone' },
    { id: 'id', label: 'ID' },
    { id: 'vpc', label: 'VPC' },
    { id: 'imported', label: '', render: renderImported },
  ],
}

const useStyles = makeStyles<Theme>((theme) => ({
  listContainer: {
    marginBottom: theme.spacing(2),
  },
  errorContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  flexGrow: {
    flexGrow: 1,
  },
  errorMessage: {
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(2),
    background: theme.components.frame.background,
  },
  apiRequestErrorMessage: {
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(1),
  },
}))

const clusterIsNotImported = (cluster) => !cluster.pf9Registered

const getErrorText = (e) => {
  if (typeof e.err === 'string') {
    return e.err
  } else if (e.err?.message) {
    return e.err.message
  } else {
    return JSON.stringify(e)
  }
}

interface Props {
  cloudProviderId: string
  selectedRegions: string[]
  stack: ClusterCloudPlatforms
  onChange: any
  value: any
  className: string
  onClustersLoad?: any
  toggleRegion?: any
}

interface ApiRequestError {
  regions?: string[]
  errorMessage: string
}

const ClustersChecklists = ({
  cloudProviderId,
  selectedRegions,
  stack,
  onChange,
  value,
  className,
  onClustersLoad,
  toggleRegion,
}: Props) => {
  const classes = useStyles()
  const [loadingClusters, setLoadingClusters] = useState(false)

  const { loading } = useListAction(loadCloudProviderDetails, {
    params: { cloudProviderId },
    requiredParams: ['cloudProviderId'],
  })
  const details = useSelectorWithParams(cloudProviderDetailsSelector, { cloudProviderId })

  const [allClusters, setAllClusters] = useState([])
  const [loadingByRegion, setLoadingByRegion] = useState({})
  const [apiRequestError, setApiRequestError] = useState<ApiRequestError>(null)

  const fetchClusters = useMemo(() => {
    return {
      eks: ({ selectedRegions, cloudProviderId, stack }) => {
        const unpopulatedRegions = selectedRegions.filter((region) => !loadingByRegion[region])
        if (!unpopulatedRegions.length) {
          return
        }
        setLoadingClusters(true)
        const placeholderResult = unpopulatedRegions.reduce(
          (accum, region) => ({ ...accum, [region]: true }),
          loadingByRegion,
        )
        setLoadingByRegion(placeholderResult)
        const retrieveClusters = async () => {
          try {
            const result = await discoverExternalClusters({
              provider: stack,
              cloudProviderId,
              regions: unpopulatedRegions,
            })
            setApiRequestError(null)
            const populatedRegions = allClusters.map((region) => region.region)
            const missingRegions = result.filter(
              (region) => !populatedRegions.includes(region.region),
            )
            setAllClusters([...allClusters, ...missingRegions])
          } catch (err) {
            setApiRequestError({
              regions: unpopulatedRegions,
              errorMessage: getErrorText(err),
            })
            if (toggleRegion) {
              for (const region of unpopulatedRegions) {
                toggleRegion(region)
              }
            }
            const placeholderResult = unpopulatedRegions.reduce(
              (accum, region) => ({ ...accum, [region]: false }),
              loadingByRegion,
            )
            setLoadingByRegion(placeholderResult)
          } finally {
            setLoadingClusters(false)
          }
        }
        retrieveClusters()
      },
      aks: ({ allRegions, cloudProviderId, stack }) => {
        if (allClusters.length) {
          return
        }
        setLoadingClusters(true)
        const getClusters = async () => {
          try {
            const externalClusters = await discoverExternalClusters({
              provider: stack,
              cloudProviderId: cloudProviderId,
            })
            setApiRequestError(null)
            setAllClusters(externalClusters)
            if (onClustersLoad) {
              onClustersLoad(externalClusters)
            }
          } catch (err) {
            setApiRequestError({
              regions: allRegions,
              errorMessage: getErrorText(err),
            })
          } finally {
            setLoadingClusters(false)
          }
        }
        if (allRegions.length) {
          getClusters()
        }
      },
      gke: ({ cloudProviderId, stack }) => {
        if (allClusters.length) {
          return
        }
        setLoadingClusters(true)
        const getClusters = async () => {
          try {
            const externalClusters = await discoverExternalClusters({
              provider: stack,
              cloudProviderId: cloudProviderId,
            })
            setApiRequestError(null)
            setAllClusters(externalClusters)
            if (onClustersLoad) {
              onClustersLoad(externalClusters)
            }
          } catch (err) {
            setApiRequestError({
              errorMessage: getErrorText(err),
            })
          } finally {
            setLoadingClusters(false)
          }
        }
        getClusters()
      },
    }
  }, [allClusters, toggleRegion])

  const allRegions = useMemo(() => {
    return details
      .map((r) => {
        return r.RegionName
      })
      .sort()
  }, [details])

  const clustersByRegion = useMemo(() => {
    if (!allClusters.length) {
      return {}
    }
    if (stack === ClusterCloudPlatforms.EKS) {
      return indexBy(prop('region'), allClusters)
    } else if ([ClusterCloudPlatforms.AKS, ClusterCloudPlatforms.GKE].includes(stack)) {
      return indexBy(prop('location'), allClusters)
    }
    return {}
  }, [allClusters, stack])

  useEffect(() => {
    // GKE will always return an empty region list, so bypass condition on GKE
    if ((fetchClusters[stack] && allRegions?.length) || stack === ClusterCloudPlatforms.GKE) {
      fetchClusters[stack]({ selectedRegions, allRegions, cloudProviderId, stack })
    }
  }, [selectedRegions, allRegions, stack])

  const renderErrorMessage = (regions, error) => (
    <div className={classes.errorContainer} key={regions?.toString() || error}>
      {regions && ensureArray(regions).length <= 5 ? (
        <Text variant="body2">
          Failed to retrieve clusters in the <b>{ensureArray(regions).join(',')}</b> region
          {regions.length > 1 ? 's' : ''} due to the following error:
        </Text>
      ) : (
        <Text>Failed to retrieve clusters due to the following error: </Text>
      )}
      <Text variant="body2" className={classes.errorMessage}>
        {error}
      </Text>
    </div>
  )

  return (
    <div className={className}>
      <Text variant="caption1" className={classes.title}>
        Clusters
      </Text>
      {loadingClusters && (
        <Text variant="body2">
          <FontAwesomeIcon spin>sync</FontAwesomeIcon> Loading clusters...
        </Text>
      )}
      {!loadingClusters && !selectedRegions.length && !apiRequestError && (
        <Text variant="body2">
          Select region(s) to the left to see available clusters in the region.
        </Text>
      )}
      {!loadingClusters &&
        apiRequestError &&
        renderErrorMessage(apiRequestError.regions, apiRequestError.errorMessage)}
      {!loadingClusters &&
        selectedRegions.map((region) => {
          const regionData = clustersByRegion[region]
          const regionError = regionData?.error
          const regionClusters = regionData?.clusters || []
          if (regionError) {
            return renderErrorMessage([region], regionError)
          }

          return (
            <div key={region}>
              {regionData && (
                <div className={classes.listContainer}>
                  <ListTableField
                    id={region}
                    data={regionClusters}
                    loading={false}
                    columns={columns[stack]}
                    onChange={(value) => onChange(value, region)}
                    value={value[region]}
                    checkboxCond={clusterIsNotImported}
                    extraToolbarContent={
                      <Text className={classes.flexGrow} variant="body1">
                        {region}
                      </Text>
                    }
                    multiSelection
                  />
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default ClustersChecklists
