import React, { FC, useCallback, useEffect } from 'react'
import { UserPreferences } from 'app/constants'
import WizardStep from 'core/components/wizard/WizardStep'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ExternalLink from 'core/components/ExternalLink'
import { awsPrerequisitesLink } from 'k8s/links'
import {
  CloudDefaults,
  CloudProviders,
} from 'app/plugins/infrastructure/components/cloudProviders/model'
import ClusterNameField from '../../form-components/NameField'
import CloudProviderField from '../../form-components/CloudProviderField'
import CloudProviderRegionField from '../../form-components/CloudProviderRegionField'
import AwsAvailabilityZoneField from '../AwsAvailabilityZone'
import SshKeyField from '../../form-components/SshKeyPicklist'
import AwsClusterSshKeyPicklist from '../AwsClusterSshKeyPicklist'
import OperatingSystemField from '../../form-components/OperatingSystemField'
import MasterNodeInstanceTypeField from '../../form-components/MasterNodeInstanceType'
import NumMasterNodesField from '../../form-components/NumMasterNodesField'
import NumWorkerNodesField from '../../form-components/NumWorkerNodesField'
import AwsRegionFlavorPicklist from '../AwsRegionFlavorPicklist'
import AllowWorkloadsonMasterField from '../../form-components/AllowWorkloadsOnMasterField'
import ClusterTemplatesField from '../../form-components/ClusterTemplatesField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AwsCustomNetworkingFields from '../AwsCustomNetworkingFields'
import ApiFqdnField from '../../form-components/ApiFqdnField'
import ServicesFqdnField from '../../form-components/ServicesFqdnField'
import ContainerAndServicesCidr from '../../form-components/container-and-services-cidr'
import HttpProxyField from '../../form-components/HttpProxyField'
import NetworkBackendField from '../../form-components/NetworkBackendField'
import CalicoNetworkFields from '../../form-components/CalicoNetworkFields'
import PrivilegedField from '../../form-components/PrivilegedContainersField'
import TagsField, { FormattedTags } from '../../form-components/TagsField'
import CustomAmiField from '../../form-components/CustomAmi'
import AdvancedApiConfigFields from '../../form-components/AdvancedApiConfigFields'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { capitalizeString, castBoolToStr } from 'utils/misc'
import { Divider } from '@material-ui/core'
import KubernetesVersionField from '../../form-components/KubernetesVersionField'
import Text from 'core/elements/Text'
import { AddonManagerAddon, AddonTogglers } from '../../cluster-addons/cluster-addon-manager'
import WorkerNodeInstanceTypeField from '../../form-components/WorkerNodeInstanceTypeField'
import { ClusterCreateTypes } from '../../model'
import { awsClusterTracking } from '../../tracking'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import CustomApiFlagsFields from '../../form-components/CustomApiFlagsFields'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import ContainerRuntimePicklist from '../../form-components/ContainerRuntimePicklist'
import PicklistField from 'core/components/validatedForm/DropdownField'
import { compareVersions } from 'k8s/util/helpers'
import { etcdBackupDefaults } from '../../cluster-addons/etcd-backup'

export const initialContext = {
  template: 'small',
  ami: 'ubuntu',
  masterFlavor: 't2.medium',
  workerFlavor: 't2.medium',
  numMasters: 1,
  numWorkers: 1,
  numMinWorkers: 1,
  enableCAS: false,
  usePf9Domain: false,
  network: 'newPublic',
  containersCidr: '10.20.0.0/16',
  servicesCidr: '10.21.0.0/16',
  networkPlugin: 'flannel',
  mtuSize: 1440,
  runtimeConfigOption: 'default',
  isPrivate: false,
  internalElb: false,
  prometheusMonitoringEnabled: true,
  tags: [],
  networkStack: 'ipv4',
  privileged: true,
  allowWorkloadsOnMaster: false,
  useRoute53: false,
  domainId: '',
  azs: [],
  // enableProfileAgent: true,
  containerRuntime: 'containerd',
  ...etcdBackupDefaults,
}

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'region', label: 'Region' },
  { id: 'ami', label: 'Operating System' },
  { id: 'masterFlavor', label: 'Master node instance type', insertDivider: true },
  { id: 'workerFlavor', label: 'Worker node instance type' },
  { id: 'numMasters', label: 'Master nodes' },
  { id: 'numWorkers', label: 'Worker nodes' },
  {
    id: 'allowWorkloadsOnMaster',
    label: 'Make Master nodes Master + Worker',
    render: (value) => castBoolToStr()(value),
  },
  { id: 'enableCAS', label: 'Auto scaling', render: (value) => castBoolToStr()(value) },
  { id: 'sshKey', label: 'SSH Key', insertDivider: true },
  { id: 'externalDnsName', label: 'API FQDN' },
  { id: 'containersCidr', label: 'Containers CIDR' },
  { id: 'servicesCidr', label: 'Services CIDR' },
  {
    id: 'networkPlugin',
    label: 'CNI',
    render: (value) => capitalizeString(value),
  },
  {
    id: 'privileged',
    label: 'Privileged',
    render: (value) => castBoolToStr()(value),
    insertDivider: true,
  },
  {
    id: 'prometheusMonitoringEnabled',
    label: 'Prometheus monitoring',
    render: (value) => castBoolToStr()(value),
  },
  {
    id: 'tags',
    label: 'Tags',
    renderArray: true,
    render: (value) => <FormattedTags tags={value} />,
  },
]

const trackingFields = {
  platform: CloudProviders.Aws,
  target: ClusterCreateTypes.Custom,
}

const templateOptions = [
  { label: 'Sm - Single Node Master & Worker (t2.medium)', value: 'small' },
  { label: 'Md - 1 Master + 3 Workers (t2.medium)', value: 'medium' },
  { label: 'Lg - 3 Masters + 5 Workers (t2.large)', value: 'large' },
  { label: 'custom', value: 'custom' },
]

// The template picker allows the user to fill out some useful defaults for the fields.
// This greatly simplifies the number of fields that need to be filled out.
// Presets are as follows:
// small (single dev) - 1 node master + worker - select instance type (default t2.small)
// medium (internal team) - 1 master + 3 workers - select instance (default t2.medium)
// large (production) - 3 master + 5 workers - no workload on masters (default t2.large)
export const options = {
  small: {
    numMasters: 1,
    numWorkers: 1,
    allowWorkloadsOnMaster: true,
    masterFlavor: 't3.xlarge',
    workerFlavor: 't3.xlarge',
  },
  medium: {
    numMasters: 1,
    numWorkers: 3,
    allowWorkloadsOnMaster: false,
    masterFlavor: 't3.xlarge',
    workerFlavor: 't3.xlarge',
  },
  large: {
    numMasters: 3,
    numWorkers: 5,
    allowWorkloadsOnMaster: false,
    masterFlavor: 't3.xlarge',
    workerFlavor: 't3.xlarge',
  },
  custom: {
    numMasters: 3,
    numWorkers: 5,
    allowWorkloadsOnMaster: false,
    masterFlavor: 't3.xlarge',
    workerFlavor: 't3.xlarge',
  },
}

const handleTemplateChoice = ({ setFieldValue, setWizardContext }) => (option) => {
  // setImmediate is used because we need the fields to show up in the form before their values can be set
  setImmediate(() => {
    Object.entries(options[option]).forEach(([key, value]) => {
      setFieldValue(key)(value)
    })
  })
  setWizardContext(options[option])
}

const configStepAddOns: AddonManagerAddon[] = [
  { addon: 'etcdBackup' },
  { addon: 'monitoring' },
  { addon: 'awsEnableCAS' },
  // { addon: 'profileAgent' },
]
const advancedClusterAddons: AddonManagerAddon[] = [{ addon: 'enableTopologyManager' }]

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
}

const AdvancedAwsClusters: FC<Props> = ({ wizardContext, setWizardContext, onNext, ...rest }) => {
  const classes = useStyles()
  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')
  const cloudDefaults =
    prefs?.[UserPreferences.CloudProviders]?.[wizardContext.cloudProviderId] || emptyObj

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  // useEffect(() => {
  //   if (compareVersions(wizardContext.kubeRoleVersion, '1.20') >= 0) {
  //     setWizardContext({ enableProfileAgent: true })
  //   } else {
  //     setWizardContext({ enableProfileAgent: false })
  //   }
  // }, [wizardContext.kubeRoleVersion])

  const updateFqdns = (values, label) => (value) => {
    setWizardContext({ domainId: value })

    const name = values.name || wizardContext.name

    const api = `${name}-api.${label}`
    setWizardContext({ externalDnsName: api })

    const service = `${name}-service.${label}`
    setWizardContext({ serviceFqdn: service })
  }

  useEffect(() => {
    if (isNilOrEmpty(cloudDefaults)) return
    setCloudDefaultsInForm()
  }, [cloudDefaults])

  const setCloudDefaultsInForm = useCallback(() => {
    if (isNilOrEmpty(cloudDefaults)) return
    setWizardContext({ ...cloudDefaults })
    if (cloudDefaults[CloudDefaults.Domain]) {
      updateFqdns(
        cloudDefaults,
        cloudDefaults[CloudDefaults.DomainLabel],
      )(cloudDefaults[CloudDefaults.Domain])
    }
  }, [cloudDefaults])

  const handleStepOneSubmit = (values) => {
    setWizardContext(values)
    if (!values.useRoute53) {
      setWizardContext({ domainId: '', externalDnsName: '', serviceFqdn: '' })
    }
  }

  return (
    <>
      {/* Step 1 - Cluster Configuration */}
      <WizardStep
        stepId="config"
        label="Initial Setup"
        onNext={awsClusterTracking.wZStepOne(trackingFields)}
        {...rest}
      >
        <ValidatedForm
          fullWidth
          classes={{ root: classes.validatedFormContainer }}
          initialValues={wizardContext}
          // onSubmit={setWizardContext}
          onSubmit={handleStepOneSubmit}
          triggerSubmit={onNext}
          withAddonManager
          elevated={false}
        >
          {({ setFieldValue, values }) => (
            <>
              <FormFieldSection
                title="Cluster Configuration"
                link={
                  <ExternalLink textVariant="caption2" url={awsPrerequisitesLink}>
                    AWS Cluster Help
                  </ExternalLink>
                }
              >
                {/* Cluster Name */}
                <ClusterNameField setWizardContext={setWizardContext} />

                {/* Cloud Provider */}
                <CloudProviderField
                  cloudProviderType={CloudProviders.Aws}
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onChange={(value) => setWizardContext({ cloudProviderId: value })}
                />

                {/* AWS Region */}
                <CloudProviderRegionField
                  cloudProviderType={CloudProviders.Aws}
                  values={values}
                  wizardContext={wizardContext}
                  onChange={(region) => setWizardContext({ region, azs: [] })}
                  disabled={!values.cloudProviderId && !values.region}
                />

                {/* AWS Availability Zone */}
                {values.region && (
                  <AwsAvailabilityZoneField
                    values={values}
                    wizardContext={wizardContext}
                    setWizardContext={setWizardContext}
                    allowMultiSelect
                  />
                )}

                {/* SSH Key */}
                <SshKeyField
                  dropdownComponent={AwsClusterSshKeyPicklist}
                  values={values}
                  // value={wizardContext.sshKey}
                  // onChange={(value) => setWizardContext({ sshKey: value })}
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />

                {/* Template Chooser */}
                <ClusterTemplatesField
                  items={templateOptions}
                  onChange={handleTemplateChoice({
                    setFieldValue,
                    setWizardContext,
                  })}
                />

                {values.template === 'custom' && (
                  <>
                    {/* Operating System */}
                    <OperatingSystemField />

                    {/* Master node instance type */}
                    <MasterNodeInstanceTypeField
                      dropdownComponent={AwsRegionFlavorPicklist}
                      values={values}
                    />

                    {/* Num master nodes */}
                    <NumMasterNodesField />

                    {/* Worker node instance type */}
                    <WorkerNodeInstanceTypeField
                      dropdownComponent={AwsRegionFlavorPicklist}
                      values={values}
                    />

                    {/* Num worker nodes */}
                    <NumWorkerNodesField />
                  </>
                )}
              </FormFieldSection>

              <FormFieldSection title="Cluster Settings">
                {/* Kubernetes Version */}
                <KubernetesVersionField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  inClusterCreation={true}
                />

                <PicklistField
                  DropdownComponent={ContainerRuntimePicklist}
                  id="containerRuntime"
                  label="Container Runtime"
                  tooltip="The container runtime for the cluster"
                  onChange={(value) => setWizardContext({ containerRuntime: value })}
                  value={wizardContext.containerRuntime}
                  kubeRoleVersion={wizardContext.kubeRoleVersion}
                  required
                />

                <CheckboxField
                  id="useRoute53"
                  label="Use Route53 for Cluster Access"
                  value={wizardContext.useRoute53}
                  info="Enable Platform9 to use a selected  Route53 domain for the
                   API Server and Service Endpoints."
                  onChange={(value) => setWizardContext({ useRoute53: value })}
                />

                <Divider className={classes.divider} />

                {/* App & Container Settings */}
                <Text variant="caption1">Application & Container Settings</Text>

                {/* Workloads on masters */}
                <AllowWorkloadsonMasterField setWizardContext={setWizardContext} />

                <PrivilegedField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />

                <Divider className={classes.divider} />

                {/* Managed Add-Ons */}
                <Text variant="caption1">Managed Add-Ons</Text>
                <AddonTogglers
                  addons={configStepAddOns}
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>

      {/* Step 2 - Network Info */}
      <WizardStep
        stepId="network"
        label="Network Info"
        onNext={awsClusterTracking.wZStepTwo(trackingFields)}
      >
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          fullWidth
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          elevated={false}
        >
          {({ values }) => (
            <>
              <FormFieldSection title="Networking Details">
                {/* Use PF9 domain */}
                {/* <CheckboxField
                      id="usePf9Domain"
                      label="Use the platform9.net domain"
                      info="Select this option if you want Platform9 to automatically generate the endpoints or if you do not have access to Route 53."
                    />
                */}

                {/* AWS Custom Networking Fields */}
                <AwsCustomNetworkingFields
                  setWizardContext={setWizardContext}
                  wizardContext={wizardContext}
                />

                {wizardContext.useRoute53 && (
                  <>
                    {/* API FQDN */}
                    <ApiFqdnField
                      setWizardContext={setWizardContext}
                      wizardContext={wizardContext}
                      disabled={wizardContext.usePf9Domain}
                    />

                    {/* Services FQDN */}
                    <ServicesFqdnField
                      setWizardContext={setWizardContext}
                      wizardContext={wizardContext}
                      required={!wizardContext.usePf9Domain}
                      disabled={wizardContext.usePf9Domain}
                    />
                  </>
                )}
              </FormFieldSection>

              <FormFieldSection title="Cluster Networking Range & HTTP Proxy">
                {/* Containers & Services CIDR */}
                <ContainerAndServicesCidr values={values} />

                {/* HTTP proxy */}
                <HttpProxyField />
              </FormFieldSection>

              <FormFieldSection title="Cluster CNI">
                {/* Network Backend */}
                <NetworkBackendField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />

                {/* Calico Network Fields

                    Contains:
                    - IP in IP Encapsulation Mode Field
                    - NAT Outgoing Field
                    - Block Size Field
                    - MTU Size Field

                */}
                {values.networkPlugin === 'calico' && (
                  <CalicoNetworkFields values={wizardContext} setValues={setWizardContext} />
                )}
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>

      {/* Step 3 - Advanced Configuration */}
      <WizardStep
        stepId="advanced"
        label="Advanced Configuration"
        onNext={awsClusterTracking.wZStepThree(trackingFields)}
      >
        <ValidatedForm
          fullWidth
          classes={{ root: classes.validatedFormContainer }}
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          withAddonManager
          elevated={false}
        >
          {({ values }) => (
            <>
              <FormFieldSection title="Advanced Configuration">
                {/* Advanced API Configuration */}
                <AdvancedApiConfigFields values={values} setWizardContext={setWizardContext} />

                {/* Custom AMI */}
                <CustomAmiField setWizardContext={setWizardContext} />

                {/* Tags */}
                <CustomApiFlagsFields
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />

                <TagsField />
                <AddonTogglers
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  addons={advancedClusterAddons}
                />
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>

      {/* Step 4 - Review */}
      <WizardStep
        stepId="review"
        label="Review"
        onNext={awsClusterTracking.wZStepFour(trackingFields)}
      >
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          fullWidth
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          elevated={false}
        >
          <FormFieldSection title="Finalize & Review">
            <FormReviewTable data={wizardContext} columns={columns} />
          </FormFieldSection>
        </ValidatedForm>
      </WizardStep>
    </>
  )
}

export default AdvancedAwsClusters
