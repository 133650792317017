import React, { useContext } from 'react'
import Alert from 'core/components/Alert'
import { ValidatedFormContext } from 'core/components/validatedForm/ValidatedForm'
import { compareVersions } from 'k8s/util/helpers'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import MonitoringParamFields from './param-fields/MonitoringParamFields'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { monitoringInfo } from 'app/plugins/infrastructure/components/clusters/constants'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'

export const monitoringFieldId = 'prometheusMonitoringEnabled'

const hideMonitoringAddonFields = (clusterVersion) =>
  clusterVersion === undefined || compareVersions(clusterVersion, '1.21') < 0

export default function Monitoring({
  wizardContext,
  setWizardContext,
  disabled,
  useToggleSwitch = false,
}) {
  return useToggleSwitch ? (
    <ToggleSwitchField
      id={monitoringFieldId}
      label="Prometheus Monitoring"
      disabled={disabled}
      onChange={(value) => setWizardContext({ [monitoringFieldId]: value })}
      info={monitoringInfo}
      infoPlacement={{ align: topMiddle.align, offset: topMiddle.offset }}
    />
  ) : (
    <CheckboxField
      id={monitoringFieldId}
      label="Prometheus Monitoring"
      info={monitoringInfo}
      disabled={disabled}
      value={wizardContext[monitoringFieldId]}
      onChange={(value) => setWizardContext({ [monitoringFieldId]: value })}
    />
  )
}

export const MonitoringAddonFields = ({ showHeader = true, setWizardContext, wizardContext }) => {
  const { values, setFieldValue } = useContext(ValidatedFormContext) as any
  const monitoringEnabled = values?.[monitoringFieldId]
  // kubeRoleVersion for legacy, version for capi
  if (hideMonitoringAddonFields(values?.kubeRoleVersion || values?.version)) {
    return null
  }

  return monitoringEnabled ? (
    showHeader ? (
      <FormFieldSection title={'Monitoring Configuration'}>
        <MonitoringParamFields
          values={values}
          setFieldValue={setFieldValue}
          showExtraParams={false}
          setWizardContext={setWizardContext}
          wizardContext={wizardContext}
        />
      </FormFieldSection>
    ) : (
      <MonitoringParamFields
        values={values}
        setFieldValue={setFieldValue}
        showExtraParams={false}
        setWizardContext={setWizardContext}
        wizardContext={wizardContext}
      />
    )
  ) : (
    <Alert
      variant="error"
      message="The PMK management plane cannot monitor your cluster's health."
      maxWidth="400px"
    />
  )
}
