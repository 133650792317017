import React, { useState, useCallback } from 'react'
import Alert from 'core/components/Alert'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Input from 'core/elements/input/Input'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteCapiCluster } from './actions'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import { ICapiClusterSelector } from './model'

const CapiClusterDeleteDialog = ({
  rows: [capiCluster],
  onClose,
  onSuccess,
}: {
  rows: ICapiClusterSelector[]
  onClose: () => void
  onSuccess?: () => void
}) => {
  const { history } = useReactRouter()

  const [error, setError] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const { update: deleteCluster, updating: deletingCluster } = useUpdateAction(deleteCapiCluster)

  const handleOnChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleDelete = useCallback(async () => {
    const { success, error } = await deleteCluster(capiCluster)
    if (success) {
      setError(null)
      onClose()
      // To get back to the list page in case of deletion from the CapiClusterDetailsPage
      if (onSuccess) {
        onSuccess()
      } else {
        history.push(routes.cluster.managed.list.path())
      }
    } else {
      setError({ title: 'Error Deleting Capi Cluster', message: error || '' })
    }
  }, [capiCluster])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (inputValue === capiCluster?.name) {
        handleDelete()
      }
    } else if (event.key === 'Escape') {
      onClose()
    }
  }

  const title = `Permanently delete cluster "${capiCluster?.name}"?`
  const cancelText = "Don't Delete"
  const confirmText = 'Delete this cluster'

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      title={title}
      footer={
        <>
          <Button onClick={onClose} variant="secondary" disabled={deletingCluster}>
            {cancelText}
          </Button>
          <Button
            onClick={handleDelete}
            variant="primary"
            disabled={inputValue !== capiCluster?.name}
            loading={deletingCluster}
          >
            {confirmText}
          </Button>
        </>
      }
    >
      {!!error && <Alert variant="error" title={error?.title} message={error?.message} />}
      <Text variant="body1" component="div">
        Please type "<b>{capiCluster?.name}</b>" to confirm.
      </Text>
      <Input
        id="clusterName"
        type="text"
        label="Cluster name"
        value={inputValue}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        autoFocus
      />
    </Modal>
  )
}

export default CapiClusterDeleteDialog
