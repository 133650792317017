import React from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'

export default function NamespacePicklistField({
  clusterId,
  label = 'Namespace',
  required = false,
  ...rest
}) {
  return (
    <DropdownField
      DropdownComponent={NamespacePicklist}
      id="namespace"
      label={label}
      selectFirst={false}
      clusterId={clusterId}
      disabled={!clusterId}
      required={required}
      {...rest}
    />
  )
}
