import { useCallback, useReducer } from 'react'
import { ICapiResource } from '../../capi/model'
import { generateApiErrorPayload } from 'api-client/helpers'
import { Reducer } from 'redux'
import { resourceUpdateFunctions } from './capi-resources'
import {
  CapiResourceKind,
  CapiOperation,
} from 'app/plugins/infrastructure/components/clusters/aws/capi/model'

const DELAY = 1000
interface Options {
  operation: CapiOperation
}

const initialState = {
  updating: false,
  errors: null,
  results: null,
  message: 'Creating Resource...',
}

interface UpdateState {
  updating: boolean
  errors: ReturnType<typeof generateApiErrorPayload>[]
  message: string
  results: ICapiResource[]
}

interface UpdateReducerAction {
  type: 'startUpdate' | 'endUpdate' | 'messageUpdate' | 'reset'
  payload?: {
    results?: ICapiResource[]
    errors?: ReturnType<typeof generateApiErrorPayload>[]
    message?: string
  }
}

type UpdateActionReducer = Reducer<UpdateState, UpdateReducerAction>

function updateActionReducer(state: UpdateState, { type, payload }: UpdateReducerAction) {
  switch (type) {
    case 'reset':
      return initialState
    case 'startUpdate':
      return {
        updating: true,
        errors: [],
        message: payload?.message,
        results: [],
      }
    case 'endUpdate':
    default:
      return {
        updating: false,
        results: payload?.results,
        errors: payload?.errors,
        message: null,
      }
  }
}

const getUpdateFn = (operation: CapiOperation) => (kind: CapiResourceKind) => {
  return resourceUpdateFunctions?.[kind]?.[operation]
}

const useCapiResourceUpdate = (options: Options = { operation: CapiOperation.Create }) => {
  const { operation } = options
  const getResourceUpdateFn = getUpdateFn(operation)
  const [{ updating, errors, message, results }, localDispatch] = useReducer<UpdateActionReducer>(
    updateActionReducer,
    initialState,
  )
  const execute = useCallback(
    async (schemas: ICapiResource[] = []) => {
      const promises: Promise<ICapiResource>[] = schemas.map(async (schema) => {
        const { kind } = schema
        const updateFn = getResourceUpdateFn(kind)
        if (!updateFn) return null

        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const result = await updateFn(schema)
              resolve(result)
            } catch (err) {
              reject(generateApiErrorPayload(err || err?.err))
            }
          }, DELAY)
        })
      })

      localDispatch({
        type: 'startUpdate',
        payload: { message: `Deploying Cluster...` },
      })
      const executions = await Promise.allSettled(promises.filter(Boolean))

      const results: ICapiResource[] = executions
        .map((execution) => (execution.status === 'fulfilled' ? execution.value : null))
        .filter(Boolean)
      const errors = executions
        .map((execution) => (execution.status === 'rejected' ? execution.reason : null))
        .filter(Boolean)

      localDispatch({ type: 'endUpdate', payload: { results, errors } })
      return { updating, results, errors }
    },
    [operation],
  )

  return { execute, updating, results, errors, message }
}

export default useCapiResourceUpdate
