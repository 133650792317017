import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import useReactRouter from 'use-react-router'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import { validators, customValidator } from 'core/utils/fieldValidators'
import ClusterHostChooser from './bareos/ClusterHostChooser'
import { ClusterSelector } from './model'
import { allPass } from 'ramda'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'
import {
  checkNodesForClockDrift,
  clockDriftErrorMessage,
  inCluster,
  isNotMaster,
  isUnassignedNode,
  isConnected,
} from 'app/plugins/infrastructure/components/nodes/helpers'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { isUninstalling } from './cluster-addons/helpers'
import { compareVersions } from 'k8s/util/helpers'
import { ClusterAddonType } from './cluster-addons/model'
import ModalForm from 'core/elements/modal/ModalForm'
import { updateCluster, attachNodes, detachNodes, listClusters } from './newActions'
import { clustersSelector } from './selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import RadioFields from 'core/components/validatedForm/radio-fields'
import { listClusterAddons } from './cluster-addons/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { clusterAddonsSelector } from './cluster-addons/selectors'

// Limit the number of workers that can be scaled at a time to prevent overload
const MAX_SCALE_AT_A_TIME = 15

const maxScaleValidator = customValidator(
  (selections) => selections.length <= MAX_SCALE_AT_A_TIME,
  `Clusters can only be scaled up to ${MAX_SCALE_AT_A_TIME} nodes at a time.`,
)

const minScaleValidator = customValidator(
  (selections) => selections.length > 0,
  'You must select at least 1 node.',
)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    maxWidth: '800px',
  },
  errorMessage: {
    padding: theme.spacing(1),
  },
  subtitles: {
    display: 'grid',
    gap: theme.spacing(2),
  },
}))

const clusterTypeDisplay = {
  local: 'BareOS',
  aws: 'AWS',
  azure: 'Azure',
}

const scalingOptions = [
  { label: 'Add worker node(s) to the cluster', value: 'add' },
  { label: 'Remove worker node(s) from the cluster', value: 'remove' },
]

type ScaleType = 'add' | 'remove'

interface ScaleWorkersProps {
  cluster: ClusterSelector
  scaleType: ScaleType
  setScaleType: (string) => void
}

function ScaleWorkers({ cluster, scaleType, setScaleType }: ScaleWorkersProps) {
  const classes = useStyles({})
  const { uuid: clusterId, cloudProviderType } = cluster || ({} as ClusterSelector)
  const isLocal = cloudProviderType === 'local'
  const isCloud = ['aws', 'azure'].includes(cloudProviderType)

  useListAction(listClusterAddons, {
    params: { clusterId },
  })
  const existingClusterAddons = useSelectorWithParams(clusterAddonsSelector, { clusterId })

  const autoscalingEnabled = useMemo(() => {
    if (compareVersions(cluster?.kubeRoleVersion, '1.21') < 0) return cluster?.enableCAS
    const addonType =
      cloudProviderType === CloudProviders.Aws
        ? ClusterAddonType.AwsAutoScaler
        : ClusterAddonType.AzureAutoScaler
    const autoscalerAddon = existingClusterAddons.find(
      (addon) => addon.type === addonType && !isUninstalling(addon),
    )
    return !!autoscalerAddon
  }, [cluster?.kubeRoleVersion, cloudProviderType, existingClusterAddons])

  const calcMin = (value: number): number => Math.max(value - MAX_SCALE_AT_A_TIME, 1)

  const calcMax = (value: number): number => value + MAX_SCALE_AT_A_TIME

  const chooseScaleNum = (
    <>
      {!autoscalingEnabled && (
        <TextField
          id="numWorkers"
          type="number"
          label="Number of worker nodes"
          info="Number of worker nodes to deploy."
          required
          validations={[
            validators.rangeValue(calcMin(cluster?.numWorkers), calcMax(cluster?.numWorkers)),
          ]}
          initialValue={cluster?.numWorkers}
        />
      )}

      {autoscalingEnabled && (
        <>
          &nbsp;
          <TextField
            id="numMinWorkers"
            type="number"
            label="Minimum number of worker nodes"
            info="Minimum number of worker nodes this cluster may be scaled down to. 
            Also, clusters can only be scaled up to 15 nodes at a time."
            validations={[
              validators.rangeValue(
                calcMin(cluster?.numMinWorkers),
                calcMax(cluster?.numMinWorkers),
              ),
            ]}
            initialValue={cluster?.numMinWorkers}
            required
          />
          &nbsp;
          <TextField
            id="numMaxWorkers"
            type="number"
            label="Maximum number of worker nodes"
            info="Maximum number of worker nodes this cluster may be scaled up to.
                  Also, clusters can only be scaled up to 15 nodes at a time."
            validations={[
              validators.rangeValue(
                calcMin(cluster?.numMaxWorkers),
                calcMax(cluster?.numMaxWorkers),
              ),
            ]}
            initialValue={cluster?.numMaxWorkers}
            required
          />
          &nbsp;
        </>
      )}
    </>
  )

  const addBareOsWorkerNodes = (
    <ClusterHostChooser
      selection="multiple"
      id="workersToAdd"
      filterFn={allPass([isUnassignedNode, isConnected])}
      validations={[minScaleValidator, maxScaleValidator]}
      isSingleNodeCluster={false}
      required
    />
  )

  const removeBareOsWorkerNodes = (
    <ClusterHostChooser
      selection="multiple"
      id="workersToRemove"
      filterFn={allPass([isNotMaster, inCluster(cluster?.uuid)])}
      validations={[minScaleValidator, maxScaleValidator]}
      isSingleNodeCluster={false}
      showResourceRequirements={false}
      required
    />
  )

  return (
    <div>
      <Text variant="subtitle1">Current Worker Nodes: {cluster?.numWorkers}</Text>
      <br />
      <RadioFields
        id="scaleType"
        title="Scaling Options"
        value={scaleType}
        options={scalingOptions}
        onChange={(type) => setScaleType(type)}
      />
      {isCloud && <div className={classes.subtitles}>{scaleType && chooseScaleNum}</div>}
      {isLocal &&
        !!scaleType &&
        (scaleType === 'add' ? addBareOsWorkerNodes : removeBareOsWorkerNodes)}
    </div>
  )
}

export default function ScaleWorkersPage() {
  const { match, history } = useReactRouter()
  const { id } = match.params

  const [scaleType, setScaleType] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const { loading: loadingClusters } = useListAction(listClusters)
  const { loading: loadingNodes } = useListAction(listNodes)
  const allNodes = useSelector(nodesSelector)
  const clusters = useSelector(clustersSelector)
  const cluster = clusters.find((x) => x.uuid === id)

  const { update, updating } = useUpdateAction(updateCluster)
  const { update: attach, updating: isAttaching, error: attachError } = useUpdateAction(attachNodes)
  const { update: detach, updating: isDetaching, error: detachError } = useUpdateAction(detachNodes)

  const isUpdating = updating || isAttaching || isDetaching

  const handleAttach = (data: { workersToAdd: string[] }) => {
    const uuids = data.workersToAdd
    const nodes = uuids.map((uuid) => ({ uuid, isMaster: false }))
    return attach({ cluster, nodes })
  }

  const handleDetach = (data: { workersToRemove: string[] }) => {
    const uuids = data.workersToRemove
    return detach({ cluster, nodes: uuids })
  }

  const handleClose = () => {
    setScaleType(null)
    setErrorMessage(null)
    history.push(routes.cluster.managed.list.path())
  }

  const handleSubmit = useCallback(
    async (data) => {
      let updateSuccess = false
      if (cluster?.cloudProviderType === CloudProviders.BareOS) {
        const nodeIds = scaleType === 'add' ? data.workersToAdd : data.workersToRemove
        const hasClockDrift = checkNodesForClockDrift(nodeIds, allNodes)
        if (hasClockDrift) {
          setErrorMessage(clockDriftErrorMessage)
          return
        }
        setErrorMessage(null)
        const scalingAction = scaleType === 'add' ? handleAttach : handleDetach
        const { success } = await scalingAction(data)
        updateSuccess = success
      } else {
        const { success } = await update({ uuid: cluster?.uuid, ...data })
        updateSuccess = success
      }
      if (updateSuccess) {
        handleClose()
      }
    },
    [cluster, scaleType, handleAttach, handleDetach],
  )

  return (
    <ModalForm
      route={routes.cluster.managed.qbert.scaleWorkers}
      title="Scale Workers"
      loading={loadingClusters || loadingNodes}
      onSubmit={scaleType ? handleSubmit : null}
      submitTitle={`${scaleType === 'add' ? 'Add' : 'Remove'} workers`}
      submitting={isUpdating}
      error={attachError || detachError || errorMessage}
      onClose={handleClose}
    >
      <ScaleWorkers cluster={cluster} scaleType={scaleType} setScaleType={setScaleType} />
    </ModalForm>
  )
}
