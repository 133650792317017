import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { hasPrometheusEnabled } from 'k8s/components/prometheus/helpers'
import DataKeys from 'k8s/DataKeys'
import { pipe } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'
import { filterIf } from 'utils/fp'
import { monitoringAddonsByClusterSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import {
  prometheusCluster,
  kubevirtCluster,
  ecoCluster,
  nonPrometheusCluster,
  advancedNetworkingCluster,
} from 'app/plugins/infrastructure/components/clusters/helpers'
import { ImportedClusterSelector } from 'app/plugins/infrastructure/components/importedClusters/model'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { ClusterParams } from 'app/plugins/infrastructure/components/common/model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import { qbertEndpointSelector } from 'app/plugins/infrastructure/components/common/selectors'

const getVpcData = (cluster) => {
  const providerType = cluster?.metadata?.labels?.provider
  const privateAccess =
    cluster?.spec?.[providerType]?.network?.vpc?.privateAccess ||
    (providerType === 'aks' && cluster?.spec?.aks?.enablePrivateCluster) ||
    (providerType === 'gke' && cluster?.spec?.gke?.privateCluster)
  const publicAccess =
    cluster?.spec?.[providerType]?.network?.vpc?.publicAccess ||
    (providerType === 'aks' && !cluster?.spec?.aks?.enablePrivateCluster) ||
    (providerType === 'gke' && !cluster?.spec?.gke?.privateCluster)
  const isPublicPrivateVpc = privateAccess === true && publicAccess === true
  const isPrivateVpc = privateAccess === true && publicAccess === false
  return {
    isPrivateVpc,
    vpcText: `${publicAccess === true ? 'Public' : ''}${isPublicPrivateVpc ? ' + ' : ''}${
      privateAccess === true ? 'Private' : ''
    } VPC`,
  }
}

export const importedClustersSelector: AppSelector<ImportedClusterSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.ImportedClusters>(DataKeys.ImportedClusters),
  getDataSelector<DataKeys.ClusterAgents>(DataKeys.ClusterAgents),
  monitoringAddonsByClusterSelector,
  qbertEndpointSelector,
  (
    importedClusters,
    clusterAgents,
    monitoringAddonsByCluster,
    qbertEndpoint,
  ): ImportedClusterSelector[] => {
    return importedClusters.map((cluster) => {
      const monitoringAddon = monitoringAddonsByCluster[cluster.uuid]
      const host = qbertEndpoint.match(/(.*?)\/qbert/)[1]
      const grafanaLink =
        `${host}/k8s/v1/clusters/${cluster.uuid}/k8sapi/api/v1/` +
        `namespaces/pf9-monitoring/services/http:grafana-ui:80/proxy/`
      const isPrometheusEnabled = hasPrometheusEnabled(monitoringAddon)
      const usage = {
        grafanaLink: isPrometheusEnabled ? grafanaLink : null,
      }
      const clusterAgent = clusterAgents.find((agent) =>
        agent?.metadata?.name.includes(cluster.uuid),
      )
      const vpcData = getVpcData(cluster)
      return {
        nodes: [],
        ...cluster,
        name: cluster.spec?.displayName,
        cloudProviderId: cluster.spec?.cloudProviderID,
        cloudProviderType: cluster?.status?.type,
        external: cluster.metadata?.labels?.external,
        region: cluster.metadata?.labels?.location,
        kubeVersion: cluster.spec?.kubeVersion,
        version: cluster.spec?.kubeVersion,
        creationTimestamp: cluster.metadata?.creationTimestamp,
        // Backend to work on standardizing common fields btwn providers in API responses
        containerCidr:
          cluster.spec?.eks?.network?.containerCidr ||
          cluster.spec?.aks?.network?.containerCIDR ||
          cluster.spec?.gke?.network?.podIpv4CIDR,
        servicesCidr:
          cluster.spec?.eks?.network?.servicesCidr ||
          cluster.spec?.aks?.network?.serviceCIDR ||
          cluster.spec?.gke?.network?.servicesIpv4CIDR,
        nodeGroups:
          cluster.spec?.eks?.nodegroups ||
          cluster.spec?.aks?.agentPools ||
          cluster.spec?.gke?.nodePools,
        providerType: cluster.metadata?.labels?.provider,
        workers: cluster.status?.workers,
        usage,
        hasPrometheus: isPrometheusEnabled,
        // Imported clusters do not support kubevirt
        hasKubevirt: false,
        clusterType: ClusterTypes.Imported,
        ecoInstalled: !!clusterAgent,
        ecoStatus: clusterAgent?.status?.phase,
        ...vpcData,
      }
    })
  },
)

export const makeParamsImportedClustersSelector = (
  defaultParams = {
    orderBy: 'creationTimestamp',
    orderDirection: 'desc',
  } as ClusterParams,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(importedClustersSelector, selectParams, (importedClusters, params) => {
    const {
      prometheusClusters,
      nonPrometheusClusters,
      ecoEnabledClusters,
      kubevirtClusters,
      advancedNetworkingClusters,
    } = params
    return pipe<
      ImportedClusterSelector[],
      ImportedClusterSelector[],
      ImportedClusterSelector[],
      ImportedClusterSelector[],
      ImportedClusterSelector[],
      ImportedClusterSelector[]
    >(
      filterIf(prometheusClusters, prometheusCluster),
      filterIf(nonPrometheusClusters, nonPrometheusCluster),
      filterIf(ecoEnabledClusters, ecoCluster),
      filterIf(kubevirtClusters, kubevirtCluster),
      filterIf(advancedNetworkingClusters, advancedNetworkingCluster),
    )(importedClusters)
  })
}
