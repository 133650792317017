import React, { useMemo, useCallback, useEffect } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { awsAmisSelector } from '../../capi/selectors'
import { useSelector } from 'react-redux'
import { propOr, concat, constructN } from 'ramda'
import { capitalizeString } from 'utils/misc'
import useListAction from 'core/hooks/useListAction'
import { listAwsAmis } from '../../capi/actions'
import Text from 'core/elements/Text'

export const cloudInit = {
  insecureSkipSecretsManager: true,
}

const renderLabel = (os: string, name: string, imageId: string) => (
  <>
    <Text variant="body1">{capitalizeString(`${os} (${imageId})`)}</Text>
    <Text variant="caption2">{name}</Text>
  </>
)

const custom = [{ label: 'Custom', value: 'custom' }]
interface Props {
  region: string
  identityName: string
  roleArn: string
  version?: string
  onChange: (value: string, selectedItem?: any) => void
}
// If the value passed to this component is not a valid option,
// set the value to 'custom'. This is how we know if the
// ami id passed to this component is a custom one or not
export default function AwsAmiPicklist({
  identityName,
  roleArn,
  region,
  version,
  value,
  onChange,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  if (!version || !identityName || !region) return null

  const { loading } = useListAction(listAwsAmis, {
    params: { identityName, region, version, roleArn },
  })

  const amis = useSelector(awsAmisSelector)

  const items = useMemo(
    () =>
      amis.map((ami) => ({
        label: renderLabel(ami?.os, ami?.name, ami?.imageId),
        value: ami?.imageId,
      })),
    [amis],
  )

  const options = useMemo(() => concat(items, custom), [items])

  const handleChange = useCallback(
    (value) => {
      const selectedItem = items.find((i) => i.value === value)
      selectedItem ? onChange(selectedItem.value, selectedItem) : onChange('custom')
    },
    [items],
  )

  useEffect(() => {
    if (loading || !value || typeof value !== 'string') return // the selectedValue can be object sometimes
    const isValidValue = !!items.find((i) => i.value === value)
    if (!isValidValue) {
      onChange('custom')
    }
  }, [items])

  return (
    <AsyncDropdown
      loading={loading}
      //@ts-ignore
      items={options}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  )
}
