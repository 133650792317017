import React, { useCallback, useState } from 'react'
import FormWrapper from 'core/components/FormWrapper'
import Wizard from 'core/components/wizard/Wizard'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import DocumentMeta from 'core/components/DocumentMeta'
import WizardStep from 'core/components/wizard/WizardStep'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import PicklistField from 'core/components/validatedForm/DropdownField'
import CloudProviderPicklist from 'k8s/components/common/CloudProviderPicklist'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ReviewClustersTable from './ReviewClustersTable'
import { registerExternalClusters } from './actions'
import { trackEvent } from 'utils/tracking'
import Bugsnag from 'utils/bugsnag'
import { ClusterCloudPlatforms } from 'app/constants'
import RegionsAndClustersSelection from './regions-and-clusters-selection'
import { generateRegionSummaryFields } from './helpers'
import EcoWarning from './EcoWarning'
import useListAction from 'core/hooks/useListAction'
import { listCloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { useSelector } from 'react-redux'
import { cloudProvidersSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'

export const toggleRegion = (region, wizardContext, setWizardContext) => {
  wizardContext.regions.includes(region)
    ? setWizardContext({ regions: wizardContext.regions.filter((r) => r !== region) })
    : setWizardContext({ regions: [...wizardContext.regions, region].sort() })
}

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  regionsAndClusters: {
    display: 'flex',
  },
  regions: {
    minWidth: 200,
  },
  clusters: {
    flexGrow: 1,
  },
}))
const wizardMetaFormattedNames = {
  name: 'EKS Provider',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const title = 'Import EKS Clusters'
const initialContext = {
  cloudProviderId: '',
  regions: [],
  selectedClusters: {},
  finalSelectedClusters: [],
}
const ImportEKSClusterPage = () => {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { message: loadingCloudProvdersMessage, loading: loadingCloudProviders } = useListAction(
    listCloudProviders,
  )
  const cloudProviders = useSelector(cloudProvidersSelector)
  const { reload: reloadImportedClusters } = useListAction(listImportedClusters)
  const providerType = CloudProviders.Aws
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (data) => {
    setSubmitting(true)
    const metadata = {
      cloud_provider_id: data.cloudProviderId,
      regions: data.regions,
      clusters: data.finalSelectedClusters.map((cluster) => cluster.id),
    }
    Bugsnag.leaveBreadcrumb('Attempting to import EKS clusters', metadata)
    trackEvent('Import EKS Clusters', metadata)
    await registerExternalClusters({
      cloudProviderId: data.cloudProviderId,
      clusters: data.finalSelectedClusters,
      stack: ClusterCloudPlatforms.EKS,
      detailsKey: 'region',
    })

    setSubmitting(false)
    // do this here bc invalidateCache in the actions doesn't seem to work
    reloadImportedClusters(true)
    history.push(routes.cluster.imported.list.path())
  }

  const getCloudProviderName = useCallback(
    (id) => {
      return cloudProviders.find((cp) => cp.uuid === id)?.name
    },
    [cloudProviders],
  )

  return (
    <FormWrapper
      title={title}
      backUrl={routes.cluster.imported.list.path()}
      loading={loadingCloudProviders || submitting}
      message={loadingCloudProvdersMessage}
    >
      <DocumentMeta title={title} bodyClasses={['form-view']} />
      <Wizard
        onComplete={handleSubmit}
        context={initialContext}
        originPath={routes.cluster.import.root.path()}
        submitLabel="Import"
      >
        {({ wizardContext, setWizardContext, onNext }) => {
          const { regionFormattedNames, regionCalloutFields } = generateRegionSummaryFields(
            wizardContext.selectedClusters,
          )
          return (
            <>
              <WizardStep
                stepId="selectProvider"
                label="Choose a Provider"
                summaryIcon={providerType}
                summaryTitle={title}
                summaryKeyOverrides={wizardMetaFormattedNames}
                summaryCalloutFields={wizardMetaCalloutFields}
              >
                <ValidatedForm
                  classes={{ root: classes.validatedFormContainer }}
                  initialValues={wizardContext}
                  onSubmit={setWizardContext}
                  triggerSubmit={onNext}
                  elevated={false}
                >
                  <FormFieldSection title="Select an AWS Provider">
                    <PicklistField
                      DropdownComponent={CloudProviderPicklist}
                      id="cloudProviderId"
                      label="AWS Provider"
                      tooltip="Find EKS clusters to import from the selected cloud provider."
                      type={CloudProviders.Aws}
                      onChange={(value) =>
                        setWizardContext({
                          cloudProviderId: value,
                          name: getCloudProviderName(value),
                        })
                      }
                      value={wizardContext.cloudProviderId}
                      required
                    />
                  </FormFieldSection>
                </ValidatedForm>
              </WizardStep>
              <WizardStep
                stepId="selectClusters"
                label="Choose Regions & Clusters"
                summaryKeyOverrides={regionFormattedNames}
                summaryCalloutFields={regionCalloutFields}
              >
                <RegionsAndClustersSelection
                  providerType={ClusterCloudPlatforms.EKS}
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep stepId="confirm" label="Review & Confirm">
                <ValidatedForm
                  classes={{ root: classes.validatedFormContainer }}
                  initialValues={wizardContext}
                  onSubmit={setWizardContext}
                  triggerSubmit={onNext}
                  elevated={false}
                >
                  <FormFieldSection title="Clusters to Import">
                    <EcoWarning />
                    <ReviewClustersTable
                      selectedClusters={wizardContext.selectedClusters}
                      onChange={(value) => setWizardContext({ finalSelectedClusters: value })}
                      value={wizardContext.finalSelectedClusters}
                      stack={ClusterCloudPlatforms.EKS}
                      required
                    />
                  </FormFieldSection>
                </ValidatedForm>
              </WizardStep>
            </>
          )
        }}
      </Wizard>
    </FormWrapper>
  )
}

export default ImportEKSClusterPage
