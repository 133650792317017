import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface NetworksFieldProps extends Omit<DropdownProps<string>, 'DropdownComponent' | 'label'> {}

export default function NetworksField({ items }: NetworksFieldProps) {
  return (
    <PicklistField
      id="network"
      DropdownComponent={Picklist}
      items={items}
      label="Network"
      tooltip="Select existing networking resources or automatically create
    and assign new networking resources."
      required
    />
  )
}
