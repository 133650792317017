import Text from 'core/elements/Text'
import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import SimpleLink from 'core/components/SimpleLink'

import {
  isTransientStatus,
  getClusterHealthStatus,
  getClusterConnectionStatus,
  getClusterApiServerHealthStatus,
} from './ClusterStatusUtils'
import { ClusterTypes, IClusterStatus } from './model'
import { capitalizeString } from 'utils/misc'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import clsx from 'clsx'
import { routes } from 'core/utils/routes'
import { clockDriftDetectedInNodes } from 'app/plugins/infrastructure/components/nodes/helpers'
import { ApiServerHealthStatus } from 'app/plugins/infrastructure/components/nodes/model'
import Tooltip from 'core/elements/tooltip'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'

const getIconOrBubbleColor = (status: IClusterStatus, theme: Theme) =>
  ({
    ok: theme.palette.green.main,
    pause: theme.palette.yellow.main,
    fail: theme.palette.red.main,
    error: theme.palette.red.main,
    loading: theme.palette.blue.main,
    unknown: theme.palette.grey.main,
    upgrade: theme.palette.orange.main,
    degraded: theme.palette.orange.main,
    pending: theme.palette.orange.main,
  }[status] || theme.palette.red.main)

const useStyles = makeStyles<Theme, IClusterStatusSpanProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    borderRadius: '4px 0 0 4px',
    backgroundColor: ({ status, inverseStatus }) =>
      inverseStatus ? getIconOrBubbleColor(status, theme) : 'inherit',
  },
  label: {
    width: 50,
  },
  circle: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(22px, max-content) 1fr',
    whiteSpace: 'nowrap',
    gridGap: 5,
    justifyItems: 'center',
    '&:before': {
      content: '""',
      display: ({ iconStatus, inverseStatus }) =>
        !inverseStatus && iconStatus === true ? 'none' : 'inherit',
      height: ({ variant, inverseStatus }) =>
        variant === 'header' ? 14 : inverseStatus ? 'auto' : 12,
      width: ({ variant, inverseStatus }) =>
        variant === 'header' ? 14 : inverseStatus ? 'auto' : 12,
      borderRadius: ({ inverseStatus }) => (inverseStatus ? '0' : '50%'),
      backgroundColor: ({ status }) => getIconOrBubbleColor(status, theme),
    },
  },
  iconColor: {
    fontSize: ({ variant }) => (variant === 'header' ? '1rem' : theme.typography.body1.fontSize),
    color: ({ status }) => getIconOrBubbleColor(status, theme),
  },
}))

export type StatusVariant = 'table' | 'header'

const iconMap = new Map<
  IClusterStatus | ApiServerHealthStatus | 'degraded',
  { icon: string; classes: string }
>([
  ['fail', { icon: 'times', classes: '' }],
  ['ok', { icon: 'check', classes: '' }],
  ['pause', { icon: 'pause-circle', classes: '' }],
  ['unknown', { icon: 'question-circle', classes: '' }],
  ['error', { icon: 'exclamation-triangle', classes: '' }],
  ['loading', { icon: 'sync', classes: 'fa-spin' }],
  ['upgrade', { icon: 'arrow-circle-up', classes: '' }],
  ['degraded', { icon: 'check', classes: '' }],
  ['pending', { icon: 'pause-circle', classes: '' }],
])

export interface IClusterStatusSpanProps {
  label?: string
  title?: string | JSX.Element
  status?: IClusterStatus
  variant?: StatusVariant
  iconStatus?: boolean
  className?: any
  inverseStatus?: boolean
  rootClassName?: any
  iconPosition?: 'right' | 'left'
}

export const ClusterStatusSpan: FC<IClusterStatusSpanProps> = (props) => {
  const {
    label,
    title,
    children,
    status = 'unknown',
    iconStatus,
    className,
    rootClassName,
    iconPosition = 'left',
  } = props
  const { circle, label: labelCls, root, iconColor } = useStyles(props)
  const renderIcon = useCallback(
    () => (
      <FontAwesomeIcon className={clsx(iconColor, iconMap.get(status).classes)}>
        {iconMap.get(status).icon}
      </FontAwesomeIcon>
    ),
    [status, iconColor],
  )
  return (
    <div className={clsx(root, rootClassName)}>
      {label && <span className={labelCls}>{label}:</span>}
      <Tooltip message={title}>
        <Text className={clsx(circle, className)} variant={'body2'} component="div">
          {!!iconStatus && iconPosition === 'left' && renderIcon()}
          {children}
          {!!iconStatus && iconPosition === 'right' && renderIcon()}
        </Text>
      </Tooltip>
    </div>
  )
}

export default ClusterStatusSpan

export const renderErrorStatus = ({ nodesDetailsUrl, text, ...rest }) => (
  <ClusterStatusSpan
    {...rest}
    iconStatus
    title="Click the link to view more details"
    status="error"
  >
    <SimpleLink variant="error" src={nodesDetailsUrl}>
      {text}
    </SimpleLink>
  </ClusterStatusSpan>
)

const renderTransientStatus = ({ cluster, variant, label, ...rest }) => {
  const { uuid, connectionStatus } = cluster
  if (!connectionStatus) {
    return null
  }
  const spanContent = `The cluster is ${connectionStatus}.`

  return (
    <ClusterStatusSpan {...rest} title={spanContent} status="loading" iconStatus>
      <SimpleLink src={routes.cluster.managed.qbert.detail.path({ tab: 'node-health', id: uuid })}>
        {label && variant === 'table' ? label : capitalizeString(connectionStatus)}
      </SimpleLink>
    </ClusterStatusSpan>
  )
}

interface IClusterStatusProps extends IClusterStatusSpanProps {
  cluster: CombinedClusterSelector
  variant?: StatusVariant
  message?: string
  iconStatus?: boolean
  label?: string
}

export const ClusterHealthStatus: FC<IClusterStatusProps> = ({
  cluster,
  variant = 'table',
  message = undefined,
  label = undefined,
  ...rest
}) => {
  if (cluster?.clusterType === ClusterTypes.Capi) return null
  if (isTransientStatus(cluster.taskStatus)) {
    return renderTransientStatus({ cluster, label, variant, ...rest })
  }

  if (cluster.status === 'pending') {
    return renderTransientStatus({
      cluster: { uuid: cluster.uuid, connectionStatus: 'converging' },
      label,
      variant,
      ...rest,
    })
  }

  const fields = getClusterHealthStatus(cluster)
  const nodesDetailsUrl = routes.cluster.managed.qbert.detail.path({ id: cluster.uuid })

  return (
    <div>
      {fields && (
        <ClusterStatusSpan
          title={fields.message}
          status={fields.status}
          variant={variant}
          {...rest}
        >
          {variant === 'header' ? (
            label || fields.label
          ) : (
            <SimpleLink src={fields.nodesDetailsUrl}>{label || fields.label}</SimpleLink>
          )}
        </ClusterStatusSpan>
      )}
      {(cluster.taskError || cluster.etcdBackup?.taskErrorDetail) &&
        renderErrorStatus({ nodesDetailsUrl, variant, text: 'Error', ...rest })}
      {clockDriftDetectedInNodes(cluster.nodes) &&
        renderErrorStatus({
          nodesDetailsUrl,
          variant,
          text: 'Node Clock Drift',
          ...rest,
        })}
    </div>
  )
}

export const ClusterConnectionStatus: FC<IClusterStatusProps> = ({
  cluster,
  variant = 'table',
  message = undefined,
  label = undefined,
  ...rest
}) => {
  if (cluster.clusterType === ClusterTypes.Capi) return null
  if (isTransientStatus(cluster.taskStatus)) {
    return renderTransientStatus({ cluster, variant, label, ...rest })
  }
  const fields = getClusterConnectionStatus(cluster)

  if (!fields) {
    return (
      <ClusterStatusSpan title={message || 'Unknown'} status="unknown" variant={variant} {...rest}>
        {message || 'Unknown'}
      </ClusterStatusSpan>
    )
  }

  return (
    <ClusterStatusSpan
      title={fields.message}
      status={fields.clusterStatus}
      variant={variant}
      {...rest}
    >
      {variant === 'header' ? (
        fields.label
      ) : (
        <SimpleLink src={fields.nodesDetailsUrl}>{label || fields.label}</SimpleLink>
      )}
    </ClusterStatusSpan>
  )
}

export const ClusterApiServerHealthStatus: FC<IClusterStatusProps> = ({
  cluster,
  variant = 'table',
  message = undefined,
  label = undefined,
  ...rest
}) => {
  const fields = getClusterApiServerHealthStatus(cluster)

  return (
    <ClusterStatusSpan
      title={fields.message}
      status={fields.clusterStatus}
      variant={variant}
      {...rest}
    >
      {variant === 'header' ? (
        label || fields.label
      ) : (
        <SimpleLink src={fields.nodesDetailsUrl}>{label || fields.label}</SimpleLink>
      )}
    </ClusterStatusSpan>
  )
}
