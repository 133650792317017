import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

const items = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
]

export default function IntervalBackupUnitPicklist(props: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      name="intervalBackupUnit"
      label="Interval Backup Unit"
      items={items}
      showAll={false}
      {...props}
    />
  )
}
