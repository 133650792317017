import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import Row from 'core/containers/Row'
import Dropdown from 'core/elements/dropdown'
import React, { useCallback } from 'react'

const spotAllocationStrategyOptions = [
  { label: 'lowest-price', value: 'lowest-price' },
  { label: 'capacity-optimized', value: 'capacity-optimized' },
]

const defaultOnDemandBaseCapacity = 0
const defaultOnDemandPercentageAboveBaseCapacity = 100
const defaultSpotAllocationStrategy = 'lowest-price'

export default function MixedInstancesPolicyFields({
  workerNodeGroup = undefined,
  context = undefined,
  onChange,
}) {
  const data = workerNodeGroup ? workerNodeGroup : context
  const { mixedInstancesPolicy } = data

  const handleToggleChange = useCallback(
    (enableMixedInstances) => {
      if (!enableMixedInstances) {
        onChange('mixedInstancesPolicy')(null)
      } else {
        onChange('mixedInstancesPolicy')({
          instancesDistribution: {
            onDemandBaseCapacity: defaultOnDemandBaseCapacity,
            onDemandPercentageAboveBaseCapacity: defaultOnDemandPercentageAboveBaseCapacity,
            spotAllocationStrategy: defaultSpotAllocationStrategy,
          },
        })
      }
    },
    [onChange],
  )

  const handleChange = useCallback(
    (field) => (value) => {
      onChange('mixedInstancesPolicy')({
        instancesDistribution: {
          ...(mixedInstancesPolicy?.instancesDistribution || {}),
          [field]: value,
        },
      })
    },
    [onChange, mixedInstancesPolicy],
  )

  return (
    <>
      <ToggleSwitchField
        id="mixedInstances"
        info="MixedInstancesPolicy describes how multiple instance
        types will be used by the Auto Scaling group."
        label="Enable Mixed Instances"
        value={!!mixedInstancesPolicy}
        onChange={handleToggleChange}
      />
      {!!mixedInstancesPolicy && (
        <>
          <Row minItemWidth="300" gap={24}>
            <TextField
              id={
                workerNodeGroup
                  ? `${workerNodeGroup.id}-onDemandBaseCapacity`
                  : 'onDemandBaseCapacity'
              }
              label="Nodes On Demand Base Capacity"
              value={mixedInstancesPolicy?.instancesDistribution?.onDemandBaseCapacity}
              onChange={handleChange('onDemandBaseCapacity')}
              info="OnDemandAllocation indicates how to allocate
              instance types to fulfill On-Demand capacity."
              type="number"
              min="0"
            />
            <TextField
              id={
                workerNodeGroup
                  ? `${workerNodeGroup.id}-onDemandPercentageAboveBaseCapacity`
                  : 'onDemandPercentageAboveBaseCapacity'
              }
              label="Nodes on Demand % Above Base"
              value={
                mixedInstancesPolicy?.instancesDistribution?.onDemandPercentageAboveBaseCapacity
              }
              onChange={handleChange('onDemandPercentageAboveBaseCapacity')}
              type="number"
              min="1"
              max="100"
            />
          </Row>
          <Row minItemWidth="300" gap={24}>
            <DropdownField
              DropdownComponent={Dropdown}
              tooltip="SpotAllocationStrategy indicates how to allocate
              instances across Spot Instance pools"
              id={
                workerNodeGroup
                  ? `${workerNodeGroup.id}-spotAllocationStrategy`
                  : 'spotAllocationStrategy'
              }
              label="Spot Instance Allocation Strategy"
              value={mixedInstancesPolicy?.instancesDistribution?.spotAllocationStrategy}
              onChange={handleChange('spotAllocationStrategy')}
              items={spotAllocationStrategyOptions}
            />
          </Row>
        </>
      )}
    </>
  )
}
