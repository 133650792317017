import React from 'react'
import Menu from 'core/elements/menu/Menu'
import useToggler from 'core/hooks/useToggler'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { styled } from '@material-ui/styles'
import { middleLeft } from 'core/elements/menu/defaults'
import clsx from 'clsx'
import EnableAddonButton from './EnableAddonButton'
import DisableAddonButton from './DisableAddonButton'
import EditAddonButton from './EditAddonButton'

const menuOffset = {
  vertical: -8,
  horizontal: 8,
}

const ActionDots = styled(({ onClick }) => (
  <FontAwesomeIcon onClick={onClick}>ellipsis-vertical</FontAwesomeIcon>
))<Theme>(({ theme }) => ({
  ...theme.typography.inputTable,
  display: 'grid',
  gridAutoFlow: 'column',
  cursor: 'pointer',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: 4,
  gap: 8,
  '&:hover': {
    backgroundColor: theme.components.table.hoverBackground,
  },
}))

export default function AddonActionMenu({ addon, cluster, addonVersions, allAddons }) {
  const classes = useStyles()
  const [isOpen, toggleIsOpen] = useToggler()
  return (
    <Menu
      id="grid-columns-menu"
      align={middleLeft.align}
      origin="top left"
      offset={menuOffset}
      className={classes.menu}
      anchor={<ActionDots onClick={toggleIsOpen} />}
      open={isOpen}
      onClose={toggleIsOpen}
    >
      {addon?.isEnabled ? (
        <>
          {addon?.isConfigurable && (
            <EditAddonButton
              className={classes.menuItem}
              addon={addon}
              cluster={cluster}
              addonVersions={addonVersions}
              allAddons={allAddons}
              toggleMenu={toggleIsOpen}
            />
          )}
          <DisableAddonButton
            className={clsx(classes.menuItem, classes.warning)}
            addon={addon}
            cluster={cluster}
            toggleMenu={toggleIsOpen}
          />
        </>
      ) : (
        <EnableAddonButton
          className={classes.menuItem}
          addon={addon}
          cluster={cluster}
          addonVersions={addonVersions}
          allAddons={allAddons}
          toggleMenu={toggleIsOpen}
        />
      )}
    </Menu>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  menu: {
    '& .menu-popover': {
      minWidth: 60,
      padding: '8px',
    },
  },
  menuItem: {
    cursor: 'pointer',
    padding: '4px 8px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  warning: {
    color: theme.palette.red[500],
  },
}))
