import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import Text from 'core/elements/Text'
import { castBoolToStr } from 'utils/misc'
import React from 'react'
import { CombinedClusterSelector } from '../../combinedClusters/model'

interface ClusterBooleanCellProps {
  key: string
  trueLabel?: string
  falseLabel?: string
}

export default function getClusterBooleanCell({
  key,
  trueLabel = 'Enabled',
  falseLabel = 'Not Enabled',
}: ClusterBooleanCellProps) {
  return ({ item: cluster }: GridCellProps<CombinedClusterSelector>) => (
    <Text variant="body2">{castBoolToStr(trueLabel, falseLabel)(!!cluster[key])}</Text>
  )
}
