import React, { useState } from 'react'
import { IClusterAction } from './model'
import SimpleLink from 'core/components/SimpleLink'
import KubeconfigDownloadModal from './KubeconfigDownloadModal'

interface Props {
  cluster: IClusterAction
  className?: string
  icon?: string
  iconPosition?: 'left' | 'right'
}

const DownloadKubeConfigLink = ({ cluster, icon, className, iconPosition = 'right' }: Props) => {
  const [showModal, setModal] = useState(false)

  const handleOpen = () => setModal(true)
  const handleClose = () => setModal(false)

  return (
    <div>
      {showModal && <KubeconfigDownloadModal open={true} cluster={cluster} onClose={handleClose} />}
      <SimpleLink
        src=""
        icon={icon}
        iconPosition={iconPosition}
        className={className}
        onClick={handleOpen}
      >
        Download kubeconfig
      </SimpleLink>
    </div>
  )
}

export default DownloadKubeConfigLink
