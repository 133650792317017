import React, { useMemo } from 'react'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from '../clusters/newActions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { makeParamsClustersSelector } from '../clusters/selectors'
import { isNilOrEmpty } from 'utils/fp'
import { uniq, flatten } from 'ramda'
import { listNodes } from './new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'

const clustersSelector = makeParamsClustersSelector()

const defaultParams = {
  healthyClusters: true,
}

interface NodeInterfacesDropdownProps extends Omit<DropdownProps<string>, 'items'> {
  clusterId?: string
  values: any
}

export default function NodeInterfacesPicklist({
  name,
  label,
  clusterId,
  value,
  onChange,
  values,
  ...props
}: NodeInterfacesDropdownProps) {
  const { loading: loadingClusters } = useListAction(listClusters)
  const clusters = useSelectorWithParams(clustersSelector, defaultParams)
  const cluster = useMemo(
    () => (clusterId ? clusters.find((cluster) => cluster.uuid === clusterId) : null),
    [clusterId, clusters],
  )
  const { loading: loadingNodes } = useListAction(listNodes)
  const availableNodes = useSelectorWithParams(nodesSelector, {})

  const getNodes = ({ masterNodes = [], workerNodes = [] }) => {
    const selectedNodeIds = new Set([...masterNodes, ...workerNodes])
    return availableNodes.filter((node) => selectedNodeIds.has(node.uuid))
  }

  const options = useMemo(() => {
    const nodes = clusterId ? cluster?.nodes : getNodes(values)
    if (isNilOrEmpty(nodes)) return []
    const uniqueInterfaces = uniq(
      flatten(nodes.map((node) => Object.keys(node.networkInterfaces || {}))),
    )
    return uniqueInterfaces.map((i) => ({ key: i, value: i, label: i }))
  }, [cluster, values?.masterNode, values?.workerNodes])

  return (
    <AsyncDropdown
      {...props}
      name={name}
      label={label}
      items={options}
      loading={loadingClusters || loadingNodes}
      value={value}
      onChange={onChange}
    />
  )
}
