import React, { useState } from 'react'
import TextField from 'core/components/validatedForm/TextField'
import Info from 'core/components/validatedForm/Info'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import axios from 'axios'
import downloadFile from 'core/utils/downloadFile'
import { iamPolicyLink } from 'k8s/links'
import Alert from 'core/components/Alert'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import CheckCloudAccessDialog from '../check-cloud-access-dialog'
import { cloudProviderValidator } from 'core/utils/fieldValidators'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'

const useStyles = makeStyles((theme: Theme) => ({
  bullets: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  bullet: {
    width: '50%',
  },
  // Weird but works to make sure that the bullets aren't overlapping with text
  bulletText: {
    paddingRight: theme.spacing(3),
    display: 'list-item',
  },
  downloadIcon: {
    marginRight: theme.spacing(0.5),
  },
  iamInfo: {
    marginBottom: theme.spacing(4),
  },
  inCardSubmit: {
    marginTop: theme.spacing(2.5),
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  toggleIamPolicy: boolean
  showSubmitInCard: boolean
  updateWizard: boolean
  errorMessage: string
  showInfo?: boolean
  setInitialValue?: boolean
}

const downloadIAMPolicy = async (setState) => {
  try {
    setState({ status: '', message: '' })
    const response = await axios.get(iamPolicyLink)
    const fileData = JSON.stringify(response.data)
    downloadFile({
      filename: 'aws-policy.json',
      contents: fileData,
    })
  } catch (err) {
    setState({ status: 'error', message: err?.message })
  }
}

const AwsCloudProviderFields = ({
  wizardContext,
  setWizardContext,
  toggleIamPolicy = false,
  showSubmitInCard = false,
  updateWizard = false,
  errorMessage = '',
  showInfo = true,
  setInitialValue = false,
}: Props) => {
  const { bullets, bullet, bulletText, downloadIcon, iamInfo, inCardSubmit, actions } = useStyles(
    {},
  )
  const [downloadState, setDownloadState] = useState({ status: '', message: '' })
  const [showCloudAccessDialog, setShowCloudAccessDialog] = useState(false)

  return (
    <>
      {showCloudAccessDialog && (
        <CheckCloudAccessDialog onClose={() => setShowCloudAccessDialog(false)} />
      )}
      {showInfo && (
        <Info
          error={downloadState.status === 'error'}
          title={toggleIamPolicy ? 'IAM Policy' : ''}
          expanded={false}
          className={iamInfo}
        >
          <div>
            The following permissions are required on your AWS account in order to deploy fully
            automated Managed Kubernetes clusters:
          </div>
          <ul className={bullets}>
            <li className={bullet}>
              <span className={bulletText}>ELB Management</span>
            </li>
            <li className={bullet}>
              <span className={bulletText}>EC2 Instance Management</span>
            </li>
            <li className={bullet}>
              <span className={bulletText}>Route 53 DNS Configuration</span>
            </li>
            <li className={bullet}>
              <span className={bulletText}>EBS Volume Management</span>
            </li>
            <li className={bullet}>
              <span className={bulletText}>
                Access to 2 or more Availability Zones within the region
              </span>
            </li>
            <li className={bullet}>
              <span className={bulletText}>VPC Management</span>
            </li>
          </ul>
          <div className={actions}>
            <Button
              type="button"
              variant="secondary"
              icon="download"
              onClick={async () => downloadIAMPolicy(setDownloadState)}
            >
              Download IAM Policy
            </Button>
            <Button
              icon="download"
              type="button"
              variant="secondary"
              onClick={() => setShowCloudAccessDialog(true)}
            >
              Check Cloud Access
            </Button>
          </div>
          {downloadState.status === 'error' && (
            <Alert variant="error" message={downloadState.message} />
          )}
        </Info>
      )}
      <FormFieldSection title="Create AWS Provider">
        <TextField
          id="name"
          label="Cloud Provider Name"
          onChange={(value) => setWizardContext({ name: value })}
          value={wizardContext.name}
          info="Name for Cloud Provider"
          disabled={updateWizard}
          validations={[cloudProviderValidator]}
          required
        />
        <TextField
          id="key"
          label="Access Key ID"
          onChange={(value) => setWizardContext({ key: value })}
          value={wizardContext.key}
          initialValue={setInitialValue ? '**********************' : ''}
          info="AWS IAM Access Key"
          required
        />
        <TextField
          id="secret"
          label="Secret Access Key"
          onChange={(value) => setWizardContext({ secret: value })}
          value={wizardContext.secret}
          initialValue={setInitialValue ? '**********************' : ''}
          info="IAM User Secret Key"
          type="password"
          required
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </FormFieldSection>
      {showSubmitInCard && (
        <div className={inCardSubmit}>
          <Button type="submit">Update Cloud Provider</Button>
        </div>
      )}
    </>
  )
}

export default AwsCloudProviderFields
