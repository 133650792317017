import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { IMachinePoolSelector, MachinePool } from './model'

import { assocPath, find, filter, pipe } from 'ramda'
import { allConfigSelector } from '../configs/selectors'
import { IConfigSelector } from '../configs/model'
import { awsMachinePoolsSelector } from '../aws-machine-pool/selectors'
import { IAwsMachinePoolSelector } from '../aws-machine-pool/model'
import { awsManagedMachinePoolSelector } from '../aws-managed-machine-pool/selectors'
import { IAwsManagedMachinePoolSelector } from '../aws-managed-machine-pool/model'
import { isNilOrEmpty, emptyObj } from 'utils/fp'
import { NodeGroupTypeLabels } from '../model'
import { createSelector } from '@reduxjs/toolkit'

export const machinePoolSelector: AppSelector<IMachinePoolSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.MachinePools>(DataKeys.MachinePools),
  awsMachinePoolsSelector,
  awsManagedMachinePoolSelector,
  allConfigSelector,
  (machinePools, awsMachinePools, awsManagedMachinePools, configs): IMachinePoolSelector[] =>
    machinePools.map((mp: MachinePool) => {
      const awsMachinePool = find(
        (template: IAwsMachinePoolSelector) =>
          template.kind === mp.spec?.template?.spec?.infrastructureRef?.kind &&
          template.name === mp.spec?.template?.spec?.infrastructureRef?.name,
        awsMachinePools,
      )
      const awsManagedMachinePool = find(
        (template: IAwsManagedMachinePoolSelector) =>
          template.kind === mp.spec?.template?.spec?.infrastructureRef?.kind &&
          template.name === mp.spec?.template?.spec?.infrastructureRef?.name,
        awsManagedMachinePools,
      )
      const config = find(
        (config: IConfigSelector) =>
          config.name === mp.spec?.template?.spec?.bootstrap?.configRef?.name,
        configs,
      )

      return {
        ...mp,
        uuid: mp.metadata?.uid,
        name: mp.metadata?.name,
        namespace: mp.metadata?.namespace,
        clusterName: mp.spec?.clusterName,
        type: NodeGroupTypeLabels.MachinePool,
        creationTimestamp: mp.metadata?.creationTimestamp,
        k8sVersion: mp.spec?.template?.spec?.version,
        phase: mp.status?.phase,
        replicas: mp.spec?.replicas,
        readyReplicas: mp.status?.readyReplicas,
        availableReplicas: mp.status?.availableReplicas,
        unavailableReplicas: mp.status?.unavailableReplicas,
        infrastructureRef: mp.spec?.template?.spec?.infrastructureRef,
        awsMachinePool,
        awsManagedMachinePool,
        config,
        resources: [
          mp,
          ...(awsMachinePool?.resources || []),
          ...(awsManagedMachinePool?.resources || []),
          ...(config?.resources || []),
        ].filter((r) => !isNilOrEmpty(r)),
      }
    }),
)

type MachinePoolsByNamespaceAndClusterSelectorModel = {
  [namespace: string]: {
    [clusterName: string]: IMachinePoolSelector[]
  }
}

export const machinePoolsByNamespaceAndClusterSelector = createSharedSelector(
  machinePoolSelector,
  (machinePools): MachinePoolsByNamespaceAndClusterSelectorModel =>
    machinePools.reduce((accum, mp) => {
      const { namespace, clusterName } = mp
      const machinePools = accum[namespace]?.[clusterName] || []
      machinePools.push(mp)
      return assocPath([namespace, clusterName], machinePools, accum)
    }, emptyObj),
)
