// import React, { useEffect, useState } from 'react'
import React, { useState, useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import { routes } from 'core/utils/routes'
import AddCloudProviderCredentialStep from './AddCloudProviderCredentialStep'
import AddCloudProviderVerificationStep from './AddCloudProviderVerificationStep'
import FormWrapperDefault from 'core/components/FormWrapper'
import { CloudProviders, CloudProviderRouteNames } from './model'
import DocumentMeta from 'core/components/DocumentMeta'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CreateButton from 'core/components/buttons/CreateButton'
import Button from 'core/elements/button'

const FormWrapper: any = FormWrapperDefault // types on forward ref .js file dont work well.

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const wizardMetaFormattedNames = {
  name: 'Cloud Provider Name',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

export const formTitle = ({ provider }) => {
  if (provider === CloudProviders.Aws) {
    return 'Create AWS Cloud Provider'
  } else if (provider === CloudProviders.Azure) {
    return 'Create Azure Cloud Provider'
  } else if (provider === CloudProviders.Gcp) {
    return 'Create Google Cloud Provider'
  }
  return 'Create Cloud Provider'
}

const cloudProviderRouteNameMap = {
  [CloudProviderRouteNames.Aws]: CloudProviders.Aws,
  [CloudProviderRouteNames.Azure]: CloudProviders.Azure,
  [CloudProviderRouteNames.Google]: CloudProviders.Gcp,
}

const AddCloudProviderPage = () => {
  const classes = useStyles()
  const { history, location } = useReactRouter()
  const urlParams = new URLSearchParams(location.search)
  const provider = urlParams.get('provider') || CloudProviders.Aws
  const [submittingStep, setSubmittingStep] = useState(false)
  const submitLastStep = (params) => {
    history.push(routes.cloudProviders[provider].edit.path({ id: params.cloudProviderId }))
  }
  const renderAdditionalActions = useCallback((ctx, stepIdx, handleNext) => {
    if (stepIdx === 0) {
      return [
        <CreateButton
          key="create-cloud-provider"
          className={classes.button}
          onClick={handleNext}
          type="submit"
        >
          Create Cloud Provider
        </CreateButton>,
      ]
    }
    if (stepIdx === 1) {
      return [
        <Button
          key="create-cloud-provider-complete"
          onClick={() => history.push(routes.cloudProviders[provider].list.path())}
          type="submit"
        >
          Complete
        </Button>,
      ]
    }
    return null
  }, [])

  const initialContext = {
    provider: cloudProviderRouteNameMap[provider] || CloudProviders.Aws,
  }

  return (
    <>
      <DocumentMeta title="Add Cloud Provider" bodyClasses={['form-view']} />
      <FormWrapper
        // title="Add New Cloud Provider"
        backUrl={routes.cloudProviders[provider].list.path()}
        loading={submittingStep}
      >
        <Wizard
          onComplete={submitLastStep}
          context={initialContext}
          hideBack
          hideAllButtons
          additionalActions={renderAdditionalActions}
        >
          {({ wizardContext, setWizardContext, onNext, handleNext }) => {
            return (
              <>
                <WizardStep
                  stepId="step1"
                  label="Choose a Platform"
                  keepContentMounted={false}
                  summaryTitle="Add a Cloud Provider"
                  summaryIcon={wizardContext.provider}
                  summaryKeyOverrides={wizardMetaFormattedNames}
                  summaryCalloutFields={wizardMetaCalloutFields}
                >
                  <AddCloudProviderCredentialStep
                    wizardContext={wizardContext}
                    setWizardContext={setWizardContext}
                    onNext={onNext}
                    handleNext={handleNext}
                    setSubmitting={setSubmittingStep}
                  />
                </WizardStep>

                <AddCloudProviderVerificationStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  history={history}
                />
              </>
            )
          }}
        </Wizard>
      </FormWrapper>
    </>
  )
}

export default AddCloudProviderPage
