import TextField from 'core/components/validatedForm/TextField'
import { mergeRight } from 'ramda'
import React from 'react'

const ServicesFqdnField = ({ setWizardContext, wizardContext, required, disabled }) => (
  <TextField
    id="serviceFqdn"
    label="Services FQDN"
    info="FQDN used to reference cluster services. If deploying onto AWS, we will automatically create the DNS records for this FQDN into AWS Route 53."
    value={wizardContext.route53?.serviceFqdn}
    onChange={(serviceFqdn) =>
      setWizardContext({ route53: mergeRight(wizardContext.route53, { serviceFqdn }) })
    }
    required={required}
    disabled={disabled}
  />
)

export default ServicesFqdnField
