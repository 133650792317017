import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import { clusterHealthStatusFields } from 'app/plugins/infrastructure/components/clusters/ClusterStatusUtils'
import { routes } from 'core/utils/routes'
import SimpleLink from 'core/components/SimpleLink'
import ClusterStatusSpan, {
  renderErrorStatus,
} from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import { hasClockDrift } from 'app/plugins/infrastructure/components/nodes/helpers'

const renderConverging = (clusterUuid) => (
  <ClusterStatusSpan status="loading" iconStatus>
    {clusterUuid ? (
      <SimpleLink
        src={routes.cluster.managed.qbert.detail.path({ tab: 'node-health', id: clusterUuid })}
      >
        Converging
      </SimpleLink>
    ) : (
      'Converging'
    )}
  </ClusterStatusSpan>
)

export function NodeHealthStatusCell({ item: node }: GridCellProps<INodesSelector>) {
  const { status, clusterUuid } = node
  if (status === 'converging') {
    return renderConverging(clusterUuid)
  }

  const healthStatus =
    status === 'disconnected' ? 'unknown' : status?.toLowerCase() === 'ok' ? 'healthy' : 'unhealthy'
  const fields = clusterHealthStatusFields[healthStatus]

  return (
    <>
      <ClusterStatusSpan title={fields.label} status={fields.status}>
        {clusterUuid ? (
          <SimpleLink
            src={routes.cluster.managed.qbert.detail.path({ tab: 'node-health', id: clusterUuid })}
          >
            {fields.label}
          </SimpleLink>
        ) : (
          fields.label
        )}
      </ClusterStatusSpan>
      {hasClockDrift(node) &&
        renderErrorStatus({
          nodesDetailsUrl: routes.nodes.detail.path({ id: node.uuid }),
          text: 'Node Clock Drift',
          variant: 'table',
        })}
    </>
  )
}
