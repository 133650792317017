import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ClusterHealthStatus } from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import React from 'react'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { ClusterTypes } from '../model'

export default function Platform9ComponentsStatusCell({
  item: cluster,
}: GridCellProps<CombinedClusterSelector>) {
  const showIconStatus =
    cluster.clusterType !== ClusterTypes.Capi &&
    (cluster.connectionStatus === 'converging' || cluster.status === 'pending')
  return <ClusterHealthStatus iconStatus={showIconStatus} cluster={cluster} />
}
