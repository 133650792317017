import React from 'react'
import Card from 'core/elements/card'
import BulletList from 'core/components/BulletList'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalLink from 'core/components/ExternalLink'
import generateTestId from 'utils/test-helpers'
import Button from 'core/elements/button'
import {
  artifactsHubLink,
  kubeCostChartLink,
  openEbsChartsLink,
  pf9ChartsLink,
  rookReleaseLink,
  tanzuChartsLink,
  traefikChartLink,
} from 'k8s/links'

export default function ConnectRepositoriesCard({ handleConnectRepositoryClick }) {
  const classes = useStyles()
  return (
    <Card
      title="Repositories"
      footer={
        <div className={classes.connectRepositoriesFooter}>
          <Button variant="secondary" onClick={handleConnectRepositoryClick}>
            Connect Repository
          </Button>
        </div>
      }
      withCustomFooter
    >
      <div className={classes.connectRepositoriesContent}>
        <Text variant="body2">
          There are no Helm Application repositories connected to this cluster. Try connecting
        </Text>
        <BulletList
          className={classes.emptyMessageBulletList}
          type="dash"
          items={[
            <Text key="rook" variant="body2" data-testid={generateTestId('rook')}>
              Rook: <ExternalLink url={rookReleaseLink}>charts.rook.io/release</ExternalLink>
            </Text>,
            <Text key="openEbs" variant="body2" data-testid={generateTestId('openEbs')}>
              OpenEBS: <ExternalLink url={openEbsChartsLink}>openebs.github.io/charts</ExternalLink>
            </Text>,
            <Text key="kubeCost" variant="body2" data-testid={generateTestId('kubeCost')}>
              KubeCost:{' '}
              <ExternalLink url={kubeCostChartLink}>kubecost.github.io/cost-analyzer</ExternalLink>
            </Text>,
            <Text key="traefik" variant="body2" data-testid={generateTestId('traefik')}>
              traefik: <ExternalLink url={traefikChartLink}>helm.traefik.io/traefik</ExternalLink>
            </Text>,
            <Text key="Velero" variant="body2" data-testid={generateTestId('Velero')}>
              Velero:{' '}
              <ExternalLink url={tanzuChartsLink}>vmware-tanzu.github.io/helm-charts</ExternalLink>
            </Text>,
            <Text key="pf9Apps" variant="body2" data-testid={generateTestId('pf9Apps')}>
              Platform9 Certified Apps:{' '}
              <ExternalLink url={pf9ChartsLink}>platform9.github.io/helm-charts</ExternalLink>
            </Text>,
          ]}
        />
        <Text variant="body2">
          Visit <ExternalLink url={artifactsHubLink}>artifacthub.io</ExternalLink> to explore all
          the great Helm enabled applications
        </Text>
      </div>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  emptyMessageBulletList: {
    margin: theme.spacing(1, 0),
  },
  connectRepositoriesFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 16px',
    borderTop: `1px solid ${theme.components.card.border}`,
    height: 'max-content',
  },
  connectRepositoriesContent: {
    maxWidth: '347px',
  },
}))
