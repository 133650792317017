import React, { useMemo } from 'react'
import { pathStrOr } from 'utils/fp'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listCloudProviderRegionDetails } from '../../cloudProviders/new-actions'
import { cloudProviderRegionDetailsSelector } from '../../cloudProviders/selectors'
interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
}

const blackListedFlavors = ['micro', 'nano', 'small']

const isBlackListedFlavor = (flavor) => !blackListedFlavors.some((name) => flavor.includes(name))

export default function AwsRegionFlavorPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const items = useMemo(() => {
    const flavors = pathStrOr([], '0.flavors', regionDetails).filter(isBlackListedFlavor)
    return flavors.map((x) => ({ label: x, value: x }))
  }, [regionDetails])

  return <AsyncDropdown loading={loadingCloudProviderRegionDetails} items={items} {...rest} />
}
