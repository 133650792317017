import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import React from 'react'

const options = [
  { label: 'True', value: true },
  { label: 'False', value: false },
]

export default function BooleanPicklistField({ id, label, ...rest }) {
  return (
    <DropdownField {...rest} DropdownComponent={Dropdown} id={id} label={label} items={options} />
  )
}
