import React from 'react'
import Text from 'core/elements/Text'
import { ClusterTypes } from '../model'
import { cloudProviderTypes } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { capitalizeString } from 'utils/misc'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'

export const getInfrastructureType = (cluster) => {
  if (cluster.clusterType === ClusterTypes.Normal) {
    return (
      cloudProviderTypes[cluster.cloudProviderType] || capitalizeString(cluster.cloudProviderType)
    )
  } else if (cluster.clusterType === ClusterTypes.Imported) {
    return (cluster.providerType || '').toUpperCase()
  } else {
    return cluster.infrastructureType.toUpperCase()
  }
}

export default function InfrastructureTypeCell({
  item: cluster,
}: GridCellProps<CombinedClusterSelector>) {
  return <Text variant="body2">{getInfrastructureType(cluster)}</Text>
}
