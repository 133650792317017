import React, { useMemo, useCallback, useState } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs } from 'app/constants'
import { listClusters } from './newActions'
import { clustersSelector } from './selectors'
import { useAppSelector } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import useReactRouter from 'use-react-router'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import ListContainer from 'core/containers/ListContainer'
import AddClusterButton from './AddClusterButton'
import { pick } from 'ramda'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import K8sVersionCell from './cluster-cells/K8sVersionCell'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ClusterNameCell from './cluster-cells/ClusterNameCell'
import ClusterTypeCell from './cluster-cells/ClusterTypeCell'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import ClustersOverviewHeader from './ClustersOverviewHeader'
import { ClusterTypes } from './model'
import {
  getClusterApiServerHealthStatus,
  getClusterConnectionStatus,
  getClusterHealthStatus,
} from './ClusterStatusUtils'
import { capiClustersSelector } from './capi/selectors'
import { listCapiClusters } from './capi/actions'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import InfrastructureTypeCell from './cluster-cells/InfrastructureTypeCell'
import { CapiClusterPhases } from './capi/model'
import PollingData from 'core/components/PollingData'
import { isAdmin } from '../common/helpers'
import getGridRedirectButton from 'core/elements/grid/helpers/getGridRedirectButton'
import {
  canDeleteCapiCluster,
  canDeleteCluster,
  canUpgradeCapiCluster,
  canUpgradeCluster,
  isCapiCluster,
  isImportedCluster,
  isQbertCluster,
  clusterIsBeingDeleted,
  getClusterStatus,
} from './helpers'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { routes } from 'core/utils/routes'
import UpgradeClusterPage from './UpgradeClusterPage'
import { isNilOrEmpty } from 'utils/fp'
import { getNodeGroupStatuses } from './capi/helpers'
import { Phase } from './capi/machine-deployment/model'
import CapiClusterDeleteDialog from './capi/CapiClusterDeleteDialog'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import { downloadClusterYamls } from './capi/details/helpers'
import ClusterDeleteDialog from './ClusterDeleteDialog'
import DetachImportedClusterDialog from '../importedClusters/DetachImportedClusterDialog'
import KubeconfigDownloadModal from './KubeconfigDownloadModal'

type SelectorModel = ArrayElement<ReturnType<typeof allClustersSelector>>

const usePrefParams = createUsePrefParamsHook('All Clusters', listTablePrefs)

export const qbertClusterIsHealthy = (cluster): boolean => {
  const connectionStatus = getClusterConnectionStatus(cluster)
  const pf9ComponentsStatus = getClusterHealthStatus(cluster)
  const apiServerHealthStatus = getClusterApiServerHealthStatus(cluster)

  const connected = connectionStatus?.clusterStatus === 'ok'
  const pf9ComponentsAreHealhy = pf9ComponentsStatus?.status === 'ok'
  const apiServerIsHealthy = apiServerHealthStatus?.clusterStatus === 'ok'

  const props = getClusterStatus(cluster?.nodes)
  const { variant } = props
  const k8sNodeStatusHealthy = variant === 'success'

  return connected && pf9ComponentsAreHealhy && apiServerIsHealthy && k8sNodeStatusHealthy
}

export const capiClusterIsHealthy = (cluster) => {
  const nodeGroups = cluster.allNodeGroups
  if (isNilOrEmpty(nodeGroups)) return false
  const statuses = getNodeGroupStatuses(nodeGroups)
  const allNodesRunning = statuses[Phase.Running].count === cluster.allNodeGroups.length

  return (
    cluster.phase === CapiClusterPhases.Provisioned && cluster.controlPlaneReady && allNodesRunning
  )
}

const getClusterOverallHealthStatus = (cluster): StatusCellModel => {
  let healthy = null

  if (clusterIsBeingDeleted(cluster)) {
    return { variant: 'error', label: 'Deleting' }
  }

  if (isImportedCluster(cluster)) {
    healthy = cluster.status?.phase === 'Running'
  } else if (isQbertCluster(cluster)) {
    healthy = qbertClusterIsHealthy(cluster)
  } else {
    healthy = capiClusterIsHealthy(cluster)
  }

  return healthy
    ? { variant: 'success', label: 'Healthy' }
    : { variant: 'error', label: 'Unhealthy' }
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: ClusterNameCell,
    memoizeCell: false,
  },
  {
    key: 'status',
    label: 'Overall Status',
    accessor: (cluster) => cluster,
    CellComponent: createGridStatusCell({
      dataFn: (cluster: CombinedClusterSelector): StatusCellModel => {
        return getClusterOverallHealthStatus(cluster)
      },
    }),
  },
  {
    key: 'clusterType',
    label: 'Cluster Type',
    CellComponent: ClusterTypeCell,
  },
  {
    key: 'infrastructureType',
    label: 'Infrastructure Type',
    CellComponent: InfrastructureTypeCell,
  },
  {
    key: 'version',
    label: 'K8s Version',
    CellComponent: K8sVersionCell,
  },
  {
    key: 'creationTimestamp',
    label: 'Created',
    CellComponent: DateAndTime,
  },
  {
    key: 'lastOp',
    label: 'Updated',
    CellComponent: DateAndTime,
  },
]

const defaultParams = {}
const searchTargets = ['name', 'uuid']
const oneSecond = 1000

export default function ClustersOverviewPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { history } = useReactRouter()
  const { loading: loadingCapiClusters, reload: reloadCapiClusters } = useListAction(
    listCapiClusters,
    {
      params,
    },
  )
  const { loading: loadingLegacyClusters, reload: reloadLegacyClusters } = useListAction(
    listClusters,
    {
      params,
    },
  )
  const { loading: loadingImportedClusters, reload: reloadImportedClusters } = useListAction(
    listImportedClusters,
    {
      params,
    },
  )
  const allClusters = useAppSelector(allClustersSelector)
  const capiClusters = useAppSelector(capiClustersSelector)
  const legacyClusters = useAppSelector(clustersSelector)
  const importedClusters = useAppSelector(importedClustersSelector)

  const totalClusters = useMemo(() => allClusters.length, [allClusters])
  const totalCapiClusters = useMemo(() => capiClusters.length, [capiClusters])
  const totalLegacyClusters = useMemo(() => legacyClusters.length, [legacyClusters])
  const totalImportedClusters = useMemo(() => importedClusters.length, [importedClusters])

  const loading = loadingCapiClusters || loadingLegacyClusters || loadingImportedClusters

  const handleReload = useCallback(async (refetch = false, updateLoadingState = true) => {
    reloadCapiClusters(refetch, updateLoadingState)
    reloadLegacyClusters(refetch, updateLoadingState)
    reloadImportedClusters(refetch, updateLoadingState)
  }, [])

  const handleClose = useCallback(() => {
    history.push(routes.cluster.overview.path())
  }, [])

  const [selectedCluster, setSelectedCluster] = useState(null)
  const [showDownloadKubeconfigModal, setShowDownloadKubeconfigModal] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      cond: (cluster) => !isImportedCluster(cluster),
      label: 'Kubeconfig Download',
      icon: 'arrow-circle-down',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowDownloadKubeconfigModal(true)
      },
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => isCapiCluster(cluster),
      label: 'Cluster YAML File Download',
      icon: 'download',
      handleClick: (cluster) => downloadClusterYamls(cluster),
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => {
        if (!isAdmin() || isImportedCluster(cluster)) return false
        return isCapiCluster(cluster)
          ? canUpgradeCapiCluster([cluster])
          : canUpgradeCluster([cluster])
      },
      label: 'Upgrade',
      icon: 'level-up',
      handleClick: (cluster) => {
        const route = isCapiCluster(cluster)
          ? routes.cluster.managed.capi.upgrade.path({ id: cluster?.uuid })
          : routes.cluster.managed.qbert.upgrade.path({ id: cluster?.uuid })
        history.push(route)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => {
        if (!isAdmin()) return false
        if (isImportedCluster(cluster)) {
          return true
        }
        return isCapiCluster(cluster)
          ? canDeleteCapiCluster([cluster])
          : canDeleteCluster([cluster])
      },
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (clusters) => !isImportedCluster(clusters[0]),
      label: 'Kubeconfig Download',
      icon: 'arrow-circle-down',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowDownloadKubeconfigModal(true)
      },
      refreshAfterSuccess: false,
    },
    {
      cond: (clusters) => {
        const cluster = clusters[0] ?? null
        if (!isAdmin() || isImportedCluster(cluster)) return false
        return isCapiCluster(cluster)
          ? canUpgradeCapiCluster([cluster])
          : canUpgradeCluster([cluster])
      },
      label: 'Upgrade',
      icon: 'level-up',
      handleAction: (clusters) => {
        const cluster = clusters[0] ?? null
        const route = isCapiCluster(cluster)
          ? routes.cluster.managed.capi.upgrade.path({ id: cluster?.uuid })
          : routes.cluster.managed.qbert.upgrade.path({ id: cluster?.uuid })
        history.push(route)
      },
      refreshAfterSuccess: true,
    },
    {
      cond: (clusters) => {
        const cluster = clusters[0] ?? null
        if (!isAdmin()) return false
        if (isImportedCluster(cluster)) {
          return true
        }
        return isCapiCluster(cluster)
          ? canDeleteCapiCluster([cluster])
          : canDeleteCluster([cluster])
      },
      label: 'Delete',
      icon: 'trash',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  const DeleteDialog = useMemo(
    () =>
      isCapiCluster(selectedCluster)
        ? CapiClusterDeleteDialog
        : isImportedCluster(selectedCluster)
        ? DetachImportedClusterDialog
        : ClusterDeleteDialog,
    [selectedCluster],
  )

  return (
    <>
      <DocumentMeta title="Clusters Overview" />
      <UpgradeClusterPage route={routes.cluster.managed.qbert.upgrade} onClose={handleClose} />
      {showDownloadKubeconfigModal && (
        <KubeconfigDownloadModal
          open={true}
          cluster={selectedCluster}
          onClose={() => setShowDownloadKubeconfigModal(false)}
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedCluster]}
          onSuccess={() => setShowDeleteDialog(false)}
        />
      )}
      <PollingData
        hidden
        loading={loading}
        onReload={handleReload}
        refreshDuration={oneSecond * 30}
      />
      <ClustersOverviewHeader
        totalClusters={totalClusters}
        totalCapiClusters={totalCapiClusters}
        totalLegacyClusters={totalLegacyClusters}
        totalImportedClusters={totalImportedClusters}
      />
      <ListContainer<any, SelectorModel>
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage="Loading clusters..."
        onRefresh={handleReload}
        data={allClusters}
        columns={columns}
        addText="Add Cluster"
        AddButtonComponent={AddClusterButton}
        getParamsUpdater={getParamsUpdater}
        label="All Clusters"
        showItemsCountInLabel
        rowMenuItems={rowMenuItems}
        rowMenuOffset={{ vertical: 30 }}
        batchActions={batchActions}
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
