import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ListAction from 'core/actions/ListAction'
import Bugsnag from 'utils/bugsnag'

const { sunpike } = ApiClient.getInstance()

const capiClusterEventsActions = ActionsSet.make<DataKeys.CapiEvents>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey.CapiEvents,
  cacheKey: DataKeys.CapiEvents,
  cache: false,
})

export const listCapiClusterEvents = capiClusterEventsActions.add(
  new ListAction<DataKeys.CapiEvents, { clusterName: string; namespace: string }>(
    async ({ clusterName, namespace }) => {
      Bugsnag.leaveBreadcrumb('Attempting to get capi cluster events', { clusterName, namespace })
      try {
        return await sunpike.getCapiClusterEvents(clusterName, namespace)
      } catch (error) {
        return []
      }
    },
  ),
)
