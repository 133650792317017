import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { IK8sNodeSelector, INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import { listK8sNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import useListAction from 'core/hooks/useListAction'

const k8sReadyStatus = {
  True: {
    statusLabel: 'Ready',
    status: 'ok',
    message: 'The node is healthy and ready to accept pods',
  },
  False: {
    statusLabel: 'NotReady',
    status: 'fail',
    message: 'The node is not healthy and is not acccepting pods',
  },
  Unknown: {
    statusLabel: 'Unknown',
    status: 'unknown',
    message:
      'The node controller has not heard from the node in the last node-monitor-grace-period',
  },
}

export default function K8sNodeStatusCell({
  item: node,
  value: readyStatus,
}: GridCellProps<INodesSelector | IK8sNodeSelector, string>) {
  const { loading: loadingK8sNodes } = useListAction(listK8sNodes)

  if (loadingK8sNodes) {
    return (
      <ClusterStatusSpan status="loading" iconStatus={true}>
        Loading...
      </ClusterStatusSpan>
    )
  }

  const { conditions, unschedulable } = node

  if (conditions === undefined) {
    return <ClusterStatusSpan status="unknown">N/A</ClusterStatusSpan>
  }

  const { statusLabel, status, message } = k8sReadyStatus[readyStatus]
  const unschedulableLabel = unschedulable ? 'SchedulingDisabled' : null
  const label = unschedulableLabel ? [statusLabel, unschedulableLabel].join(', ') : statusLabel
  return (
    <ClusterStatusSpan title={message} status={status}>
      {label}
    </ClusterStatusSpan>
  )
}
