import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import ModalForm from 'core/elements/modal/ModalForm'
import useUpdateAction from 'core/hooks/useUpdateAction'
import React from 'react'
import { updateAwsManagedControlPlane } from '../../control-plane/aws-managed-control-plane/actions'
import Text from 'core/elements/Text'
import {
  apiServerLogsInfo,
  auditLogsInfo,
  authenticatorLogsInfo,
  controllerManagerLogsInfo,
  schedulerLogsInfo,
} from '../../constants'

export default function EditEksControlPlaneModal({ open, onClose, controlPlane }) {
  const { update, updating, error } = useUpdateAction(updateAwsManagedControlPlane)

  const handleSubmit = async (values) => {
    const { success } = await update({
      namespace: controlPlane?.namespace,
      name: controlPlane?.name,
      body: {
        spec: {
          logging: values,
        },
      },
      updateMethod: 'PATCH',
    })
    if (success) {
      onClose()
    }
  }

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      title="Edit Control Plane"
      onSubmit={handleSubmit}
      submitting={updating}
      initialValues={controlPlane?.logging}
      error={error}
    >
      <FormFieldSection title="">
        <Text variant="caption1">Logging</Text>
        <ToggleSwitchField id="apiServer" label="API Server" info={apiServerLogsInfo} />
        <ToggleSwitchField id="audit" label="Audit" info={auditLogsInfo} />
        <ToggleSwitchField id="authenticator" label="Authenticator" info={authenticatorLogsInfo} />
        <ToggleSwitchField
          id="controllerManager"
          label="Controller Manager"
          info={controllerManagerLogsInfo}
        />
        <ToggleSwitchField id="scheduler" label="Scheduler" info={schedulerLogsInfo} />
      </FormFieldSection>
    </ModalForm>
  )
}
