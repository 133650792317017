import jsYaml from 'js-yaml'
import { isNilOrEmpty } from 'utils/fp'
import JSZip from 'jszip'
import { ClusterCloudPlatforms } from 'app/constants'
import { requiredValidator, customValidator } from 'core/utils/fieldValidators'
import moize from 'moize'

export const downloadClusterYamls = (cluster) => {
  if (!cluster) return
  const clusterYamls = convertResourcesToYamls(cluster.resources)
  const nodeGroupYamls = (cluster.allNodeGroups || []).reduce((accum, nodeGroup) => {
    accum[nodeGroup.name] = convertResourcesToYamls(nodeGroup.resources)
    return accum
  }, {})
  downloadAllYamlsZipFile(clusterYamls, nodeGroupYamls, cluster.name)
}

export interface Yamls {
  [kind: string]: string
}

export const convertResourcesToYamls = (resources = []): Yamls => {
  const yamls: Yamls = {}

  resources.forEach((resource) => {
    try {
      const yaml = jsYaml.dump(resource)
      yamls[resource.kind] = yaml
    } catch (e) {
      console.error('Error converting Resource to YAML', e)
    }
  })
  return yamls
}

export const downloadYamlsZipFile = (yamls, fileName = 'yamls') => {
  if (isNilOrEmpty(yamls)) return
  try {
    const zip = new JSZip()
    Object.entries(yamls).forEach(([kind, content]) => {
      zip.file(`${kind}.yaml`, content as string)
    })
    zip.generateAsync({ type: 'blob' }).then(function(content) {
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(content)
      elem.download = fileName
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    })
  } catch (error) {
    console.error('Error downloading yaml files', error)
  }
}

interface NodeGroupYamls {
  [nodeGroupName: string]: Yamls
}

export const downloadAllYamlsZipFile = (
  clusterYamls: Yamls,
  nodeGroupYamls: NodeGroupYamls,
  fileName = 'yamls',
) => {
  if (isNilOrEmpty(clusterYamls)) return
  try {
    const zip = new JSZip()
    Object.entries(clusterYamls).forEach(([fileName, content]) => {
      zip.file(`${fileName}.yaml`, content as string)
    })
    if (!isNilOrEmpty(nodeGroupYamls)) {
      Object.entries(nodeGroupYamls).forEach(([groupName, resources]) => {
        zip.folder(`node-groups/${groupName}`)
        Object.entries(resources).forEach(([fileName, content]) => {
          zip.folder(`node-groups/${groupName}`).file(`${fileName}.yaml`, content as string)
        })
      })
    }
    zip.generateAsync({ type: 'blob' }).then(function(content) {
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(content)
      elem.download = fileName
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    })
  } catch (error) {
    console.error('Error downloading yaml files', error)
  }
}

export const infrastructureResourceKinds = ['Cluster', 'AWSCluster']
export const controlPlaneKinds = [
  'NodeletControlPlane',
  'AWSManagedControlPlane',
  'AWSMachineTemplate',
]
export const identityResourceKinds = ['AWSClusterRoleIdentity', 'AWSClusterStaticIdentity']

export const getInfrastructureResourceKinds = (infraType) => {
  const result = new Set(infrastructureResourceKinds)
  if (infraType !== ClusterCloudPlatforms.EKS) {
    identityResourceKinds.map((k) => result.add(k))
  }
  return result
}

export const getControlPlaneResourceKinds = (infraType) => {
  const result = new Set(controlPlaneKinds)
  if (infraType === ClusterCloudPlatforms.EKS) {
    identityResourceKinds.map((k) => result.add(k))
  }
  return result
}

export const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

export const defaultCodeMirrorYamlValidations = [
  requiredValidator,
  customValidator((yaml) => {
    try {
      moizedYamlLoad(yaml)
      return true
    } catch (err) {
      return false
    }
  }, 'Provided YAML code is invalid'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.kind
  }, 'YAML kind must be set'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.name
  }, 'metadata.name must be set'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.namespace
  }, 'metadata.namespace must be set'),
]
