import { makeStyles } from '@material-ui/styles'
import { clientActions } from 'core/client/clientReducers'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import Text from 'core/elements/Text'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { listClusters } from './newActions'
import { clustersSelector } from './selectors'
import { propEq } from 'ramda'
import { IClusterEditPageTabs, ClusterSelector } from './model'
import ClusterAddonsPage from './cluster-addons/edit-cluster-page/ClusterAddonsPage'
import GeneralEditClusterPage from './GeneralEditClusterPage'

export default function EditClusterPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { loading, reload: reloadClusters } = useListAction(listClusters, {})
  const clusters = useSelectorWithParams(clustersSelector, {})
  const cluster = useMemo(() => clusters.find(propEq('uuid', id)) || ({} as ClusterSelector), [
    clusters,
    id,
  ])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: cluster?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [cluster?.name, id])

  return (
    <>
      <DocumentMeta title="Edit Cluster" breadcrumbs />
      <Text className={classes.header} variant="h3">
        Edit Cluster Settings
      </Text>
      <Tabs route={routes.cluster.managed.qbert.edit}>
        <Tab label="General" value={IClusterEditPageTabs.General}>
          <GeneralEditClusterPage cluster={cluster} loading={loading} />
        </Tab>
        <Tab label="Add-ons" value={IClusterEditPageTabs.Addons}>
          <ClusterAddonsPage
            clusterId={id}
            cluster={cluster}
            loading={loading}
            reloadClusters={reloadClusters}
          />
        </Tab>
      </Tabs>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    marginBottom: theme.spacing(5),
  },
}))
