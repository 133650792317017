import React from 'react'
import TextField from 'core/components/validatedForm/TextField'
import Row from 'core/containers/Row'

export default function AzureAutoscalerParamFields({ setFieldValue }) {
  return (
    <Row>
      <TextField
        key="minNumWorkers"
        id="minNumWorkers"
        label="Min Number of Workers"
        onChange={setFieldValue('minNumWorkers')}
        type="number"
        min="0"
        required
      />
      <TextField
        key="maxNumWorkers"
        id="maxNumWorkers"
        label="Max Number of Workers"
        onChange={setFieldValue('maxNumWorkers')}
        type="number"
        min="0"
        required
      />
    </Row>
  )
}
