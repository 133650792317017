import { listTablePrefs, allKey, TablePrefsParams } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import EventsTypePicklist from 'app/plugins/infrastructure/components/clusters/EventsTypePicklist'
import React, { useMemo } from 'react'
import { calculateAge } from 'utils/misc'
import { isNilOrEmpty, switchCase } from 'utils/fp'
import ListContainer from 'core/containers/ListContainer'
import { pick } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import useListAction from 'core/hooks/useListAction'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import InferActionParams from 'core/actions/InferActionParams'
import { listCapiClusterEvents } from './events/actions'
import { capiClusterEventsSelector } from './events/selectors'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import { ICapiClusterSelector } from '../../model'
import usePluginRouter from 'core/hooks/usePluginRouter'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'

type ModelDataKey = DataKeys.CapiEvents
type SelectorModel = ArrayElement<ReturnType<typeof capiClusterEventsSelector>>
type ActionParams = InferActionParams<typeof listCapiClusterEvents>

type Params = ActionParams & {
  type: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterName']
const defaultParams: Params & SortingState = {
  clusterName: null,
  namespace: null,
  type: allKey,
  orderBy: 'metadata.creationTimestamp',
  orderDirection: 'desc',
}

export const getEventTypeStatus = (status) => {
  const variant = switchCase(
    {
      Normal: 'success',
      Warning: 'danger',
    },
    'unknown',
  )(status)
  return { variant, label: status || 'Unknown' }
}

const renderAge = (value, entity = {}) => {
  const { deprecatedLastTimestamp } = entity as any
  return calculateAge(value || deprecatedLastTimestamp)
}
const searchTargets = ['type', 'regarding.uid']

const getColumns = ({ pluginId = 'kubernetes', clusterId }): GridViewColumn<SelectorModel>[] => {
  const routePathPods = pluginId === 'kubevirt' ? 'kubevirtPods' : 'pods'
  return [
    {
      key: 'metadata.creationTimestamp',
      label: 'Age',
      formatFn: (value, entity) => renderAge(value, entity),
    },
    {
      key: 'type',
      label: 'Type',
      CellComponent: createGridStatusCell({
        dataFn: getEventTypeStatus,
      }),
    },
    { key: 'reason', label: 'Reason' },
    { key: 'message', label: 'Message' },
    {
      key: 'involvedObject',
      label: 'From',
      render: ({ name, kind, uid }) =>
        kind === 'Pod' ? (
          <SimpleLink
            src={routes[routePathPods].details.path({ clusterId, id: uid })}
          >{`${name} (${kind})`}</SimpleLink>
        ) : (
          `${name} (${kind})`
        ),
    },
  ]
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Events', listTablePrefs)

export default function CapiClusterOverviewEvents({ cluster }: { cluster: ICapiClusterSelector }) {
  const { currentPluginId } = usePluginRouter()
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { message, loading: loadingEvents, reload } = useListAction(listCapiClusterEvents, {
    params: { clusterName: cluster?.name, namespace: cluster?.namespace },
    requiredParams,
  })
  const events = useSelector(capiClusterEventsSelector)

  const filters = useMemo(
    () => [
      {
        columnKey: 'type',
        FilterComponent: EventsTypePicklist,
        onChange: getParamsUpdater('type'),
      } as GridFilterSpec<SelectorModel, Params, 'type'>,
    ],
    [],
  )

  const columns = getColumns({
    pluginId: currentPluginId,
    clusterId: cluster?.uuid,
  })

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ListContainer<ModelDataKey, SelectorModel>
        showBreadcrumbs={false}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loadingEvents}
        loadingMessage={message}
        onRefresh={reload}
        data={events}
        label="Events"
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        filters={filters}
        {...pick(listTablePrefs, params)}
      />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridColumnEnd: 3,
    margin: theme.spacing(1, 0, 0, 0),
    maxHeight: 700,
    overflow: 'auto',
  },
}))
