import PicklistField from 'core/components/validatedForm/DropdownField'
import React from 'react'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'

const numMasterOptions = [
  { label: '1', value: 1 },
  { label: '3', value: 3 },
  { label: '5', value: 5 },
]

const NumMasterNodesField = ({ options = numMasterOptions }) => (
  <PicklistField
    DropdownComponent={AsyncDropdown}
    id="numMasters"
    items={options}
    label="Master nodes"
    tooltip="Number of master nodes to deploy.  3 nodes are required for an High Availability (HA) cluster."
    required
  />
)

export default NumMasterNodesField
