import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import React, { useContext, useEffect } from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import { WizardContext } from 'core/components/wizard/Wizard'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Dropdown from 'core/elements/dropdown'

export const topologyManagerDefaults = {
  enableTopologyManager: false,
  topologyManagerPolicy: 'none',
  cpuManagerPolicy: 'none',
  reservedCPUs: '',
}

const TopologyManagerField = ({ wizardContext, setWizardContext }) => (
  <CheckboxField
    id="enableTopologyManager"
    label="Add Topology Manager support"
    info="Add Topology Manager support"
    value={wizardContext?.enableTopologyManager}
    // Reset default values upon toggling
    onChange={(value) => {
      setWizardContext({ ...topologyManagerDefaults, enableTopologyManager: value })
    }}
  />
)

const topologyManagerPolicyoptions = [
  { label: 'none', value: 'none' },
  { label: 'best-effort', value: 'best-effort' },
  { label: 'restricted', value: 'restricted' },
  { label: 'single-numa-node', value: 'single-numa-node' },
]

export const TopologyManagerAddonFields = () => {
  const {
    wizardContext,
    setWizardContext,
  }: { wizardContext: any; setWizardContext: any } = useContext(WizardContext) as any

  if (wizardContext.topologyManagerPolicy !== 'none' && wizardContext.cpuManagerPolicy === 'none') {
    setWizardContext({ cpuManagerPolicy: 'static' })
  }
  if (
    wizardContext.topologyManagerPolicy === 'none' &&
    wizardContext.cpuManagerPolicy === 'static'
  ) {
    setWizardContext({ cpuManagerPolicy: 'none' })
  }

  return (
    <FormFieldSection title="Add Topology Manager Policy">
      <DropdownField
        DropdownComponent={Dropdown}
        id="topologyManagerPolicy"
        label="TopologyManagerPolicy"
        items={topologyManagerPolicyoptions}
        value={wizardContext?.topologyManagerPolicy}
        onChange={(value) => {
          setWizardContext({ topologyManagerPolicy: value })
        }}
        required
      />

      <TextField
        value={wizardContext.reservedCPUs}
        onChange={(value) => setWizardContext({ reservedCPUs: value })}
        id="reservedCPUs"
        label="Reserved CPUs"
        info="Enter a comma separated list of CPUs to be reserved for the system,example:4-8,9-12"
      />
    </FormFieldSection>
  )
}

export default TopologyManagerField
