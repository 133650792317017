import React, { useMemo } from 'react'
import { pick } from 'ramda'
import { listTablePrefs, allKey, TablePrefsParams } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { IK8sNodeSelector } from './model'
import ListContainer from 'core/containers/ListContainer'
import DocumentMeta from 'core/components/DocumentMeta'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listK8sNodes } from './new-actions'
import { EksNodeDetailCell } from 'app/plugins/infrastructure/components/nodes/node-cells/NodeDetailCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import K8sNodeStatusCell from 'app/plugins/infrastructure/components/nodes/node-cells/K8sNodeStatusCell'
import NetworkInterfacesCell from 'app/plugins/infrastructure/components/nodes/node-cells/NetworkInterfacesCell'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import UUIDCell from 'app/plugins/infrastructure/components/common/cells/UUIDCell'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import InferActionParams from 'core/actions/InferActionParams'
import { makeParamsEksNodesSelector } from './k8sNodes-selectors'
import { AwsClusterTypes } from '../clusters/capi/model'

type ModelDataKey = DataKeys.Nodes
type SelectorModel = IK8sNodeSelector
type ActionParams = InferActionParams<typeof listK8sNodes>
type Params = ActionParams & {
  clusterId: string
}

const filterNonEksClusters = (clusters) =>
  clusters?.filter((cluster) => cluster?.infrastructureType === AwsClusterTypes.EKS)

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'uuid',
    label: 'UUID',
    CellComponent: UUIDCell,
    display: false,
  } as GridViewColumn<SelectorModel, 'uuid'>,
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: EksNodeDetailCell,
  },
  {
    key: 'clusterName',
    label: 'Cluster',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId }) => routes.cluster.managed.capi.details.path({ id: clusterId }),
    }),
  } as GridViewColumn<SelectorModel, 'clusterName'>,
  {
    key: 'ready',
    label: 'K8s Node Status',
    CellComponent: K8sNodeStatusCell,
  } as GridViewColumn<SelectorModel, 'ready'>,
  { key: 'nodeKubeVersion', label: 'Node Kube Version' },
  { key: 'operatingSystem', label: 'Operating System' },
]

const searchTargets = ['name', 'uuid']

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Nodes', listTablePrefs)
const columnsOrder = ['uuid', 'name', 'clusterName']

const defaultParams: Params = {
  clusterId: allKey,
}
const requiredParams: Array<keyof ActionParams> = ['clusterId']
const eksNodesSelector = makeParamsEksNodesSelector()

export default function EksNodesListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { message, loading, reload } = useListAction(listK8sNodes)
  const eksNodes = useSelectorWithParams(eksNodesSelector, params)

  const filters = useMemo(
    () => [
      {
        columnKey: 'clusterId',
        FilterComponent: (props) => (
          <ClusterPicklist
            {...props}
            showNone
            noneLabel="No Cluster"
            filterFn={filterNonEksClusters}
          />
        ),
        initialValue: allKey,
        onChange: getParamsUpdater('clusterId'),
        controlled: true,
      } as GridFilterSpec<SelectorModel, Params, 'clusterId', 'clusterId', string, string>,
    ],
    [],
  )
  return (
    <>
      <DocumentMeta title="Clusters" />
      <ListContainer<ModelDataKey, any>
        dataKey={DataKeys.Nodes}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={eksNodes}
        columns={columns}
        filters={filters}
        addUrl={routes.nodes.add.path()}
        addText="Onboard a Node"
        getParamsUpdater={getParamsUpdater}
        columnsOrder={columnsOrder}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
