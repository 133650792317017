import React, { FunctionComponent } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import PageContainer from 'core/components/pageContainer/PageContainer'
import NumberedSteps from 'core/components/numbered-steps'
import ExternalLink from 'core/components/ExternalLink'
import { airCtlDocumentationLink } from 'k8s/links'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import CopyToClipboard from 'core/components/CopyToClipboard'
import CodeBlock from 'core/components/CodeBlock'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 850,
    margin: '0px 16px 16px 16px',
  },
  walkthrough: {
    padding: '16px 48px',
    margin: '16px 0px',
    backgroundColor: hexToRgbaCss(theme.palette.primary.main, 0.1),
  },
  step: {
    display: 'grid',
    gap: 16,
  },
}))

const airCtlCommand =
  'airctl configure-hosts --config <AirCtl configuration file path> --reset-pf9-managed-docker-conf  --skip-docker-img-import'

export default function AirCtlWalkthrough() {
  const classes = useStyles()
  return (
    <PageContainer className={classes.container}>
      <Text variant="subtitle1">Onboard new nodes using the AirCtl CLI</Text>
      <div className={classes.walkthrough}>
        <NumberedSteps
          step={1}
          description={
            <Text>
              Prepare your Airctl configuration file by following steps in{' '}
              <ExternalLink url={airCtlDocumentationLink} textVariant="body1">
                this document
              </ExternalLink>
              .
            </Text>
          }
        />
        <NumberedSteps
          step={2}
          description={
            <div className={classes.step}>
              <Text>
                Run the following command using airctl CLI to configure your nodes before they can
                be added to a PMK cluster.
              </Text>
              <CopyToClipboard copyText={airCtlCommand}>
                <CodeBlock>{airCtlCommand}</CodeBlock>
              </CopyToClipboard>
            </div>
          }
        />
      </div>
    </PageContainer>
  )
}
