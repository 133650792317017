import Alert from 'core/components/Alert'
import React, { useState } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import Text from 'core/elements/Text'
import { isNilOrEmpty } from 'utils/fp'
import SimpleLink from 'core/components/SimpleLink'
import Theme from 'core/themes/model'

const maxErrorMsgLength = 500

const ErrorMessage = ({ text = '', maxLength }) => {
  const limitTextLength = maxLength && text.length >= maxLength
  const [viewAll, setViewAll] = useState(!limitTextLength)
  const messageToShow = limitTextLength ? (viewAll ? text : text.slice(0, maxLength)) : text

  return (
    <div>
      <Text variant="body2">{messageToShow}</Text>
      {limitTextLength && (
        <SimpleLink onClick={() => setViewAll(!viewAll)}>{`View ${
          viewAll ? 'Less' : 'More'
        }`}</SimpleLink>
      )}
    </div>
  )
}

export default function CapiEditModalErrors({ errors }) {
  if (isNilOrEmpty(errors)) return null
  const classes = useStyles()
  return (
    <div className={classes.errorContainer}>
      {errors.map((error) => {
        const k8sResource =
          error?.raw?.apiErrorMetadata?.k8sResource || error?.raw?.response?.config?.data?.kind
        const title = `Error updating ${k8sResource || 'resource'}`
        const messageText = error?.message || error?.raw?.err
        const messageComponent = <ErrorMessage text={messageText} maxLength={maxErrorMsgLength} />
        return <Alert key={title} variant="error" title={title} message={messageComponent} />
      })}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  errorContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))
