import { getMetalLbCidr } from '../helpers'
import { encodeStr } from 'utils/misc'
import { compareVersions } from 'k8s/util/helpers'
import { omit } from 'ramda'
import { isNilOrEmpty } from 'utils/fp'
import {
  IClusterAddon,
  ClusterAddonType,
  CoreDnsParams,
  MetallbParams,
  AwsAutoScalerParams,
  AzureAutoscalerParams,
  MonitoringParams,
  Metal3Params,
  EtcdBackupParams,
} from './model'
import {
  monitoringInfo,
  awsAutoscalerInfo,
  metalLbInfo,
  azureAutoscalerInfo,
  profileAgentInfo,
  luigiInfo,
} from 'app/plugins/infrastructure/components/clusters/constants'
import monitoringJson from './schema/monitoring.json'
import coreDnsJson from './schema/coredns.json'
import profileAgentJson from './schema/pf9-profile-agent.json'
import metricsServerJson from './schema/metrics-server.json'
import kubernetesDashboardJson from './schema/kubernetes-dashboard.json'
import capiAutoScalerJson from './schema/cluster-auto-scaler-capi.json'

export const clusterAddonDisplayNames = {
  [ClusterAddonType.CoreDns]: 'Core DNS',
  [ClusterAddonType.KubernetesDashboard]: 'Kubernetes Dashboard',
  [ClusterAddonType.MetricsServer]: 'Metrics Server',
  [ClusterAddonType.MetalLb]: 'Metal LB',
  [ClusterAddonType.AzureAutoScaler]: 'Azure Cluster Autoscaler',
  [ClusterAddonType.AwsAutoScaler]: 'AWS Cluster Autoscaler',
  [ClusterAddonType.CapiAutoScaler]: 'CAPI Cluster Autoscaler',
  [ClusterAddonType.Monitoring]: 'Prometheus Monitoring',
  [ClusterAddonType.ProfileAgent]: 'Profile Agent',
  [ClusterAddonType.Kubevirt]: 'Kubevirt',
  [ClusterAddonType.Luigi]: 'Luigi',
  [ClusterAddonType.Metal3]: 'Metal³',
  [ClusterAddonType.EtcdBackup]: 'ETCD Backup',
  [ClusterAddonType.DnsAutoscaler]: 'DNS Autoscaler',
}

// These addon names are used to get the addon versions
export const addonTypeToNameMap = {
  [ClusterAddonType.CoreDns]: 'coredns',
  [ClusterAddonType.KubernetesDashboard]: 'dashboard',
  [ClusterAddonType.MetricsServer]: 'metricsserver',
  [ClusterAddonType.MetalLb]: 'metallb',
  [ClusterAddonType.AzureAutoScaler]: 'casazure',
  [ClusterAddonType.AwsAutoScaler]: 'casaws',
  [ClusterAddonType.CapiAutoScaler]: 'cascapi',
  [ClusterAddonType.Monitoring]: 'monitoring',
  [ClusterAddonType.ProfileAgent]: 'profileagent',
  [ClusterAddonType.Kubevirt]: 'kubevirtaddon',
  [ClusterAddonType.Luigi]: 'luigi',
  [ClusterAddonType.Metal3]: 'metal3',
}

const metricsServerParams = []
const kubernetesDashboardParams = []
const profileAgentParams = []
const kubevirtParams = []
const luigiParams = []
const capiAutoScalerParams = []

const coreDnsParams = [
  CoreDnsParams.DnsMemoryLimit,
  CoreDnsParams.DnsDomain,
  CoreDnsParams.Base64EncAdditionalDnsConfig,
  CoreDnsParams.Base64EncEntireDnsConfig,
  CoreDnsParams.CoresPerReplica,
  CoreDnsParams.NodesPerReplica,
  CoreDnsParams.MinReplicas,
  CoreDnsParams.MaxReplicas,
  CoreDnsParams.PollPeriodSecs,
]

export const metalLbParams = [MetallbParams.MetallbIpRange, MetallbParams.Base64EncMetallbConfig]

const awsAutoScalerParams = [
  AwsAutoScalerParams.ClusterUUID,
  AwsAutoScalerParams.ClusterRegion,
  AwsAutoScalerParams.CPURequest,
  AwsAutoScalerParams.CPULimit,
  AwsAutoScalerParams.MemRequest,
  AwsAutoScalerParams.MemLimit,
  AwsAutoScalerParams.EnableAddonResizer,
  AwsAutoScalerParams.CpuRequestLimit,
  AwsAutoScalerParams.CpuExtra,
  AwsAutoScalerParams.MemRequestLimit,
  AwsAutoScalerParams.MemExtra,
  AwsAutoScalerParams.PercentThreshold,
  AwsAutoScalerParams.minClusterSize,
]

const azureAutoScalerParams = [
  AzureAutoscalerParams.MinNumWorkers,
  AzureAutoscalerParams.MaxNumWorkers,
]
const monitoringParams = [
  MonitoringParams.RetentionTime,
  MonitoringParams.StorageClassName,
  MonitoringParams.PvcSize,
  MonitoringParams.PvName,
]

export const metal3Params = [
  Metal3Params.Metal3DhcpInterface,
  Metal3Params.Metal3DhcpRange,
  Metal3Params.Metal3DhcpGateway,
  Metal3Params.Metal3DnsServer,
  Metal3Params.Metal3KernelURL,
  Metal3Params.Metal3RamdiskURL,
  Metal3Params.Metal3SshKey,
  Metal3Params.StorageClassName,
]

export const etcdBackupParams = [
  EtcdBackupParams.StorageType,
  EtcdBackupParams.IsEtcdBackupEnabled,
  EtcdBackupParams.LocalPath,
  EtcdBackupParams.DailyBackupTime,
  EtcdBackupParams.MaxTimestampBackupCount,
  EtcdBackupParams.IntervalInMins,
  EtcdBackupParams.IntervalInHours,
  EtcdBackupParams.MaxIntervalBackupCount,
]

export const clusterAddonParams = {
  [ClusterAddonType.MetricsServer]: metricsServerParams,
  [ClusterAddonType.KubernetesDashboard]: kubernetesDashboardParams,
  [ClusterAddonType.CoreDns]: coreDnsParams,
  [ClusterAddonType.MetalLb]: metalLbParams,
  [ClusterAddonType.AzureAutoScaler]: azureAutoScalerParams,
  [ClusterAddonType.AwsAutoScaler]: awsAutoScalerParams,
  [ClusterAddonType.CapiAutoScaler]: capiAutoScalerParams,
  [ClusterAddonType.Monitoring]: monitoringParams,
  [ClusterAddonType.ProfileAgent]: profileAgentParams,
  [ClusterAddonType.Kubevirt]: kubevirtParams,
  [ClusterAddonType.Luigi]: luigiParams,
  [ClusterAddonType.Metal3]: metal3Params,
  [ClusterAddonType.EtcdBackup]: etcdBackupParams,
}

// Maps the API param names to the UI input field IDs
export const etcdBackupUIFieldIdMap = {
  localPath: 'etcdStoragePath',
  dailyBackupTime: 'etcdBackupTimestamp',
  maxTimestampBackupCount: 'maxTimestampBackups',
  intervalInMins: 'etcdBackupIntervalMinutes',
  intervalInHours: 'etcdBackupIntervalHours',
  maxIntervalBackupCount: 'maxIntervalBackups',
}

interface ClusterAddonRequstBodyParams {
  clusterId?: string
  clusterName?: string
  namespace?: string
  type: string
  overrideParams?: OverrideParams[]
  version: string
}

interface OverrideParams {
  name: string
  value: string
}

export const addUnitToParam = (key, value) => {
  if (typeof value !== 'number') return value
  const unit = paramsWithUnits[key] || ''
  return value.toString() + unit
}

const formatParams = (overrideParams) => {
  const params = { ...overrideParams }

  Object.entries(params).forEach(([key, value]) => {
    let newValue = value

    if (key === MetallbParams.MetallbIpRange) {
      newValue = getMetalLbCidr(params[MetallbParams.MetallbIpRange])
    }

    if (key === Metal3Params.Metal3DhcpRange) {
      const { key: startAddress, value: endAddress } = value[0]
      newValue = [startAddress, endAddress].join(',')
    }

    // Add units to the param values if needed. Ex. CPU Limit is in Mi
    if (typeof value === 'number') {
      newValue = addUnitToParam(key, value)
    }

    // Convert value to string. API only accepts strings
    if (typeof newValue !== 'string') {
      newValue = (newValue || '').toString()
    }

    // Encode configs to base64
    if (configParams.has(key)) {
      newValue = encodeStr(newValue as string)
    }
    params[key] = newValue
  })

  return params
}

export const getClusterAddonBody = ({
  clusterId,
  type,
  overrideParams,
  version,
}: ClusterAddonRequstBodyParams) => {
  const formattedParams = formatParams(overrideParams)
  const overrideParamsList = Object.entries(formattedParams).map(([key, value]) => ({
    name: key,
    value: value,
  }))
  return {
    apiVersion: 'sunpike.platform9.com/v1alpha2',
    kind: 'ClusterAddon',
    metadata: {
      labels: { 'sunpike.pf9.io/cluster': clusterId, type },
      name: `${clusterId}-${type}`,
      namespace: 'default',
    },
    spec: {
      clusterID: clusterId,
      override: {
        params: overrideParamsList,
      },
      type,
      version,
      watch: true,
    },
  }
}

export const getCapiClusterAddonBody = ({
  clusterName,
  type,
  overrideParams,
  version,
  namespace,
}: ClusterAddonRequstBodyParams) => {
  const formattedParams = formatParams(overrideParams)
  const overrideParamsList = Object.entries(formattedParams).map(([key, value]) => ({
    name: key,
    value: value,
  }))
  return {
    apiVersion: 'sunpike.platform9.com/v1alpha2',
    kind: 'ClusterAddon',
    metadata: {
      labels: { 'sunpike.pf9.io/cluster': clusterName, type, clusterName },
      name: `${clusterName}-${type}`,
      namespace,
    },
    spec: {
      override: {
        params: overrideParamsList,
      },
      type,
      version,
      watch: true,
    },
  }
}

/*
If the param name can be uncamelized and then have the first character capitazlied,
then no need to add the mapping here. Ex. no need to add retentionTime
because uncamelizeString(retentionTime) => Retention Time
*/
export const paramDisplayNames = {
  [CoreDnsParams.DnsMemoryLimit]: 'DNS Memory Limit',
  [CoreDnsParams.DnsDomain]: 'DNS Domain',
  [CoreDnsParams.Base64EncAdditionalDnsConfig]: 'Additional DNS Config',
  [CoreDnsParams.Base64EncEntireDnsConfig]: 'DNS Config',
  [MetallbParams.MetallbIpRange]: 'MetalLB IP Range',
  [MetallbParams.Base64EncMetallbConfig]: 'MetalLB Config',
  [AwsAutoScalerParams.ClusterUUID]: 'Cluster ID',
  [AwsAutoScalerParams.CPURequest]: 'CPU Request',
  [AwsAutoScalerParams.CPULimit]: 'CPU Limit',
  [AwsAutoScalerParams.MemRequest]: 'Memory Request',
  [AwsAutoScalerParams.MemLimit]: 'Memory Limit',
  [AwsAutoScalerParams.CpuRequestLimit]: 'CPU Request Limit',
  [AwsAutoScalerParams.CpuExtra]: 'CPU Extra',
  [AwsAutoScalerParams.MemRequestLimit]: 'Memory Request Limit',
  [AwsAutoScalerParams.MemExtra]: 'Memory Extra',
  [AzureAutoscalerParams.MinNumWorkers]: 'Min Number of Workers',
  [AzureAutoscalerParams.MaxNumWorkers]: 'Max Number of Workers',
  [MonitoringParams.PvcSize]: 'PVC Size',
  [MonitoringParams.PvName]: 'PV Name',
  [Metal3Params.Metal3DhcpInterface]: 'PXE Interface Name',
  [Metal3Params.Metal3DhcpRange]: 'DHCP Range',
  [Metal3Params.Metal3DhcpGateway]: 'DHCP Gateway',
  [Metal3Params.Metal3DnsServer]: 'DNS Server',
  [Metal3Params.Metal3KernelURL]: 'Kernel URL',
  [Metal3Params.Metal3RamdiskURL]: 'Ramdisk URL',
  [Metal3Params.Metal3SshKey]: 'SSH Key',
  [Metal3Params.StorageClassName]: 'Storage Class',
}

/*
The addon manager API requires the units be be added to certain values
Since we can't expect users to enter in the correct units, the textfields in the UI for
these params should only accept numbers and we must add in the units ourselves
*/
export const paramsWithUnits = {
  [CoreDnsParams.DnsMemoryLimit]: 'Mi',
  [AwsAutoScalerParams.CPURequest]: 'm',
  [AwsAutoScalerParams.CPULimit]: 'm',
  [AwsAutoScalerParams.MemRequest]: 'Mi',
  [AwsAutoScalerParams.MemLimit]: 'Mi',
  [AwsAutoScalerParams.CpuRequestLimit]: 'm',
  [AwsAutoScalerParams.CpuExtra]: 'm',
  [AwsAutoScalerParams.MemRequestLimit]: 'Mi',
  [AwsAutoScalerParams.MemExtra]: 'Mi',
  [MonitoringParams.PvcSize]: 'Gi',
  [MonitoringParams.RetentionTime]: 'd',
}

export const configParams = new Set<any>([
  CoreDnsParams.Base64EncAdditionalDnsConfig,
  CoreDnsParams.Base64EncEntireDnsConfig,
  MetallbParams.Base64EncMetallbConfig,
])

export const addonInfoMessages = {
  [ClusterAddonType.MetalLb]: metalLbInfo,
  [ClusterAddonType.AzureAutoScaler]: azureAutoscalerInfo,
  [ClusterAddonType.AwsAutoScaler]: awsAutoscalerInfo,
  [ClusterAddonType.ProfileAgent]: profileAgentInfo,
  [ClusterAddonType.Monitoring]: monitoringInfo,
  [ClusterAddonType.Luigi]: luigiInfo,
}

export const isUninstalling = (addon) => {
  return addon?.status?.phase === 'Uninstalling' || !!addon?.metadata?.deletionTimestamp
}

export const addonManagerIsSupported = (clusterVersion) =>
  compareVersions(clusterVersion, '1.20') >= 0

export const getClusterAddonHealthStatus = (addon) => {
  const healthy = addon?.status?.healthy
  const phase = addon?.status?.phase
  if (healthy) {
    return 'Healthy'
  } else if (phase === 'Installing') {
    return 'Installing'
  } else if (healthy === undefined && phase === undefined) {
    return 'Unknown'
  } else {
    return 'Error'
  }
}

const etcdBackupFieldsToOmit = [
  'storageProperties',
  'isEtcdBackupEnabled',
  'taskStatus',
  'taskErrorDetail',
]

export const getEtcdBackupParams = (params) => {
  if (isNilOrEmpty(params)) return params
  const fields: any = omit(etcdBackupFieldsToOmit, params)
  fields.localPath = params.storageProperties?.localPath
  fields.useTimestampBackup = !!params.dailyBackupTime
  fields.useIntervalBackup = !!params.intervalInMins || !!params.intervalInHours
  fields.intervalBackupUnit = params.intervalInMins ? 'minutes' : 'hours'
  return fields
}

export const createEtcdBackupAddonObj = (cluster) =>
  ({
    name: ClusterAddonType.EtcdBackup,
    type: ClusterAddonType.EtcdBackup,
    params: getEtcdBackupParams(cluster?.etcdBackup),
    isConfigurable: true,
    status: {
      healthy:
        cluster?.etcdBackup?.taskStatus === 'success'
          ? true
          : cluster?.etcdBackup?.taskStatus === ''
          ? undefined
          : false,
      phase: undefined,
    },
    clusterId: cluster?.uuid,
    isEnabled: cluster?.etcdBackupEnabled,
    version: cluster?.etcdVersion,
  } as IClusterAddon)

export const getDisabledAddonObj = (addonType, cluster, currentAddonVersions) => {
  const name = addonTypeToNameMap[addonType]
  return {
    name: addonType,
    type: addonType,
    isEnabled: false,
    version: currentAddonVersions[name],
    latestVersion: currentAddonVersions[name],
    isConfigurable: !isNilOrEmpty(clusterAddonParams[addonType]),
    clusterId: cluster?.uuid,
    cluster,
  }
}

export const sameMajorAndMinorVersion = (baseVersion: string, comparedVersion: string) => {
  const baseVersions = baseVersion.split('.')
  const comparedVersions = comparedVersion.split('.')
  return baseVersions[0] === comparedVersions[0] && baseVersions[1] === comparedVersions[1]
}

export const getPatchVersion = (version: string) => {
  const strippedVersions = version.split('-')[0]
  const versions = strippedVersions.split('.')
  return parseInt(versions[2])
}
