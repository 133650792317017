import React from 'react'
import { deauthNode } from 'app/plugins/infrastructure/components/nodes/new-actions'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import useUpdateAction from 'core/hooks/useUpdateAction'
import BulletList from 'core/components/BulletList'

export default function NodeDeAuthDialog({
  rows: [node],
  onClose,
}: GridDialogProps<INodesSelector>) {
  const { update: deauth, updating: deAuthorizing, error, reset } = useUpdateAction(deauthNode)

  const handleDeauthNode = async () => {
    const { success } = await deauth({ node })
    if (!success) {
      return
    }
    reset()
    onClose()
  }

  const deauthText = [
    'Deauthorize will mark the node to be unusable and it could no longer be added to a cluster; but still will have packages installed by PMK.',
    'If you want to remove all Platform9 packages from this node, use Pf9ctl CLI’s decommission command.',
    "To mark this node to be usable again after Deauthorize, press the 'Authorize' button on the UI.",
  ]

  return (
    <ConfirmationDialog
      title="De-authorize node"
      error={error}
      text={
        <>
          You are about to de-authorize the node {node?.name} ({node?.primaryIp}).
          <br />
          Are you sure?
          <BulletList items={deauthText} />
        </>
      }
      loading={deAuthorizing}
      open
      onCancel={onClose}
      onConfirm={handleDeauthNode}
    />
  )
}
