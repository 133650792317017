import { makeStyles } from '@material-ui/styles'
import { clientActions } from 'core/client/clientReducers'
import Alert from 'core/components/Alert'
import DocumentMeta from 'core/components/DocumentMeta'
import ExternalLink from 'core/components/ExternalLink'
import PollingData from 'core/components/PollingData'
import Progress from 'core/components/progress/Progress'
import UsageWidget from 'core/components/widgets/UsageWidget'
import Card from 'core/elements/card'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import { isDecco } from 'core/utils/helpers'
import { routes } from 'core/utils/routes'
import { cloudProviderTypes } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { clusterActions } from 'app/plugins/infrastructure/components/clusters/actions'
import {
  ClusterConnectionStatus,
  ClusterApiServerHealthStatus,
  ClusterHealthStatus,
} from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { emptyObj } from 'utils/fp'
import { capitalizeString, cleanupStacktrace } from 'utils/misc'
import { NodeHealthWithTasksToggler } from 'app/plugins/infrastructure/components/nodes/ConvergingNodeBreakdown'
import ClusterAddonsPage from './cluster-addons/cluster-details-page/ClusterAddonsPage'
import Theme from 'core/themes/model'
import ClusterInfo from './ClusterInfo'
import ClusterNodes from './ClusterNodes'
import ClusterDeployedApps from './deployed-apps/ClusterDeployedApps'
import DownloadKubeConfigLink from './DownloadKubeConfigLink'
import { useAppSelector } from 'app/store'
import {
  ClusterSelector,
  IClusterAction,
} from 'app/plugins/infrastructure/components/clusters/model'
import { SessionState } from 'core/session/sessionReducers'
import ClusterAlarms from './ClusterAlarms'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from './newActions'
import { clustersSelector } from './selectors'
import usePluginRouter from 'core/hooks/usePluginRouter'

const oneSecond = 1000

const useStyles = makeStyles<Theme>((theme) => ({
  pageContainer: {
    position: 'relative',
  },
  backLink: {
    position: 'absolute',
    right: 0,
    top: 8,
    zIndex: 100,
    ...theme.typography.caption2,
  },
  statsContainer: {
    maxWidth: 1000,
    marginBottom: theme.spacing(1),
    '& .MuiGrid-item': {
      marginBottom: theme.spacing(2),
    },
  },
  statusItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  headerCard: {
    maxWidth: '434px',
  },
  harderCardFooter: {
    alignItems: 'center',
    borderTop: `1px solid ${theme.components.card.border}`,
    padding: '24px 32px',
  },
  headerCardFooterBody: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  headerCardHeader: {
    display: 'grid',
    gridGap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    '& h1': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
      maxWidth: '300px',
    },
  },
  headerIcon: {
    gridArea: 'icon',
    fontSize: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  headerCardBody: {
    margin: theme.spacing(1, 0),
    display: 'grid',
    gridAutoRows: 'max-content',
    gap: '4px',
  },
  tabContainer: {
    paddingTop: theme.spacing(2),
    maxWidth: 1234,
  },
  nodesContainer: {
    maxWidth: 'none',
  },
  detailsHeader: {
    display: 'grid',
    gridTemplateColumns: 'max-content repeat(3, max-content)',
    gridTemplateRows: 'max-content max-content',
    gridGap: theme.spacing(2),
    paddingBottom: 20,
  },
  statusContainer: {
    '& > div': {
      width: '100%',
    },
  },
  status: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
  alert: {
    margin: '0 0 24px 0',
    '& code': {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  },
}))

const clusterTaskErrorTitle = 'Error Impacting Cluster Availability'
const etcdBackupTaskErrorTitle = 'ETCD Backup Error'

const ClusterTaskError = ({ title, taskError }) => {
  const classes = useStyles()
  const formattedError = cleanupStacktrace(taskError)
  return (
    <Alert title={title} variant="error" className={classes.alert}>
      <pre>
        <Text component="code" variant="body2">
          {formattedError}
        </Text>
      </pre>
    </Alert>
  )
}

const ClusterDetailsPage = () => {
  const { match } = useReactRouter()
  const dispatch = useDispatch()
  const { currentPluginId } = usePluginRouter()
  const classes = useStyles()
  const { id: clusterId } = match.params
  const [loadingState, setLoadingState] = useState({ hasLoaded: false, loading: true })
  const { loading, reload } = useListAction(listClusters, { loadingFeedback: false })
  const clusters = useSelector(clustersSelector)
  const { features } = useAppSelector<SessionState>((store) => store.session)
  const cluster = useMemo(() => clusters.find((x) => x.uuid === clusterId), [clusters, clusterId])
  const tabsRoute =
    currentPluginId === 'kubevirt'
      ? routes.kubevirtCluster.detail
      : routes.cluster.managed.qbert.detail

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: cluster?.name || clusterId,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [cluster?.name, clusterId])

  useEffect(() => {
    if (loading && !loadingState.hasLoaded && loadingState.loading) {
      setLoadingState({ hasLoaded: true, loading: true })
    }
    if (!loading && loadingState.hasLoaded && loadingState.loading) {
      setLoadingState({ hasLoaded: true, loading: false })
    }
    if (!loading && cluster && loadingState.loading) {
      // For if the clusters were already loaded before visiting page
      setLoadingState({ hasLoaded: true, loading: false })
    }
  }, [loading, loadingState])

  if (loadingState.loading) {
    return <Progress loading message="Loading Clusters..." />
  }
  if (!cluster) {
    return null
  }

  return (
    <div className={classes.pageContainer}>
      <DocumentMeta title={`Clusters - ${cluster.name}`} breadcrumbs />
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={oneSecond * 30} />
      {/* TODO change the header to render the alarms chart when match.params.tab === alarms */}
      {cluster.taskError && (
        <ClusterTaskError title={clusterTaskErrorTitle} taskError={cluster.taskError} />
      )}
      {cluster.etcdBackup?.taskErrorDetail && (
        <ClusterTaskError
          title={etcdBackupTaskErrorTitle}
          taskError={cluster.etcdBackup.taskErrorDetail}
        />
      )}
      <ClusterStatusAndUsage cluster={cluster} loading={loading} />

      <Tabs route={tabsRoute}>
        <Tab value="nodes" label="Nodes">
          <div className={`${classes.tabContainer} ${classes.nodesContainer}`}>
            <ClusterNodes />
          </div>
        </Tab>
        <Tab value="node-health" label="Node Health">
          <div className={classes.tabContainer}>
            <NodeHealthWithTasksToggler />
          </div>
        </Tab>
        <Tab value="details" label="Cluster Details">
          <div className={classes.tabContainer}>
            <ClusterInfo />
          </div>
        </Tab>
        {isDecco(features) && (
          <Tab value="deployed-apps" label="Deployed Apps">
            <ClusterDeployedApps cluster={cluster} />
          </Tab>
        )}
        <Tab value="addons" label="Add-ons">
          <ClusterAddonsPage cluster={cluster} />
        </Tab>
        <Tab value="alarms" label="Alarms">
          <ClusterAlarms cluster={cluster} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default ClusterDetailsPage

const ClusterStatus = ({ cluster, loading }) => {
  const { usage = emptyObj, name, links = emptyObj, cloudProviderType = '', version } = cluster
  const classes = useStyles()
  const clusterLinks = {
    grafana: usage.grafanaLink,
    ...links,
  }
  const deployment = cloudProviderTypes[cloudProviderType] || capitalizeString(cloudProviderType)
  return (
    <HeaderCard
      title={name}
      subtitle={`${deployment} - ${version}`}
      links={clusterLinks}
      cluster={cluster}
    >
      <ClusterConnectionStatus
        iconStatus
        rootClassName={classes.statusContainer}
        className={classes.status}
        variant="header"
        cluster={cluster}
        message={loading ? 'loading' : undefined}
        iconPosition="right"
      />
      <ClusterHealthStatus
        iconStatus
        label="Platform9 Components"
        rootClassName={classes.statusContainer}
        className={classes.status}
        variant="header"
        cluster={cluster}
        message={loading ? 'loading' : undefined}
        iconPosition="right"
      />
      <ClusterApiServerHealthStatus
        iconStatus
        label="API Responding"
        rootClassName={classes.statusContainer}
        className={classes.status}
        variant="header"
        cluster={cluster}
        message={loading ? 'loading' : undefined}
        iconPosition="right"
      />
    </HeaderCard>
  )
}

const ClusterStatusAndUsage = ({ cluster, loading }) => {
  const { usage = emptyObj } = cluster
  const classes = useStyles()
  return (
    <div className={classes.detailsHeader}>
      <ClusterStatus cluster={cluster} loading={loading} />
      <UsageWidget title="CPU" units="GHz" stats={usage.compute} />
      <UsageWidget title="Storage" units="GiB" stats={usage.disk} />
      <UsageWidget title="Memory" units="GiB" stats={usage.memory} />
    </div>
  )
}

interface HeaderCardProps {
  title: string
  subtitle?: string
  links?: {
    grafana?: string
    dashboard?: string
    kubeconfig?: {
      cluster?: IClusterAction
    }
  }
  cluster?: ClusterSelector
  children: React.ReactNode
}

export const HeaderCard = ({ title, subtitle, links = {}, cluster, children }: HeaderCardProps) => {
  const hasLinks = !!links.grafana || !!links.dashboard || !!links.kubeconfig
  const classes = useStyles({ hasLinks })
  return (
    <Card
      className={classes.headerCard}
      footer={
        hasLinks ? (
          <footer className={classes.harderCardFooter}>
            <div className={classes.headerCardFooterBody}>
              {!!links.grafana && (
                <ExternalLink icon="arrow-up-right-from-square" url={links.grafana}>
                  View Grafana
                </ExternalLink>
              )}
              {!!links.dashboard && (
                <ExternalLink icon="arrow-up-right-from-square" url={links.dashboard}>
                  View Kubernetes Dashboard
                </ExternalLink>
              )}
              {!!links.kubeconfig && cluster && (
                <DownloadKubeConfigLink icon="circle-arrow-down" cluster={cluster} />
              )}
            </div>
          </footer>
        ) : null
      }
      withCustomFooter
    >
      <header className={classes.headerCardHeader}>
        <Tooltip message={title}>
          <Text className={classes.title} variant="subtitle1" component="h1" lineClamp={2}>
            {title}
          </Text>
        </Tooltip>
        <Text variant="body2" component="p">
          {subtitle}
        </Text>
      </header>

      <Text variant="caption1">Overview</Text>
      <div className={classes.headerCardBody}>{children}</div>
    </Card>
  )
}
