import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import Text from 'core/elements/Text'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { ClusterTypes } from '../model'

export const formattedClusterTypes = {
  [ClusterTypes.Normal]: 'Qbert',
  [ClusterTypes.Imported]: 'Imported',
  [ClusterTypes.Capi]: 'CAPI',
}

export default function ClusterTypeCell({
  item: { clusterType },
}: GridCellProps<CombinedClusterSelector>) {
  return <Text variant="body2">{formattedClusterTypes[clusterType]}</Text>
}
