import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TextField from 'core/components/validatedForm/TextField'
import { customValidator, requiredValidator } from 'core/utils/fieldValidators'
import PicklistField from 'core/components/validatedForm/DropdownField'
import IntervalBackupUnitPicklist from '../../cluster-addons/IntervalBackupUnitPicklist'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import Row from 'core/containers/Row'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { assoc, dissoc, mergeRight } from 'ramda'
import { EtcdBackup } from '../../capi/control-plane/nodelet-control-plane/model'

const useStyles = makeStyles((theme: Theme) => ({
  paramFields: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

export enum EtcdBackupTypes {
  Daily = 'daily',
  Interval = 'interval',
}

const defaultEtcdBackup: EtcdBackup[] = [
  {
    name: EtcdBackupTypes.Daily,
    target: 'local',
    localPath: '/etc/pf9/etcd-backup',
    dailyBackupTime: '00:10',
    backupCount: 5,
  },
  {
    name: EtcdBackupTypes.Interval,
    target: 'local',
    localPath: '/etc/pf9/etcd-backup',
    intervalMins: 30,
    backupCount: 5,
  },
]

const timestampValidations = [
  requiredValidator,
  customValidator((timestamp) => {
    if (!timestamp || !timestamp.includes(':')) {
      return false
    }
    const [hours, minutes] = timestamp.split(':')
    return hours && minutes && hours >= '00' && hours <= '23' && minutes >= '00' && minutes <= '59'
  }, 'Timestamp must be between 00:00 and 23:59'),
]

const backupCountValidation = customValidator(
  (count) => count > 0,
  'Backup count must be at least 1',
)

const intervalHoursValidation = customValidator(
  (hours) => hours > 0 && hours < 24,
  'Hours must be between 1 and 23',
)

const intervalMinutesValidation = customValidator(
  (minutes) => minutes > 9 && minutes < 60,
  'Minutes must be between 10 and 59',
)

export default function CapiEtcdBackupFields({ wizardContext, setWizardContext }) {
  const classes = useStyles()

  const defaultDailyBackup = useMemo(() => {
    const etcdBackup = wizardContext?.etcdBackup || defaultEtcdBackup
    return etcdBackup.find((backup) => backup?.name === EtcdBackupTypes.Daily)
  }, [wizardContext?.etcdBackup])

  const defaultIntervalBackup = useMemo(() => {
    const etcdBackup = wizardContext?.etcdBackup || defaultEtcdBackup
    return etcdBackup.find((backup) => backup?.name === EtcdBackupTypes.Interval)
  }, [wizardContext?.etcdBackup])

  const [isDailyBackupEnabled, setIsDailyBackupEnabled] = useState(!!wizardContext?.useDailyBackups)
  const [isIntervalBackupEnabled, setIsIntervalBackupEnabled] = useState(
    !!wizardContext?.useIntervalBackups,
  )
  const [dailyBackup, setDailyBackup] = useState<EtcdBackup>(defaultDailyBackup)
  const [intervalBackup, setIntervalBackup] = useState<EtcdBackup>(defaultIntervalBackup)

  const [intervalBackupUnit, setIntervalBackupUnit] = useState<'minutes' | 'hours'>(
    wizardContext?.intervalBackupsUnit || 'minutes',
  )

  const handleChanges = (type: EtcdBackupTypes, field: keyof EtcdBackup) => (value) => {
    const updateBackupFn = type === EtcdBackupTypes.Daily ? setDailyBackup : setIntervalBackup
    const backup = type === EtcdBackupTypes.Daily ? dailyBackup : intervalBackup
    updateBackupFn(mergeRight(backup, { [field]: value }))
  }

  useEffect(() => {
    const _dailyBackup = isDailyBackupEnabled ? dailyBackup : null
    const _intervalBackup = isIntervalBackupEnabled ? intervalBackup : null

    const etcdBackup =
      !isDailyBackupEnabled && !isIntervalBackupEnabled
        ? null
        : [_dailyBackup, _intervalBackup].filter(Boolean)

    setWizardContext({ etcdBackup })
  }, [
    dailyBackup,
    intervalBackup,
    isDailyBackupEnabled,
    isIntervalBackupEnabled,
    intervalBackupUnit,
  ])

  const updateUnitInIntervalBackup = useCallback(
    (unit: 'hours' | 'minutes' = intervalBackupUnit) => {
      setIntervalBackup(
        unit === 'hours'
          ? dissoc('intervalMins', intervalBackup)
          : dissoc('intervalHours', intervalBackup),
      )

      setIntervalBackup((prev) =>
        unit === 'hours' ? assoc('intervalHours', 1, prev) : assoc('intervalMins', 30, prev),
      )
    },
    [intervalBackup, intervalBackupUnit],
  )

  return (
    <div className={classes.paramFields}>
      <Row minItemWidth="300" gap={24}>
        <ToggleSwitchField
          id="useDailyBackups"
          label="Daily Backups"
          info="Enable automated etcd backups on this cluster daily at a specified time"
          onChange={(value) => setIsDailyBackupEnabled(value)}
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <TextField
          id="dailyBackupsStoragePath"
          label="Storage Path"
          info="This is the disk path where the etcd backup data will be stored on each master node of this cluster"
          disabled={!isDailyBackupEnabled}
          value={dailyBackup?.localPath}
          onChange={handleChanges(EtcdBackupTypes.Daily, 'localPath')}
          required
        />

        <TextField
          id="dailyBackupsTimestamp"
          label="Daily Backup Time (00:00-23:59)"
          maxLength={5}
          placeholder="22:00"
          validations={timestampValidations}
          info="The time that a backup will be saved each day"
          disabled={!isDailyBackupEnabled}
          value={dailyBackup?.dailyBackupTime}
          onChange={handleChanges(EtcdBackupTypes.Daily, 'dailyBackupTime')}
          required
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <TextField
          id="dailyBackupsMaxTimestampBackups"
          label="Max Timestamp Backup Count"
          type="number"
          step="1"
          min="1"
          info="Maximum number of backups to be retained daily"
          validations={[backupCountValidation]}
          disabled={!isDailyBackupEnabled}
          value={dailyBackup?.backupCount}
          onChange={handleChanges(EtcdBackupTypes.Daily, 'backupCount')}
          required
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <ToggleSwitchField
          id="useIntervalBackups"
          label="Use Interval Backups"
          info="Enable automated etcd backups on this cluster based on time intervals"
          onChange={(value) => setIsIntervalBackupEnabled(value)}
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <TextField
          id="intervalBackupsStoragePath"
          label="Storage Path"
          info="This is the disk path where the etcd backup data will be stored on each master node of this cluster"
          // onChange={setFieldValue('etcdStoragePath')}
          disabled={!isIntervalBackupEnabled}
          value={intervalBackup?.localPath}
          onChange={handleChanges(EtcdBackupTypes.Interval, 'localPath')}
          required
        />
        <PicklistField
          DropdownComponent={IntervalBackupUnitPicklist}
          id="intervalBackupsUnit"
          label="Interval Backup Unit"
          disabled={!isIntervalBackupEnabled}
          value={intervalBackupUnit}
          onChange={(value) => {
            setIntervalBackupUnit(value), updateUnitInIntervalBackup(value)
          }}
          required
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        {intervalBackupUnit === 'minutes' && (
          <TextField
            id="etcdBackupIntervalMinutes"
            label="Backup Interval in Minutes (10-59)"
            type="number"
            step="1"
            min="1"
            max="59"
            info="A backup will be taken every specified number of minutes."
            validations={[intervalMinutesValidation]}
            disabled={!isIntervalBackupEnabled}
            value={intervalBackup?.intervalMins}
            onChange={handleChanges(EtcdBackupTypes.Interval, 'intervalMins')}
            required
          />
        )}
        {intervalBackupUnit === 'hours' && (
          <TextField
            id="etcdBackupIntervalHours"
            label="Backup Interval in Hours (1-23)"
            type="number"
            step="1"
            min="1"
            max="23"
            info="A backup will be taken every specified number of hours."
            validations={[intervalHoursValidation]}
            disabled={!isIntervalBackupEnabled}
            value={intervalBackup?.intervalHours}
            onChange={handleChanges(EtcdBackupTypes.Interval, 'intervalHours')}
            required
          />
        )}
        <TextField
          id="intervalBackupsMaxIntervalBackups"
          label="Max Interval Backup Count"
          type="number"
          step="1"
          min="1"
          info="Maximum number of backups to be retained for the specified interval"
          validations={[backupCountValidation]}
          disabled={!isIntervalBackupEnabled}
          value={intervalBackup?.backupCount}
          onChange={handleChanges(EtcdBackupTypes.Interval, 'backupCount')}
          required
        />
      </Row>
    </div>
  )
}
