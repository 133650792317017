import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { NodeGroupTypeLabels } from '../model'
import { AwsMachineTemplate, IAwsMachineTemplateSelector } from './model'
import { emptyObj } from 'utils/fp'
import { assocPath } from 'ramda'

export const awsMachineTemplatesSelector: AppSelector<IAwsMachineTemplateSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.AwsMachineTemplates>(DataKeys.AwsMachineTemplates),
  (machineTemplates): IAwsMachineTemplateSelector[] =>
    machineTemplates.map((mt: AwsMachineTemplate) => {
      return {
        ...mt,
        uuid: mt.metadata?.uid,
        name: mt.metadata?.name,
        namespace: mt.metadata?.namespace,
        creationTimestamp: mt.metadata?.creationTimestamp,
        type: NodeGroupTypeLabels.AWSMachinTemplate,
        instanceType: mt.spec?.template?.spec?.instanceType,
        amiId: mt.spec?.template?.spec?.ami?.id,
        publicIP: mt.spec?.template?.spec?.publicIP,
        spotMarketOptions: mt.spec?.template?.spec?.spotMarketOptions,
        spotInstances: !!mt.spec?.template?.spec?.spotMarketOptions,
        spotInstanceMaxPrice: mt.spec?.template?.spec?.spotMarketOptions
          ? mt.spec?.template?.spec?.spotMarketOptions.maxPrice
          : 'Max Price',
        sshKeyName: mt.spec?.template?.spec?.sshKeyName,
        resources: [mt],
      }
    }),
)

type AwsMachineTemplatesByNamespaceAndNameSelector = {
  [namespace: string]: {
    [name: string]: IAwsMachineTemplateSelector
  }
}

export const awsMachineTemplatesByNamespaceAndNameSelector = createSharedSelector(
  awsMachineTemplatesSelector,
  (machineTemplates): AwsMachineTemplatesByNamespaceAndNameSelector =>
    machineTemplates.reduce((accum, mt) => {
      const { namespace, name } = mt
      return assocPath([namespace, name], mt, accum)
    }, emptyObj),
)
