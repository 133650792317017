import React from 'react'
import { ClusterAddonType } from '../model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { switchCase } from 'utils/fp'

const CoreDnsParamFields = React.lazy(async () => import('../param-fields/CoreDnsParamFields'))
const MonitoringParamFields = React.lazy(async () =>
  import('../param-fields/MonitoringParamFields'),
)
const AwsAutoscalerParamFields = React.lazy(async () =>
  import('../param-fields/AwsAutoscalerParamFields'),
)
const AzureAutoscalerParamFields = React.lazy(async () =>
  import('../param-fields/AzureAutoscalerParamFields'),
)
const CoreDnsAutoscalerParamFields = React.lazy(async () =>
  import('../param-fields/CoreDnsAutoscalerParamFields'),
)
const MetalLbParamFields = React.lazy(async () => import('../param-fields/MetalLbParamFields'))
const Metal3ParamFields = React.lazy(async () => import('../param-fields/Metal3ParamFields'))
const EtcdBackupParamFields = React.lazy(async () =>
  import('../param-fields/EtcdBackupParamFields'),
)

export const CoreDnsAndAutoscalerParams = ({ values, setFieldValue, addonVersion }) => (
  <>
    <CoreDnsParamFields values={values} setFieldValue={setFieldValue} addonVersion={addonVersion} />
    <FormFieldSection title="Configure Core DNS Autoscaler">
      <CoreDnsAutoscalerParamFields values={values} setFieldValue={setFieldValue} />
    </FormFieldSection>
  </>
)

export const Metal3AndMetalLbParams = ({ values, setFieldValue, addonVersion }) => (
  <>
    <Metal3ParamFields values={values} setFieldValue={setFieldValue} />
    <FormFieldSection title="Configure Metal LB">
      <MetalLbParamFields setFieldValue={setFieldValue} addonVersion={addonVersion} />
    </FormFieldSection>
  </>
)

export const getMetal3AddonParams = (action, currentAddons) => {
  const hasMetalLbEnabled = currentAddons.find((addon) => addon.type === ClusterAddonType.MetalLb)

  if (action === 'Edit' || (action === 'Enable' && hasMetalLbEnabled)) {
    return Metal3ParamFields
  }
  return Metal3AndMetalLbParams
}

export const getAddonParamFields = (addonType, action = undefined, existingClusterAddons = []) => {
  return switchCase(
    {
      [ClusterAddonType.CoreDns]: CoreDnsAndAutoscalerParams,
      [ClusterAddonType.Monitoring]: MonitoringParamFields,
      [ClusterAddonType.AwsAutoScaler]: AwsAutoscalerParamFields,
      [ClusterAddonType.AzureAutoScaler]: AzureAutoscalerParamFields,
      [ClusterAddonType.MetalLb]: MetalLbParamFields,
      [ClusterAddonType.Metal3]: getMetal3AddonParams(action, existingClusterAddons),
      [ClusterAddonType.EtcdBackup]: EtcdBackupParamFields,
    },
    null,
  )(addonType)
}
