import React, { forwardRef } from 'react'
import MultiSelect from 'core/components/MultiSelect'
import PropTypes from 'prop-types'
import { compose } from 'utils/fp'
import withFormContext, {
  ValidatedFormInputPropTypes,
} from 'core/components/validatedForm/withFormContext'

interface Props {
  id: string
  initialValue?: string | number
  info?: string
  required?: boolean
  onChange: (value: any) => any
  values: any
  setWizardContext: (value: any) => any
}

const AzureAvailabilityZoneChooser = forwardRef(
  ({ onChange, values, setWizardContext, ...rest }: Props, ref) => {
    const handleValuesChange = (zoneValues) => {
      setWizardContext({ zones: zoneValues })
      onChange && onChange(zoneValues)
    }

    const options = [
      { label: 'Zone 1', value: '1' },
      { label: 'Zone 2', value: '2' },
      { label: 'Zone 3', value: '3' },
    ]

    return (
      <MultiSelect
        label="Availability Zones"
        options={options}
        value={values.zones}
        onChange={handleValuesChange}
        {...rest}
      />
    )
  },
)

AzureAvailabilityZoneChooser.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  ...ValidatedFormInputPropTypes,
}

export default compose(withFormContext)(AzureAvailabilityZoneChooser)
