import React, { useMemo } from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { orderInterfaces } from 'app/plugins/infrastructure/components/nodes/helpers'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import Text from 'core/elements/Text'
import { emptyObj } from 'utils/fp'

export default function NetworkInterfacesCell({
  item: node,
  wrapText,
}: GridCellProps<INodesSelector> & { wrapText?: boolean }) {
  const primaryNetwork = node?.primaryNetwork || node.primaryIp
  const networkInterfaces = node?.networkInterfaces || emptyObj
  const orderedInterfaces = useMemo(() => orderInterfaces(networkInterfaces, primaryNetwork), [
    networkInterfaces,
    primaryNetwork,
  ])
  return (
    <>
      {orderedInterfaces.map(([interfaceName, interfaceIp]) => (
        <Text
          key={`${interfaceName}-${interfaceIp}`}
          variant="body2"
          className={!wrapText ? 'no-wrap-text' : ''}
        >
          {interfaceIp === primaryNetwork ? `${interfaceName} (primary)` : interfaceName} -{' '}
          {interfaceIp}
        </Text>
      ))}
    </>
  )
}
