import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { ConfigTypeLabels } from '../model'
import {
  IConfigSelector,
  NodeletConfig,
  EksConfig,
  EksConfigTemplate,
  NodeletConfigTemplate,
} from './model'

export const nodeletConfigSelector: AppSelector<IConfigSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.NodeletConfigs>(DataKeys.NodeletConfigs),
  (nodeletConfigs): IConfigSelector[] =>
    nodeletConfigs.map((config: NodeletConfig) => {
      return {
        ...config,
        uuid: config.metadata?.uid,
        name: config.metadata?.name,
        namespace: config.metadata?.namespace,
        creationTimestamp: config.metadata?.creationTimestamp,
        type: ConfigTypeLabels.NodeletConfig,
        labels: config.spec?.labels,
        annotations: config.metadata?.annotations,
        taints: config.spec?.taints,
        resources: [config],
      }
    }),
)
export const nodeletConfigTemplateSelector: AppSelector<IConfigSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.NodeletConfigTemplates>(DataKeys.NodeletConfigTemplates),
  (nodeletConfigs): IConfigSelector[] =>
    nodeletConfigs.map((config: NodeletConfigTemplate) => {
      return {
        ...config,
        uuid: config.metadata?.uid,
        name: config.metadata?.name,
        namespace: config.metadata?.namespace,
        creationTimestamp: config.metadata?.creationTimestamp,
        type: ConfigTypeLabels.NodeletConfigTemplate,
        labels: config.spec?.template?.spec?.labels,
        annotations: config.metadata?.annotations,
        taints: config.spec?.template?.spec?.taints,
        resources: [config],
      }
    }),
)

export const eksConfigSelector: AppSelector<IConfigSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.EksConfigs>(DataKeys.EksConfigs),
  (eksConfigs): IConfigSelector[] =>
    eksConfigs.map((config: EksConfig) => {
      return {
        ...config,
        uuid: config.metadata?.uid,
        name: config.metadata?.name,
        namespace: config.metadata?.namespace,
        creationTimestamp: config.metadata?.creationTimestamp,
        type: ConfigTypeLabels.EksConfig,
        labels: config.metadata?.labels,
        annotations: config.metadata?.annotations,
        taints: config.spec?.taints,
        resources: [config],
      }
    }),
)
export const eksConfigTemplateSelector: AppSelector<IConfigSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.EksConfigTemplates>(DataKeys.EksConfigTemplates),
  (eksConfigTemplates): IConfigSelector[] =>
    eksConfigTemplates.map((config: EksConfigTemplate) => {
      return {
        ...config,
        uuid: config.metadata?.uid,
        name: config.metadata?.name,
        namespace: config.metadata?.namespace,
        creationTimestamp: config.metadata?.creationTimestamp,
        type: ConfigTypeLabels.EksConfigTemplates,
        labels: config.metadata?.labels,
        annotations: config.metadata?.annotations,
        taints: config.spec?.taints,
        resources: [config],
      }
    }),
)

export const allConfigSelector = createSharedSelector(
  nodeletConfigSelector,
  nodeletConfigTemplateSelector,
  eksConfigSelector,
  eksConfigTemplateSelector,
  (nodeletConfigs, nodeletConfigTemplates, eksConfigs, eksConfigTemplates) => {
    return [...nodeletConfigs, ...nodeletConfigTemplates, ...eksConfigs, ...eksConfigTemplates]
  },
)
