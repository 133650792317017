import React from 'react'
import ClusterNameField from '../../form-components/NameField'
import CloudProviderRegionField from '../../form-components/CloudProviderRegionField'
import AwsClusterSshKeyPicklist from '../AwsClusterSshKeyPicklist'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import SshKeyPicklist from '../../form-components/SshKeyPicklist'
import { arnAwsValidator, ipv4CidrValidator } from '../../form-components/validators'
import TextField from 'core/components/validatedForm/TextField'
import CapiSpec from './CapiSpec'
import Row from 'core/containers/Row'
import { last } from 'ramda'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import CapiCloudProviderField from './CapiCloudProviderField'
import { getAccountIdfromAwsArn, getIdentityRefName } from './capiUtils'
import { IdentityRef } from '../create-templates/CapiAwsClusters'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { kubernetesNameValidator } from 'core/utils/fieldValidators'

interface Props {
  wizardContext: any
  setWizardContext: any
  values: any
}

export default function CapiClusterSpec({ wizardContext, setWizardContext, values }: Props) {
  const classes = useStyles()
  return (
    <CapiSpec title="Cluster Spec">
      <Row minItemWidth="300" gap={24}>
        {/* Cluster Name */}
        <ClusterNameField
          maxLength={40}
          setWizardContext={setWizardContext}
          onChange={(value) =>
            setWizardContext({
              name: value,
              controlPlaneName: `${value}-control-plane`,
              identityRefName: getIdentityRefName(wizardContext?.awsAssumeRole, wizardContext),
            })
          }
          validations={[kubernetesNameValidator]}
        />
        {/* Cloud Provider */}
        <CapiCloudProviderField
          id="capiCloudProvider"
          cloudProviderType={CloudProviders.Aws}
          setWizardContext={setWizardContext}
          wizardContext={wizardContext}
        />
      </Row>

      <>
        {/* AWS Assume Role */}
        <Row minItemWidth="300" gap={24} className={classes.alignCenter}>
          {/* AWS Assume Role Toggle */}
          <ToggleSwitchField
            id="awsAssumeRole"
            label="AWS Assume Role"
            value={wizardContext?.awsAssumeRole}
            onChange={(value) =>
              setWizardContext({
                awsAssumeRole: value,
                identityRefKind: value
                  ? IdentityRef.AWSClusterRoleIdentity
                  : IdentityRef.AWSClusterStaticIdentity,
                identityRefName: getIdentityRefName(value, wizardContext),
              })
            }
          />
          {/* Assume Role Target ARN */}
          <TextField
            id="assumeRoleTargetArn"
            label="Assume Role Target ARN"
            disabled={!wizardContext?.awsAssumeRole}
            required={wizardContext?.awsAssumeRole}
            value={wizardContext?.assumeRoleTargetArn}
            onChange={(value) =>
              setWizardContext({
                assumeRoleTargetArn: value,
                awsAccountId: getAccountIdfromAwsArn(value),
                identityRefName: getIdentityRefName(true, wizardContext),
              })
            }
            validations={wizardContext?.awsAssumeRole ? [arnAwsValidator] : []}
          />
        </Row>
      </>
      <Row minItemWidth="300" gap={24}>
        {/*Cluster Pod CIDR Range */}
        <TextField
          id="podCidr"
          label="Cluster Pod CIDR Range"
          info="The network ranges from which service Pod networks are allocated"
          required
          value={
            Array.isArray(wizardContext?.clusterPodCidr) ? last(wizardContext?.clusterPodCidr) : ''
          }
          onChange={(value) => setWizardContext({ clusterPodCidr: [value] })}
          validations={[ipv4CidrValidator]}
        />
        {/* Cluster Service CIDR Range */}
        <TextField
          id="serviceCidr"
          label="Cluster Service CIDR Range"
          info="The network ranges from which service Virtual IPs are allocated."
          required
          value={
            Array.isArray(wizardContext?.clusterServiceCidr)
              ? last(wizardContext?.clusterServiceCidr)
              : ''
          }
          onChange={(value) => setWizardContext({ clusterServiceCidr: [value] })}
          validations={[ipv4CidrValidator]}
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        {/* AWS Region */}
        <CloudProviderRegionField
          cloudProviderType={CloudProviders.Aws}
          values={wizardContext}
          wizardContext={wizardContext}
          onChange={(region) => setWizardContext({ region, azs: [] })}
        />

        {/* SSH Key */}
        {wizardContext.awsAssumeRole ? (
          <TextField
            id="assumeRoleSshKey"
            label="SSH Key Name"
            required
            value={wizardContext.sshKey}
            onChange={(value) => setWizardContext({ sshKey: value })}
          />
        ) : (
          <SshKeyPicklist
            dropdownComponent={AwsClusterSshKeyPicklist}
            values={values}
            wizardContext={wizardContext}
            setWizardContext={setWizardContext}
          />
        )}
      </Row>
    </CapiSpec>
  )
}

const useStyles = makeStyles<Theme>(() => ({
  alignCenter: {
    alignItems: 'center',
  },
}))
