import React, { useCallback } from 'react'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteCapiCluster } from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { isCapiCluster } from './helpers'
import { deleteCluster } from './newActions'

const BulkClusterDeleteDialog = ({
  rows: clusters,
  onClose,
  onSuccess,
}: {
  rows: any[]
  onClose: () => void
  onSuccess?: () => void
}) => {
  const { update: delCluster, updating: deletingCluster } = useUpdateAction(deleteCluster)
  const { update: delCapiCluster, updating: deletingCapiCluster } = useUpdateAction(
    deleteCapiCluster,
  )

  const handleBulkDelete = useCallback(async () => {
    const qbertClusters = []
    const capiClusters = []
    clusters.forEach((cluster) => {
      if (isCapiCluster(cluster)) {
        capiClusters.push(cluster)
      } else {
        qbertClusters.push(cluster)
      }
    })

    await Promise.allSettled(capiClusters.map(delCapiCluster))
    await Promise.allSettled(qbertClusters.map(delCluster))

    onClose()
  }, [])

  return (
    <ConfirmationDialog
      open
      text={`Delete ${clusters?.length} cluster(s)?`}
      onCancel={onClose}
      onConfirm={handleBulkDelete}
      loading={deletingCluster || deletingCapiCluster}
    />
  )
}

export default BulkClusterDeleteDialog
