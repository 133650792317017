import React, { useCallback, useState } from 'react'
import WizardStep from 'core/components/wizard/WizardStep'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Divider from 'core/elements/Divider'
import KubernetesVersionField from '../../form-components/KubernetesVersionField'
import Row from 'core/containers/Row'
import TextField from 'core/components/validatedForm/TextField'
import CalicoNetworkFields from '../../form-components/CalicoNetworkFields'
import ServicesFqdnField from '../../form-components/ServicesFqdnField'
import ApiFqdnField from '../../form-components/ApiFqdnField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterDomainPicklist from '../../ClusterDomainPicklist'
import CustomApiFlagsFields from '../../form-components/CustomApiFlagsFields'
import CapiSpec from './CapiSpec'
import BooleanPicklistField from '../../form-components/BooleanPicklistField'
import MasterNodeInstanceTypeField from '../../form-components/MasterNodeInstanceType'
import AwsRegionFlavorPicklist from '../AwsRegionFlavorPicklist'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import { last, mergeRight, split } from 'ramda'
import CapiAwsAdvancedApiConfigFields from './CapiAwsAdvancedApiConfigFields'
import CapiAwsNetworkBackendField, {
  NetworkBackendTypes,
} from 'k8s/components/infrastructure/clusters/aws/capi/CapiAwsNetworkBackendField'
import AwsAmiPicklist, { cloudInit } from './AwsAmiPicklist'
import AwsAmiField from '../../form-components/AwsAmiField'
import { YamlTemplate } from 'core/components/wizard/YamlTemplates'
import nodeletControlPlane from './schema/default/nodelet-control-plane.json'
import awsMachineTemplate from './schema/default/control-plane-aws-machine-template.json'
import { AwsClusterTypes } from '../../capi/model'
import CapiEtcdBackupFields from './CapiEtcdBackupFields'

const publicMasterNodeFieldInfo = `Enabling this will put master nodes in public subnets of
  the VPC and assign an elastic IP for the master nodes so that master nodes are publicly
  accessible and users can ssh into them`

const yamlTemplates: YamlTemplate[] = [
  {
    title: 'NodeletControlPlane',
    schema: nodeletControlPlane,
    hide: (values) => values.type !== AwsClusterTypes.AWS,
  },
  {
    title: 'AWSMachineTemplate',
    schema: awsMachineTemplate,
    hide: (values) => values.type !== AwsClusterTypes.AWS,
  },
]

export default function CapiControlPlaneWizardStep({
  wizardContext,
  setWizardContext,
  onNext,
  ...rest
}) {
  const classes = useStyles()
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

  const updateFqdns = useCallback(
    (value) => {
      const name = wizardContext.name

      const hostedZoneID = last(split('/')(value?.domainId))
      const apiFqdn = `${name}-api.${value.label}`
      const serviceFqdn = `${name}-service.${value.label}`

      setWizardContext({
        route53: mergeRight(wizardContext.route53, { hostedZoneID, apiFqdn, serviceFqdn }),
      })
    },
    [wizardContext.name],
  )

  const toggleRoute53 = useCallback(
    (value) => {
      setWizardContext({ useRoute53: value }, (wizardContext) =>
        wizardContext?.useRoute53
          ? setWizardContext({ route53: {} })
          : setWizardContext({ route53: null }),
      )
    },
    [wizardContext.route53],
  )

  return (
    <WizardStep
      stepId="controlPlane"
      label="Control Plane"
      yamlTemplates={yamlTemplates}
      showSummaryLabels={false}
      showSummaryYamls
      // Todo: Write tracking fn
      // onNext={awsClusterTracking.wZStepTwo(trackingFields)}
      {...rest}
    >
      <ValidatedForm
        fullWidth
        initialValues={wizardContext}
        onSubmit={setWizardContext}
        triggerSubmit={onNext}
        elevated={false}
      >
        {({ values, setFieldValue }) => (
          <>
            <CapiSpec title="Properties">
              <Row minItemWidth="300" gap={24}>
                <KubernetesVersionField
                  id="version"
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  inClusterCreation
                  isCapiCluster
                />
              </Row>
              <Row minItemWidth="300" gap={24}>
                <TextField
                  id="replicas"
                  label="Replica Count"
                  type="number"
                  min="1"
                  step="2"
                  value={wizardContext?.replicas}
                  onChange={(value) => setWizardContext({ replicas: value })}
                  required
                />

                {/* Not sure if MasterNodeInstanceTypeField is the right component for this
                    Double check it later */}
                <MasterNodeInstanceTypeField
                  id="instanceType"
                  dropdownComponent={AwsRegionFlavorPicklist}
                  label="Instance Type"
                  values={wizardContext}
                  value={wizardContext?.instanceType}
                  onChange={(value) => setWizardContext({ instanceType: value })}
                />
              </Row>
              <Row minItemWidth="300" gap={24}>
                <AwsAmiField
                  id="amiId"
                  dropdownComponent={AwsAmiPicklist}
                  label="Operating System"
                  version={wizardContext?.version}
                  identityName={wizardContext.provider}
                  roleArn={wizardContext?.assumeRoleTargetArn}
                  region={wizardContext?.region}
                  onChange={(value, selectedItem) =>
                    value !== 'custom'
                      ? setWizardContext({
                          amiId: value,
                          isCustomAmiId: false,
                          cloudInit: !selectedItem?.secretsManager ? cloudInit : null, // this additional config is needed if 'secretsManager:false'
                        })
                      : setWizardContext({ isCustomAmiId: true })
                  }
                />
                <TextField
                  id="customAwsAmiId"
                  label="Custom AMI ID (optional)"
                  placeholder="Enter Value..."
                  disabled={!wizardContext?.isCustomAmiId}
                  onChange={(value) => setWizardContext({ amiId: value, cloudInit: cloudInit })}
                />
              </Row>
              <Row minItemWidth="300" gap={24}>
                <BooleanPicklistField
                  id="publicIP"
                  label="Make Master Nodes Public"
                  tooltip={publicMasterNodeFieldInfo}
                  value={wizardContext?.publicIP}
                  onChange={(value) => setWizardContext({ publicIP: value })}
                />
                <BooleanPicklistField
                  id="allowWorkloadsOnMaster"
                  label="Make Master Nodes Master + Worker"
                  onChange={(value) => setWizardContext({ allowWorkloadsOnMaster: value })}
                />
              </Row>
              {/* Currently only privileged containers are supported */}
              {/* <Row minItemWidth="300" gap={24}>
                <BooleanPicklistField
                  id="privileged"
                  label="Privileged Containers"
                  onChange={(value) =>
                    setWizardContext({
                      privileged: value,
                      apiserver: wizardContext?.apiserver
                        ? assoc('privileged', value, wizardContext?.apiserver)
                        : null,
                    })
                  }
                />
              </Row> */}
            </CapiSpec>
            <Divider />
            <CapiSpec title="Networking">
              <Row minItemWidth="300" gap={24}>
                <TextField
                  id="httpProxy"
                  label="HTTP Proxy"
                  onChange={(value) => setWizardContext({ httpProxy: value })}
                />
              </Row>
              <ToggleSwitchField
                id="useRoute53"
                label="Use Route53 for Cluster Access"
                value={wizardContext.useRoute53}
                info="Enable Platform9 to use a selected  Route53 domain for the
                 API Server and Service Endpoints."
                onChange={toggleRoute53}
              />

              {/* Use Route53 for Cluster Acess */}
              {wizardContext.useRoute53 && (
                <>
                  <Row minItemWidth="300" gap={24}>
                    <PicklistField
                      DropdownComponent={ClusterDomainPicklist} // Double check this
                      id="domainId"
                      label="Domain"
                      onChange={updateFqdns}
                      cloudProviderId={wizardContext.cloudProviderId}
                      cloudProviderRegionId={wizardContext.region}
                      tooltip="Select the base domain name to be used for the API and service FQDNs"
                      required={!wizardContext.usePf9Domain}
                      disabled={wizardContext.usePf9Domain}
                    />
                  </Row>
                  <Row minItemWidth="300" gap={24}>
                    <ApiFqdnField
                      setWizardContext={setWizardContext}
                      wizardContext={wizardContext}
                      disabled={wizardContext.usePf9Domain}
                    />

                    <ServicesFqdnField
                      setWizardContext={setWizardContext}
                      wizardContext={wizardContext}
                      required={!wizardContext.usePf9Domain}
                      disabled={wizardContext.usePf9Domain}
                    />
                  </Row>
                </>
              )}
            </CapiSpec>
            <Divider />
            {/* Cluster CNI */}
            <CapiSpec title="Cluster CNI">
              <Row minItemWidth="300" gap={24}>
                <CapiAwsNetworkBackendField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />
              </Row>
              {values.networkPlugin === NetworkBackendTypes.Calico && (
                <CalicoNetworkFields
                  values={wizardContext}
                  setValues={setWizardContext}
                  useToggleSwitch
                  isCapiCluster
                />
              )}
            </CapiSpec>
            <Divider />
            <CapiSpec title="ETCD Backup">
              <CapiEtcdBackupFields
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
              />
            </CapiSpec>
            <Divider />
            <div className={classes.advancedOptionsLabel}>
              <Text variant="body2">Advanced Options</Text>
              <FontAwesomeIcon
                solid
                size="xs"
                className={classes.toggleCaret}
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
              >
                {showAdvancedOptions ? 'chevron-up' : 'chevron-down'}
              </FontAwesomeIcon>
            </div>
            {showAdvancedOptions && (
              <div className={classes.advancedOptionContent}>
                {/* API Server Configuration */}
                <CapiSpec title="Configuration">
                  <Row minItemWidth="300" gap={24}>
                    <CapiAwsAdvancedApiConfigFields
                      wizardContext={wizardContext}
                      setWizardContext={setWizardContext}
                    />
                  </Row>
                  <CustomApiFlagsFields
                    isCapiCluster
                    wizardContext={wizardContext}
                    setWizardContext={setWizardContext}
                  />
                </CapiSpec>
              </div>
            )}
          </>
        )}
      </ValidatedForm>
    </WizardStep>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  advancedOptionsLabel: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  advancedOptionContent: {
    display: 'grid',
    gap: theme.spacing(4),
  },
}))
