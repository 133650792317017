import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import CopyToClipboard from 'core/components/CopyToClipboard'
import React from 'react'
import Text from 'core/elements/Text'

export default function UUIDCell({ value: uuid }: GridCellProps<unknown, string>) {
  return (
    <CopyToClipboard copyText={uuid} codeBlock={false}>
      <Text variant="body2">{uuid}</Text>
    </CopyToClipboard>
  )
}
