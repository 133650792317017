import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { isTransientStatus } from 'app/plugins/infrastructure/components/clusters/ClusterStatusUtils'
import { CombinedClusterSelector } from '../../combinedClusters/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/core/styles'
import { switchCase } from 'utils/fp'
import useListAction from 'core/hooks/useListAction'
import { listClusterVersions } from '../cluster-addons/new-actions'
import { useSelector } from 'react-redux'
import { clusterVersionsSelector } from '../cluster-addons/selectors'
import ClusterVersionStatus from './ClusterVersionStatus'

const QbertClusterUpgradeLinks = ({ cluster }) => {
  const classes = useStyles()
  const { version, canUpgrade, upgradingTo, upgrading, upgradeFailed, taskStatus } = cluster

  const { loading } = useListAction(listClusterVersions)
  const clusterVersions = useSelector(clusterVersionsSelector)

  const upgradeAvailable = canUpgrade
  const continueUpgrade = !isTransientStatus(taskStatus) && upgradingTo

  const upgradingRoute = routes.cluster.managed.qbert.detail.path({
    tab: 'node-health',
    id: cluster?.uuid,
  })
  const upgradeAvailableRoute = routes.cluster.managed.qbert.upgrade.path({ id: cluster?.uuid })
  const upgradeFailedRoute = routes.cluster.managed.qbert.upgrade.path({ id: cluster?.uuid })
  return (
    <>
      <ClusterVersionStatus
        version={version}
        clusterVersions={clusterVersions}
        upgrading={upgrading}
        upgradingRoute={upgradingRoute}
        upgradeAvailable={upgradeAvailable}
        upgradeAvailableRoute={upgradeAvailableRoute}
        upgradeFailed={upgradeFailed}
        upgradeFailedRoute={upgradeFailedRoute}
        continueUpgrade={continueUpgrade}
      />
    </>
  )
}

const CapiClusterUpgradeLinks = ({ cluster }) => {
  const classes = useStyles()
  const {
    version,
    canUpgrade,
    phase,
    upgrading,
    upgradeFailed,
    infrastructureReady,
    controlPlaneReady,
  } = cluster
  const upgradeAvailable =
    canUpgrade &&
    !isTransientStatus((phase || '').toLowerCase()) &&
    infrastructureReady &&
    controlPlaneReady

  const { loading } = useListAction(listClusterVersions)
  const clusterVersions = useSelector(clusterVersionsSelector)

  const upgradingRoute = routes.cluster.managed.capi.upgradeProgress.path({
    id: cluster?.uuid,
    jobId: cluster?.currentUpgradeJob?.id,
  })
  const upgradeAvailableRoute = routes.cluster.managed.capi.details.path({
    id: cluster?.uuid,
  })
  const upgradeFailedRoute = routes.cluster.managed.capi.details.path({
    id: cluster?.uuid,
  })
  return (
    <>
      <ClusterVersionStatus
        version={version}
        clusterVersions={clusterVersions}
        upgrading={upgrading}
        upgradingRoute={upgradingRoute}
        upgradeAvailable={upgradeAvailable}
        upgradeAvailableRoute={upgradeAvailableRoute}
        upgradeFailed={upgradeFailed}
        upgradeFailedRoute={upgradeFailedRoute}
        continueUpgrade={false}
      />
    </>
  )
}

const ImportedClusterK8sVersionCell = ({ cluster }) => (
  <Text variant="body2">{cluster?.version}</Text>
)

export default function K8sVersionCell({ item: cluster }: GridCellProps<CombinedClusterSelector>) {
  const { clusterType, version } = cluster
  const VersionsComponent = switchCase({
    [ClusterTypes.Normal]: QbertClusterUpgradeLinks,
    [ClusterTypes.Imported]: ImportedClusterK8sVersionCell,
    [ClusterTypes.Capi]: CapiClusterUpgradeLinks,
  })(clusterType)
  return <>{!clusterType || !version ? null : <VersionsComponent cluster={cluster} />}</>
}

const useStyles = makeStyles<Theme>((theme) => ({
  versionContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content 1fr',
    gridGap: theme.spacing(1),
  },
  upgradingContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  spinner: {
    color: theme.palette.blue.main,
  },
}))
