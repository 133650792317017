import Card from 'core/elements/card'
import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { castBoolToStr } from 'utils/misc'
import { renderAvailabilityZones, renderCidrRanges } from '../view-helpers'
import { getFieldsForCard } from 'core/components/InfoPanel'
import { TableRows, TableRow, SubsectionHeader } from '../PropertyCardTable'
import SubnetConfigsTableFields from '../infrastructure/SubnetConfigsTableFields'
import { isNilOrEmpty } from 'utils/fp'
import { renderResourceLabels } from 'k8s/components/common/entity/labels-and-annotations/helpers'

const nodeletCpColumns = [
  { id: 'spec.version', title: 'Kubernetes Version', required: true },
  {
    id: 'spec.replicas',
    title: 'Replica Count',
    render: (count) => String(count),
    required: true,
  },
  {
    id: 'instanceType',
    title: 'Instance Type',
    required: true,
  },
  {
    id: 'amiId',
    title: 'Operating System',
    required: true,
  },
  {
    id: 'publicIP',
    title: 'Make Master nodes public',
    render: (value) => castBoolToStr('True', 'False')(value),
    required: true,
  },
  {
    id: 'allowWorkloadsOnMaster',
    title: 'Make Master nodes Master + Worker',
    render: (value) => castBoolToStr('True', 'False')(value),
    required: true,
  },
  {
    id: 'privileged',
    title: 'Privileged Containers',
    render: (value) => castBoolToStr('True', 'False')(value),
    required: true,
  },
]

const awsCpColumns = [
  { id: 'version', title: 'Kubernetes Version', required: true },
  {
    id: 'endpointAccessLabel',
    title: 'API Server Endpoint Access',
    required: true,
  },
  {
    id: 'endpointAccess.publicCIDRs',
    title: 'Public IP Access CIDR Ranges',
    render: renderCidrRanges,
    required: true,
  },
]

const loggingColumns = [
  {
    id: 'apiServer',
    title: 'API Server',
    render: (value) => castBoolToStr('True', 'False')(value),
    required: true,
  },
  {
    id: 'audit',
    title: 'Audit',
    render: (value) => castBoolToStr('True', 'False')(value),
    required: true,
  },
  {
    id: 'authenticator',
    title: 'Authenticator',
    render: (value) => castBoolToStr('True', 'False')(value),
    required: true,
  },
  {
    id: 'controllerManager',
    title: 'Controller Manager',
    render: (value) => castBoolToStr('True', 'False')(value),
    required: true,
  },
  {
    id: 'scheduler',
    title: 'Scheduler',
    render: (value) => castBoolToStr('True', 'False')(value),
    required: true,
  },
]

const nodeletCpClusterCniColumns = [
  {
    id: 'cniPlugin',
    title: 'CNI',
    required: true,
  },
  {
    id: 'calico.IPIPMode',
    title: 'IP in IP Encapsulation Mode',
    required: true,
    condition: (controlPlane) => !!controlPlane?.calico,
  },
  {
    id: 'calico.IPv4DetectionMethod',
    title: 'Interface Detection Method',
    required: true,
    condition: (controlPlane) => !!controlPlane?.calico,
  },
  {
    id: 'calico.natOutgoing',
    title: 'NAT Outgoing',
    required: true,
    condition: (controlPlane) => !!controlPlane?.calico,
    render: (value) => castBoolToStr('True', 'False')(value),
  },
  {
    id: 'calico.IPv4BlkSize',
    title: 'Block Size',
    required: true,
    render: (value) => value?.toString(),
    condition: (controlPlane) => !!controlPlane?.calico,
  },
  {
    id: 'calico.MTUSize',
    title: 'MTU Size',
    required: true,
    render: (value) => value?.toString(),
    condition: (controlPlane) => !!controlPlane?.calico,
  },
]

const awsCpClusterCniColumns = [
  {
    id: 'disableVPCCNI',
    title: 'AWS VPC CNI',
    required: true,
    render: (value) => castBoolToStr('True', 'False')(!value),
  },
  {
    id: 'secondaryCidrBlock',
    title: 'Secondary CIDR Black',
    condition: (controlPlane) => !controlPlane?.disableVPCCNI,
  },
]

const nodeletCpNetworkingColumns = [
  {
    id: 'httpProxy.proxy',
    title: 'HTTP Proxy',
    required: true,
  },
  {
    id: 'route53', // TODO: Figure out what field corresponds to this column
    title: 'Route53 for Cluster Access',
    render: (route53) => (route53 ? 'Enabled' : 'Disabled'),
    required: true,
  },
  {
    id: 'route53.apiFqdn',
    title: 'API FQDN',
    required: true,
  },
  {
    id: 'null', // TODO: Figure out what field corresponds to this column
    title: 'Service FQDN',
    required: true,
  },
]

const awsManagedCpNetworkingColumns = [
  {
    id: 'region',
    title: 'Region',
    required: true,
  },
  {
    id: 'sshKey',
    title: 'SSH Key',
    required: true,
  },
  {
    id: 'awsAssumeRoleEnabled',
    title: 'AWS Assume Role',
    render: (value) => castBoolToStr()(value),
    required: true,
  },
  {
    id: 'cloudProviderName',
    title: 'Cloud Provider',
    required: true,
  },
  {
    id: 'roleArn',
    title: 'Assume Role Target ARN',
    required: true,
  },
  {
    id: 'vpcId',
    title: 'VPC ID',
    required: true,
  },
  {
    id: 'vpcCidrBlock',
    title: 'VPC CIDR Block',
    required: true,
  },
  {
    id: 'availabilityZones',
    title: 'Availability Zones',
    render: renderAvailabilityZones,
    required: true,
  },
]

const renderResources = (resources = []) => {
  return (
    <div>
      {resources.map((resource) => (
        <Text key={resource} variant="caption1" component="p">
          {resource}
        </Text>
      ))}
    </div>
  )
}

const nodeletCpAdvancedColumns = [
  {
    id: 'null',
    title: 'Advanced API Configuration',
    required: true,
  },
  {
    id: 'null',
    title: 'API Server Flags',
    required: true,
  },
  {
    id: 'null',
    title: 'Scheduler Flags',
    required: true,
  },
  {
    id: 'null',
    title: 'Controller Manager Flags',
    required: true,
  },
]

const awsCpAdvancedColumns = [
  {
    id: 'encryption',
    title: 'Resource Encyption',
    render: (_, config) => castBoolToStr('True', 'False')(!isNilOrEmpty(config)),
    required: true,
  },
  {
    id: 'provider',
    title: 'Provider',
    condition: (config) => !isNilOrEmpty(config),
  },
  {
    id: 'resources',
    title: 'Resources',
    render: (resources) => renderResources(resources),
    condition: (config) => !isNilOrEmpty(config),
  },
]

const etcdBackupColumns = {
  daily: [
    { id: 'localPath', title: 'Storage Path' },
    {
      id: 'dailyBackupTime',
      title: 'Daily Backup Time (00:00 - 23:59)',
      render: (value) => String(value),
    },
    { id: 'backupCount', title: 'Max Timestamp Backup Count', render: (value) => String(value) },
  ],
  interval: [
    { id: 'localPath', title: 'Storage Path' },
    { id: 'backupUnit', title: 'Interval Backup Unit', render: (value) => String(value) },
    {
      id: 'intervalHours',
      title: 'Backup Interval in Hours (1-23)',
      render: (value) => String(value),
    },
    { id: 'intervalMins', title: 'Backup Interval in Minutes', render: (value) => String(value) },
    { id: 'backupCount', title: 'Max Timestamp Backup Count', render: (value) => String(value) },
  ],
}

export default function ControlPlanePropertiesCard({ controlPlane, machineTemplate }) {
  const classes = useStyles()

  const generalPropertyFields = useMemo(() => {
    const columns = controlPlane?.kind === 'NodeletControlPlane' ? nodeletCpColumns : awsCpColumns
    const data =
      controlPlane?.kind === 'NodeletControlPlane'
        ? {
            ...controlPlane,
            instanceType: machineTemplate?.spec?.template?.spec?.instanceType,
            amiId: machineTemplate?.spec?.template?.spec?.ami?.id,
            publicIP: machineTemplate?.spec?.template?.spec?.publicIP,
          }
        : controlPlane
    return getFieldsForCard(columns, data)
  }, [controlPlane, machineTemplate])

  const loggingFields = useMemo(() => {
    if (controlPlane?.kind === 'NodeletControlPlane') return {}
    return getFieldsForCard(loggingColumns, controlPlane?.logging)
  }, [controlPlane])

  const clusterCniFields = useMemo(() => {
    const columns =
      controlPlane?.kind === 'NodeletControlPlane'
        ? nodeletCpClusterCniColumns
        : awsCpClusterCniColumns
    return getFieldsForCard(columns, controlPlane)
  }, [controlPlane])

  const networkingFields = useMemo(() => {
    const columns =
      controlPlane?.kind === 'NodeletControlPlane'
        ? nodeletCpNetworkingColumns
        : awsManagedCpNetworkingColumns
    return getFieldsForCard(columns, controlPlane)
  }, [controlPlane])

  const advancedConfigFields = useMemo(() => {
    const columns =
      controlPlane?.kind === 'NodeletControlPlane' ? nodeletCpAdvancedColumns : awsCpAdvancedColumns
    return getFieldsForCard(columns, controlPlane?.encryptionConfig)
  }, [controlPlane])

  const etcdDailyBackupFields = useMemo(() => {
    return getFieldsForCard(etcdBackupColumns.daily, controlPlane?.etcdDailyBackup)
  }, [controlPlane?.etcdDailyBackup])

  const etcdIntervalBackupFields = useMemo(() => {
    return getFieldsForCard(etcdBackupColumns.interval, controlPlane?.etcdIntervalBackup)
  }, [controlPlane?.etcdIntervalBackup])

  return (
    <Card className={classes.card} title="Properties">
      <div className={classes.table}>
        <TableRows fields={generalPropertyFields} />
        {/* Networking Rows */}
        <SubsectionHeader title="Networking" />
        <TableRows fields={networkingFields} />

        <SubnetConfigsTableFields subnetConfigs={controlPlane?.subnetConfigs} />

        {/* Cluster CNI Rows */}
        <SubsectionHeader title="Cluster CNI" />
        <TableRows fields={clusterCniFields} />

        {/* Logging Rows */}
        {controlPlane?.kind === 'AWSManagedControlPlane' && <SubsectionHeader title="Logging" />}
        <TableRows fields={loggingFields} />
        {/* ETCD Backup Rows */}
        {controlPlane?.kind === 'NodeletControlPlane' && (
          <>
            <SubsectionHeader title="ETCD Backup" />
            <TableRow
              label="Daily Backups"
              value={castBoolToStr('Enabled', 'Disabled')(!!controlPlane?.etcdDailyBackup)}
            />
            {controlPlane?.etcdDailyBackup && (
              <TableRows fields={etcdDailyBackupFields} labelClassName={classes.indentedFields} />
            )}
            <TableRow
              label="Interval Backups"
              value={castBoolToStr('Enabled', 'Disabled')(!!controlPlane?.etcdIntervalBackup)}
            />
            {controlPlane?.etcdIntervalBackup && (
              <TableRows
                fields={etcdIntervalBackupFields}
                labelClassName={classes.indentedFields}
              />
            )}
          </>
        )}

        {/* Tags */}
        {!!controlPlane?.tags && controlPlane?.kind === 'AWSManagedControlPlane' && (
          <>
            <SubsectionHeader title="Tags" />
            {renderResourceLabels({ separator: '=', type: 'panel', ellipsisAt: 80 })(
              controlPlane.tags,
            )}
          </>
        )}
        {/* Advanced Configuration Rows */}
        <SubsectionHeader title="Advanced Configuration" />
        <TableRows fields={advancedConfigFields} />
      </div>
    </Card>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    height: 'max-content',
  },
  table: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  indentedLabel: {
    marginLeft: '8px',
  },
  subsection: {
    marginTop: theme.spacing(2),
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(1, 0),
  },
  zone: {
    display: 'inline-flex',
  },
  azDivider: {
    height: '15px',
    width: 0,
    border: `1px solid ${theme.components.card.border}`,
    margin: theme.spacing(0.5, 1),
  },
  indentedFields: {
    marginLeft: '8px',
  },
}))
