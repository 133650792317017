import React, { useMemo, useCallback } from 'react'
import withFormContext from 'core/components/validatedForm/withFormContext'
import Text from 'core/elements/Text'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'
import { makeStyles } from '@material-ui/styles'
import { isNil, always } from 'ramda'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import PollingData from 'core/components/PollingData'
import Theme from 'core/themes/model'
import NoContentMessage from 'core/components/NoContentMessage'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import clsx from 'clsx'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import {
  ClusterType,
  minAvailableDiskSpace,
  HardwareType,
  nodeHardwareRequirements,
} from './constants'
import Tooltip from 'core/elements/tooltip'
import NetworkInterfacesCell from 'app/plugins/infrastructure/components/nodes/node-cells/NetworkInterfacesCell'
import ControlledGrid, { ControlledGridProps } from 'core/elements/grid/ControlledGrid'
import { GridViewColumn } from 'core/elements/grid/Grid'
import NodeDetailCell from 'app/plugins/infrastructure/components/nodes/node-cells/NodeDetailCell'
import UUIDCell from 'app/plugins/infrastructure/components/common/cells/UUIDCell'
import { getAvailableSpace } from 'app/plugins/infrastructure/components/nodes/helpers'
import withProgress from 'core/components/progress/withProgress'

interface ClusterHostChooserProps {
  id: string
  title?: string
  value?: string[]
  hasError?: boolean
  errorMessage?: string
  pollForNodes?: boolean
  onChange?: (nodes: string[]) => void
  filterFn?: (node: INodesSelector) => boolean
  selection?: 'none' | 'single' | 'multiple'
  isSingleNodeCluster?: boolean
  showResourceRequirements?: boolean
  airgapped?: boolean
  allowMasterAndWorkerNodesSelection?: boolean
}

const useStyles = makeStyles<Theme>((theme) => ({
  status: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridGap: theme.spacing(0.5),
  },
  availableResourceIcon: {
    alignSelf: 'center',
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.grey['000'],
    fontSize: 10,
    lineHeight: 2,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    marginRight: theme.spacing(0.5),
    '&.success': {
      backgroundColor: theme.palette.green.main,
    },
    '&.fail': {
      backgroundColor: theme.palette.red.main,
    },
  },
}))

const AsyncControlledGrid = withProgress<ControlledGridProps<INodesSelector>>(ControlledGrid)

const ResourceStatus = ({ hasEnough, info = '', children }) => {
  const classes = useStyles()
  return (
    <div className={classes.status}>
      <Tooltip message={info}>
        <FontAwesomeIcon
          className={clsx(classes.availableResourceIcon, {
            success: hasEnough,
            fail: !hasEnough,
          })}
        >
          {hasEnough ? 'check' : 'times'}
        </FontAwesomeIcon>
      </Tooltip>
      {children}
    </div>
  )
}

const getCpuCountCell = (clusterType) => ({ value: cpuCount }) => {
  if (!cpuCount) return null
  const recommendedCpuCount = nodeHardwareRequirements[clusterType][HardwareType.CPU]
  const hasEnough = cpuCount >= recommendedCpuCount
  const cpuCountHooverText = `Recommended number of CPUs is ${recommendedCpuCount}`
  return (
    <ResourceStatus hasEnough={hasEnough} info={cpuCountHooverText}>
      <Text variant="body2">{cpuCount}</Text>
    </ResourceStatus>
  )
}

const getRamCapacityCell = (clusterType) => ({ value: ram }) => {
  if (!ram) return null
  const recommendedRamCapacity = nodeHardwareRequirements[clusterType][HardwareType.RAM]
  const enoughSpace = ram.max >= recommendedRamCapacity
  const ramCapacityHooverText = `Recommended RAM capacity is ${recommendedRamCapacity} GB`
  return (
    <ResourceStatus hasEnough={enoughSpace} info={ramCapacityHooverText}>
      <Text variant="body2">{`${ram?.max.toFixed(2)} GB`}</Text>
    </ResourceStatus>
  )
}

const getDiskSpaceStatusCell = (clusterType) => ({ value: disk }) => {
  if (!disk) return null
  const recommendedTotalDiskSpace = nodeHardwareRequirements[clusterType][HardwareType.Disk]
  const availableSpace = getAvailableSpace(disk)
  const totalSpace = disk.max
  const enoughSpace =
    availableSpace >= minAvailableDiskSpace && totalSpace >= recommendedTotalDiskSpace
  const diskSpaceHooverText = `
Recommended available disk space is ${minAvailableDiskSpace} GB and
recommended total disk space is ${recommendedTotalDiskSpace} GB`
  return (
    <ResourceStatus hasEnough={enoughSpace} info={diskSpaceHooverText}>
      <div>
        <Text variant="body2">Available: {`${availableSpace.toFixed(2)} GB`}</Text>
        <Text variant="body2">Total: {`${totalSpace.toFixed(2)} GB`}</Text>
      </div>
    </ResourceStatus>
  )
}

const ClockDriftStatusCell = ({ value: message }) => {
  const hasClockDrift = message && message.warn && message.warn[0]
  const clockDriftHooverText = 'Nodes with clock out of sync cannot be attached'
  return (
    <ResourceStatus hasEnough={!hasClockDrift} info={hasClockDrift ? clockDriftHooverText : ''}>
      <Text variant="body2">{hasClockDrift ? 'Clock Drift Detected' : 'None'}</Text>
    </ResourceStatus>
  )
}

export default withFormContext<string[], ClusterHostChooserProps>(function ClusterHostChooser({
  filterFn = always(true),
  onChange,
  value: selectedUids = [],
  hasError,
  errorMessage,
  pollForNodes = false,
  selection = 'single',
  isSingleNodeCluster = false,
  airgapped = false,
  showResourceRequirements = true,
  allowMasterAndWorkerNodesSelection = false,
  title,
}) {
  const { loading, message, reload: loadMore } = useListAction(listNodes)
  const nodes = useSelector(nodesSelector)

  const clusterType = isSingleNodeCluster
    ? ClusterType.SingleNodeCluster
    : ClusterType.MultiNodeCluster

  const columns: GridViewColumn<INodesSelector>[] = useMemo(
    () => [
      {
        key: 'uuid',
        label: 'UUID',
        tooltip:
          'This is the unique ID generated by PMK for this node. ' +
          'You will need this ID to perform any API based operations with PMK',
        CellComponent: UUIDCell,
        display: false,
      } as GridViewColumn<INodesSelector, 'uuid'>,
      ...(allowMasterAndWorkerNodesSelection
        ? [
            {
              key: 'isMaster',
              label: 'Type (Master/Worker)',
              width: 'small',
              formatFn: (isMaster, node) =>
                isNil(node.clusterUuid) ? '' : isMaster ? 'Master' : 'Worker',
            } as GridViewColumn<INodesSelector, 'isMaster'>,
          ]
        : []),
      {
        key: 'name',
        label: 'Hostname',
        width: 'small',
        CellComponent: NodeDetailCell,
      },
      {
        key: 'primaryIp',
        label: 'IP Address',
        width: 'small',
        CellComponent: NetworkInterfacesCell,
      },
      {
        key: 'osInfo',
        label: 'Operating System',
        width: 'small',
        accessor: (node) => node?.combined?.osInfo,
      },
      {
        key: 'cpuSockets',
        label: 'CPU Count',
        width: 50,
        accessor: (node) => node?.combined?.resmgr?.info?.cpu_info?.cpu_sockets,
        CellComponent: getCpuCountCell(clusterType),
      },
      {
        key: 'cpuCores',
        label: 'CPU Cores',
        width: 50,
        accessor: (node) => node?.combined?.resmgr?.info?.cpu_info?.cpu_cores,
      },
      {
        key: 'memory',
        label: 'RAM Capacity',
        width: 50,
        accessor: (node) => node?.combined?.usage?.memory,
        CellComponent: getRamCapacityCell(clusterType),
      },
      {
        key: 'disk',
        label: 'Storage Capacity',
        width: 50,
        accessor: (node) => node?.combined?.usage?.disk,
        CellComponent: getDiskSpaceStatusCell(clusterType),
      },
      {
        key: 'resmgr',
        label: 'Clock Drift',
        width: 50,
        accessor: (node) => node?.combined?.resmgr?.message,
        CellComponent: ClockDriftStatusCell,
      },
    ],
    [clusterType, allowMasterAndWorkerNodesSelection],
  )
  const selectableNodes = useMemo(() => nodes.filter(filterFn), [nodes, filterFn])
  const selectedItems = useMemo(() => nodes.filter((node) => selectedUids.includes(node.uuid)), [
    nodes,
    selectedUids,
  ])
  const handleSelectChange = useCallback(
    (selectedItems) => {
      onChange(selectedItems.map(({ uuid }) => uuid))
    },
    [onChange],
  )

  // useEffect(() => {
  //   if (selectedUids.length === 0) return
  //   // On first render, make sure that the selected nodes (value in props) are in the selectableNodes list
  //   // This is to fix the bug where if you choose worker nodes in the cluster creation form and
  //   // then go back to select one of the worker nodes as the master node, the node will be in both
  //   // the selected master and selected worker list
  //   onChange(selectedUids.filter((uuid) => selectableNodes.find((node) => node.uuid === uuid)))
  // }, [])

  const resourceRequirementsText = `All nodes must meet minimum resource requirement of ${
    nodeHardwareRequirements[clusterType][HardwareType.CPU]
  } CPUs, ${nodeHardwareRequirements[clusterType][HardwareType.RAM]} GB RAM, ${
    nodeHardwareRequirements[clusterType][HardwareType.Disk]
  } GB Disk`

  return (
    <FormFieldSection
      title={title}
      info={showResourceRequirements ? resourceRequirementsText : undefined}
      errorMessage={hasError && errorMessage}
    >
      {pollForNodes && (
        <div>
          <PollingData loading={loading} onReload={loadMore} pause={!pollForNodes} />
        </div>
      )}
      {selectableNodes.length === 0 && !airgapped && (
        <NoContentMessage message="Waiting... Connect nodes using the OVA or PF9 CLI." />
      )}
      {selectableNodes.length === 0 && airgapped && (
        <NoContentMessage message="Waiting... Connect nodes using the airctl commands." />
      )}
      {selectableNodes.length > 0 && (
        <AsyncControlledGrid
          uniqueIdentifier="uuid"
          data={selectableNodes}
          selectedItems={selectedItems}
          onSelectChange={handleSelectChange}
          columns={columns}
          loading={loading}
          loadingMessage={message}
          multiSelection={selection === 'multiple'}
          disableRowSelection={selection === 'none'}
        />
      )}
    </FormFieldSection>
  )
})
