import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ModalForm from 'core/elements/modal/ModalForm'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { mergeDeepRight } from 'ramda'
import React, { useMemo } from 'react'
import { updateNodeletControlPlane } from '../../control-plane/nodelet-control-plane/actions'
import TextField from 'core/components/validatedForm/TextField'
import {
  createAwsMachineTemplate,
  deleteAwsMachineTemplate,
} from '../../aws-machine-templates/actions'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import IntervalBackupUnitPicklist from '../../../cluster-addons/IntervalBackupUnitPicklist'
import PicklistField from 'core/components/validatedForm/DropdownField'
import {
  timestampValidations,
  backupCountValidation,
  intervalHoursValidation,
  intervalMinutesValidation,
} from '../../../cluster-addons/param-fields/EtcdBackupParamFields'
import { cleanResource } from '../../../aws/capi/capiUtils'
import { isNilOrEmpty } from 'utils/fp'
import { defaultEtcBackupPath } from 'app/constants'
import Divider from 'core/elements/Divider'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { EtcdBackupTypes } from '../../../aws/capi/CapiEtcdBackupFields'
import CapiEditModalErrors from '../CapiEditModalErrors'
import { etcdBackupDefaults } from '../../../cluster-addons/etcd-backup'

const getControlPlaneUpdatebody = (values, controlPlane) => {
  const dailyBackup = Object.assign({}, controlPlane?.etcdDailyBackup)
  const intervalBackup = Object.assign({}, controlPlane?.etcdIntervalBackup)
  if (values.dailyBackupsEnabled) {
    dailyBackup.localPath = values.dailyBackupLocalPath
    dailyBackup.dailyBackupTime = values.dailyBackupTime
    dailyBackup.backupCount = values.dailyBackupCount
    dailyBackup.name = EtcdBackupTypes.Daily
    dailyBackup.target = 'local'
  }
  if (values.intervalBackupsEnabled) {
    intervalBackup.localPath = values.intervalBackupLocalPath
    intervalBackup.intervalHours = values.intervalHours
    intervalBackup.intervalMins = values.intervalMins
    intervalBackup.backupCount = values.intervalBackupCount
    intervalBackup.name = EtcdBackupTypes.Interval
    intervalBackup.target = 'local'
  }
  const etcdBackup = [dailyBackup, intervalBackup].filter((i) => !isNilOrEmpty(i))

  return {
    spec: {
      replicas: values.replicas,
      etcdBackup,
    },
  }
}

const getMachineTemplateCreateBody = (values, machineTemplate) => {
  const currentMachineTemplate = cleanResource([
    'metadata.creationTimestamp',
    'metadata.resourceVersion',
  ])(machineTemplate)
  const updatedValues = {
    spec: {
      template: {
        spec: {
          ami: {
            id: values.amiId || machineTemplate?.spec?.template?.spec?.ami?.id,
          },
          instanceType: values.instanceType || machineTemplate?.spec?.template?.spec?.instanceType,
          publicIP: values.publicIP,
        },
      },
    },
  }
  return mergeDeepRight(currentMachineTemplate, updatedValues)
}

export default function EditAwsControlPlaneModal({
  open,
  onClose,
  controlPlane,
  machineTemplate,
  region,
}) {
  // TODO: Replace these with the useCapiResourceUpdate hook
  const {
    update: updateNodeletCp,
    updating: updatingNodeletCp,
    error: updateControlPlaneError,
  } = useUpdateAction(updateNodeletControlPlane)
  const {
    update: createMachineTemplate,
    updating: creatingMachineTemplate,
    error: updateMachineTemplateError,
  } = useUpdateAction(createAwsMachineTemplate)
  const { update: deleteMachineTemplate, updating: deletingMachineTemplate } = useUpdateAction(
    deleteAwsMachineTemplate,
  )

  const initialValues = useMemo(() => {
    return {
      replicas: controlPlane?.spec?.replicas,
      instanceType: machineTemplate?.spec?.template?.spec?.instanceType,
      region: region,
      amiId: machineTemplate?.spec?.template?.spec?.ami?.id,
      publicIP: machineTemplate?.spec?.template?.spec?.publicIP,
      dailyBackupsEnabled: !!controlPlane?.etcdDailyBackup,
      dailyBackupLocalPath:
        controlPlane?.etcdDailyBackup?.localPath || etcdBackupDefaults?.etcdStoragePath,
      dailyBackupTime:
        controlPlane?.etcdDailyBackup?.dailyBackupTime || etcdBackupDefaults?.etcdBackupTimestamp,
      dailyBackupCount:
        controlPlane?.etcdDailyBackup?.backupCount || etcdBackupDefaults?.maxTimestampBackups,
      intervalBackupsEnabled: !!controlPlane?.etcdIntervalBackup,
      intervalBackupLocalPath:
        controlPlane?.etcdIntervalBackup?.localPath || etcdBackupDefaults?.etcdStoragePath,
      intervalMins:
        controlPlane?.etcdIntervalBackup?.intervalMins ||
        etcdBackupDefaults?.etcdBackupIntervalMinutes,
      intervalHours:
        controlPlane?.etcdIntervalBackup?.intervalHours ||
        etcdBackupDefaults?.etcdBackupIntervalHours,
      intervalBackupCount:
        controlPlane?.etcdIntervalBackup?.backupCount || etcdBackupDefaults?.maxIntervalBackups,
      intervalBackupUnit: controlPlane?.etcdIntervalBackup?.intervalHours ? 'hours' : 'minutes',
    }
  }, [controlPlane, machineTemplate])

  const handleSubmit = async (values) => {
    let success = false

    // Update NodeletControlPlane
    const cpUpdateBody = getControlPlaneUpdatebody(values, controlPlane)
    const { success: updateCpSuccess } = await updateNodeletCp({
      namespace: controlPlane?.namespace,
      name: controlPlane?.name,
      body: cpUpdateBody,
      updateMethod: 'PATCH',
    })
    success = updateCpSuccess

    // Update AWSMachineTemplate. It's immutable so we must delete it
    // first and then create a new one
    const mtNamespace = machineTemplate?.metadata?.namespace
    const mtName = machineTemplate?.metadata?.name
    await deleteMachineTemplate({
      namespace: mtNamespace,
      name: mtName,
    })
    const mtCreateBody = getMachineTemplateCreateBody(values, machineTemplate)
    const { success: updateMtSuccess } = await createMachineTemplate({
      namespace: mtNamespace,
      name: mtName,
      body: mtCreateBody,
    })
    success = success && updateMtSuccess

    if (success) {
      onClose()
    }
  }

  const submitting = updatingNodeletCp || creatingMachineTemplate || deletingMachineTemplate
  const classes = useStyles()
  const errorComponent = useMemo(() => {
    const errors = [updateControlPlaneError, updateMachineTemplateError].filter(Boolean)
    return <CapiEditModalErrors errors={errors} />
  }, [updateControlPlaneError, updateMachineTemplateError])

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      title="Edit Control Plane"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitting={submitting}
      customErrorComponent={errorComponent}
    >
      {({ setFieldValue, values, setValues }) => {
        return (
          <>
            <FormFieldSection title="">
              <TextField
                id="replicas"
                label="Replica Count"
                type="number"
                min="1"
                step="2"
                required
              />
              {/* Intentionally commented as no backend support for now*/}
              {/* <AwsAmiField
                dropdownComponent={AwsAmiPicklist}
                label="Operating System"
                value={values.amiId}
                values={values}
                id="amiId"
                onChange={(value) =>
                  value !== 'custom'
                    ? setValues({
                        amiId: value?.id,
                        isCustomAmiId: false,
                        cloudInit: !value?.secretsManager ? cloudInit : null, // this additional config is needed if 'secretsManager:false'
                      })
                    : setValues({ isCustomAmiId: true })
                }
              />
              <TextField
                id="customAwsAmiId"
                label="Custom AMI ID (optional)"
                placeholder="Enter Value..."
                disabled={!values?.isCustomAmiId}
                onChange={(value) => setValues({ amiId: value, cloudInit: cloudInit })}
              />
              <BooleanPicklistField
                id="publicIP"
                label="Make Master Nodes Public"
                tooltip={publicMasterNodeFieldInfo}
                onChange={(value) => setFieldValue({ publicIP: value })}
              />*/}
              <Divider className={classes.divider} />
              <ToggleSwitchField
                id="dailyBackupsEnabled"
                label="Use Timestamp Backups"
                info="Enable automated etcd backups on this cluster daily at a specified time"
                value={values.dailyBackupsEnabled}
                onChange={setFieldValue('dailyBackupsEnabled')}
              />
              {values.dailyBackupsEnabled && (
                <>
                  <TextField
                    id="dailyBackupLocalPath"
                    label="Storage Path"
                    placeholder={defaultEtcBackupPath}
                    info="This is the disk path where the etcd backup data will be stored on each master node of this cluster"
                    required
                  />
                  <TextField
                    id="dailyBackupTime"
                    label="Daily Backup Time (00:00-23:59)"
                    maxLength={5}
                    placeholder="22:00"
                    validations={timestampValidations}
                    info="The time that a backup will be saved each day"
                    required
                  />
                  <TextField
                    id="dailyBackupCount"
                    label="Max Daily Backup Count"
                    type="number"
                    step="1"
                    min="1"
                    info="Maximum number of timestamp-based backups that will be saved"
                    validations={[backupCountValidation]}
                    required
                  />
                </>
              )}
              <Divider className={classes.divider} />
              <ToggleSwitchField
                id="intervalBackupsEnabled"
                label="Use Interval Backups"
                value={values.intervalBackupsEnabled}
                info="Enable automated etcd backups on this cluster based on time intervals"
                onChange={setFieldValue('intervalBackupsEnabled')}
              />
              {values.intervalBackupsEnabled && (
                <>
                  <TextField
                    id="intervalBackupLocalPath"
                    label="Storage Path"
                    placeholder={defaultEtcBackupPath}
                    info="This is the disk path where the etcd backup data will be stored on each master node of this cluster"
                    required
                  />
                  <PicklistField
                    DropdownComponent={IntervalBackupUnitPicklist}
                    id="intervalBackupUnit"
                    label="Interval Backup Unit"
                    required
                  />
                  {values.intervalBackupUnit === 'minutes' && (
                    <TextField
                      id="intervalMins"
                      label="Backup Interval in Minutes (10-59)"
                      type="number"
                      step="1"
                      min="1"
                      max="59"
                      info="A backup will be taken every specified number of minutes."
                      validations={[intervalMinutesValidation]}
                      required
                    />
                  )}
                  {values.intervalBackupUnit === 'hours' && (
                    <TextField
                      id="intervalHours"
                      label="Backup Interval in Hours (1-23)"
                      type="number"
                      step="1"
                      min="1"
                      max="23"
                      info="A backup will be taken every specified number of hours."
                      validations={[intervalHoursValidation]}
                      required
                    />
                  )}
                  <TextField
                    id="intervalBackupCount"
                    label="Max Interval Backup Count"
                    type="number"
                    step="1"
                    min="1"
                    info="Maximum number of interval-based backups that will be saved"
                    validations={[backupCountValidation]}
                    required
                  />
                </>
              )}
            </FormFieldSection>
          </>
        )
      }}
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: 400,
  },
}))
