import React, { useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import AwsCloudProviderVerification from './aws/AwsCloudProviderVerification'
import AzureCloudProviderVerification from './azure/AzureCloudProviderVerification'
import { emptyObj, switchCase, onlyDefinedValues, noop } from 'utils/fp'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AwsCloudProviderFields from './aws/AwsCloudProviderFields'
import AzureCloudProviderFields from './azure/AzureCloudProviderFields'
import { CloudProviders, ICloudProvidersSelector, CloudProviderRouteNames } from './model'
import DocumentMeta from 'core/components/DocumentMeta'
import { omit, pick, prop, propEq } from 'ramda'
import { routes } from 'core/utils/routes'
import useScopedPreferences from 'core/session/useScopedPreferences'
import GoogleCloudProviderVerification from './google/GoogleCloudProviderVerification'
import GoogleCloudProviderFields from './google/GoogleCloudProviderFields'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { isDecco } from 'core/utils/helpers'
import { RootState } from 'app/store'
import { UserPreferences } from 'app/constants'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listCloudProviders, updateCloudProvider } from './new-actions'
import { cloudProvidersSelector } from './selectors'
import { cloudProviderRouteNameMap } from './helpers'

const useStyles = makeStyles((theme: Theme) => ({
  updateCloudProvider: {
    marginTop: 24,
  },
  cpName: {
    fontSize: 21,
    fontWeight: 600,
    color: theme.palette.grey['700'],
    margin: theme.spacing(2, 0),
  },
  form: {
    maxWidth: '800px',
    flexGrow: 1,
  },
  updateFields: {
    padding: theme.spacing(2, 3),
    border: `1px solid ${theme.palette.grey['300']}`,
    marginTop: theme.spacing(2),
    color: theme.palette.grey['700'],
    display: 'flex',
    flexFlow: 'column wrap',
  },
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

const formCpBody = (data) => {
  // Do not accept empty strings for these properties
  // User may type and then delete the input before submitting
  if (data.type === CloudProviders.Aws) {
    return pick(['key', 'secret'], onlyDefinedValues(data))
  } else if (data.type === CloudProviders.Azure) {
    return pick(['clientId', 'clientSecret', 'tenantId', 'subscriptionId'], onlyDefinedValues(data))
  }
  return {}
}

export default function UpdateCloudProviderForm() {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const isDeccoEnv = isDecco(features)
  const { prefs, fetchUserDefaults, updateUserDefaults } = useScopedPreferences('defaults')
  const { id: cloudProviderId } = match.params

  const { message, loading } = useListAction(listCloudProviders)
  const cloudProviders = useSelector(cloudProvidersSelector)
  const cloudProvider = useMemo(
    () =>
      cloudProviders.find(propEq('uuid', cloudProviderId)) || (emptyObj as ICloudProvidersSelector),
    [cloudProviders, cloudProviderId],
  )
  const cloudDefaults = prefs?.[UserPreferences.CloudProviders]?.[cloudProvider?.uuid] || emptyObj
  const cloudProviderRouteName =
    cloudProviderRouteNameMap[cloudProvider.type] || CloudProviderRouteNames.Aws

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  const { update, updating, error, reset } = useUpdateAction(updateCloudProvider)

  const updatedInitialValues: ICloudProvidersSelector = useMemo(() => {
    if (!cloudProvider?.uuid) {
      return null
    }
    return {
      ...cloudProvider,
      cloudProviderId: cloudProvider.uuid,
    }
  }, [cloudProvider])

  const submitForm = async (data) => {
    const body = formCpBody({ ...cloudProvider, ...data })
    const { success } = await update({
      ...body,
      uuid: data.uuid || cloudProvider?.uuid,
    })
    if (success) handleClose()
  }

  const handleClose = () => {
    updateUserDefaults(
      UserPreferences.CloudProviders,
      omit([cloudProvider.uuid], cloudDefaults),
      true,
    )
    reset()
    history.push(routes.cloudProviders[cloudProviderRouteName].list.path())
  }

  const VerificationFields = useMemo(() => {
    return switchCase({
      [CloudProviders.Aws]: AwsCloudProviderVerification,
      [CloudProviders.Azure]: AzureCloudProviderVerification,
      [CloudProviders.Gcp]: GoogleCloudProviderVerification,
    })(cloudProvider.type)
  }, [cloudProvider.type])

  const UpdateForm = useMemo(() => {
    return switchCase({
      [CloudProviders.Aws]: AwsCloudProviderFields,
      [CloudProviders.Azure]: AzureCloudProviderFields,
      [CloudProviders.Gcp]: GoogleCloudProviderFields,
    })(cloudProvider.type)
  }, [cloudProvider.type])

  if (!cloudProvider?.uuid) {
    return null
  }

  return (
    <>
      <DocumentMeta title="Update Cloud Provider" bodyClasses={['form-view']} />
      {updatedInitialValues?.type && (
        <ModalForm
          route={routes.cloudProviders[cloudProviderRouteName].edit}
          title={`Update Cloud Provider / ${updatedInitialValues?.name}`}
          initialValues={updatedInitialValues as any}
          onBackdropClick={noop}
          onClose={handleClose}
          onSubmit={cloudProvider.type !== CloudProviders.Azure ? submitForm : undefined}
          submitting={updating}
          loading={loading}
          loadingMessage={message}
          error={error}
          submitTitle="Save"
        >
          {({ values, setValues }) => {
            return (
              <>
                <div className={classes.form}>
                  <UpdateForm
                    wizardContext={values}
                    setWizardContext={setValues}
                    showInfo={values.type === CloudProviders.Aws}
                    toggleIamPolicy
                    showSubmitInCard
                    updateWizard
                    setInitialValue
                  />
                  <VerificationFields
                    wizardContext={values}
                    setWizardContext={setValues}
                    updateUserDefaults={updateUserDefaults}
                    cloudDefaults={cloudDefaults}
                    showDefaultButtons={isDeccoEnv}
                  />
                </div>
              </>
            )
          }}
        </ModalForm>
      )}
    </>
  )
}
