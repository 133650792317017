/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import Modal from 'core/elements/modal'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import {
  getInstallTaskContent,
  getTaskIdxFeatures,
  getNodeKubeStatusData,
} from 'app/plugins/infrastructure/components/nodes/helpers'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Button from 'core/elements/button'

const handleViewLogs = (node) => {
  window.open(node.logs)
}

interface Props {
  node: INodesSelector
  isOpen: boolean
  onClose: () => void
}

export default function NodeTasksModal({ node, isOpen, onClose }: Props) {
  const classes = useStyles({})

  const nodeKubeStatusData = getNodeKubeStatusData(node)
  const nodeState = nodeKubeStatusData?.pf9_kube_node_state
  const allTasks = nodeKubeStatusData.all_tasks || []
  const completedTasks = nodeKubeStatusData.completed_tasks || []
  const lastFailedTask = nodeKubeStatusData.last_failed_task || null

  const { failedIdx, hasFailed } = getTaskIdxFeatures(allTasks, completedTasks, lastFailedTask)

  const completedTaskCount = failedIdx >= 0 ? failedIdx : completedTasks.length
  const tasksWithStatus = allTasks.map((task, index) =>
    getInstallTaskContent({
      task,
      completedTasks,
      nodeState,
      currentIdx: index,
      failedIdx,
    }),
  )
  if (!isOpen) return null
  return (
    <Modal
      title={node.name}
      info={`${completedTaskCount} of ${allTasks.length} steps complete`}
      onClose={onClose}
      open
      footer={
        <>
          {hasFailed && (
            <Button onClick={() => handleViewLogs(node)} variant="tertiary">
              View Logs
            </Button>
          )}
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
        </>
      }
    >
      <div className={classes.nodeTasksModal}>
        {tasksWithStatus.map((item) => (
          <TaskRow key={item.task} {...item} />
        ))}
      </div>
    </Modal>
  )
}

interface TaskRowProps {
  task: string
  currentIdx: number
  status: string
  icon: string
  isActive: boolean
  isCompleted: boolean
}
function TaskRow({ task, currentIdx, status, icon, isActive, isCompleted }: TaskRowProps) {
  const classes = useStyles({ status })
  return (
    <div className={classes.taskRow}>
      <Text
        variant="body1"
        className={clsx(classes.taskBubble, {
          first: currentIdx === 0,
          active: isActive,
          completed: isCompleted,
        })}
      >
        {!!icon ? <FontAwesomeIcon spin={icon === 'sync'}>{icon}</FontAwesomeIcon> : currentIdx + 1}
      </Text>
      <Text variant="body2" component="span" className="task-value">
        {task}
      </Text>
    </div>
  )
}

interface StyleProps {
  status: string
}
const useStyles = makeStyles<Theme, Partial<StyleProps>>((theme) => ({
  nodeTasksModal: {
    display: 'grid',
    alignItems: 'center',
    gridAutoRows: 'max-content',
    gap: 32,
  },
  taskRow: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'max-content 1fr',
    gap: 16,
  },
  taskBubble: {
    position: 'relative',
    width: 32,
    height: 32,
    fontWeight: 500,
    borderRadius: 100,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ status }) =>
      status === 'default'
        ? theme.components.typography.passive
        : theme.components.wizard.step.bubbleActiveText,
    backgroundColor: ({ status }) => theme.components.graph[status],
    border: ({ status }) => `1px solid ${theme.components.graph[status]}`,

    '&:not(.first):after': {
      content: '""',
      backgroundColor: ({ status }) => theme.components.graph[status],
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      transform: 'translate(-1px, 0px)',
      width: 2,
      height: 34,
      zIndex: -1,
    },
    '& i': {
      color: theme.components.wizard.step.bubbleActiveText,
      fontWeight: 600,
      fontSize: 16,
      width: '12px',
      height: '12px',
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))
