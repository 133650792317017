import React, { useMemo } from 'react'
import { pathStrOr, projectAs } from 'utils/fp'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { loadAwsRegionInfo } from '../../../../cloudProviders/new-actions'
import useListAction from 'core/hooks/useListAction'
import { awsRegionInfoSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import DataKeys from 'k8s/DataKeys'
import { useSelector } from 'react-redux'
import { propEq } from 'ramda'

// @todo fix these typings
interface Props {
  identityName: string
  roleArn: string
  region: string
  vpcId: string
}

export default function CapiAwsRouteTablePicklist({
  identityName,
  roleArn,
  region,
  vpcId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading } = useListAction<DataKeys.AwsRegionInfo>(loadAwsRegionInfo, {
    params: { identityName, region, roleArn },
  })
  const regionInfo = useSelector(awsRegionInfoSelector)

  const internetGateways = useMemo(() => {
    const vpc = pathStrOr([], '0.vpcs', regionInfo).find(propEq('VpcId', vpcId))
    return pathStrOr([], 'RouteTables', vpc)
  }, [regionInfo, vpcId])

  const options = useMemo((): { value: string; label: string }[] => {
    return projectAs({ value: 'RouteTableId', label: 'RouteTableName' }, internetGateways)
  }, [internetGateways])

  return <AsyncDropdown {...rest} loading={loading} items={options} />
}
