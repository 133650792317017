import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import AddonConfigurationsModal from 'app/plugins/infrastructure/components/clusters/cluster-addons/edit-cluster-page/AddonConfigurationsModal'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createClusterAddon } from '../../../cluster-addons/new-actions'
import { getDefaultOverrideParams } from '../../helpers'

export default function EnableAddonButton({
  className,
  addon,
  cluster,
  addonVersions,
  allAddons,
  toggleMenu,
}) {
  const [showDialog, setShowDialog] = useState(false)

  const {
    update: enableAddon,
    updating: enablingAddon,
    error: errorEnablingAddon,
  } = useUpdateAction(createClusterAddon)

  const handleClick = useCallback(() => {
    if (enablingAddon) {
      return
    }
    if (addon.isConfigurable) {
      setShowDialog(true)
      toggleMenu()
    } else {
      const overrideParams = getDefaultOverrideParams(addon?.type, cluster)
      enableAddon({
        clusterId: cluster?.uuid,
        clusterName: cluster?.name,
        clusterType: cluster?.clusterType,
        namespace: cluster?.namespace,
        type: addon?.type,
        version: addon?.version,
        overrideParams,
      })
      toggleMenu()
    }
  }, [addon, cluster, setShowDialog, enablingAddon, toggleMenu])

  return (
    <>
      <Text className={className} variant="body2" onClick={handleClick}>
        Enable
      </Text>
      {showDialog && (
        <AddonConfigurationsModal
          addon={addon}
          open
          onClose={() => setShowDialog(false)}
          action="Enable"
          cluster={cluster}
          existingClusterAddons={allAddons}
          currentAddonVersions={addonVersions}
        />
      )}
    </>
  )
}
