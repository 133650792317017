import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import { createSelector } from '@reduxjs/toolkit'
import getDataSelector from 'core/utils/getDataSelector'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { IMachineDeploymentSelector, MachineDeployment } from './model'
import { NodeGroupTypeLabels } from '../model'
import { machinePoolSelector } from '../machine-pool/selectors'
import { allConfigSelector } from '../configs/selectors'
import { IAwsMachineTemplateSelector } from '../aws-machine-templates/model'
import { assocPath, find, filter, pipe } from 'ramda'
import { IConfigSelector } from '../configs/model'
import { awsMachineTemplatesSelector } from '../aws-machine-templates/selectors'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { NodeUpdateStrategyTypes } from '../../aws/capi/CapiMachineDeploymentNodeUpdateStrategies'

const minNumWorkersAnnotationLabel = 'cluster.x-k8s.io/cluster-api-autoscaler-node-group-min-size'
const maxNumWorkersAnnotationLabel = 'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size'

export const machineDeploymentSelector: AppSelector<IMachineDeploymentSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.MachineDeployments>(DataKeys.MachineDeployments),
  awsMachineTemplatesSelector,
  allConfigSelector,
  (machineDeployments, awsMachineTemplates, configs): IMachineDeploymentSelector[] =>
    machineDeployments.map((md: MachineDeployment) => {
      const name = md.metadata?.name

      const machineTemplate = find(
        (template: IAwsMachineTemplateSelector) =>
          template.name === md.spec?.template?.spec?.infrastructureRef?.name,
        awsMachineTemplates,
      )

      const config = find(
        (config: IConfigSelector) =>
          config.name === md.spec?.template?.spec?.bootstrap?.configRef?.name,
        configs,
      )

      // Autoscaling fields
      const annotations = md.metadata?.annotations
      const minWorkers = annotations?.[minNumWorkersAnnotationLabel]
      const maxWorkers = annotations?.[maxNumWorkersAnnotationLabel]

      const rollingUpdate = md.spec?.strategy?.rollingUpdate
      const maxSurge = rollingUpdate?.maxSurge
      const maxSurgeType =
        typeof maxSurge === 'string'
          ? NodeUpdateStrategyTypes.Percentage
          : NodeUpdateStrategyTypes.Count
      const maxUnavailable = rollingUpdate?.maxUnavailable
      const maxUnavailableType =
        typeof maxUnavailable === 'string'
          ? NodeUpdateStrategyTypes.Percentage
          : NodeUpdateStrategyTypes.Count

      return {
        ...md,
        uuid: md.metadata?.uid,
        name,
        namespace: md.metadata?.namespace,
        clusterName: md.spec?.clusterName,
        type: NodeGroupTypeLabels.MachineDeployment,
        creationTimestamp: md.metadata?.creationTimestamp,
        k8sVersion: md.spec?.template?.spec?.version,
        phase: md.status?.phase,
        replicas: md.spec?.replicas,
        readyReplicas: md.status?.readyReplicas,
        availableReplicas: md.status?.availableReplicas,
        unavailableReplicas: md.status?.unavailableReplicas,
        autoscalingEnabled: !!minWorkers || !!maxWorkers,
        annotations,
        minCasSize: annotations?.[minNumWorkersAnnotationLabel],
        maxCasSize: annotations?.[maxNumWorkersAnnotationLabel],
        updateStrategy: md?.spec?.strategy.type,
        rollingUpdate,
        maxSurge,
        maxSurgeType,
        maxUnavailable,
        maxUnavailableType,
        infrastructureRef: md.spec?.template?.spec?.infrastructureRef,
        configRef: md.spec?.template?.spec?.bootstrap?.configRef,
        machineTemplate: machineTemplate,
        config: config,
        resources: [md, ...(machineTemplate?.resources || []), ...(config?.resources || [])].filter(
          (r) => !isNilOrEmpty(r),
        ),
        numNodes: md.status?.readyReplicas,
      }
    }),
)

type MachineDeploymentsByNamespaceAndNameSelectorModel = {
  [namespace: string]: {
    [clusterName: string]: IMachineDeploymentSelector[]
  }
}

export const machineDeploymentsByNamespaceAndClusterSelector = createSharedSelector(
  machineDeploymentSelector,
  (machineDeployments): MachineDeploymentsByNamespaceAndNameSelectorModel =>
    machineDeployments.reduce((accum, md) => {
      const { namespace, clusterName } = md
      const machineDeployments = accum[namespace]?.[clusterName] || []
      machineDeployments.push(md)
      return assocPath([namespace, clusterName], machineDeployments, accum)
    }, emptyObj),
)

export const makeFilteredMachineDeploymentsSelector = (
  defaultParams = {} as { clusterName: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(machineDeploymentSelector, selectParams, (machineDeployments, params) => {
    const { clusterName } = params
    const filterByClusterName = filter<IMachineDeploymentSelector>((md) => {
      return md.clusterName === clusterName
    })
    return pipe<IMachineDeploymentSelector[], IMachineDeploymentSelector[]>(filterByClusterName)(
      machineDeployments,
    )
  })
}

export const allNodeGroupsSelector = createSharedSelector(
  machineDeploymentSelector,
  machinePoolSelector,
  (machineDeployments, machinePools) => {
    return [...machineDeployments, ...machinePools]
  },
)
