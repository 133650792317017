import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

export const awsManagedMachinePoolActions = ActionsSet.make<DataKeys.AwsManagedMachinePools>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.AwsManagedMachinePools],
  cacheKey: DataKeys.AwsManagedMachinePools,
})

export const listAwsManagedMachinePools = awsManagedMachinePoolActions.add(
  new ListAction<DataKeys.AwsManagedMachinePools>(
    async ({ clusterName }: { clusterName: string }) => {
      Bugsnag.leaveBreadcrumb('Attempting to get AWS Managed Machine Pools', { clusterName })
      return sunpike.getAwsManagedMachinePools(clusterName)
    },
  ),
)

export const updateAwsManagedMachinePool = awsManagedMachinePoolActions.add(
  new UpdateAction<DataKeys.AwsManagedMachinePools, { name; namespace; body }>(
    async ({ name, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update AWS Managed Machine Pools', {
        name,
        namespace,
        body,
      })
      return sunpike.updateAwsManagedMachinePool(body)
    },
  ),
)
