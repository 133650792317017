import React from 'react'
import { IconInfo } from 'core/components/validatedForm/Info'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gridGap: 16,
  },
}))

const EcoWarning = () => {
  const classes = useStyles({})

  return (
    <IconInfo title="Install ECO to Complete Import" icon="exclamation-triangle" spacer={false}>
      <div className={classes.container}>
        <Text variant="body2">
          <b>External Cluster Operator (ECO)</b>: Once an external cluster has been successfully
          imported, install ECO by clicking Edit Cluster from the Imported Clusters dashboard.
        </Text>
        <Text variant="body2">
          ECO must be installed using kubectl and the supplied YAML for each cluster.
        </Text>
      </div>
    </IconInfo>
  )
}

export default EcoWarning
