import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import CreateAction from 'core/actions/CreateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

export const awsMachineTemplateActions = ActionsSet.make<DataKeys.AwsMachineTemplates>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.AwsMachineTemplates],
  cacheKey: DataKeys.AwsMachineTemplates,
})

export const listAwsMachineTemplates = awsMachineTemplateActions.add(
  new ListAction<DataKeys.AwsMachineTemplates>(async ({ clusterName }: { clusterName: string }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get AWS Machine Templates', { clusterName })
    return sunpike.getAwsMachineTemplates(clusterName)
  }),
)

export const createAwsMachineTemplate = awsMachineTemplateActions.add(
  new CreateAction<
    DataKeys.AwsMachineTemplates,
    { namespace: string; name: string; body: unknown }
  >(async ({ namespace, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create AWS Machine Template', {
      namespace,
      name,
      body,
    })
    return sunpike.createAwsMachineTemplate(body, namespace)
  }),
)

export const deleteAwsMachineTemplate = awsMachineTemplateActions.add(
  new DeleteAction<DataKeys.AwsMachineTemplates, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete AWS Machine Template', { namespace, name })
      await sunpike.deleteAwsMachineTemplate(namespace, name)
    },
  ),
)
