import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import Stepper from './Stepper'
import ClusterStatusSpan from '../../ClusterStatusSpan'
import Accordion from 'core/components/accordion/Accordion'
import { ClusterUpgradeSteps } from './model'
import { getControlPlaneStatus } from './helpers'
import { switchCase } from 'utils/fp'

const StepHeader = ({ resourceType, upgradeProgressCount = '' }) => {
  const classes = useStyles()
  return (
    <div className={classes.headerContainer}>
      <Text variant="h4">
        {resourceType}
        <span className={classes.upgradeText}> Upgrade</span>
      </Text>
      {upgradeProgressCount && <Text variant="body2">{upgradeProgressCount}</Text>}
    </div>
  )
}

const ControlPlaneStepProgress = ({ status, severity }) => {
  const classes = useStyles()
  const controlPlaneStatus = getControlPlaneStatus(status, severity)
  return (
    <>
      <StepHeader resourceType="Control Plane" />
      <div className={classes.tableRow}>
        <ClusterStatusSpan
          status={controlPlaneStatus}
          iconStatus={!!status && status !== 'Unknown'}
        >
          Control Plane
        </ClusterStatusSpan>
      </div>
    </>
  )
}

const nodeGroupUpgradeStatusMap = {
  Unknown: 'unknown',
  NotStarted: 'unknown',
  InProgress: 'loading',
  Completed: 'ok',
  Failed: 'fail',
}

const nodeGroupUpgradeSuccessful = (nodeGroup) =>
  nodeGroup?.phase === 'Completed' ||
  (nodeGroup?.completionTime && nodeGroup?.phase === 'InProgress')

const getNodeGroupStatus = (nodeGroup) => {
  if (!nodeGroup?.phase) return 'unknown'
  if (nodeGroupUpgradeSuccessful(nodeGroup)) return 'ok'
  return nodeGroupUpgradeStatusMap[nodeGroup?.phase]
}

const NodeGroupsStepProgress = ({ nodeGroups = [] }) => {
  const classes = useStyles()
  const numCompleted = nodeGroups.filter(nodeGroupUpgradeSuccessful).length
  const total = (nodeGroups || []).length
  const upgradeProgressCount = `${numCompleted} of ${total} completed`
  return (
    <>
      <StepHeader resourceType="Worker Node Groups" upgradeProgressCount={upgradeProgressCount} />
      {nodeGroups.map((nodeGroup) => {
        const { kind, name, startTime, completionTime, phase } = nodeGroup
        return (
          <Accordion
            id={name}
            key={name}
            title={
              <div className={classes.accordianRow}>
                <ClusterStatusSpan
                  status={getNodeGroupStatus(nodeGroup)}
                  iconStatus={!!phase && phase !== 'Unknown' && phase !== 'NotStarted'}
                  className={classes.nodeGroupStatus}
                >
                  {name}
                </ClusterStatusSpan>
                <Text variant="body2">{kind}</Text>
              </div>
            }
            className={classes.accordian}
          >
            <div className={classes.nodeGroupAccordianContent}>
              <div className={classes.startTime}>
                <Text variant="caption1">Start Time</Text>
                <Text variant="body2">{startTime || 'N/A'}</Text>
              </div>
              <div>
                <Text variant="caption1">Completion Time</Text>
                <Text variant="body2">{completionTime || 'N/A'}</Text>
              </div>
            </div>
          </Accordion>
        )
      })}
    </>
  )
}

const getAddonStatus = (status) =>
  switchCase(
    {
      Unknown: 'unknown',
      True: 'ok',
      False: 'fail',
    },
    'unknown',
  )(status)

const AddonsStepProgress = ({ status }) => {
  const classes = useStyles()
  const addonsStatus = getAddonStatus(status)
  return (
    <>
      <StepHeader resourceType="Add-ons" />
      <div className={classes.tableRow}>
        <ClusterStatusSpan status={addonsStatus} iconStatus={!!status && status !== 'Unknown'}>
          Add-ons
        </ClusterStatusSpan>
      </div>
    </>
  )
}

export default function CapiClusterUpgradeProgressTracker({ clusterUpgradeJob }) {
  const {
    controlPlaneCondition,
    nodeGroups,
    nodeGroupsCondition,
    addonsCondition,
    completedSteps,
  } = clusterUpgradeJob || {}
  const upgradeSteps = [
    {
      stepId: ClusterUpgradeSteps.ControlPlane,
      content: (
        <ControlPlaneStepProgress
          status={controlPlaneCondition?.status}
          severity={controlPlaneCondition?.severity}
        />
      ),
      failed:
        getControlPlaneStatus(controlPlaneCondition?.status, controlPlaneCondition?.severity) ===
        'fail',
    },
    {
      stepId: ClusterUpgradeSteps.WorkerNodeGroups,
      content: <NodeGroupsStepProgress nodeGroups={nodeGroups} />,
      failed:
        nodeGroupsCondition?.status === 'False' && nodeGroupsCondition?.reason !== 'InProgress',
    },
    {
      stepId: ClusterUpgradeSteps.Addons,
      content: <AddonsStepProgress status={addonsCondition?.status} />,
      failed:
        nodeGroupsCondition?.status === 'False' && nodeGroupsCondition?.reason !== 'InProgress',
    },
  ]
  return <Stepper steps={upgradeSteps} completedSteps={completedSteps} />
}

const useStyles = makeStyles<Theme>((theme) => ({
  headerContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '614px',
    padding: '12px 16px',
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    backgroundColor: theme.components.accordion.background,
    borderColor: theme.components.card.border,
  },
  upgradeText: {
    fontWeight: 'normal',
  },
  tableRow: {
    display: 'grid',
    border: `1px solid ${theme.components.card.border}`,
    boxSizing: 'border-box',
    padding: '12px',
    '& > div': {
      marginLeft: theme.spacing(2),
    },
  },
  accordianRow: {
    display: 'grid',
    gridTemplateColumns: '296px 185px',
    gap: theme.spacing(2),
    padding: '12px',
    width: '100%',
  },
  nodeGroupStatus: {
    whiteSpace: 'break-spaces',
  },
  accordian: {
    '& .accordionTopBar': {
      backgroundColor: 'unset',
      borderTop: 'unset',
      border: `1px solid ${theme.components.card.border}`,
    },
    '& .accordianContent': {
      backgroundColor: theme.components.card.activeBackground,
    },
  },
  nodeGroupAccordianContent: {
    display: 'grid',
    gridTemplateColumns: '303px 185px',
    gap: theme.spacing(2),
    padding: '12px',
    marginLeft: '10px',
  },
  startTime: {
    marginLeft: '32px',
  },
}))
