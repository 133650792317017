import Accordion from 'core/components/accordion/Accordion'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { take } from 'ramda'
import React from 'react'

const networkingColumns = [
  { id: 'httpProxy', label: 'HTTP Proxy' },
  {
    id: 'useRoute53',
    label: 'Use Route53 for Cluster Access',
    render: (value) => (value ? 'True' : 'False'),
  },
  { id: 'route53.apiFqdn', label: 'API FQDN', hide: (data) => !data?.useRoute53 },
  { id: 'route53.serviceFqdn', label: 'Service FQDN', hide: (data) => !data?.useRoute53 },
]
let clusterCniColumns = [
  { id: 'networkPlugin', label: 'Network Backend' },
  {
    id: 'calico.IPIPMode',
    label: 'IP in IP Encapsulation Mode',
  },
  {
    id: 'calico.IPv4DetectionMethod',
    label: 'Interface Detection Method',
    hide: (data) => !data?.calico,
  },
  {
    id: 'calico.natOutgoing',
    label: 'NAT Outgoing',
    render: (value) => (value ? 'True' : 'False'),
    hide: (data) => !data?.calico,
  },
  { id: 'calico.IPv4BlkSize', label: 'Block Size', hide: (data) => !data?.calico },
  { id: 'calico.MTUSize', label: 'MTU Size', hide: (data) => !data?.calico },
]

export default function CapiNetworkingReview({ wizardContext, open }) {
  if (wizardContext['networkPlugin'] !== 'calico') {
    clusterCniColumns = take(1, clusterCniColumns)
  }
  return (
    <>
      <Accordion open={open} id="networking" title="Networking">
        <FormReviewTable data={wizardContext} columns={networkingColumns} />
      </Accordion>
      <Accordion id="clusterCni" title="Cluster CNI">
        <FormReviewTable data={wizardContext} columns={clusterCniColumns} />
      </Accordion>
    </>
  )
}
