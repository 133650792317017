import StackedAreaChart from 'core/components/graphs/StackedAreaChart'
import moment from 'moment'
import { identity } from 'ramda'
import React from 'react'

const generateParsedGraphFields = (data = [], name, valueTransformer = identity) =>
  data.map(([ts, value]) => ({
    time: moment.unix(ts).format('hA'),
    [name]: valueTransformer(value),
  }))

export default function ResourceUsageGraph({ data, name, valueTransformer = undefined }) {
  const fields = generateParsedGraphFields(data, name, valueTransformer)
  return (
    <StackedAreaChart
      values={fields}
      keys={[{ name, color: 'blue.300' }]}
      xAxis="time"
      responsiveHeight={95}
      horizontalAxisLines={false}
      responsive
    />
  )
}
