import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ClusterApiServerHealthStatus } from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import React from 'react'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'

export default function ApiServerHealthCell({
  item: cluster,
}: GridCellProps<CombinedClusterSelector>) {
  return <ClusterApiServerHealthStatus cluster={cluster} />
}
