import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { IAwsManagedMachinePoolSelector, AwsManagedMachinePool } from './model'
import { NodeGroupTypeLabels } from '../model'
import { Phase } from '../machine-deployment/model'
import { createSelector } from '@reduxjs/toolkit'
import { filter, pipe, find } from 'ramda'
import { NodeUpdateStrategyTypes } from '../../aws/capi/CapiMachineDeploymentNodeUpdateStrategies'
import { MachinePool } from '../machine-pool/model'

export const awsManagedMachinePoolSelector: AppSelector<IAwsManagedMachinePoolSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.AwsManagedMachinePools>(DataKeys.AwsManagedMachinePools),
  getDataSelector<DataKeys.MachinePools>(DataKeys.MachinePools),
  (awsManagedMachinePools, machinePools): IAwsManagedMachinePoolSelector[] =>
    awsManagedMachinePools.map((mp: AwsManagedMachinePool) => {
      const name = mp.metadata?.name
      const maxUnavailable = mp.spec?.updateConfig?.maxUnavailable

      // NOTE: maxUnavailablePrecentage is not a typo. It's the API that misspelled percentage
      const maxUnavailablePrecentage = mp.spec?.updateConfig?.maxUnavailablePrecentage
      const maxUnavailableType = !!maxUnavailable
        ? NodeUpdateStrategyTypes.Count
        : NodeUpdateStrategyTypes.Percentage

      // Resource with kind = MachinePool. AWSManagedMachinePool is a type of MachinePool
      const machinePool = find(
        (machinePool: MachinePool) => machinePool.metadata?.name === name,
        machinePools,
      )

      return {
        ...mp,
        uuid: mp.metadata?.uid,
        name: mp.metadata?.name,
        namespace: mp.metadata?.namespace,
        clusterName: mp.metadata?.labels?.['cluster.x-k8s.io/cluster-name'],
        type: NodeGroupTypeLabels.AWSManagedMachinePool,
        creationTimestamp: mp.metadata?.creationTimestamp,
        availabilityZones: mp.spec?.availabilityZones,
        scaling: mp.spec?.scaling,
        instanceType: mp.spec?.instanceType,
        updateConfig: mp.spec?.updateConfig,
        capacityType: mp.spec?.capacityType,
        amiType: mp.spec?.amiType,
        amiVersion: mp.spec?.amiVersion,
        ready: mp.status?.ready,
        failureMessage: mp.status?.failureMessage,
        phase: mp.status?.ready ? Phase.Running : Phase.Failed,
        resources: [mp],
        maxUnavailableType,
        maxUnavailable,
        maxUnavailablePrecentage,
        numNodes: machinePool?.status?.readyReplicas,
      }
    }),
)

export const makeFilteredAwsManagedMachinePoolsSelector = (
  defaultParams = {} as { clusterName: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(awsManagedMachinePoolSelector, selectParams, (machinePools, params) => {
    const { clusterName } = params
    const filterByClusterName = filter<IAwsManagedMachinePoolSelector>((mp) => {
      return mp.clusterName === clusterName
    })
    return pipe<IAwsManagedMachinePoolSelector[], IAwsManagedMachinePoolSelector[]>(
      filterByClusterName,
    )(machinePools)
  })
}
