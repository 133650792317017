import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const items = [
  { key: 'Warning', label: 'Warning', value: 'Warning' },
  { key: 'Normal', label: 'Normal', value: 'Normal' },
]

interface Props {}

export default function EventsTypePicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name="type" label="Type" compact showAll items={items} {...props} />
}
