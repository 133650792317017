import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { sunpike } = ApiClient.getInstance()

export const machineDeploymentActions = ActionsSet.make<DataKeys.MachineDeployments>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.MachineDeployments],
  cacheKey: DataKeys.MachineDeployments,
})

export const listMachineDeployments = machineDeploymentActions.add(
  new ListAction<DataKeys.MachineDeployments>(async ({ clusterName }: { clusterName: string }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Machine Deployments', { clusterName })
    return sunpike.getMachineDeployments(clusterName)
  }),
)

export const updateMachineDeployment = machineDeploymentActions.add(
  new UpdateAction<DataKeys.MachineDeployments, { namespace: string; name: string; body: unknown }>(
    async ({ namespace, name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update Machine Deployment', { namespace, name, body })
      return sunpike.updateMachineDeployment(namespace, name, body)
    },
  ),
)

export const deleteMachineDeployment = machineDeploymentActions.add(
  new DeleteAction<DataKeys.MachineDeployments, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Machine Deployment', { namespace, name })
      await sunpike.deleteMachineDeployment(namespace, name)
    },
  ),
)
