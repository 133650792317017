import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import { pathStr } from 'utils/fp'
import { IClusterAddon } from '../../cluster-addons/model'
import { IClusterUpgradeJobSelector } from './model'
import { createClusterUpgradeJobBody } from './action-helpers'
import { IAwsMachinePoolSelector } from '../aws-machine-pool/model'
import { IMachineDeploymentSelector } from '../machine-deployment/model'
import { EksAddons } from '../control-plane/aws-managed-control-plane/model'

const { sunpike } = ApiClient.getInstance()

export const clusterUpgradeJobActions = ActionsSet.make<DataKeys.ClusterUpgradeJobs>({
  uniqueIdentifier: 'metadata.name',
  entityName: entityNamesByKey[DataKeys.ClusterUpgradeJobs],
  cacheKey: DataKeys.ClusterUpgradeJobs,
})

export const listClusterUpgradeJobs = clusterUpgradeJobActions.add(
  new ListAction<DataKeys.ClusterUpgradeJobs>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get all Cluster Upgrade Jobs')
    return sunpike.getAllClusterUpgradeJobs()
  }),
)

export const createClusterUpgradeJob = clusterUpgradeJobActions.add(
  new CreateAction<
    DataKeys.ClusterUpgradeJobs,
    {
      namespace: string
      clusterName: string
      addons: IClusterAddon[]
      eksAddons?: EksAddons[]
      nodeGroups: Array<
        IMachineDeploymentSelector | IAwsMachinePoolSelector | IAwsMachinePoolSelector
      >
      upgradeJobs: IClusterUpgradeJobSelector[]
      targetVersion: string
      sourceVersion: string
    }
  >(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to create Cluster Upgrade Job', params)
    const body = createClusterUpgradeJobBody(params)
    const resource = await sunpike.createClusterUpgradeJob(body, params.namespace)
    trackEvent('Create Cluster Update Job', {
      name: pathStr('metadata.name', resource),
      namespace: pathStr('metadata.namespace', resource),
      clusterName: pathStr('spec.clusterName', resource),
    })
    return resource
  }),
)
