import React, { useMemo } from 'react'
import Card from 'core/elements/card'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createClusterAddon } from '../new-actions'
import { AddonName } from './ClusterAddonCard'
import { addonTypeToNameMap } from '../helpers'
import { ClusterAddonType } from '../model'

const enableText = {
  [ClusterAddonType.Kubevirt]: 'The Luigi add-on must be enabled first.',
  [ClusterAddonType.Metal3]: 'The Metal LB add-on must be enabled first.',
}

export default function DisabledClusterAddonCard({
  addon,
  handleEnableClick,
  existingClusterAddons,
  currentAddonVersions,
  cluster,
}) {
  const classes = useStyles()
  const { clusterId, type } = addon
  const version = currentAddonVersions[addonTypeToNameMap[type]]
  const { update: enableAddon, updating: enablingAddon } = useUpdateAction(createClusterAddon)

  const cannotBeEnabled = useMemo(() => {
    if (type === ClusterAddonType.Kubevirt) {
      const luigiIsEnabled = !!existingClusterAddons.find(
        (addon) => addon.type === ClusterAddonType.Luigi && addon.isEnabled,
      )
      return !luigiIsEnabled ? true : false
    }
    if (type === ClusterAddonType.Metal3) {
      const metalLbIsEnabled = !!existingClusterAddons.find(
        (addon) => addon.type === ClusterAddonType.MetalLb && addon.isEnabled,
      )
      return !metalLbIsEnabled ? true : false
    }
    return false
  }, [type, existingClusterAddons])

  const handleEnableAddon = () => {
    if (addon.isConfigurable) {
      handleEnableClick && handleEnableClick(addon)
    } else {
      enableAddon({
        clusterId,
        type,
        version,
        overrideParams: {},
      })
    }
  }

  return (
    <Card className={classes.card}>
      <div className={classes.cardContent}>
        <div>
          <AddonName addonType={type} />
          <Text variant="caption1" className={classes.version}>
            {version}
          </Text>
        </div>
        <Button
          onClick={handleEnableAddon}
          loading={enablingAddon}
          disabled={cannotBeEnabled}
          info={cannotBeEnabled ? enableText[type] : null}
        >
          Enable
        </Button>
      </div>
    </Card>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    maxHeight: '80px',
  },
  cardContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 95px',
  },
  version: {
    color: theme.palette.grey['500'],
  },
}))
