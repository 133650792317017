import React, { useMemo } from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

export default function TaintEffectsPicklist({ id, isAwsManagedMachinePool = false, ...rest }) {
  const classes = useStyles()
  const options = useMemo(
    () => [
      { label: 'NoSchedule', value: isAwsManagedMachinePool ? 'no-schedule' : 'NoSchedule' },
      {
        label: 'PreferNoSchedule',
        value: isAwsManagedMachinePool ? 'no-execute' : 'PreferNoSchedule',
      },
      { label: 'NoExecute', value: isAwsManagedMachinePool ? 'prefer-no-schedule' : 'NoExecute' },
    ],
    [isAwsManagedMachinePool],
  )
  return (
    <DropdownField
      {...rest}
      id={id}
      DropdownComponent={Dropdown}
      items={options}
      className={classes.classes}
    />
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  classes: {
    maxWidth: 175,
  },
}))
