import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import CloudProviderPicklist from 'k8s/components/common/CloudProviderPicklist'

const CloudProviderField = ({
  cloudProviderType,
  wizardContext,
  setWizardContext,
  onChange,
  ...rest
}) => {
  const defaultOnChange = (value) => setWizardContext({ cloudProviderId: value })
  return (
    <PicklistField
      id="cloudProviderId"
      DropdownComponent={CloudProviderPicklist}
      type={cloudProviderType}
      value={wizardContext.cloudProviderId}
      onChange={onChange || defaultOnChange}
      required
      {...rest}
    />
  )
}

export default CloudProviderField
