import React from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'

const runtimeConfigOptions = [
  { label: 'Default API groups and versions', value: 'default' },
  { label: 'All API groups and versions', value: 'all' },
  { label: 'Custom', value: 'custom' },
]

const AdvancedApiConfigFields = ({ options = runtimeConfigOptions, values, setWizardContext }) => (
  <>
    <DropdownField
      DropdownComponent={AsyncDropdown}
      id="runtimeConfigOption"
      label="Advanced API Configuration"
      items={options}
      tooltip="Make sure you are familiar with the Kubernetes API configuration documentation before enabling this option."
      onChange={(value) => setWizardContext({ runtimeConfigOption: value })}
      required
    />

    {values.runtimeConfigOption === 'custom' && (
      <TextField
        id="customRuntimeConfig"
        label="API Server Runtime Config"
        info={
          <>
            A set of key=value pairs that enable or disable built-in APIs. Supported options are:
            <br />
            v1=true|false for the core API group
            <br />
            &lt;group&gt;/&lt;version&gt;=true|false for a specific API group and version (e.g.
            apps/v1=true)
            <br />
            api/all=true|false controls all API versions
            <br />
            api/ga=true|false controls all API versions of the form v[0-9]+
            <br />
            api/beta=true|false controls all API versions of the form v[0-9]+beta[0-9]+
            <br />
            api/alpha=true|false controls all API versions of the form v[0-9]+alpha[0-9]+
            <br />
            api/legacy is deprecated, and will be removed in a future version
          </>
        }
        onChange={(value) => setWizardContext({ customRuntimeConfig: value })}
      />
    )}
  </>
)

export default AdvancedApiConfigFields
