import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import React, { useMemo, useEffect, useRef, useState, useCallback } from 'react'
import jsYaml from 'js-yaml'
import Text from 'core/elements/Text'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

import Button from 'core/elements/button'
import EditYamlDialog from './EditYamlDialog'
import Alert from 'core/components/Alert'
import { defaultCodeMirrorYamlValidations } from 'app/plugins/infrastructure/components/clusters/capi/details/helpers'

const CardHeader = ({ kind, showYaml, toggleYamlCardView, enableEdit, handleEditChange }) => {
  const classes = useStyles({})

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <Text variant="body2">
          <b>{kind}</b>
          {' YAML'}
        </Text>
        <FontAwesomeIcon solid size="xs" className={classes.icon} onClick={toggleYamlCardView}>
          {showYaml ? 'chevron-down' : 'chevron-right'}
        </FontAwesomeIcon>
      </div>
      <ToggleSwitch
        className={classes.switch}
        active={enableEdit}
        onClick={handleEditChange}
        label="Enable Edit"
      />
    </div>
  )
}

interface UpdateProps {
  namespace: string
  kind: string
  name: string
  updatedYaml: string
}
interface BaseYamlProps {
  kind: string
  yaml: string
  updateYamlFn?: (props: UpdateProps) => Promise<{ success: boolean; error?: any; result?: any }>
  yamlValidations?: any[]
  open?: boolean
  loading?: boolean
}

export default function YamlCard({
  kind,
  yaml: _yaml,
  updateYamlFn,
  yamlValidations = defaultCodeMirrorYamlValidations,
  open = true,
  loading = false,
}: BaseYamlProps) {
  const [yaml, setYaml] = useState(null)
  const [showYaml, setShowYaml] = useState(open)
  const [enableEdit, setEnableEdit] = useState(false)
  const [height, setHeight] = useState(0)
  const [showEditModal, setShowEditModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const content = useRef(null)
  const classes = useStyles({ showYaml, height })
  const formFieldSetter = useRef(null)
  const fieldSetter = (setField) => {
    formFieldSetter.current = { setField }
  }

  useEffect(() => {
    if (_yaml === null) return
    formFieldSetter.current?.setField('yaml')(_yaml)
    setYaml(_yaml)
  }, [_yaml])

  useEffect(() => {
    // We need to set the height a little larger than the scrollHeight to accomodate any
    // error messages in the codemirror component
    setHeight(showYaml ? content.current?.scrollHeight + 100 : 0)
  }, [showYaml])

  const resetYaml = useCallback(() => {
    formFieldSetter.current.setField('yaml')(yaml)
  }, [yaml])

  const toggleEdit = useCallback(() => {
    if (enableEdit) {
      setEnableEdit(false)
      resetYaml()
    } else {
      setShowEditModal(true)
    }
  }, [enableEdit, setEnableEdit, setShowEditModal, resetYaml])

  const toggleYamlCardView = useCallback(() => {
    if (showYaml) {
      setEnableEdit(false)
    }
    setShowYaml(!showYaml)
  }, [showYaml, enableEdit])

  const titleComponent = useMemo(
    () => (
      <CardHeader
        kind={kind}
        showYaml={showYaml}
        toggleYamlCardView={toggleYamlCardView}
        enableEdit={enableEdit}
        handleEditChange={toggleEdit}
      />
    ),
    [kind, showYaml, toggleYamlCardView, enableEdit, toggleEdit],
  )

  const codeMirrorOptions = useMemo(() => {
    if (!enableEdit && errorMessage) {
      setErrorMessage(null)
      resetYaml()
    }
    return {
      readOnly: enableEdit ? false : true,
      // When the YAML is in read only mode, we do not want to show the cursor when
      // when the user clicks into it. If we do readOnly: 'nocursor', then the user
      // is not allowed to copy the text. To fix this, we just modify the cursorHeight
      // and set readOnly: true
      cursorHeight: enableEdit ? 1 : 0,
    }
  }, [enableEdit, errorMessage])

  const closeModal = useCallback(() => {
    setShowEditModal(false)
  }, [enableEdit, setShowEditModal])

  const handleEnableEdit = useCallback(() => {
    setEnableEdit(true)
    closeModal()
    setShowYaml(true)
  }, [setEnableEdit, closeModal])

  const renderActionsBar = useCallback(() => {
    return (
      <>
        <Button variant="secondary" onClick={resetYaml}>
          Reset
        </Button>
        <Button>Save Changes</Button>
      </>
    )
  }, [resetYaml])

  const handleSubmit = useCallback(
    async (values) => {
      if (values.yaml === yaml) return
      // Get the current namespace and name
      const { namespace, name } = (jsYaml.load(yaml) || {}).metadata
      const updatedYaml = jsYaml.load(values.yaml)
      const { success, error } = await updateYamlFn({ kind, namespace, name, updatedYaml })
      if (success) {
        setEnableEdit(false)
        setErrorMessage(null)
      } else {
        setErrorMessage(error || '')
      }
    },
    [yaml, updateYamlFn],
  )

  return (
    <>
      {showEditModal && <EditYamlDialog onClose={closeModal} enableEdit={handleEnableEdit} />}
      <Card className={classes.card} title={titleComponent} withCustomBody>
        <ValidatedForm
          elevated={false}
          classes={{ root: classes.validatedForm }}
          fieldSetter={fieldSetter}
          onSubmit={handleSubmit}
          formActions={enableEdit ? renderActionsBar() : null}
        >
          {({ setFieldValue, values }) => (
            <>
              <div ref={content} className={classes.code}>
                <CodeMirror
                  variant="light"
                  className={classes.codeMirror}
                  id="yaml"
                  value={values.yaml}
                  onChange={setFieldValue('yaml')}
                  validations={yamlValidations}
                  options={codeMirrorOptions}
                  loading={loading}
                />
                {errorMessage !== null && (
                  <Alert
                    className={classes.error}
                    variant="error"
                    title="Error Updating YAML"
                    message={errorMessage}
                  />
                )}
              </div>
            </>
          )}
        </ValidatedForm>
      </Card>
    </>
  )
}

const useStyles = makeStyles<Theme, { showYaml?: boolean; height?: number }>((theme) => ({
  card: {
    height: 'max-content',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  title: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  },
  validatedForm: {
    marginBottom: '0px',
    '& .progress-root': {
      display: 'flex',
    },
    '& .formActions': {
      display: 'flex',
      gap: '16px !important',
      justifyContent: 'right',
      padding: '16px 24px 16px 0',
    },
  },
  code: {
    overflow: 'hidden',
    transition: ' max-height 0.3s ease',
    maxHeight: ({ height }) => height,
  },
  codeMirror: {
    marginTop: '0 !important',
    width: '100% !important',
    '& .CodeMirror': {
      minHeight: 300,
      maxHeight: 850,
    },
    '& .errorMessage': {
      margin: '16px',
    },
  },
  error: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
}))
