import React, { FC } from 'react'
import ClusterStatusSpan, { IClusterStatusSpanProps, StatusVariant } from '../ClusterStatusSpan'

interface InfrastructureStatusProps extends IClusterStatusSpanProps {
  infrastructureReady: boolean
  variant?: StatusVariant
  iconStatus?: boolean
  label?: string
}

export const InfrastructureStatus: FC<InfrastructureStatusProps> = ({
  infrastructureReady,
  variant = 'table',
  label = 'Infrastructure',
  ...rest
}) => {
  return (
    <ClusterStatusSpan status={infrastructureReady ? 'ok' : 'error'} variant={variant} {...rest}>
      {label}
    </ClusterStatusSpan>
  )
}

interface ControlPlaneStatusProps extends IClusterStatusSpanProps {
  controlPlaneReady: boolean
  variant?: StatusVariant
  iconStatus?: boolean
  label?: string
}

export const ControlPlaneStatus: FC<ControlPlaneStatusProps> = ({
  controlPlaneReady,
  variant = 'table',
  label = 'ControlPlane',
  ...rest
}) => {
  return (
    <ClusterStatusSpan status={controlPlaneReady ? 'ok' : 'error'} variant={variant} {...rest}>
      {label}
    </ClusterStatusSpan>
  )
}
