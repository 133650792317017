import {
  ConnectionStatus,
  TransientStatus,
  HealthStatus,
  ClusterTypes,
} from 'app/plugins/infrastructure/components/clusters/model'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import { EtcdBackup } from 'api-client/qbert.model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EmptyObj {}

export const importedClusterStatusMap = {
  Pending: 'pause',
  Running: 'ok',
  Terminating: 'loading',
  Failing: 'fail',
  '': 'unknown',
}

export enum IEditImportedClusterPageTabs {
  General = 'general',
  ECO = 'eco',
  Addons = 'addons',
}

export interface ImportedClusterSelector extends ImportedCluster {
  name: ImportedCluster['spec']['displayName']
  namespace?: string
  cloudProviderId: ImportedCluster['spec']['cloudProviderID']
  cloudProviderType: CloudProviders
  external: ImportedCluster['metadata']['labels']['external']
  region: ImportedCluster['metadata']['labels']['region']
  kubeVersion: ImportedCluster['spec']['kubeVersion']
  creationTimestamp: ImportedCluster['metadata']['creationTimestamp']
  containerCidr: ImportedCluster['spec']['eks']['network']['containerCidr']
  servicesCidr: ImportedCluster['spec']['eks']['network']['servicesCidr']
  nodeGroups: ImportedCluster['spec']['eks']['nodegroups']
  agentPools: ImportedCluster['spec']['aks']['agentPools']
  ecoStatus: IAgentStatus
  providerType: string
  clusterType: ClusterTypes.Imported
  hasPrometheus: boolean
  taskError: string | null
  nodes: INodesSelector[]
  masterNodesHealthStatus: HealthStatus | TransientStatus
  workerNodesHealthStatus: HealthStatus | TransientStatus
  connectionStatus: ConnectionStatus | TransientStatus
  healthStatus: HealthStatus | TransientStatus
  taskStatus: TransientStatus
  etcdBackup: EtcdBackup
  masterNodes: INodesSelector[]
  workerNodes: INodesSelector[]
  healthyMasterNodes: INodesSelector[]
  healthyWorkerNodes: INodesSelector[]
  ecoInstalled: boolean
  isPrivateVpc: boolean
  vpcText: string
  version: ImportedCluster['spec']['kubeVersion']
}

export interface ImportedCluster {
  uuid: string
  metadata: ItemMetadata
  spec: Spec
  status: Status
}

interface ItemMetadata {
  name: string
  namespace: string
  selfLink: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels: Record<string, string>
}

interface Spec {
  cloudProviderID: string
  projectID: string
  kubeVersion: string
  clusterNetwork: EmptyObj
  kubeproxy: EmptyObj
  kubelet: EmptyObj
  containerRuntime: ContainerRuntime
  loadBalancer: LoadBalancer
  storageBackend: StorageBackend
  auth: Auth
  ha: Ha
  scheduler: EmptyObj
  controllerManager: EmptyObj
  apiserver: EmptyObj
  controlPlaneEndpoint: EmptyObj
  cni: Cni
  addons: Addons
  pf9: EmptyObj
  displayName: string
  external: boolean
  eks?: Eks
  aks?: Aks
  gke?: Gke
}

interface Addons {
  appCatalog: EmptyObj
  cas: EmptyObj
  luigi: EmptyObj
  kubevirt: EmptyObj
  cpuManager: EmptyObj
}

interface Auth {
  keystone: EmptyObj
}

interface Cni {
  calico: EmptyObj
  flannel: EmptyObj
}

interface ContainerRuntime {
  docker: EmptyObj
}

interface Eks {
  region: string
  kubernetesVersion: string
  eksVersion: string
  createdAt: string
  status: string
  ca: string
  iamRole: string
  network: EksNetwork
  logging: Logging
  tags?: Record<string, string>
  nodegroups?: Nodegroup[]
}

interface Logging {
  clusterLogging: ClusterLogging[]
}

interface ClusterLogging {
  types: string[]
  enabled?: boolean
}

interface EksNetwork {
  containerCidr: string[]
  servicesCidr: string
  vpc: Vpc
}

interface Vpc {
  vpcId: string
  publicAccess: boolean
  privateAccess: boolean
  clusterSecurityGroupId?: string
  subnets: string[]
}

export interface Nodegroup {
  name: string
  arn: string
  kubernetesVersion: string
  createdAt: string
  updatedAt: string
  status: string
  capacityType: string
  instanceTypes: string[]
  subnets: string[]
  ami: string
  tags?: Record<string, string>
  labels?: Record<string, string>
  sshKey: string
  scalingConfig: ScalingConfig
  iamRole: string
  instances?: NodegroupInstance[]
}

export interface NodegroupInstance {
  instanceId: string
  availabilityZone: string
  instanceType: string
  network: InstanceNetwork
}

interface InstanceNetwork {
  privateDnsName: string
  publicDnsName: string
  privateIpAddress: string
  publicIpAddress: string
  vpcId: string
}

interface ScalingConfig {
  minSize: number
  maxSize: number
  desiredSize: number
}

interface Ha {
  keepalived: EmptyObj
}

interface LoadBalancer {
  metallb: EmptyObj
}

interface StorageBackend {
  etcd: EmptyObj
}

interface Status {
  phase: string
  message: string
  controlPlaneEndpoint: string
  workers?: number
}

interface Aks {
  location: string
  kubernetesVersion: string
  type: string
  provisioningState: string
  powerState: string
  enableRBAC: boolean
  maxAgentPools: number
  nodeResourceGroup: string
  network: Network
  agentPools: AgentPool[]
  instances: AksInstance[]
  servicePrincipalClientID: string
  dnsPrefix: string
  tags: Record<string, string>
  fqdn: string
  enablePrivateCluster?: boolean
}

interface AgentPool {
  name: string
  count: number
  vmSize: string
  osDiskSizeGB: number
  osDiskType: string
  maxPods: number
  type: string
  availabilityZones: string[]
  provisioningState: string
  powerState: string
  kubernetesVersion: string
  mode: string
  osType: string
  nodeImageVersion: string
  vnetSubnetID: string
}

interface AksInstance {
  name: string
  location: string
  instanceId: string
  vmId: string
  zones: string[]
  sku: Sku
  virtualMachineScaleSetName: string
  agentPoolName: string
  tags: Record<string, string>
  networkInterfaces: string[]
  osProfile: OSProfile
}

interface OSProfile {
  computerName: string
  adminUsername: string
  linuxConfiguration: LinuxConfiguration
}

interface LinuxConfiguration {
  sshKeys: SSHKey[]
  disablePasswordAuthentication: boolean
  provisionVMAgent: boolean
}

interface SSHKey {
  keyData: string
  path: string
}

interface Sku {
  name: string
  tier: string
}

interface Network {
  plugin: string
  policy: string
  serviceCIDR: string
  containerCIDR: string
  dnsServiceIP: string
  outboundType: string
  loadBalancerSKU: string
  loadBalancerProfile: LoadBalancerProfile
}

interface LoadBalancerProfile {
  managedOutboundIPs: number
  effectiveOutboundIPs: string[]
}

interface Gke {
  locations: string[]
  id: string
  clusterCaCertificate: string
  initialClusterVersion: string
  releaseChannel: string
  databaseEncryption: string
  network: GkeNetwork
  nodePools: NodePool[]
  privateCluster: boolean
}

interface GkeNetwork {
  useIpAliases: boolean
  network: string
  subnetwork: string
  podIpv4CIDR: string
  servicesIpv4CIDR: string
  networkPolicyConfig: boolean
}

export interface NodePool {
  name: string
  diskSizeGb: number
  machineType: string
  imageType: string
  diskType: string
  nodeCount: number
  maxPodsPerNode: string
  status: string
  k8sVersion: string
  locations: string[]
  instances: GkeInstance[]
}

interface GkeInstance {
  name: string
  status: string
  id: string
  location: string
  instanceTemplate: string
  instanceGroupName: string
}

export interface ClusterAgentsResponse {
  kind: string
  apiVersion: string
  metadata: Record<string, string>
  items: AgentItem[]
}

export interface AgentItem {
  metadata: AgentItemMetadata
  spec: Record<string, string>
  status: AgentStatus
}

interface AgentItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels: Record<string, string>
}
interface AgentStatus {
  phase: string
  message: string
  lastHeartBeat: string
  version: string
}

export type IAgentStatus = 'connected' | 'errored' | 'offline'
