export enum NetworkStackTypes {
  IPv4 = 'ipv4',
  IPv6 = 'ipv6',
  // DualStack = 'dualstack',
}

export const getDefaultCIDRIpsForStack = (stack) =>
  ({
    [NetworkStackTypes.IPv4]: {
      containersCidr: '10.20.0.0/16',
      servicesCidr: '10.21.0.0/16',
    },
    [NetworkStackTypes.IPv6]: {
      containersCidr: 'fd00:101::/116',
      servicesCidr: 'fd00:102::/116',
    },
  }?.[stack])

export const downloadAndInstallPf9CliCommand = `bash <(curl -sL https://pmkft-assets.s3-us-west-1.amazonaws.com/pf9ctl_setup)`
export const configureCliCommand = 'pf9ctl config set'
export const runPf9CliCommand = 'pf9ctl prep-node'
export const monitoringInfo = 'Enable monitoring for this cluster'
export const awsAutoscalerInfo = 'Auto scaling may not be used with spot instances.'
export const metalLbInfo = `Platform9 uses MetalLB for bare metal service level load balancing.
  Enabling MetalLB will provide the ability to create services of type load-balancer.`
export const azureAutoscalerInfo = `The cluster may scale up to the max worker nodes specified.
  Auto scaling may not be used with spot instances.`
export const profileAgentInfo = 'Simplify cluster governance using the Platform9 Profile Agent'
export const luigiInfo = `The network plugin operator will deploy Platform9 CRDs to enable multiple CNIs
  and features such as SR-IOV.`
export const metricsServerInfo =
  'Metrics Server is a scalable, efficient source of container resource metrics for Kubernetes built-in autoscaling pipelines.'
export const kubernetesDashboardInfo =
  'Kubernetes Dashboard is a general purpose, web-based UI for Kubernetes clusters.'
export const coreDnsInfo = 'CoreDNS is a DNS server/forwarder kubernetes clusters.'
export const clusterAutoScalerInfo =
  'Cluster Autoscaler is a tool that automatically adjusts the size of the Kubernetes cluster based on the utilization of Pods and Nodes in your cluster.'
