import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import UpdateAction from 'core/actions/UpdateAction'

const { sunpike } = ApiClient.getInstance()

export const nodeletControlPlaneActions = ActionsSet.make<DataKeys.NodeletControlPlanes>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.NodeletControlPlanes],
  cacheKey: DataKeys.NodeletControlPlanes,
})

export const listNodeletControlPlanes = nodeletControlPlaneActions.add(
  new ListAction<DataKeys.NodeletControlPlanes>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get CAPI Nodelet Control Planes', params)
    return sunpike.getNodeletControlPlanes()
  }),
)

export const updateNodeletControlPlane = nodeletControlPlaneActions.add(
  new UpdateAction<
    DataKeys.NodeletControlPlanes,
    { namespace: string; name: string; body: unknown; updateMethod: 'PATCH' | 'PUT' }
  >(async ({ namespace, name, body, updateMethod = 'PUT' }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update CAPI Nodelet Control Plane', {
      namespace,
      name,
      body,
    })
    const updateFn =
      updateMethod === 'PUT' ? sunpike.updateNodeletControlPlane : sunpike.patchNodeletControlPlane
    return updateFn(namespace, name, body)
  }),
)
