import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { memoizedObj } from 'utils/misc'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Divider from 'core/elements/Divider'
import clsx from 'clsx'

const NetworkPluginOperator = React.lazy(async () => import('./NetworkPluginOperator'))

const KubevirtPluginOperator = React.lazy(async () => import('./KubevirtPluginOperator'))

const MetalLbField = React.lazy(async () => import('./MetalLbField'))
const MetalLbAddonField = React.lazy(async () =>
  import('./MetalLbField').then((module) => ({
    default: module.MetalLbAddonField,
  })),
)
const MetalLbLayer2Field = React.lazy(async () =>
  import('./MetalLbField').then((module) => ({
    default: module.MetalLbLayer2Field,
  })),
)
// const MetalLbAddonLayer2Field = React.lazy(async () =>
//   import('./MetalLbField').then((module) => ({
//     default: module.MetalLbAddonLayer2Field,
//   })),
// )
const MetalLbAddonCreationField = React.lazy(async () =>
  import('./MetalLbField').then((module) => ({
    default: module.MetalLbAddonCreationField,
  })),
)

// const Metal3Field = React.lazy(async () => import('./Metal3'))
// const Metal3AddonFields = React.lazy(async () =>
//   import('./Metal3').then((module) => ({
//     default: module.Metal3AddonFields,
//   })),
// )

// const ProfileAgentField = React.lazy(async () => import('./ProfileAgent'))

const MetricsServerField = React.lazy(async () => import('./MetricsServer'))

const KubernetesDashboardField = React.lazy(async () => import('./KubernetesDashboard'))

const EtcdBackupFields = React.lazy(async () => import('./etcd-backup'))

const CapiAutoScalerField = React.lazy(async () => import('./CapiAutoScalerField'))

const EdcdBackupAddonFields = React.lazy(async () =>
  import('./etcd-backup').then((module) => ({ default: module.EdcdBackupAddonFields })),
)

const AdvancedBatchUpgradeField = React.lazy(async () => import('./advanced-batch-upgrade'))

const AdvancedBatchUpgradeAddonField = React.lazy(async () =>
  import('./advanced-batch-upgrade').then((module) => ({
    default: module.AdvancedBatchUpgradeAddonField,
  })),
)

const SequentialClusterUpgradeField = React.lazy(async () => import('./SequentialClusterUpgrade'))

const PercentageClusterUpgradeField = React.lazy(async () => import('./PercentageClusterUpgrade'))
const PercentageClusterUpgradeAddonField = React.lazy(async () =>
  import('./PercentageClusterUpgrade').then((module) => ({
    default: module.PercentageClusterUpgradeAddonField,
  })),
)

const AzureAutoScalingField = React.lazy(async () => import('./AzureAutoScalingField'))
const AzureAutoScalingAddonFields = React.lazy(async () =>
  import('./AzureAutoScalingField').then((module) => ({
    default: module.AzureAutoScalingAddonFields,
  })),
)

const AwsAutoScalingField = React.lazy(async () => import('./AwsAutoScalingField'))
const AwsAutoScalingAddonFields = React.lazy(async () =>
  import('./AwsAutoScalingField').then((module) => ({
    default: module.AwsAutoScalingAddonFields,
  })),
)

const TopologyManagerField = React.lazy(async () => import('./TopologyManager'))
const TopologyManagerAddonFields = React.lazy(async () =>
  import('./TopologyManager').then((module) => ({
    default: module.TopologyManagerAddonFields,
  })),
)

const MonitoringField = React.lazy(async () => import('./monitoring'))
const MonitoringAddonFields = React.lazy(async () =>
  import('./monitoring').then((module) => ({
    default: module.MonitoringAddonFields,
  })),
)

const CoreDnsField = React.lazy(async () => import('./CoreDns'))
const CoreDnsAddonFields = React.lazy(async () =>
  import('./CoreDns').then((module) => ({
    default: module.CoreDnsAndAutoscalerAddonFields,
  })),
)

const addonMap = {
  networkPluginOperator: {
    formId: 'deployLuigiOperator',
    toggler: NetworkPluginOperator,
    details: { component: null },
  },
  kubevirtPluginOperator: {
    toggler: KubevirtPluginOperator,
    details: { component: null },
  },
  // profileAgent: {
  //   toggler: ProfileAgentField,
  //   details: { component: null },
  // },
  metricsServer: {
    toggler: MetricsServerField,
    details: { component: null },
  },
  kubernetesDashboard: {
    toggler: KubernetesDashboardField,
    details: { component: null },
  },
  etcdBackup: {
    toggler: EtcdBackupFields,
    details: { component: EdcdBackupAddonFields },
  },
  sequentialClusterUpgrade: {
    toggler: SequentialClusterUpgradeField,
    details: { component: null },
  },
  percentageClusterUpgrade: {
    toggler: PercentageClusterUpgradeField,
    details: { component: PercentageClusterUpgradeAddonField },
  },
  advancedBatchUpgrade: {
    toggler: AdvancedBatchUpgradeField,
    details: { component: AdvancedBatchUpgradeAddonField },
  },
  enableMetallb: {
    toggler: MetalLbField,
    details: { component: MetalLbAddonField },
  },
  enableMetallbLayer2: {
    formId: 'enableMetallb',
    toggler: MetalLbLayer2Field,
    details: { component: MetalLbAddonCreationField },
  },
  // enableMetal3: {
  //   toggler: Metal3Field,
  //   details: { component: Metal3AddonFields },
  // },
  enableCAS: {
    toggler: AzureAutoScalingField,
    details: { component: AzureAutoScalingAddonFields },
  },
  awsEnableCAS: {
    formId: 'enableCAS',
    toggler: AwsAutoScalingField,
    details: { component: AwsAutoScalingAddonFields },
  },
  capiAutoScaler: {
    toggler: CapiAutoScalerField,
    details: { component: null },
  },
  enableTopologyManager: {
    toggler: TopologyManagerField,
    details: { component: TopologyManagerAddonFields },
  },
  monitoring: {
    toggler: MonitoringField,
    details: {
      component: MonitoringAddonFields,
      alwaysShow: true,
    },
  },
  coreDns: {
    formId: 'enableCoreDns',
    toggler: CoreDnsField,
    details: {
      component: CoreDnsAddonFields,
    },
  },
}

interface WizardAddonContext {
  addons: AddonManagerAddon[]
  setAddonContext: (addons: AddonManagerAddon[]) => void
}

type SupportedAddons = keyof typeof addonMap

export interface AddonManagerAddon {
  addon: SupportedAddons
  disabled?: boolean | ((wizardContext: object) => boolean)
}

interface IAddonTogglers {
  addons: AddonManagerAddon[]
  wizardContext: any
  setWizardContext: any
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AddonContext = React.createContext<WizardAddonContext>({} as WizardAddonContext)

export const AddonTogglers = ({ addons, wizardContext, setWizardContext }: IAddonTogglers) => {
  const { setAddonContext } = useContext(AddonContext)
  useEffect(() => {
    setAddonContext(addons)
  }, [memoizedObj(addons)])
  return (
    <Suspense fallback="loading...">
      {addons?.map(({ addon, disabled }) => {
        const Addon = getAddonComponent(addon, 'toggler')
        const isDisabled = typeof disabled === 'function' ? disabled(wizardContext) : disabled
        return (
          Addon && (
            <Addon
              key={addon}
              wizardContext={wizardContext}
              setWizardContext={setWizardContext}
              disabled={isDisabled}
            />
          )
        )
      })}
    </Suspense>
  )
}

export const AddonDetailCards = ({ values, ...rest }) => {
  const { addons } = useContext(AddonContext)
  return (
    <Suspense fallback="loading...">
      {addons?.map(({ addon }) => {
        const addonId = getAddonComponent(addon, 'formId') || addon
        const { component: Addon, reverse = false, alwaysShow = false } = getAddonComponent(
          addon,
          'details',
        )
        if (!values[addonId]) {
          return reverse || alwaysShow ? <Addon key={addon} values={values} {...rest} /> : null
        }

        return Addon && !reverse ? <Addon key={addon} values={values} {...rest} /> : null
      })}
    </Suspense>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  addonsContainer: {
    margin: theme.spacing(4, 0),
    display: 'grid',
    gridTemplateColumns: '220px 1fr',
    gridGap: theme.spacing(4),
  },
  addonFieldsContainer: {
    display: 'grid',
    gap: 24,
  },
}))

interface IAddonsSectionProps {
  addons: AddonManagerAddon[]
  wizardContext: any
  setWizardContext: (value) => void
  values: any
  setFieldValue: (fieldId) => (value, validateAll) => void
  className?: string
}

export const AddonsSection = ({ addons, className, ...rest }: IAddonsSectionProps) => {
  const lastIndex = addons.length - 1
  return (
    <div>
      {addons?.map(({ addon, disabled }, index) => (
        <Addon
          {...rest}
          key={addon}
          addon={addon}
          disabled={disabled}
          hideDivider={index === lastIndex}
          className={className}
        />
      ))}
    </div>
  )
}

const Addon = ({
  addon,
  disabled,
  wizardContext,
  setWizardContext,
  values,
  setFieldValue,
  hideDivider,
  className,
}) => {
  const classes = useStyles()
  const AddonToggler = getAddonComponent(addon, 'toggler')
  const isDisabled = typeof disabled === 'function' ? disabled(wizardContext) : disabled

  const addonId = getAddonComponent(addon, 'formId') || addon
  const { component: AddonParamFields, reverse = false, alwaysShow = false } = getAddonComponent(
    addon,
    'details',
  )

  const showParams =
    (AddonParamFields && wizardContext[addonId] && !reverse) ||
    (!wizardContext[addonId] && (reverse || alwaysShow))

  return (
    AddonToggler && (
      <div>
        <div className={clsx(classes.addonsContainer, className)}>
          <Suspense fallback="loading...">
            <AddonToggler
              wizardContext={wizardContext}
              setWizardContext={setWizardContext}
              disabled={isDisabled}
              useToggleSwitch
            />
          </Suspense>
          {showParams && (
            <Suspense fallback="loading...">
              <div className={classes.addonFieldsContainer}>
                <AddonParamFields
                  values={values}
                  setFieldValue={setFieldValue}
                  setWizardContext={setWizardContext}
                  wizardContext={wizardContext}
                  showHeader={false}
                  useToggleSwitch
                />
              </div>
            </Suspense>
          )}
        </div>
        {AddonParamFields && !hideDivider && <Divider />}
      </div>
    )
  )
}

const getAddonComponent = (addon, target) => {
  if (!addonMap[addon]) return null
  return addonMap[addon][target] || null
}

function ClusterAddonManager({ children }) {
  const [addons, setAddons] = useState([])
  const setAddonsProvider = useCallback((addons) => setAddons(addons), [])
  const addonProvider = useMemo(() => ({ addons, setAddonContext: setAddonsProvider }), [
    addons,
    setAddonsProvider,
  ])

  return <AddonContext.Provider value={addonProvider}>{children}</AddonContext.Provider>
}

export default ClusterAddonManager
