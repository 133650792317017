import React, { useMemo } from 'react'
import { pathStrOr } from 'utils/fp'
import { identity, intersection } from 'ramda'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { compareArrByValue } from 'utils/misc'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listCloudProviderRegionDetails } from '../../cloudProviders/new-actions'
import { cloudProviderRegionDetailsSelector } from '../../cloudProviders/selectors'

interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
  filterByZones?: boolean
  selectedZones: string[]
}

export default function AzureSkuPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  selectedZones,
  filterByZones,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const items = useMemo(() => {
    const skus = pathStrOr([], '0.skus', regionDetails)
    const zonesFilter = (sku) =>
      intersection(pathStrOr([], 'locationInfo.0.zones', sku), selectedZones).length > 0
    return skus
      .filter(filterByZones ? zonesFilter : identity)
      .map((x) => ({ label: x.name, value: x.name }))
      .sort(compareArrByValue('value'))
  }, [regionDetails, selectedZones, filterByZones])

  return <AsyncDropdown loading={loadingCloudProviderRegionDetails} items={items} {...rest} />
}
