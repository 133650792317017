import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import { makeStyles } from '@material-ui/styles'

import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import DocumentMeta from 'core/components/DocumentMeta'

import EksClusterNodeGroups from 'app/plugins/infrastructure/components/importedClusters/node-groups'
import GkeClusterNodeGroups from 'app/plugins/infrastructure/components/importedClusters/gke-node-groups'
import AgentPools from 'app/plugins/infrastructure/components/importedClusters/agent-pools'
import AksClusterNodes from 'app/plugins/infrastructure/components/importedClusters/aks-nodes'
import EksClusterNodes from 'app/plugins/infrastructure/components/importedClusters/nodes'
import GkeClusterNodes from 'app/plugins/infrastructure/components/importedClusters/gke-nodes'
import AksClusterDetails from 'app/plugins/infrastructure/components/importedClusters/aks-details'
import EksClusterDetails from 'app/plugins/infrastructure/components/importedClusters/details'
import GkeClusterDetails from 'app/plugins/infrastructure/components/importedClusters/gke-details'
import HeaderCard from 'app/plugins/infrastructure/components/importedClusters/header-card'

import Theme from 'core/themes/model'
import ClusterDeployedApps from 'app/plugins/infrastructure/components/clusters/deployed-apps/ClusterDeployedApps'
import { ClusterCloudPlatforms } from 'app/constants'
import ClusterAddonsPage from 'app/plugins/infrastructure/components/clusters/cluster-addons/cluster-details-page/ClusterAddonsPage'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { useSelector } from 'react-redux'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import useListAction from 'core/hooks/useListAction'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import ClusterAlarms from '../clusters/ClusterAlarms'

const useStyles = makeStyles<Theme>((theme) => ({
  backLink: {
    position: 'absolute',
    right: 0,
    top: 8,
    zIndex: 100,
    ...theme.typography.caption2,
  },
  deployedAppsContainer: {
    paddingTop: theme.spacing(2),
  },
}))

const clusterNodeGroupsComponents = {
  [ClusterCloudPlatforms.AKS]: AgentPools,
  [ClusterCloudPlatforms.EKS]: EksClusterNodeGroups,
  [ClusterCloudPlatforms.GKE]: GkeClusterNodeGroups,
}

const clusterNodesComponents = {
  [ClusterCloudPlatforms.AKS]: AksClusterNodes,
  [ClusterCloudPlatforms.EKS]: EksClusterNodes,
  [ClusterCloudPlatforms.GKE]: GkeClusterNodes,
}

const clusterDetailsComponents = {
  [ClusterCloudPlatforms.AKS]: AksClusterDetails,
  [ClusterCloudPlatforms.EKS]: EksClusterDetails,
  [ClusterCloudPlatforms.GKE]: GkeClusterDetails,
}

function ImportedClusterDetails() {
  const classes = useStyles()
  const { match, history } = useReactRouter()
  const dispatch = useDispatch()
  const { id: clusterId } = match.params
  const { loading, reload } = useListAction(listImportedClusters)
  const clusters = useSelector(importedClustersSelector)
  const cluster = useMemo(() => clusters.find((x) => x.uuid === clusterId), [clusters, clusterId])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: cluster?.name || clusterId,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [cluster?.name, clusterId])

  useEffect(() => {
    if (!cluster && !loading) {
      history.push(routes.cluster.imported.list.path())
    }
  }, [cluster, history, loading])

  if (!cluster) {
    return null
  }

  const ClusterNodeGroups = clusterNodeGroupsComponents[cluster.providerType]
  const ClusterNodes = clusterNodesComponents[cluster.providerType]
  const ClusterDetails = clusterDetailsComponents[cluster.providerType]

  return (
    <>
      <DocumentMeta title={`Cluster - ${cluster.name}`} breadcrumbs />
      {/* TODO change the header to render the alarms chart when match.params.tab === alarms */}
      <HeaderCard title={cluster?.name} cluster={cluster} />
      <Tabs route={routes.cluster.imported.details}>
        <Tab value="node-groups" label="Node Groups">
          <ClusterNodeGroups cluster={cluster} reload={reload} loading={loading} />
        </Tab>
        <Tab value="nodes" label="Nodes">
          <ClusterNodes cluster={cluster} reload={reload} loading={loading} />
        </Tab>
        <Tab value="details" label="Details">
          <ClusterDetails cluster={cluster} reload={reload} loading={loading} />
        </Tab>
        <Tab value="deployedApps" label="Deployed Apps">
          <div className={classes.deployedAppsContainer}>
            <ClusterDeployedApps cluster={cluster} reload={reload} loading={loading} />
          </div>
        </Tab>
        {cluster.hasPrometheus && (
          <Tab value="alarms" label="Alarms">
            <div className={classes.tabContainer}>
              <ClusterAlarms cluster={cluster} />
            </div>
          </Tab>
        )}
        <Tab value="addons" label="Add-ons">
          <ClusterAddonsPage cluster={cluster} clusterType={ClusterTypes.Imported} />
        </Tab>
      </Tabs>
    </>
  )
}

export default ImportedClusterDetails
