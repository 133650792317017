import { useAppSelector } from 'app/store'
import Text from 'core/elements/Text'
import { getFieldsForCard } from 'core/components/InfoPanel'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import { routes } from 'core/utils/routes'
import React, { useMemo, useCallback } from 'react'
import useReactRouter from 'use-react-router'
import { listCapiClusters } from '../actions'
import { PropertiesTable } from '../details/PropertyCardTable'
import { capiClustersSelector } from '../selectors'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { uncamelizeString } from 'utils/misc'
import ClusterStatusSpan from '../../ClusterStatusSpan'
import CapiClusterUpgradeProgressTracker from './CapiClusterUpgradeProgressTracker'
import { formatDate } from 'utils/misc'
import Progress from 'core/components/progress/Progress'
import { propEq } from 'ramda'

enum ClusterUpgradeStatus {
  Unknown = 'Unknown',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  Canceled = 'Canceled',
}

const clusterUpgradeStatusMap = {
  [ClusterUpgradeStatus.Unknown]: 'unknown',
  [ClusterUpgradeStatus.NotStarted]: 'unknown',
  [ClusterUpgradeStatus.InProgress]: 'loading',
  [ClusterUpgradeStatus.Completed]: 'ok',
  [ClusterUpgradeStatus.Failed]: 'fail',
  [ClusterUpgradeStatus.Canceled]: 'error',
}

const UpgradeStatus = ({ status }) => {
  if (!status) return null
  return (
    <div>
      <ClusterStatusSpan iconStatus status={clusterUpgradeStatusMap[status]}>
        <Text variant="caption1">{uncamelizeString(status)}</Text>
      </ClusterStatusSpan>
    </div>
  )
}

const renderUpgradeStatus = (status) => <UpgradeStatus status={status} />

const metadataFields = [
  { id: 'targetVersion', title: 'Target Version', required: true },
  { id: 'sourceVersion', title: 'Current Version', required: true },
  {
    id: 'phase',
    title: 'Overall Upgrade Status',
    render: renderUpgradeStatus,
    required: true,
  },
  {
    id: 'startTime',
    title: 'Start Time',
    required: true,
    render: (value) => (value ? formatDate(value) : 'N/A'),
  },
  {
    id: 'completionTime',
    title: 'Completion Time',
    required: true,
    render: (value) => (value ? formatDate(value) : 'N/A'),
  },
  {
    id: 'reason',
    title: 'Reason',
    condition: (upgradeJob) => upgradeJob?.phase === 'Failed',
  },
  {
    id: 'message',
    title: 'Message',
    condition: (upgradeJob) => upgradeJob?.phase === 'Failed',
  },
]

export default function CapiClusterUpgradeProgressModal() {
  const { history, match } = useReactRouter()
  const { id, jobId } = match.params
  const { loading, reload } = useListAction(listCapiClusters)
  const clusters = useAppSelector(capiClustersSelector)
  const cluster = clusters?.find((cluster) => cluster.uuid === id)
  const clusterUpgradeJob = (cluster?.upgradeJobs || []).find((job) => job.id === jobId)

  const handleClose = () => {
    history.push(routes.cluster.managed.capi.details.path({ id }))
  }

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, clusterUpgradeJob)
  }, [clusterUpgradeJob])

  const handleSubmit = useCallback(
    () => history.push(routes.cluster.managed.capi.upgrade.path({ id: cluster?.uuid })),
    [cluster?.uuid],
  )

  return (
    <ModalForm
      title="Upgrade Cluster Progress"
      entityName={cluster?.name}
      route={routes.cluster.managed.capi.upgradeProgress}
      onClose={handleClose}
      submitTitle="Retry Upgrade"
      onSubmit={handleSubmit}
      disableSubmit={clusterUpgradeJob?.phase !== 'Failed'}
    >
      <FormFieldSection title="">
        <PropertiesTable fields={metadata} />
      </FormFieldSection>
      <Progress loading={loading} renderContentOnMount={false}>
        <CapiClusterUpgradeProgressTracker clusterUpgradeJob={clusterUpgradeJob} />
      </Progress>
    </ModalForm>
  )
}
