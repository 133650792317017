import calcUsageTotalByPath from 'k8s/util/calcUsageTotals'
import { isAdminRole } from 'k8s/util/helpers'
import store from 'app/store'

export const calculateNodeUsages = (nodes) => {
  const calcNodesTotals = calcUsageTotalByPath(nodes)
  return {
    compute: calcNodesTotals('usage.compute.current', 'usage.compute.max'),
    memory: calcNodesTotals('usage.memory.current', 'usage.memory.max'),
    disk: calcNodesTotals('usage.disk.current', 'usage.disk.max'),
  }
}
export const isAdmin = () => isAdminRole(store.getState().session)
