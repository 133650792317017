import React from 'react'
import { getFieldsForCard, IDetailFields } from 'core/components/InfoPanel'
import {
  ImportedClusterSelector,
  importedClusterStatusMap,
} from 'app/plugins/infrastructure/components/importedClusters/model'
// import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import Alert from 'core/components/Alert'
import InfoCard from 'k8s/components/common/entity/info-card'
import { EcoStatus } from 'app/plugins/infrastructure/components/importedClusters/imported-cluster-cells/EcoStatusCell'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridGap: 16,
  },
  clusterInfo: {
    display: 'grid',
    gridTemplateColumns: '550px 550px',
    gridGap: '16px',
    alignItems: 'start',
  },
  column: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  text: {
    color: theme.palette.common.white,
  },
  link: {
    color: theme.palette.primary.light,
  },
}))

// couldn't export this from the ListPage, gets undefined always, perhaps there's a circular dependency?
const renderStatus = (phase) => {
  return (
    <ClusterStatusSpan title={phase} variant="table" status={importedClusterStatusMap[phase]}>
      {phase}
    </ClusterStatusSpan>
  )
}

const renderVpcText = (_, cluster) => {
  const providerType = cluster?.metadata?.labels?.provider
  const privateAccess =
    cluster?.spec?.[providerType]?.network?.vpc?.privateAccess ||
    (providerType === 'aks' && cluster?.spec?.aks?.enablePrivateCluster) ||
    (providerType === 'gke' && cluster?.spec?.gke?.privateCluster)
  const publicAccess =
    cluster?.spec?.[providerType]?.network?.vpc?.publicAccess ||
    (providerType === 'aks' && !cluster?.spec?.aks?.enablePrivateCluster) ||
    (providerType === 'gke' && !cluster?.spec?.gke?.privateCluster)
  const isPublicPrivateVpc = privateAccess === true && publicAccess === true
  const vpcText = `${publicAccess === true ? 'Public' : ''}${isPublicPrivateVpc ? ' + ' : ''}${
    privateAccess === true ? 'Private' : ''
  }`
  return vpcText
}

const clusterStatusFields: Array<IDetailFields<ImportedClusterSelector>> = [
  {
    id: 'cloudProviderType',
    title: 'Cloud Provider Type',
    required: true,
    render: (providerType) => providerType?.toUpperCase(),
  },
  {
    id: 'version',
    title: 'Kubernetes Version',
    required: true,
  },
  {
    id: 'spec',
    title: 'VPC',
    render: renderVpcText,
  },
  {
    id: 'region',
    title: 'Region',
    required: true,
  },
  {
    id: 'status.phase',
    title: 'Status',
    required: true,
    render: renderStatus,
  },
]

// Got this from the details page
const networkFields: Array<IDetailFields<ImportedClusterSelector>> = [
  { id: 'containerCidr', title: 'Container CIDR', required: true },
  { id: 'servicesCidr', title: 'Services CIDR', required: true },
  { id: 'spec.eks.network.vpc.vpcId', title: 'VPC ID', required: true },
  {
    id: 'spec.eks.network.vpc.subnets',
    title: 'Subnets',
    render: (subnets = []) => subnets.map((net) => <div>{net}</div>),
  },
]

export const ecoFields: Array<IDetailFields<ImportedClusterSelector>> = [
  {
    id: 'ecoInstalled',
    title: 'Installation Status',
    required: true,
    render: (installed) => <b>{installed ? 'Installed' : 'Not Installed'}</b>,
  },
  {
    id: 'ecoStatus',
    title: 'Health Status',
    required: true,
    render: (status, cluster) =>
      status ? (
        <EcoStatus status={status} installed={cluster.ecoInstalled} cluster={cluster} />
      ) : (
        <b>'N/A'</b>
      ),
  },
]

const clusterStatusStats = (cluster) => getFieldsForCard(clusterStatusFields, cluster)
const networkStats = (cluster) => getFieldsForCard(networkFields, cluster)
const ecoStats = (cluster) => getFieldsForCard(ecoFields, cluster)

const GeneralPage = ({ cluster }) => {
  const classes = useStyles({})

  const clusterStatus = clusterStatusStats(cluster)
  const networkStatus = networkStats(cluster)
  const ecoStatus = ecoStats(cluster)
  return (
    <div className={classes.root}>
      <Alert
        variant="primary"
        title={`External Cluster Management`}
        message={`External Clusters require outbound connectivity from within the Cluster to the Platform9 SaaS Management Plane. This is achieved through the External Cluster Operator (ECO). ECO is responsible for creating a secure connection from the Cluster to Platform9. To manage this connection select the External Cluster Operator tab.`}
      />
      <div className={classes.clusterInfo}>
        <div className={classes.column}>
          <InfoCard items={clusterStatus} title="Cluster Status" />
          <InfoCard title="Network" items={networkStatus} />
        </div>
        <div className={classes.column}>
          <InfoCard title="ECO Status" items={ecoStatus} />
        </div>
      </div>
    </div>
  )
}

export default GeneralPage
