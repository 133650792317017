import React from 'react'
import PieUsageWidget from 'core/components/widgets/PieUsageWidget'
import { PieDataEntry } from 'core/components/graphs/PieGraph'
import { EtcdBackupStatus } from '../../control-plane/nodelet-control-plane/model'

const getData = (backups = []) => {
  const basePieData = {
    Success: {
      name: 'Success',
      color: 'fadedSuccess',
      value: 0,
    },
    Failed: {
      name: 'Failed',
      color: 'fadedError',
      value: 0,
    },
    Unknown: {
      name: 'Unknown',
      color: 'unknown',
      value: 0,
    },
  }
  const data = backups?.reduce((acc, backup) => {
    const status = backup.succeeded ? 'Success' : 'Failed'
    acc[status]['value'] += 1
    return acc
  }, basePieData)
  return [...Object.values(data)]
}

export default function CapiBackupsHealthStatusesCard({
  backups,
}: {
  backups: EtcdBackupStatus[]
}) {
  const pieData = getData(backups) as PieDataEntry[]

  return <PieUsageWidget data={pieData} primary={'Success'} />
}
