import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import { UserPreferences } from 'app/constants'
import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import FormReviewTable from 'core/components/validatedForm/review-table'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import WizardStep from 'core/components/wizard/WizardStep'
import {
  CloudDefaults,
  CloudProviders,
} from 'app/plugins/infrastructure/components/cloudProviders/model'
import { azurePrerequisitesLink } from 'k8s/links'
import React, { FC, useEffect } from 'react'
import { emptyObj, isNilOrEmpty, pathStrOr } from 'utils/fp'
import { castBoolToStr } from 'utils/misc'
import AdvancedApiConfigFields from '../../form-components/AdvancedApiConfigFields'
import AllowWorkloadsOnMasterField from '../../form-components/AllowWorkloadsOnMasterField'
import ApiFqdnField from '../../form-components/ApiFqdnField'
import AssignPublicIpsField from '../../form-components/AssignPublicIps'
import CloudProviderField from '../../form-components/CloudProviderField'
import CloudProviderRegionField from '../../form-components/CloudProviderRegionField'
import ClusterTemplatesField from '../../form-components/ClusterTemplatesField'
import ContainerAndServicesCidrField from '../../form-components/container-and-services-cidr'
import ExistingNetworkField from '../../form-components/ExistingNetworkField'
import HttpProxyField from '../../form-components/HttpProxyField'
import KubernetesVersionField from '../../form-components/KubernetesVersionField'
import MasterNodeSkuField from '../../form-components/MasterNodeSku'
import MasterNodeSubnetField from '../../form-components/MasterNodeSubnetField'
import ClusterNameField from '../../form-components/NameField'
import NetworksField from '../../form-components/NetworksField'
import NumMasterNodesField from '../../form-components/NumMasterNodesField'
import NumWorkerNodesField from '../../form-components/NumWorkerNodesField'
import PrivilegedField from '../../form-components/PrivilegedContainersField'
import SshKeyTextField from '../../form-components/SshKeyTextfield'
import TagsField, { FormattedTags } from '../../form-components/TagsField'
import WorkerNodeSkuField from '../../form-components/WorkerNodeSkuField'
import WorkerNodeSubnetField from '../../form-components/WorkerNodeSubnetField'
import AzureAvailabilityZoneFields from '../azure-availability-zone'
import AzureResourceGroupPicklist from '../AzureResourceGroupPicklist'
import AzureSkuPicklist from '../AzureSkuPicklist'
import AzureSubnetPicklist from '../AzureSubnetPicklist'
import AzureVnetPicklist from '../AzureVnetPicklist'
import Text from 'core/elements/Text'
import { AddonManagerAddon, AddonTogglers } from '../../cluster-addons/cluster-addon-manager'
import { azureClusterTracking } from '../../tracking'
import { ClusterCreateTypes } from '../../model'
import CustomApiFlagsFields from '../../form-components/CustomApiFlagsFields'
import useScopedPreferences from 'core/session/useScopedPreferences'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ContainerRuntimePicklist from '../../form-components/ContainerRuntimePicklist'
import { compareVersions } from 'k8s/util/helpers'
import { etcdBackupDefaults } from '../../cluster-addons/etcd-backup'
import { listCloudProviderRegionDetails } from '../../../cloudProviders/new-actions'
import useListAction from 'core/hooks/useListAction'
import { cloudProviderRegionDetailsSelector } from '../../../cloudProviders/selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'

export const initialContext = {
  template: 'small',
  masterSku: 'Standard_A1_v2',
  workerSku: 'Standard_A1_v2',
  numMasters: 1,
  numWorkers: 1,
  numMinWorkers: 1,
  enableCAS: false,
  usePf9Domain: true,
  network: 'newNetwork',
  containersCidr: '10.20.0.0/16',
  servicesCidr: '10.21.0.0/16',
  networkPlugin: 'flannel',
  runtimeConfigOption: 'default',
  useAllAvailabilityZones: true,
  assignPublicIps: false,
  prometheusMonitoringEnabled: true,
  tags: [],
  networkStack: 'ipv4',
  privileged: true,
  allowWorkloadsOnMaster: false,
  // enableProfileAgent: true,
  containerRuntime: 'containerd',
  ...etcdBackupDefaults,
}

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'location', label: 'Region' },
  { id: 'masterSku', label: 'Master node SKU', insertDivider: true },
  { id: 'workerSku', label: 'Worker node SKU' },
  { id: 'numMasters', label: 'Master nodes' },
  { id: 'numWorkers', label: 'Worker nodes' },
  {
    id: 'allowWorkloadsOnMaster',
    label: 'Make Master nodes Master + Worker',
    render: (value) => castBoolToStr()(value),
  },
  { id: 'enableCAS', label: 'Auto scaling', render: (value) => castBoolToStr()(value) },
  {
    id: 'assignPublicIps',
    label: "Assign public IP's",
    render: (value) => castBoolToStr()(value),
    insertDivider: true,
  },
  { id: 'externalDnsName', label: 'API FQDN' },
  { id: 'containersCidr', label: 'Containers CIDR' },
  { id: 'servicesCidr', label: 'Services CIDR' },
  { id: 'privileged', label: 'Privileged', render: (value) => castBoolToStr()(value) },
  {
    id: 'prometheusMonitoringEnabled',
    label: 'Prometheus monitoring',
    render: (value) => castBoolToStr()(value),
    insertDivider: true,
  },
  {
    id: 'tags',
    label: 'Tags',
    renderArray: true,
    render: (value) => <FormattedTags tags={value} />,
  },
]

const trackingFields = {
  platform: CloudProviders.Azure,
  target: ClusterCreateTypes.Custom,
}

const networkOptions = [
  { label: 'Select existing', value: 'existing' },
  { label: 'Create new network', value: 'newNetwork' },
]

const templateOptions = [
  { label: 'Sm - Single Node Master & Worker', value: 'small' },
  { label: 'Md - 1 Master + 3 Workers', value: 'medium' },
  { label: 'Lg - 3 Masters + 5 Workers', value: 'large' },
  { label: 'custom', value: 'custom' },
]

// The template picker allows the user to fill out some useful defaults for the fields.
// This greatly simplifies the number of fields that need to be filled out.
// Presets are as follows:
// small (single dev) - 1 node master + worker - select instance type (default t2.small)
// medium (internal team) - 1 master + 3 workers - select instance (default t2.medium)
// large (production) - 3 master + 5 workers - no workload on masters (default t2.large)
const options = {
  small: {
    numMasters: 1,
    numWorkers: 1,
    allowWorkloadsOnMaster: true,
    masterSku: 'Standard_A4_v2',
    workerSku: 'Standard_A4_v2',
  },
  medium: {
    numMasters: 1,
    numWorkers: 3,
    allowWorkloadsOnMaster: false,
    masterSku: 'Standard_A4_v2',
    workerSku: 'Standard_A4_v2',
  },
  large: {
    numMasters: 3,
    numWorkers: 5,
    allowWorkloadsOnMaster: false,
    masterSku: 'Standard_A4_v2',
    workerSku: 'Standard_A4_v2',
  },
  custom: {
    numMasters: 3,
    numWorkers: 5,
    allowWorkloadsOnMaster: false,
    masterSku: 'Standard_A4_v2',
    workerSku: 'Standard_A4_v2',
  },
}

const handleTemplateChoice = ({ setFieldValue, setWizardContext }) => (option) => {
  // setImmediate is used because we need the fields to show up in the form before their values can be set
  setImmediate(() => {
    Object.entries(options[option]).forEach(([key, value]) => {
      setFieldValue(key)(value)
    })
  })
  setWizardContext(options[option])
}

const configStepAddOns: AddonManagerAddon[] = [
  { addon: 'etcdBackup' },
  { addon: 'monitoring' },
  { addon: 'enableCAS' },
  // { addon: 'profileAgent' },
]
const advancedClusterAddons: AddonManagerAddon[] = [{ addon: 'enableTopologyManager' }]

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(3, 0),
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
}

const AdvancedAzureCluster: FC<Props> = ({ wizardContext, setWizardContext, onNext, ...rest }) => {
  const classes = useStyles()
  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')
  const cloudDefaults =
    prefs?.[UserPreferences.CloudProviders]?.[wizardContext.cloudProviderId] || emptyObj

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  // useEffect(() => {
  //   if (compareVersions(wizardContext.kubeRoleVersion, '1.20') >= 0) {
  //     setWizardContext({ enableProfileAgent: true })
  //   } else {
  //     setWizardContext({ enableProfileAgent: false })
  //   }
  // }, [wizardContext.kubeRoleVersion])

  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: {
        cloudProviderId: wizardContext.cloudProviderId,
        cloudProviderRegionId: wizardContext.region,
      },
    },
  )
  const cloudProviderRegionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId: wizardContext.cloudProviderId,
    cloudProviderRegionId: wizardContext.region,
  })

  const virtualNetworks = pathStrOr([], '0.virtualNetworks', cloudProviderRegionDetails)

  const handleRegionChange = (regionName) =>
    setWizardContext({ region: regionName, location: regionName })

  useEffect(() => {
    if (isNilOrEmpty(cloudDefaults)) return
    setWizardContext({ ...cloudDefaults, location: cloudDefaults[CloudDefaults.Region] })
  }, [cloudDefaults])

  return (
    <>
      {/* Step 1 - Cluster Configuration */}
      <WizardStep
        stepId="config"
        label="Initial Setup"
        onNext={azureClusterTracking.wZStepOne(trackingFields)}
        {...rest}
      >
        <ValidatedForm
          fullWidth
          classes={{ root: classes.validatedFormContainer }}
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          withAddonManager
          elevated={false}
        >
          {({ setFieldValue, values }) => (
            <>
              <FormFieldSection
                title="Cluster Configuration"
                link={
                  <ExternalLink textVariant="caption2" url={azurePrerequisitesLink}>
                    Azure Cluster Help
                  </ExternalLink>
                }
              >
                {/* Cluster Name */}
                <ClusterNameField setWizardContext={setWizardContext} />

                {/* Cloud Provider */}
                <CloudProviderField
                  cloudProviderType={CloudProviders.Azure}
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onChange={(value) =>
                    setWizardContext({
                      cloudProviderId: value,
                    })
                  }
                />

                {/* Cloud Provider Region */}
                <CloudProviderRegionField
                  cloudProviderType={CloudProviders.Azure}
                  values={values}
                  wizardContext={wizardContext}
                  onChange={handleRegionChange}
                  disabled={!values.cloudProviderId && !values.region}
                />

                {/* SSH Key */}
                <SshKeyTextField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />

                {/* Template Chooser */}
                <ClusterTemplatesField
                  items={templateOptions}
                  onChange={handleTemplateChoice({
                    setFieldValue,
                    setWizardContext,
                  })}
                />

                {values.template === 'custom' && (
                  <>
                    {/* Use All Availability Zones Checkbox Field and Azure Availability Zone Chooser */}
                    <AzureAvailabilityZoneFields
                      values={values}
                      setWizardContext={setWizardContext}
                    />

                    {/* Num master nodes */}
                    <NumMasterNodesField />

                    {/* Master node SKU */}
                    <MasterNodeSkuField dropdownComponent={AzureSkuPicklist} values={values} />

                    {/* Num worker nodes */}
                    <NumWorkerNodesField />

                    {/* Worker node SKU */}
                    <WorkerNodeSkuField dropdownComponent={AzureSkuPicklist} values={values} />
                  </>
                )}
              </FormFieldSection>

              <FormFieldSection title="Cluster Settings">
                {/* Kubernetes Version */}
                <KubernetesVersionField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  inClusterCreation={true}
                />

                <PicklistField
                  DropdownComponent={ContainerRuntimePicklist}
                  id="containerRuntime"
                  label="Container Runtime"
                  tooltip="The container runtime for the cluster"
                  onChange={(value) => setWizardContext({ containerRuntime: value })}
                  value={wizardContext.containerRuntime}
                  kubeRoleVersion={wizardContext.kubeRoleVersion}
                  required
                />

                <hr className={classes.divider} />

                {/* App & Container Settings */}
                <Text variant="caption1">Application & Container Settings</Text>

                {/* Allow workloads on masters */}
                <AllowWorkloadsOnMasterField setWizardContext={setWizardContext} />

                <PrivilegedField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />

                <hr className={classes.divider} />

                {/* Managed Add-Ons */}
                <Text variant="caption1">Cluster Add-Ons</Text>
                <AddonTogglers
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  addons={configStepAddOns}
                />
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>

      {/* Step 2 - Network Info */}
      <WizardStep
        stepId="network"
        label="Network Info"
        onNext={azureClusterTracking.wZStepTwo(trackingFields)}
        keepContentMounted={false}
      >
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          fullWidth
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          elevated={false}
        >
          {({ values }) => (
            <>
              <FormFieldSection title="Network Info">
                {/* Assign public IP's */}
                <AssignPublicIpsField />

                {/* Network */}
                <NetworksField items={networkOptions} />

                {values.network === 'existing' && virtualNetworks.length > 0 && (
                  <>
                    {/* Resource group */}
                    <PicklistField
                      DropdownComponent={AzureResourceGroupPicklist}
                      disabled={!(wizardContext.cloudProviderId && wizardContext.region)}
                      id="vnetResourceGroup"
                      label="Resource group"
                      cloudProviderId={wizardContext.cloudProviderId}
                      cloudProviderRegionId={wizardContext.region}
                      onChange={(value) => setWizardContext({ vnetResourceGroup: value })}
                      tooltip="Select the resource group that your networking resources belong to."
                      required
                    />

                    {/* Existing network.  I don't get the point of this field. */}
                    <ExistingNetworkField dropdownComponent={AzureVnetPicklist} values={values} />

                    {/* Master node subnet */}
                    <MasterNodeSubnetField
                      dropdownComponent={AzureSubnetPicklist}
                      values={values}
                    />

                    {/* Worker node subnet */}
                    <WorkerNodeSubnetField
                      dropdownComponent={AzureSubnetPicklist}
                      values={values}
                    />
                  </>
                )}

                {values.network === 'existing' && virtualNetworks.length === 0 && (
                  <Alert
                    variant="error"
                    message={`No existing virtual networks in location ${wizardContext.region}.`}
                  />
                )}

                {/* API FQDN */}
                <ApiFqdnField setWizardContext={setWizardContext} wizardContext={wizardContext} />

                {/* Containers CIDR and Services CIDR */}
                <ContainerAndServicesCidrField values={values} />

                {/* HTTP proxy */}
                <HttpProxyField />
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>

      {/* Step 3 - Advanced Configuration */}
      <WizardStep
        stepId="advanced"
        label="Advanced Configuration"
        onNext={azureClusterTracking.wZStepThree(trackingFields)}
      >
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          fullWidth
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          elevated={false}
          withAddonManager
        >
          {({ values }) => (
            <>
              <FormFieldSection title="Advanced Configuration">
                {/* Advanced API Configuration & Custom Runtime Config */}
                <AdvancedApiConfigFields values={values} setWizardContext={setWizardContext} />

                {/* Tags */}
                <CustomApiFlagsFields
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />
                <TagsField />
                <AddonTogglers
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  addons={advancedClusterAddons}
                />
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>

      {/* Step 4 - Review */}
      <WizardStep
        stepId="review"
        label="Review"
        onNext={azureClusterTracking.wZStepFour(trackingFields)}
      >
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          fullWidth
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          elevated={false}
        >
          <FormFieldSection title="Finalize & Review">
            <FormReviewTable data={wizardContext} columns={columns} />
          </FormFieldSection>
        </ValidatedForm>
      </WizardStep>
    </>
  )
}

export default AdvancedAzureCluster
