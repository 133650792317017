import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'

import useReactRouter from 'use-react-router'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { switchCase } from 'utils/fp'
import BareosClusterRequirements from './bareos/BareosClusterRequirements'
import AwsClusterRequirements from './aws/AwsClusterRequirements'
import AzureClusterRequirements from './azure/AzureClusterRequirements'
import DocumentMeta from 'core/components/DocumentMeta'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import CloudProviderSelectableCard from 'k8s/components/common/CloudProviderSelectableCard'

const requirementsMap = {
  [CloudProviders.VirtualMachine]: BareosClusterRequirements,
  [CloudProviders.PhysicalMachine]: BareosClusterRequirements,
  [CloudProviders.Aws]: AwsClusterRequirements,
  [CloudProviders.Azure]: AzureClusterRequirements,
}
type ValueOf<T> = T[keyof T]

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    maxWidth: 'max-content',
  },
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 242px)',
    gridGap: theme.spacing(3),
    minWidth: 1050,
  },
}))

const AddClusterPage = () => {
  const classes = useStyles()
  const { history, location } = useReactRouter()
  const providerType =
    new URLSearchParams(location.search).get('type') || CloudProviders.VirtualMachine
  const [activeProvider, setActiveProvider] = useState<CloudProviders>(
    providerType as CloudProviders,
  )

  const handleNextView = (url) => {
    history.push(url)
  }

  useEffect(() => {
    // update the url as the provider changes
    history.push(routes.cluster.add.root.path({ type: activeProvider }))
  }, [activeProvider])

  const ActiveView: ValueOf<typeof requirementsMap> = useMemo(
    () => switchCase(requirementsMap)(activeProvider),
    [activeProvider],
  )
  return (
    <>
      <DocumentMeta title="Add Cluster" breadcrumbs />
      <div className={classes.container}>
        <FormFieldSection title="Where to deploy your cluster" step={1}>
          <CloudProviderSelectableCard
            active={activeProvider === CloudProviders.VirtualMachine}
            onClick={setActiveProvider}
            type={CloudProviders.VirtualMachine}
          />
          <CloudProviderSelectableCard
            active={activeProvider === CloudProviders.PhysicalMachine}
            onClick={setActiveProvider}
            type={CloudProviders.PhysicalMachine}
          />
          <CloudProviderSelectableCard
            active={activeProvider === CloudProviders.Aws}
            onClick={setActiveProvider}
            type={CloudProviders.Aws}
          />
          {/* <CloudProviderSelectableCard
            active={activeProvider === CloudProviders.Azure}
            onClick={setActiveProvider}
            type={CloudProviders.Azure}
          /> */}
        </FormFieldSection>
        <FormFieldSection title="">
          <ActiveView onComplete={handleNextView} provider={activeProvider} />
        </FormFieldSection>
      </div>
    </>
  )
}

export default AddClusterPage
