import React, { useMemo, useEffect } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import { routes } from 'core/utils/routes'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import {
  ImportedClusterSelector,
  IEditImportedClusterPageTabs,
} from 'app/plugins/infrastructure/components/importedClusters/model'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import { useDispatch } from 'react-redux'

import GeneralPage from './GeneralPage'
import EcoInstallationPage from './EcoInstallationPage'
import ClusterAddonsPage from '../../clusters/cluster-addons/edit-cluster-page/ClusterAddonsPage'
import { clientActions } from 'core/client/clientReducers'

const EditImportedClusterPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { loading } = useListAction(listImportedClusters, {})
  const importedClusters = useSelectorWithParams(importedClustersSelector, {})
  const cluster = useMemo(() => {
    if (loading) {
      return {} as ImportedClusterSelector
    }
    return (
      importedClusters.find((importedCluster) => importedCluster.uuid === id) ||
      ({} as ImportedClusterSelector)
    )
  }, [id, importedClusters, loading])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: cluster?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [cluster?.name, id])

  return (
    <>
      <DocumentMeta title="Edit Imported Cluster" breadcrumbs />
      <Tabs route={routes.cluster.imported.edit}>
        <Tab label="General" value={IEditImportedClusterPageTabs.General}>
          <GeneralPage cluster={cluster} />
        </Tab>
        <Tab label="External Cluster Operator" value={IEditImportedClusterPageTabs.ECO}>
          <EcoInstallationPage cluster={cluster} />
        </Tab>
        <Tab label="Add-ons" value={IEditImportedClusterPageTabs.Addons}>
          <ClusterAddonsPage clusterId={id} cluster={cluster} loading={loading} />
        </Tab>
      </Tabs>
    </>
  )
}

export default EditImportedClusterPage
