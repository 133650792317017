import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import Button from 'core/elements/button'
import Progress from 'core/components/progress/Progress'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { trackEvent } from 'utils/tracking'
import { stopPropagation } from 'utils/fp'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { ImportedClusterSelector } from 'app/plugins/infrastructure/components/importedClusters/model'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteImportedCluster } from 'app/plugins/infrastructure/components/importedClusters/new-actions'

export default function DetachImportedClusterDialog({
  rows: [cluster],
  onClose,
}: GridDialogProps<ImportedClusterSelector>) {
  const { update: detachCluster, updating: detachingCluster } = useUpdateAction(
    deleteImportedCluster,
  )

  const handleDetach = useCallback(async () => {
    const { success } = await detachCluster({ clusterId: cluster.uuid })
    if (success) {
      trackEvent('Detach Imported Cluster', {
        cluster_uuid: cluster.uuid,
        cluster_name: cluster.name,
        cloud_provider_type: cluster.providerType,
      })
    }
    onClose(success)
  }, [cluster])

  return (
    <Dialog open onClose={onClose} onClick={stopPropagation}>
      <DialogTitle>
        <Text variant="subtitle1">{`Detach cluster "${cluster?.name}"?`}</Text>
      </DialogTitle>
      <ValidatedForm initialValues={cluster} fullWidth onSubmit={handleDetach} elevated={false}>
        {({ values }) => (
          <Progress loading={detachingCluster} renderContentOnMount>
            <DialogContent>
              <Text variant="body1" component="div">
                Please type "<b>{cluster?.name}</b>" to confirm.
              </Text>
              <TextField id="clusterName" type="text" label="Cluster name" />
            </DialogContent>
            <DialogActions>
              <Button variant="secondary" onClick={onClose}>
                Don't Detach
              </Button>
              <Button type="submit" disabled={values?.clusterName !== cluster?.name}>
                Detach this cluster
              </Button>
            </DialogActions>
          </Progress>
        )}
      </ValidatedForm>
    </Dialog>
  )
}
