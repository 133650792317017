import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import AddonConfigurationsModal from 'app/plugins/infrastructure/components/clusters/cluster-addons/edit-cluster-page/AddonConfigurationsModal'

export default function EditAddonButton({
  className,
  addon,
  cluster,
  addonVersions,
  allAddons,
  toggleMenu,
}) {
  const [showDialog, setShowDialog] = useState(false)

  const handleClick = useCallback(() => {
    setShowDialog(true)
    toggleMenu()
  }, [setShowDialog, toggleMenu])

  return (
    <>
      <Text className={className} variant="body2" onClick={handleClick}>
        Edit
      </Text>
      {showDialog && (
        <AddonConfigurationsModal
          addon={addon}
          open
          onClose={() => setShowDialog(false)}
          action="Edit"
          cluster={cluster}
          existingClusterAddons={allAddons}
          currentAddonVersions={addonVersions}
        />
      )}
    </>
  )
}
