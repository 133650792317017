import React from 'react'
import Dropdown from 'core/elements/dropdown'

const options = [
  { label: 'Master', value: 'master' },
  { label: 'Worker', value: 'worker' },
]
const NodeRolesPicklist = ({ onChange, ...rest }) => {
  return (
    <Dropdown
      {...rest}
      compact
      showAll
      label="Role"
      name="role"
      onChange={onChange}
      items={options}
    />
  )
}

export default NodeRolesPicklist
