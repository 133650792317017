import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalLink from 'core/components/ExternalLink'
import { azurePrerequisitesLink } from 'k8s/links'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { RegionAvailability, setCloudProviderDefaults } from '../helpers'
import { CloudProviders, CloudDefaults } from '../model'
import Button from 'core/elements/button'
import SshKeyTextfield from 'app/plugins/infrastructure/components/clusters/form-components/SshKeyTextfield'
import CloudProviderRegionField from 'app/plugins/infrastructure/components/clusters/form-components/CloudProviderRegionField'
// This ssh-pub-key-validation package doesn't seem to work anymore, tried with current SSH pub key
// and others reported it not working as well (reported in UX-2155)
// import { isKeyValid } from 'ssh-pub-key-validation'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { loadCloudProviderDetails } from '../new-actions'
import { cloudProviderDetailsSelector } from '../selectors'

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginTop: theme.spacing(3),
  },
  spaceRight: {
    marginRight: theme.spacing(4),
  },
  checkIcon: {
    color: theme.components.graph.success,
    marginRight: theme.spacing(1),
  },
  timesIcon: {
    color: theme.components.graph.error,
    marginRight: theme.spacing(1),
  },
  selectionArea: {
    display: 'grid',
    gap: 8,
  },
  setDefaultButton: {
    alignSelf: 'center',
    justifySelf: 'flex-start',
  },
  publicSSHDefaultButton: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-start',
  },
  sshKey: {
    width: '70%',
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  updateUserDefaults: any
  cloudDefaults: any
  showDefaultButtons: boolean
}

const AzureCloudProviderVerification = ({
  wizardContext,
  setWizardContext,
  updateUserDefaults,
  cloudDefaults,
  showDefaultButtons,
}: Props) => {
  const classes = useStyles({})

  const { loading: regionsLoading } = useListAction(loadCloudProviderDetails, {
    params: {
      cloudProviderId: wizardContext.cloudProviderId,
    },
    requiredParams: ['cloudProviderId'],
  })
  const regions = useSelectorWithParams(cloudProviderDetailsSelector, {
    cloudProviderId: wizardContext.cloudProviderId,
  })

  const handleSetUserDefault = (values) =>
    setCloudProviderDefaults(
      wizardContext.cloudProviderId,
      cloudDefaults,
      values,
      updateUserDefaults,
    )

  return (
    <>
      <FormFieldSection
        className={classes.section}
        title="Available Regions"
        link={
          <ExternalLink textVariant="caption2" url={azurePrerequisitesLink}>
            Azure Help
          </ExternalLink>
        }
      >
        <Text variant="body2">
          Platform9 deploys Kubernetes clusters into specified Azure Regions.
        </Text>
        <div className={classes.selectionArea}>
          <CloudProviderRegionField
            cloudProviderType={CloudProviders.Azure}
            onChange={(value) => setWizardContext({ region: value, regionLabel: 'Region' })}
            wizardContext={wizardContext}
            values={wizardContext}
            required={false}
          />
          {showDefaultButtons && (
            <Button
              className={classes.setDefaultButton}
              disabled={
                !wizardContext.region ||
                cloudDefaults[CloudDefaults.Region] === wizardContext.region
              }
              onClick={() =>
                handleSetUserDefault({
                  [CloudDefaults.Region]: wizardContext.region,
                  [CloudDefaults.RegionLabel]: wizardContext.regionLabel,
                })
              }
            >
              Set As Default
            </Button>
          )}
          {wizardContext.cloudProviderId && !regionsLoading && (
            <RegionAvailability classes={classes} regions={regions} />
          )}
        </div>
      </FormFieldSection>
      <FormFieldSection className={classes.section} title="Public SSH Key">
        <Text variant="body2">
          Add a Public SSH Key to be used as the default key during cluster creation.
        </Text>
        <div className={classes.selectionArea}>
          <SshKeyTextfield
            wizardContext={wizardContext}
            setWizardContext={setWizardContext}
            required={false}
          />
          {showDefaultButtons && (
            <Button
              className={classes.publicSSHDefaultButton}
              disabled={
                !wizardContext.sshKey ||
                cloudDefaults[CloudDefaults.SshKey] === wizardContext.sshKey
              }
              onClick={() => handleSetUserDefault({ [CloudDefaults.SshKey]: wizardContext.sshKey })}
            >
              Set As Default
            </Button>
          )}
        </div>
      </FormFieldSection>
    </>
  )
}

export default AzureCloudProviderVerification
