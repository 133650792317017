import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CodeBlock from 'core/components/CodeBlock'
import ExternalLink from 'core/components/ExternalLink'
import SimpleLink from 'core/components/SimpleLink'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import { pf9CPCLILink } from 'k8s/links'
import CopyToClipboard from 'core/components/CopyToClipboard'

const installCommand =
  'bash <(curl -sL https://pmkft-assets.s3.us-west-1.amazonaws.com/IAM_Checker/pf9cp_setup)'
const downloadLink = 'https://pmkft-assets.s3.us-west-1.amazonaws.com/IAM_Checker/pf9cp-win64.exe '

const useStyles = makeStyles((theme: Theme) => ({
  infoText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  downloadIcon: {
    marginRight: theme.spacing(0.5),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  code: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  downloadButton: {
    marginLeft: theme.spacing(1),
  },
}))

const CheckCloudAccessDialog = ({ onClose }) => {
  const classes = useStyles()
  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogContent>
        <FormFieldCard
          title="Platform9 pf9cp CLI for Mac, Linux and Windows"
          link={
            <ExternalLink url={pf9CPCLILink}>
              <Text variant="caption2">Learn more about pf9cp</Text>
            </ExternalLink>
          }
        >
          <Text variant="body1" className={classes.infoText} component="div">
            The pf9cp CLI helps users check IAM access and confirm pre-requisites for AWS, Azure and
            Google clouds. The CLI works by connecting to the target cloud using the same
            credentials as the Cloud Provider and validates if the credentials provided have the
            required access for Platform9 to build and manage clusters.
          </Text>
          <Text variant="body1" className={classes.text}>
            To install on Linux & macOS copy the command below:
          </Text>
          <CopyToClipboard className={classes.code} copyText={installCommand}>
            <CodeBlock className={classes.code}>{`$ ${installCommand}`}</CodeBlock>
          </CopyToClipboard>
          <Text variant="body1" className={classes.text}>
            To install on Windows 10/11 download and run the executable below:
          </Text>
          <div className={classes.downloadButton}>
            <SimpleLink src={downloadLink}>
              <Button type="button" icon="download">
                Download
              </Button>
            </SimpleLink>
          </div>
        </FormFieldCard>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckCloudAccessDialog
