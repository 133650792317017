import React, { useRef, useMemo, useEffect, Suspense } from 'react'
import ModalForm from 'core/elements/modal/ModalForm'
import {
  clusterAddonDisplayNames,
  configParams,
  paramsWithUnits,
  clusterAddonParams,
  etcdBackupUIFieldIdMap,
  addonTypeToNameMap,
} from '../helpers'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { decodeStr, stripUnitFromValue } from 'utils/misc'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { createClusterAddon, updateClusterAddon } from '../new-actions'
import { parseMetalLbCidr } from '../MetalLbField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { parseMetal3IpAddressRange } from 'app/plugins/metal3/components/helpers'
import { keys, pick } from 'ramda'
import { etcdBackupDefaults } from '../etcd-backup'
import { updateCluster } from '../../newActions'
import { ClusterAddonType, MetallbParams, Metal3Params, IClusterAddon } from '../model'
import { getAddonParamFields } from './viewHelpers'
import { ClusterSelector } from '../../model'

const getTitle = (addonType, action) => {
  if (!addonType) return action
  return `${action} ${clusterAddonDisplayNames[addonType]}`
}

// Monitoring addon doesn't allow empty strings for optional parameters
// Add more addons as they are discovered
const addonsToFilterEmptyParams = [ClusterAddonType.Monitoring]

const filterEmptyParams = (params) => {
  const paramKeys = keys(params)
  return paramKeys.reduce((accum, key) => {
    if (isNilOrEmpty(params[key])) {
      return accum
    }
    return {
      ...accum,
      [key]: params[key],
    }
  }, {})
}

const getOverrideParams = (addonType, fieldValues, cluster) => {
  const overrideParams = pick(clusterAddonParams[addonType], fieldValues)
  if (addonType === ClusterAddonType.AwsAutoScaler) {
    overrideParams.clusterUUID = cluster?.clusterId || ''
    overrideParams.clusterRegion = cluster?.cloudProperties?.region
  }
  return addonsToFilterEmptyParams.includes(addonType)
    ? filterEmptyParams(overrideParams)
    : overrideParams
}

interface Props {
  addon: any
  open: any
  onClose: any
  action: 'Enable' | 'Edit'
  existingClusterAddons: IClusterAddon[]
  currentAddonVersions: any
  cluster: ClusterSelector
}

const paramsWithUnitsAttached = new Set(Object.keys(paramsWithUnits))

export default function AddonConfigurationsModal({
  addon,
  open,
  onClose,
  action,
  cluster,
  existingClusterAddons,
  currentAddonVersions,
}: Props) {
  const formFieldSetter = useRef(null)
  const {
    update: enableAddon,
    updating: enablingAddon,
    error: errorEnablingAddon,
  } = useUpdateAction(createClusterAddon)

  const {
    update: updateAddon,
    updating: updatingAddon,
    error: errorUpdatingAddon,
  } = useUpdateAction(updateClusterAddon)

  const {
    update: updateClusterObj,
    updating: updatingCluster,
    error: errorUpdatingCluster,
  } = useUpdateAction(updateCluster)

  const { clusterId, type, version, params } = addon || emptyObj

  useEffect(() => {
    // If editing the configurations of an existing addon, fill out the param
    // fields with the current param values
    if (action === 'Enable' || isNilOrEmpty(params)) return
    Object.entries(addon.params).forEach(([key, value]) => {
      let fieldKey = key
      let fieldValue = value

      if (type === ClusterAddonType.EtcdBackup && etcdBackupUIFieldIdMap[key]) {
        fieldKey = etcdBackupUIFieldIdMap[key]
      }
      if (key === MetallbParams.MetallbIpRange) {
        fieldValue = parseMetalLbCidr(value)
      }
      if (key === Metal3Params.Metal3DhcpRange) {
        fieldValue = parseMetal3IpAddressRange(value)
      }
      // Decode config values
      else if (configParams.has(key)) {
        fieldValue = decodeStr(value.toString())
      }
      // Strip units from values if needed (Ex. 100Mi -> 100)
      else if (paramsWithUnitsAttached.has(key)) {
        fieldValue = stripUnitFromValue(value)
      }
      formFieldSetter?.current?.setField(fieldKey)(fieldValue)
    })
  }, [action, params])

  const setUpFormFieldSetter = (setField) => {
    formFieldSetter.current = { setField }
  }

  const handleSubmit = async (values) => {
    if (type === ClusterAddonType.EtcdBackup) {
      const { success } = await updateClusterObj({
        uuid: clusterId,
        etcdBackup: { ...values },
        ...values,
      })
      if (success) onClose()
    } else {
      const overrideParams = getOverrideParams(type, values, cluster)
      if (action === 'Enable') {
        const { success } = await enableAddon({
          clusterId: clusterId || cluster?.uuid,
          clusterName: cluster?.name,
          clusterType: cluster?.clusterType,
          namespace: cluster?.namespace,
          type,
          version: currentAddonVersions[addonTypeToNameMap[type]],
          overrideParams,
        })
        if (success) onClose()
      } else {
        const { success } = await updateAddon({
          ...addon,
          clusterId: addon?.clusterId || cluster?.uuid,
          clusterName: cluster?.name,
          clusterType: cluster?.clusterType,
          namespace: cluster?.namespace,
          overrideParams,
        })
        if (success) onClose()
      }
    }
  }

  const AddonParamFields = useMemo(() => {
    return getAddonParamFields(addon?.type, action, existingClusterAddons)
  }, [addon?.type, action, existingClusterAddons])

  const initialValues = useMemo(
    () => ({
      clusterId: addon?.clusterId,
      kubeRoleVersion: cluster?.kubeRoleVersion,
      ...(addon?.type === ClusterAddonType.EtcdBackup && action === 'Enable'
        ? etcdBackupDefaults
        : {}),
    }),
    [addon?.clusterId, addon?.type, cluster?.kubeRoleVersion, action],
  )
  const submitting = enablingAddon || updatingAddon || updatingCluster
  //since there will be one error at a time
  const error = errorUpdatingCluster || errorEnablingAddon || errorUpdatingAddon

  return (
    <ModalForm
      title={getTitle(addon?.type, action)}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitTitle={action === 'Edit' ? 'Save Changes' : 'Enable'}
      fieldSetter={setUpFormFieldSetter}
      submitting={submitting}
      error={error}
      initialValues={initialValues}
    >
      {({ setFieldValue, values }) => (
        <FormFieldSection title="">
          {addon?.type && (
            <Suspense fallback="loading...">
              <AddonParamFields
                values={values}
                setFieldValue={setFieldValue}
                addonVersion={version}
                action={action}
              />
            </Suspense>
          )}
        </FormFieldSection>
      )}
    </ModalForm>
  )
}
