import React, { useMemo } from 'react'
import { pathStrOr } from 'utils/fp'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import { loadCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'
import Text from 'core/elements/Text'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'
import { dropLastWhile } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { cloudProviderRegionDetailsSelector } from '../cloudProviders/selectors'
import { listCloudProviderRegionDetails } from '../cloudProviders/new-actions'

interface ClusterDomainPicklistProps extends Omit<DropdownProps<string>, 'items'> {
  cloudProviderId: string
  cloudProviderRegionId: string
}

const renderLabel = (domain) => {
  const isPrivate = pathStrOr(false, 'Config.PrivateZone', domain)
  return (
    <div>
      <Text variant="body1">{domain.Name}</Text>
      <Text variant="caption2">
        {pathStrOr('', 'Config.Comment', domain)} ({isPrivate ? 'private' : 'public'})
      </Text>
    </div>
  )
}

export default function ClusterDomainPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: ClusterDomainPicklistProps) {
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const domains = useMemo(() => {
    const allDomains = pathStrOr([], '0.domains', regionDetails)
    return allDomains.map((x) => ({
      ...x,
      Name: dropLastWhile((x) => x === '.', x.Name),
    }))
  }, [regionDetails])

  const options = useMemo(
    () =>
      domains.map((x) => ({
        key: x.Id, // Added a key otherwise DropdownItem will take the value obj as key
        label: renderLabel(x),
        value: { domainId: x.Id, label: x.Name },
        labelName: x.Name,
      })),
    [domains],
  )
  return <Picklist {...rest} loading={loadingCloudProviderRegionDetails} items={options} />
}
