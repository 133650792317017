import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useMemo } from 'react'
import Text from 'core/elements/Text'
import ClusterStatusSpan from '../../ClusterStatusSpan'
import { CapiClusterPhases } from '../model'
import { getClusterAddonHealthStatus } from '../../cluster-addons/helpers'
import { AllResourceStatuses, getNodeGroupStatuses } from '../helpers'

const getAddonsStatuses = (addons) => {
  const statuses: AllResourceStatuses = {
    Healthy: {
      count: 0,
      tooltipText: 'Healthy',
      variant: 'ok',
    },
    Installing: {
      count: 0,
      tooltipText: 'Installing',
      variant: 'pause',
    },
    Error: {
      count: 0,
      tooltipText: 'Error',
      variant: 'error',
    },
    Unknown: {
      count: 0,
      tooltipText: 'Unknown',
      variant: 'unknown',
    },
  }
  return addons.reduce((accum, addon) => {
    const addonStatus = getClusterAddonHealthStatus(addon)
    if (!addonStatus || !statuses[addonStatus]) return accum
    accum[addonStatus].count += 1
    return accum
  }, statuses)
}

const MultipleStatuses = ({ statuses }: { statuses: AllResourceStatuses }) => {
  const classes = useStyles()
  const numStatuses = Object.keys(statuses).length
  return (
    <div className={classes.nodeGroupStatuses}>
      {Object.entries(statuses).map(([key, { count, variant, tooltipText }], idx) => {
        return (
          <div key={key} className={classes.status}>
            <ClusterStatusSpan status={variant} title={tooltipText}>
              {count}
            </ClusterStatusSpan>
            {idx < numStatuses - 1 && <div className={classes.divider} />}
          </div>
        )
      })}
    </div>
  )
}

export default function CapiDetailsPageHeader({ cluster, nodeGroups, addons }) {
  const classes = useStyles()
  const infraProvisionedStatus = cluster?.phase === CapiClusterPhases.Provisioned ? 'ok' : 'fail'
  const infraReadyStatus = cluster?.infrastructureReady ? 'ok' : 'fail'
  const controlPlaneStatus = cluster?.controlPlaneReady ? 'ok' : 'fail'
  const nodeGroupsStatuses = useMemo(() => getNodeGroupStatuses(nodeGroups), [nodeGroups])
  const addonsStatuses = useMemo(() => getAddonsStatuses(addons), [addons])

  return (
    <header className={classes.headerBar}>
      <div className={classes.info}>
        <Text variant="subtitle2" className={classes.label}>
          Infrastructure
        </Text>
        <div className={classes.statuses}>
          <ClusterStatusSpan status={infraProvisionedStatus}>
            {cluster?.phase === CapiClusterPhases.Provisioned ? 'Provisioned' : 'Not Provisioned'}
          </ClusterStatusSpan>
          <ClusterStatusSpan status={infraReadyStatus}>
            {' '}
            {cluster?.infrastructureReady ? 'Ready' : 'Not Ready'}
          </ClusterStatusSpan>
        </div>
      </div>
      <div className={classes.info}>
        <Text variant="subtitle2" className={classes.label}>
          Control Plane
        </Text>
        <div className={classes.statuses}>
          <ClusterStatusSpan status={controlPlaneStatus}>
            {cluster?.controlPlaneReady ? 'Ready' : 'Not Ready'}
          </ClusterStatusSpan>
        </div>
      </div>
      <div className={classes.info}>
        <Text variant="subtitle2" className={classes.label}>
          Node Groups
        </Text>
        <MultipleStatuses statuses={nodeGroupsStatuses} />
      </div>
      <div className={classes.info}>
        <Text variant="subtitle2" className={classes.label}>
          Add-ons
        </Text>
        <MultipleStatuses statuses={addonsStatuses} />
      </div>
    </header>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  headerBar: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(264px, 1fr))',
    height: '100px',
    margin: '7px 20px 56px 0px',
    backgroundColor: theme.components.card.background,
    width: '100%',
  },
  info: {
    display: 'grid',
    borderLeft: `1px solid ${theme.components.card.border}`,
    padding: theme.spacing(2, 3),
    marginRight: theme.spacing(2),
  },
  statuses: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  nodeGroupStatuses: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(1),
  },
  status: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(1),
  },
  divider: {
    height: '75%',
    borderLeft: `1px solid ${theme.components.card.border}`,
  },
}))
