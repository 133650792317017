import React, { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import clsx from 'clsx'
import Tooltip from 'core/elements/tooltip'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { topMiddle } from 'core/elements/menu/defaults'

interface Props {
  title: string
  fullWidth?: boolean
  className?: string
  info?: string
}
interface StyleProps {
  fullWidth: boolean
}

export default function CapiSpec({
  title,
  children,
  fullWidth = false,
  className,
  info = undefined,
}: PropsWithChildren<Props>) {
  const classes = useStyles({ fullWidth })

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.titleContainer}>
        <Text className={classes.label} variant="caption1" component="h5">
          {title}
        </Text>
        {info && (
          <Tooltip
            message={info}
            align={topMiddle.align}
            offset={topMiddle.offset}
            origin="right center"
          >
            <FontAwesomeIcon className={classes.icon}>question-circle</FontAwesomeIcon>
          </Tooltip>
        )}
      </div>
      <div className={classes.container}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '170px 1fr',
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
  },
  icon: {
    cursor: 'help',
    marginRight: 8,
    transition: 'color .2s ease',
    color: theme.components.input.label.hint,
  },
  container: {
    display: 'grid',
    minWidth: 400,
    // justifyContent: 'end',
    // gridTemplateColumns: ({ fullWidth }) =>
    //   fullWidth ? '1fr' : 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(3),
    alignItems: 'baseline',
    '& .dropdown-container': {
      width: 'inherit',
    },
  },
}))
