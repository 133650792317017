import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import ExternalLink from 'core/components/ExternalLink'
import { getFieldsForCard, IDetailFields } from 'core/components/InfoPanel'
import { routes } from 'core/utils/routes'
import InfoCard from 'k8s/components/common/entity/info-card'
import React, { useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { castBoolToStr } from 'utils/misc'

import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'

import { orderInterfaces } from './helpers'
import { INodesSelector } from './model'
import { DetailRow } from './NodeDetailsPage'
import { capiHostsSelector } from '../clusters/capi/details/overview/hosts/selectors'
import usePluginRouter from 'core/hooks/usePluginRouter'

const renderLink = (url, text) => <ExternalLink url={url}>{text}</ExternalLink>

const miscFields = [
  {
    id: 'cpuArchitecture',
    title: 'CPU Architecture',
  },
  {
    id: 'operatingSystem',
    title: 'Operating System',
  },
  {
    id: 'roles',
    title: 'Roles',
    render: (roles) => roles.map((role) => role + ' '),
  },
  {
    id: 'logs',
    title: 'Logs',
    render: (url) => renderLink(url, 'View Logs'),
  },
]

const useStyles = makeStyles<Theme>((theme) => ({
  nodeInfo: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: theme.spacing(2),
    alignItems: 'start',
    justifyItems: 'start',
  },
  miscNodeInfo: {
    display: 'grid',
    gridTemplateRows: 'max-content max-content',
    gridTemplateColumns: 'max-content',
    gridGap: theme.spacing(2),
  },
  networkInterfaces: {
    width: 'inherit !important',
  },
}))

const getMiscNodeDetails = (node) => getFieldsForCard(miscFields, node)

const renderNetworkInterfaceDetails = (interfaces, primaryNetwork) => {
  return (
    <table>
      <tbody>
        {interfaces.map(([interfaceName, interfaceIp]) => (
          <DetailRow
            key={interfaceIp}
            label={interfaceIp === primaryNetwork ? `${interfaceName} (primary)` : interfaceName}
            value={interfaceIp}
          />
        ))}
      </tbody>
    </table>
  )
}

const NodeInfo = () => {
  const { match } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const classes = useStyles({})
  useListAction(listNodes)
  const nodes = useSelector(nodesSelector)
  const hosts = useSelector(capiHostsSelector)
  const data = useMemo(() => [...nodes, ...hosts], [nodes, hosts])
  const node = data.find((x) => x.uuid === match.params.id)

  //@ts-ignore
  const { primaryNetwork, networkInterfaces } = node

  const miscDetails = getMiscNodeDetails(node)
  const networkInterfacesDetails = useMemo(() => {
    const fieldsToDisplay = {}
    const orderedInterfaces = orderInterfaces(networkInterfaces, primaryNetwork)
    orderedInterfaces.forEach(([interfaceName, interfaceIp]) => {
      fieldsToDisplay[
        interfaceIp === primaryNetwork ? `${interfaceName} (primary)` : interfaceName
      ] = {
        value: interfaceIp,
      }
    })
    return fieldsToDisplay
  }, [networkInterfaces, primaryNetwork])

  const k8sNodeDetailsFields: Array<IDetailFields<INodesSelector>> = useMemo(
    () => [
      {
        id: 'name',
        title: 'Name',
        required: true,
      },
      {
        id: 'uuid',
        title: 'Unique ID',
        required: true,
        helpMessage: 'This is the unique ID that PMK has assigned to this node.',
      },
      {
        id: 'primaryIp',
        title: 'Primary IP',
      },
      {
        id: 'isMaster',
        title: 'Is Master',
        render: castBoolToStr(),
      },
      {
        id: 'masterless',
        title: 'Masterless',
        render: castBoolToStr(),
      },
      {
        id: 'status',
        title: 'Status',
      },
      {
        id: 'api_responding',
        title: 'API Responding',
        render: castBoolToStr(),
      },
      {
        id: 'projectId',
        title: 'Project ID',
      },
      {
        id: 'startKube',
        title: 'Start Kube',
        render: castBoolToStr(),
      },
      {
        id: 'nodeKubeVersion',
        title: 'Actual Kube Role Version',
      },
      {
        id: 'cloudInstanceId',
        title: 'Cloud Instance ID',
      },
      {
        id: 'nodePoolUuid',
        title: 'Node Pool Unique ID',
      },
      {
        id: 'nodePoolName',
        title: 'Node Pool Name',
      },
      {
        id: 'clusterUuid',
        title: 'Cluster Unique ID',
      },
      {
        id: 'clusterName',
        title: 'Cluster Name',
        render: (value, { clusterUuid, clusterId, clusterName }) =>
          renderLink(
            currentPluginId === 'kubevirt'
              ? routes.kubevirtCluster.detail.path({ id: clusterUuid })
              : clusterUuid
              ? routes.cluster.managed.qbert.detail.path({ id: clusterUuid })
              : clusterName
              ? routes.cluster.managed.capi.details.path({ id: clusterId })
              : null,
            value,
          ),
      },
      {
        id: 'cloudProviderType',
        title: 'Cloud Provider Type',
      },
      {
        id: 'clusterKubeRoleVersion',
        title: 'Cluster Kube Role Version',
      },
      {
        id: 'isAuthorized',
        title: 'Is Authorized',
        render: castBoolToStr(),
      },
    ],
    [currentPluginId],
  )

  const getNodeDetails = useMemo(() => (node) => getFieldsForCard(k8sNodeDetailsFields, node), [
    k8sNodeDetailsFields,
  ])
  const details = getNodeDetails(node)

  return (
    <div className={classes.nodeInfo}>
      <InfoCard title="Kubernetes Node Details" items={details} />
      <div className={classes.miscNodeInfo}>
        <InfoCard title="Misc" items={miscDetails} />
        <InfoCard title="Network Interfaces" items={networkInterfacesDetails} />
      </div>
    </div>
  )
}

export default NodeInfo
