import React, { useMemo, useCallback } from 'react'
import { isNil, pick } from 'ramda'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import useReactRouter from 'use-react-router'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { allKey, listTablePrefs } from 'app/constants'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import useListAction from 'core/hooks/useListAction'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'
import { NodeHealthStatusCell } from 'app/plugins/infrastructure/components/nodes/node-cells/NodeHealthStatusCell'
import K8sNodeStatusCell from 'app/plugins/infrastructure/components/nodes/node-cells/K8sNodeStatusCell'
import NodeDetailCell from 'app/plugins/infrastructure/components/nodes/node-cells/NodeDetailCell'
import LogsCell from 'app/plugins/infrastructure/components/nodes/node-cells/LogsCell'
import UUIDCell from 'app/plugins/infrastructure/components/common/cells/UUIDCell'
import NetworkInterfacesCell from 'app/plugins/infrastructure/components/nodes/node-cells/NetworkInterfacesCell'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import NodeStatusCell from 'app/plugins/infrastructure/components/nodes/node-cells/NodeStatusCell'
import NodeApiHealthCell from 'app/plugins/infrastructure/components/nodes/node-cells/NodeApiHealthCell'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import RemoteSupportDialog from 'app/plugins/infrastructure/components/nodes/RemoteSupportDialog'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import NodeRolesPicklist from 'app/plugins/infrastructure/components/nodes/NodeRolesPicklist'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'

type SelectorModel = INodesSelector
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = {
  clusterId: string
  role: string
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'uuid',
    label: 'UUID',
    tooltip:
      'This is the unique ID generated by PMK for this node. You will need this ID to perform any API based operations with PMK',
    CellComponent: UUIDCell,
    display: false,
  } as GridViewColumn<SelectorModel, 'uuid'>,
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: NodeDetailCell,
  },
  {
    key: 'isMaster',
    label: 'Role',
    width: 'small',
    formatFn: (isMaster, node) => (isNil(node?.clusterUuid) ? '' : isMaster ? 'Master' : 'Worker'),
  } as GridViewColumn<SelectorModel, 'isMaster'>,
  { key: 'connectionStatus', label: 'Connection status', CellComponent: NodeStatusCell },
  {
    key: 'platform9ComponentsStatus',
    label: 'Platform9 Components',
    CellComponent: NodeHealthStatusCell,
  },
  { key: 'api_responding', label: 'API Server Health', CellComponent: NodeApiHealthCell },
  {
    key: 'ready',
    label: 'K8s Node Status',
    CellComponent: K8sNodeStatusCell,
  } as GridViewColumn<SelectorModel, 'ready'>,
  { key: 'logs', label: 'Logs', CellComponent: LogsCell } as GridViewColumn<SelectorModel, 'logs'>,
  { key: 'actualKubeRoleVersion', label: 'Node Kube Version' },
  { key: 'primaryIp', label: 'Network Interfaces', CellComponent: NetworkInterfacesCell },
  { key: 'usage', label: 'Resource Utilization', CellComponent: StatsCell } as GridViewColumn<
    SelectorModel,
    'resourceUtilization'
  >,
  { key: 'operatingSystem', label: 'Operating System' },
  {
    key: 'assignedRoles',
    label: 'Assigned Roles',
    formatFn: (roles) => roles.join(', '),
  } as GridViewColumn<SelectorModel, 'assignedRoles'>,
]

const usePrefParams = createUsePrefParamsHook('Nodes', listTablePrefs)

const batchActions: GridBatchActionSpec<SelectorModel>[] = [
  {
    cond: isAdmin,
    icon: 'headset',
    label: 'Remote Support',
    BatchActionButton: getGridDialogButton(RemoteSupportDialog),
  },
]

export default function ClusterNodes() {
  const { match } = useReactRouter()
  const clusterId: string = match.params.id
  const defaultParams = useMemo<Params>(
    () => ({
      role: allKey,
      clusterId,
    }),
    [clusterId],
  )
  const { params, getParamsUpdater } = usePrefParams(defaultParams)

  const filters = useMemo<GridFilterSpec<SelectorModel, Params>[]>(
    () => [
      {
        columnKey: 'isMaster',
        FilterComponent: NodeRolesPicklist,
        equalityComparerFn: (isMaster, selectedRole) => {
          const role = isMaster ? 'master' : 'worker'
          return selectedRole === allKey || selectedRole === role
        },
        onChange: getParamsUpdater('role'),
      } as GridFilterSpec<SelectorModel, Params, 'isMaster', 'role'>,
    ],
    [],
  )
  const { message, loading: loadingNodes, reload } = useListAction(listNodes, {
    params,
    requiredParams: ['clusterId'],
  })
  const { loading: loadingClusters } = useListAction(listClusters)
  const handleRefresh = useCallback(() => reload(true), [reload])

  const nodes = useSelectorWithParams(nodesSelector, params)
  const clusters = useSelectorWithParams(clustersSelector, params)

  const nodesInCluster = useMemo(() => {
    const cluster = clusters.find((cluster) => cluster.uuid === clusterId)
    if (cluster) {
      const clusterNodesUids = cluster.nodes.map(({ uuid }) => uuid)
      return nodes.filter((node) => clusterNodesUids.includes(node.uuid))
    }
    return []
  }, [clusters, nodes])

  return (
    <Grid<SelectorModel>
      label="Nodes"
      compact
      uniqueIdentifier="uuid"
      loading={loadingClusters || loadingNodes}
      loadingMessage={message}
      onRefresh={handleRefresh}
      data={nodesInCluster}
      columns={columns}
      filters={filters}
      batchActions={batchActions}
      emptyContent="Nothing yet, waiting for nodes..."
      onColumnsChange={getParamsUpdater('visibleColumns', 'columnsOrder')}
      {...pick(listTablePrefs, params)}
    />
  )
}
