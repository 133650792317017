import createContextLoader from 'core/helpers/createContextLoader'
import ApiClient from 'api-client/ApiClient'
import { loadServiceCatalog } from 'k8s/components/apiAccess/endpoints/actions'
import { loadResMgrHosts } from 'app/plugins/infrastructure/components/common/actions'
import createContextUpdater from 'core/helpers/createContextUpdater'
import { trackEvent } from 'utils/tracking'
import {
  nodesSelector,
  makeParamsNodesSelector,
} from 'app/plugins/infrastructure/components/nodes/selectors'
import DataKeys from 'k8s/DataKeys'
import { Resmgr } from './model'
import { Node, ClusterElement } from 'api-client/qbert.model'
import { isUnauthorizedHost } from './helpers'
import Bugsnag from 'utils/bugsnag'
import listFnWithDependencies from 'core/helpers/list-with-dependencies'
import { someAsync } from 'utils/async'
import { flatten, pluck } from 'ramda'
import { k8sNodesSelector } from './k8sNodes-selectors'

const { keystone, qbert, resMgr } = ApiClient.getInstance()

export const getDownloadLinks = async () => {
  return keystone.getDownloadLinks()
}

export const loadK8sNodes = createContextLoader(
  DataKeys.K8sNodes,
  async () => {
    const clusters = await qbert.getClusters()
    return someAsync(pluck<'uuid', ClusterElement>('uuid', clusters).map(qbert.getK8sNodes)).then(
      flatten,
    )
  },
  {
    uniqueIdentifier: 'metadata.uid',
    selector: k8sNodesSelector,
  },
)

export const loadNodes = createContextLoader(
  DataKeys.Nodes,
  listFnWithDependencies(async () => {
    const [rawNodes, hosts] = await Promise.all([qbert.getNodes(), loadResMgrHosts()])

    // Find the unauthorized nodes from Resmgr
    // TODO: maybe instead we should always define this model in resmgr
    const unauthorizedNodes: Node[] = hosts
      .filter((host: Resmgr) => isUnauthorizedHost(host))
      .map((host) => {
        return {
          name: host.info.hostname,
          uuid: host.id,
          isAuthorized: false,
        }
      })
    const authorizedNodes: Node[] = rawNodes.map((node) => {
      return {
        ...node,
        isAuthorized: true, // all nodes that are obtained from Qbert are authorized
      }
    })

    return [...authorizedNodes, ...unauthorizedNodes]
  }, [loadK8sNodes, loadServiceCatalog]),
  {
    uniqueIdentifier: 'uuid',
    selector: nodesSelector,
    selectorCreator: makeParamsNodesSelector,
  },
)

export const authNode = createContextUpdater(
  DataKeys.Nodes,
  async (node) => {
    Bugsnag.leaveBreadcrumb('Attempting to authorize node', { node })
    await resMgr.addRole(node.uuid, 'pf9-kube', {})
    trackEvent('Authorize Node', {
      node_name: node.name,
    })
    return loadNodes()
  },
  {
    operation: 'authNode',
  },
)

export const deAuthNode = createContextUpdater(
  DataKeys.Nodes,
  async (node) => {
    Bugsnag.leaveBreadcrumb('Attempting to unauthorize node', { node })
    await resMgr.unauthorizeHost(node.uuid)
    trackEvent('Deauthorize Node', {
      node_name: node.name,
    })
    return loadNodes()
  },
  {
    operation: 'deAuthNode',
  },
)
