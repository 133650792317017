import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React from 'react'
import Text from 'core/elements/Text'
import clsx from 'clsx'

export default function CapiClustersNodeGroupsHeader({
  items,
  className,
}: {
  items: { label: string; value: number }[]
  className?: string
}) {
  const classes = useStyles()
  return (
    <header className={clsx(className, classes.header)}>
      {items.map(({ label, value }) => (
        <div className={classes.info} key={label}>
          <Text variant="caption1" className={classes.label}>
            {label}
          </Text>
          <Text variant="h2" className={classes.value}>
            {value || 0}
          </Text>
        </div>
      ))}
    </header>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
    margin: '8px 20px 64px 0px',
    backgroundColor: theme.components.card.background,
    width: '100%',
  },
  info: {
    display: 'grid',
    borderLeft: `1px solid ${theme.components.card.border}`,
    padding: theme.spacing(3, 1, 3, 2),
    marginRight: theme.spacing(2),
    maxWidth: 500,
  },
  value: {
    fontWeight: 400,
  },
}))
