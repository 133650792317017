import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { CloudProviderRequirementTD } from 'k8s/components/common/CloudProviderRequirementTD'

export default function AzureMinimumPermissions() {
  const classes = useStyles()

  return (
    <>
      <Text variant="subtitle2">Minimum Permissions Required</Text>
      <table className={classes.table}>
        <tbody>
          <tr>
            <CloudProviderRequirementTD text="Azure VM Instance managment" />
            <CloudProviderRequirementTD text="Azure Traffic Manager managment" />
          </tr>
          <tr>
            <CloudProviderRequirementTD text="Azure Application Gateway mangement" />
            <CloudProviderRequirementTD text="Azure Managed Disks management" />
          </tr>
        </tbody>
      </table>
    </>
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    minWidth: '100%',
    tableLayout: 'fixed',
    borderSpacing: 0,
    marginTop: theme.spacing(2),
    borderColor: theme.components.selectableCard.border,
    borderStyle: 'solid',
    borderWidth: '0 1px  1px  1px',
    borderRadius: 4,

    '& tr td': {
      padding: theme.spacing(1),
      border: 0,
      borderColor: theme.components.selectableCard.border,
      borderStyle: 'solid',
      '&:first-child': {
        borderTopWidth: '1px',
        borderRightWidth: '1px',
      },
      '&:last-child': {
        borderTopWidth: '1px',
      },
    },
  },
}))
