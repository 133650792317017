import Accordion from 'core/components/accordion/Accordion'
import FormReviewTable from 'core/components/validatedForm/review-table'
import React from 'react'
import { objToCommaSeperatedString, objToKeyValueArr } from 'utils/fp'
import KeyValueReview from './KeyValueReview'

const columns = [
  {
    id: 'apiserver.apiGroupsAndVersions',
    label: 'Advanced API Configuration',
    render: (value) => {
      switch (value) {
        case '-': // AS the review table adds a '-' to empty values
          return 'Default API groups and versions'
        case 'api/all=true':
          return 'All API groups and versions'
        default:
          return `(Custom) ${value}` //Custom:
      }
    },
  },
  {
    id: 'apiServerFlag',
    label: 'API Server Flags',
    render: (flags) => objToCommaSeperatedString(flags),
  },
  {
    id: 'schedulerFlag',
    label: 'Scheduler Flags',
    render: (flags) => objToCommaSeperatedString(flags),
  },
  {
    id: 'controllerManagerFlag',
    label: 'Controller Manager Flags',
    render: (flags) => objToCommaSeperatedString(flags),
  },
]

export default function CapiConfigurationReview({ wizardContext, open }) {
  return (
    <>
      <Accordion open={open} id="configuration" title="Configuration (Advanced Options)">
        <FormReviewTable data={wizardContext} columns={columns} />
      </Accordion>
      {!!wizardContext?.tags && (
        <Accordion open={open} id="tags" title="Tags (Advanced Options)">
          <KeyValueReview
            items={
              objToKeyValueArr(wizardContext?.additionalTags || {}) as Record<string, string>[]
            }
          />
        </Accordion>
      )}
    </>
  )
}
