import makeStyles from '@material-ui/styles/makeStyles'
import Text from 'core/elements/Text'
import React, { useMemo } from 'react'
import { ensureArray } from 'utils/fp'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  zone: {
    display: 'inline-flex',
  },
  azDivider: {
    height: '15px',
    width: 0,
    border: `1px solid ${theme.components.card.border}`,
    margin: theme.spacing(0.5, 1),
  },
}))

const AvailabilityZones = ({ azs = [] }) => {
  const classes = useStyles()
  const numZones = useMemo(() => azs.length, [azs])
  return (
    <div className={classes.azs}>
      {azs.map((zone, idx) => (
        <div key={zone} className={classes.zone}>
          <Text variant="caption1">{zone}</Text>
          {idx !== numZones - 1 && <hr className={classes.azDivider} />}
        </div>
      ))}
    </div>
  )
}

export const renderAvailabilityZones = (azs) => <AvailabilityZones azs={azs} />

export const renderCidrRanges = (value) => {
  const cidrRanges = ensureArray(value)
  return (
    <>
      {cidrRanges.map((range) => (
        <Text key={range} variant="caption1" component="p">
          {range}
        </Text>
      ))}
    </>
  )
}
