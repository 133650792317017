import React, { useCallback, useEffect, useState } from 'react'
import TextField from 'core/components/validatedForm/TextField'
import Row from 'core/containers/Row'
import CapiSpec from './capi/CapiSpec'
import TaintEffectsPicklist from '../form-components/TaintEffectsPicklist'
import { assoc } from 'ramda'
import WorkerNodeGroupTypesPicklist from './WorkerNodeGroupTypesPicklist'
import BooleanPicklistField from '../form-components/BooleanPicklistField'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/core/styles'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import CapiAwsManagedMachinePoolNodeUpdateStrategies from './capi/CapiAwsManagedMachinePoolNodeUpdateStrategies'
import CapiAwsAvailabilityZoneField from './capi/capi-vpc/CapiAwsAvailiabilityZoneField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import MixedInstancesPolicyFields from './capi/MixedInstancesPolicyFields'
import { spotMaxPriceValidator } from 'core/utils/fieldValidators'
import AwsRegionFlavorPicklist from './AwsRegionFlavorPicklist'
import WorkerNodeInstanceTypeField from '../form-components/WorkerNodeInstanceTypeField'
import { keyValueArrToObj, objToKeyValueArr } from 'utils/fp'
import CapiMachinePoolNodeUpdateStrategies from './capi/CapiMachinePoolNodeUpdateStrategies'
import CapiMachineDeploymentNodeUpdateStrategies from './capi/CapiMachineDeploymentNodeUpdateStrategies'
import AwsAmiField from '../form-components/AwsAmiField'
import AwsAmiPicklist, { cloudInit } from './capi/AwsAmiPicklist'
import {
  amiVersionInfo,
  eksNodeGroupTypeInfo,
  labelsTooltipDescription,
  makeNodesPublicTooltipDescription,
  taintsTooltipDescription,
} from '../capi/constants'
import { ConfigTypes, NodeGroupTypes } from '../capi/model'
import EksAmiTypePicklist from '../capi/eks-ami-types/EksAmiTypePicklist'

const machinePoolTypeOptions = [
  { label: 'AWS Managed Machine Pool', value: NodeGroupTypes.AwsManagedMachinePool },
  { label: 'AWS Machine Pool', value: NodeGroupTypes.AwsMachinePool },
]

const getWorkerNodeGroupNameSuffix = (
  type: NodeGroupTypes.MachinePool | NodeGroupTypes.MachineDeployment,
  machinePoolType: NodeGroupTypes.AwsMachinePool | NodeGroupTypes.AwsManagedMachinePool,
) => {
  return type === NodeGroupTypes.MachinePool &&
    machinePoolType === NodeGroupTypes.AwsManagedMachinePool
    ? 'mmp'
    : type === NodeGroupTypes.MachinePool
    ? 'mp'
    : type === NodeGroupTypes.MachineDeployment
    ? 'md'
    : 'worker-node'
}

const getConfigKind = (isEksCluster, workerNodeType: NodeGroupTypes): ConfigTypes => {
  return isEksCluster
    ? workerNodeType === NodeGroupTypes.MachineDeployment
      ? ConfigTypes.EksConfigTemplate
      : ConfigTypes.EksConfig
    : workerNodeType === NodeGroupTypes.MachineDeployment
    ? ConfigTypes.NodeletConfigTemplate
    : ConfigTypes.NodeletConfig
}
export default function WorkerNodeGroupFields({
  wizardContext,
  values,
  onChange,
  isEksCluster = false,
  index = 0,
}) {
  const classes = useStyles()
  const [workerNodeGroup, setWorkerNodeGroup] = useState(values)

  useEffect(() => {
    handleChanges([
      {
        key: 'name',
        value: `${wizardContext?.name}-${getWorkerNodeGroupNameSuffix(
          workerNodeGroup?.type,
          workerNodeGroup?.machinePoolType,
        )}-${index}`,
      },
      { key: 'clusterName', value: wizardContext?.name },
      { key: 'sshKey', value: wizardContext?.sshKey },
      { key: 'wgVersion', value: wizardContext?.version },
      { key: 'clusterType', value: wizardContext?.type },
      { key: 'config', value: getConfigKind(isEksCluster, workerNodeGroup.type) },
      { key: 'namespace', value: wizardContext?.namespace },
    ])
  }, [
    wizardContext?.name,
    wizardContext?.sshKey,
    wizardContext?.type,
    wizardContext?.namespace,
    workerNodeGroup?.type,
    workerNodeGroup?.machinePoolType,
    isEksCluster,
  ])

  const getOnChangeHandler = useCallback(
    (field) => (value) => {
      setWorkerNodeGroup((prev) => {
        const updatedGroup = assoc(field, value, prev)
        onChange(updatedGroup)
        return updatedGroup
      })
    },
    [onChange],
  )

  const handleChanges = useCallback(
    (keyValues: { key: string; value: any }[] = []) => {
      setWorkerNodeGroup((prev) => {
        const updatedGroup = keyValues.reduce((acc, { key, value }) => assoc(key, value, acc), prev)
        onChange(updatedGroup)
        return updatedGroup
      })
    },
    [onChange],
  )

  return (
    <div>
      <CapiSpec title="Worker Node Group">
        <Row minItemWidth="300" gap={24}>
          {/* Worker Node Group Type --> Machine Deployement or Machine Pool */}
          <WorkerNodeGroupTypesPicklist
            id={`workerNodeGroups.${index}.type`}
            value={workerNodeGroup.type}
            onChange={(value) => {
              getOnChangeHandler('type')(value)
              getOnChangeHandler('config')(getConfigKind(isEksCluster, value))
            }}
            info={isEksCluster ? eksNodeGroupTypeInfo : null}
            required
          />
          {/* Machine Pool  Type --> AWS Machine Pool  or AWS Managed Machine Pool */}
          {isEksCluster && workerNodeGroup.type === NodeGroupTypes.MachinePool && (
            <DropdownField
              DropdownComponent={Dropdown}
              id={`workerNodeGroups.${index}.machinePoolType`}
              label="Machine Pool Type"
              value={workerNodeGroup.machinePoolType}
              onChange={getOnChangeHandler('machinePoolType')}
              items={machinePoolTypeOptions}
            />
          )}
        </Row>
        {/* Name */}
        <Row minItemWidth="300" gap={24}>
          <TextField
            id={`workerNodeGroups.${index}.name`}
            label="Name"
            value={workerNodeGroup.name}
            onChange={(value) => {
              getOnChangeHandler('name')(value)
              getOnChangeHandler('machineName')(`${workerNodeGroup?.clusterName}-${value}`)
              getOnChangeHandler('configName')(`${workerNodeGroup?.clusterName}-${value}`)
            }}
            required
          />
          {/* Not needed as per the latest update from Anmol */}
          {/* <KubernetesVersionField
            id={`workerNodeGroups.${index}.wgVersion`}
            wizardContext={workerNodeGroup}
            value={workerNodeGroup.wgVersion}
            showAwsEksVersions={isEksCluster}
            setWizardContext={(valueObj = {}) =>
              handleChange('wgVersion')(Object.values(valueObj)[0])
            }
          /> */}
        </Row>
        {/* Availabilty Zones  */}
        {workerNodeGroup.type === NodeGroupTypes.MachinePool && (
          <Row minItemWidth="300" gap={24}>
            <CapiAwsAvailabilityZoneField
              id={`workerNodeGroups.${index}.availabilityZones`}
              wizardContext={wizardContext}
              value={workerNodeGroup.availabilityZones}
              onChange={getOnChangeHandler('availabilityZones')}
              isEksCluster={isEksCluster}
              showOnlyClusterAzs={true}
            />
          </Row>
        )}
        {/* Node Count  */}
        <Row minItemWidth="300" gap={24}>
          <TextField
            id={`workerNodeGroups.${index}.nodeCount`}
            label="Node Count"
            value={workerNodeGroup.nodeCount}
            type="number"
            onChange={(value) => {
              getOnChangeHandler('nodeCount')(value)
              getOnChangeHandler('minCasSize')(workerNodeGroup.cas ? value?.toString() : '')
              getOnChangeHandler('scaling')(assoc('maxSize', value, workerNodeGroup.scaling || {})) // only useful for AwsManagedMachinePool
            }}
            min="1"
            required
          />
          {/* Instance Type  */}
          <WorkerNodeInstanceTypeField
            dropdownComponent={AwsRegionFlavorPicklist}
            label="Instance Type"
            values={wizardContext}
            value={workerNodeGroup.instanceType}
            onChange={getOnChangeHandler('instanceType')}
          />
        </Row>
        <Row minItemWidth="300" gap={24}>
          {isEksCluster &&
          workerNodeGroup.type === NodeGroupTypes.MachinePool &&
          workerNodeGroup.machinePoolType === NodeGroupTypes.AwsManagedMachinePool ? (
            <>
              {/* Operating System AMI Type  */}
              <DropdownField
                DropdownComponent={EksAmiTypePicklist}
                id={`workerNodeGroups.${index}.amiType`}
                label="Operating System AMI Type"
                value={workerNodeGroup.amiType}
                onChange={getOnChangeHandler('amiType')}
                identityName={wizardContext.provider}
              />
              {/* Operating System AMI Version (Optional) */}
              <TextField
                id={`workerNodeGroups.${index}.amiVersion`}
                label="Operating System AMI Version (Optional)"
                value={workerNodeGroup.amiVersion}
                onChange={getOnChangeHandler('amiVersion')}
                info={amiVersionInfo}
              />
            </>
          ) : (
            // AMI ID
            !isEksCluster && (
              <>
                <AwsAmiField
                  id={`workerNodeGroups.${index}.amiId`}
                  dropdownComponent={AwsAmiPicklist}
                  label="Operating System"
                  version={wizardContext?.version}
                  identityName={wizardContext.provider}
                  roleArn={wizardContext.assumeRoleTargetArn}
                  region={wizardContext?.region}
                  onChange={(value) => {
                    value === 'custom'
                      ? getOnChangeHandler('isCustomAmiId')(true)
                      : handleChanges([
                          { key: 'amiId', value },
                          // { key: 'amiId', value: selectedItem?.id },
                          { key: 'isCustomAmiId', value: false },
                        ])
                  }}
                />
                <TextField
                  id={`workerNodeGroups.${index}.customAmiId`}
                  label="Custom AMI ID (optional)"
                  placeholder="Enter Value..."
                  disabled={!workerNodeGroup.isCustomAmiId}
                  onChange={(value) =>
                    handleChanges([
                      { key: 'amiId', value },
                      {
                        key: 'cloudInit',
                        value: cloudInit,
                      },
                    ])
                  }
                />
              </>
            )
          )}
        </Row>
        {!isEksCluster && (
          <Row minItemWidth="300" gap={24}>
            <BooleanPicklistField
              id={`workerNodeGroups.${index}.publicIP`}
              label="Make Nodes Public"
              value={workerNodeGroup.publicIP}
              onChange={getOnChangeHandler('publicIP')}
              tooltip={makeNodesPublicTooltipDescription}
            />
          </Row>
        )}
        <div className={classes.spacer} />

        {/* Auto Scaling */}
        {workerNodeGroup.type === NodeGroupTypes.MachineDeployment && (
          <>
            <ToggleSwitch
              label="Enable Autoscaling"
              active={!!workerNodeGroup.cas}
              onClick={(value) =>
                value
                  ? handleChanges([
                      { key: 'cas', value: {} },
                      { key: 'minCasSize', value: workerNodeGroup?.nodeCount?.toString() },
                      { key: 'maxCasSize', value: '10' },
                    ])
                  : handleChanges([
                      { key: 'cas', value: null },
                      { key: 'minCasSize', value: '' },
                      { key: 'maxCasSize', value: '' },
                    ])
              }
            />
            {!!workerNodeGroup?.cas && (
              <Row minItemWidth="300" gap={24}>
                <TextField
                  id={`workerNodeGroups.${index}.minAutoScalingSize`}
                  label="Min Number of Workers"
                  value={workerNodeGroup.minCasSize}
                  onChange={(value) => getOnChangeHandler('minCasSize')(value?.toString())}
                  type="number"
                  min={workerNodeGroup.nodeCount}
                  max={workerNodeGroup.maxCasSize}
                  required
                />
                <TextField
                  id={`${workerNodeGroup?.id}-maxAutoScalingSize`}
                  label="Max Number of Workers"
                  value={workerNodeGroup.maxCasSize}
                  onChange={(value) => getOnChangeHandler('maxCasSize')(value?.toString())}
                  type="number"
                  min={workerNodeGroup.nodeCount}
                  required
                />
              </Row>
            )}
          </>
        )}
        {/* Spot Instances */}
        {(workerNodeGroup.type === NodeGroupTypes.MachineDeployment ||
          workerNodeGroup.machinePoolType === NodeGroupTypes.AwsManagedMachinePool) && (
          <>
            <ToggleSwitch
              label="Enable Spot Instances"
              active={
                workerNodeGroup.machinePoolType === NodeGroupTypes.AwsManagedMachinePool
                  ? workerNodeGroup?.capacityType === 'spot'
                  : !!workerNodeGroup?.spotMarketOptions
              }
              onClick={(value) =>
                workerNodeGroup.machinePoolType === NodeGroupTypes.AwsManagedMachinePool
                  ? value
                    ? getOnChangeHandler('capacityType')('spot')
                    : getOnChangeHandler('capacityType')('onDemand')
                  : value
                  ? getOnChangeHandler('spotMarketOptions')({ maxPrice: '' })
                  : getOnChangeHandler('spotMarketOptions')('')
              }
            />
            {workerNodeGroup.machinePoolType !== NodeGroupTypes.AwsManagedMachinePool &&
              workerNodeGroup.spotMarketOptions && (
                <Row minItemWidth="300" gap={24}>
                  <TextField
                    id={`workerNodeGroups.${index}.spotMaxPrice`}
                    label="Spot Instance Maximum Price"
                    onChange={(value) =>
                      getOnChangeHandler('spotMarketOptions')({ maxPrice: value })
                    }
                    placeholder="Empty Means Max Price (Type String)"
                    validations={[spotMaxPriceValidator]}
                  />
                </Row>
              )}
            <div className={classes.spacer} />
          </>
        )}
        {/* Mixed Instances */}
        {workerNodeGroup.type === NodeGroupTypes.MachinePool &&
          workerNodeGroup.machinePoolType === NodeGroupTypes.AwsMachinePool && (
            <MixedInstancesPolicyFields
              workerNodeGroup={workerNodeGroup}
              onChange={getOnChangeHandler}
            />
          )}
        {/* Nodes Update Strategy */}
        {workerNodeGroup.type === NodeGroupTypes.MachinePool &&
          workerNodeGroup.machinePoolType === NodeGroupTypes.AwsManagedMachinePool && (
            <CapiAwsManagedMachinePoolNodeUpdateStrategies
              workerNodeGroup={workerNodeGroup}
              onChange={getOnChangeHandler}
            />
          )}
        {/* Nodes Update Strategy - Machine Pool*/}
        {workerNodeGroup.type === NodeGroupTypes.MachinePool &&
          workerNodeGroup.machinePoolType === NodeGroupTypes.AwsMachinePool && (
            <CapiMachinePoolNodeUpdateStrategies
              workerNodeGroup={workerNodeGroup}
              onChange={getOnChangeHandler}
            />
          )}
        {/* Nodes Update Strategy - Machine Deployment*/}
        {workerNodeGroup.type === NodeGroupTypes.MachineDeployment && (
          <CapiMachineDeploymentNodeUpdateStrategies
            workerNodeGroup={workerNodeGroup}
            onChange={getOnChangeHandler}
          />
        )}
      </CapiSpec>
      {(!isEksCluster ||
        workerNodeGroup.machinePoolType === NodeGroupTypes.AwsManagedMachinePool) && (
        <>
          <CapiSpec title="Labels" className={classes.section} info={labelsTooltipDescription}>
            <KeyValuesField
              id={`workerNodeGroups.${index}.labels`}
              value={objToKeyValueArr(workerNodeGroup.labels)}
              onChange={(value) => getOnChangeHandler('labels')(keyValueArrToObj(value))}
              addLabel="Add Label"
              allowMultipleValues
            />
          </CapiSpec>
          <CapiSpec title="Taints" info={taintsTooltipDescription}>
            <KeyValuesField
              id={`workerNodeGroups.${index}.taints`}
              value={workerNodeGroup.taints}
              onChange={getOnChangeHandler('taints')}
              addLabel="Add Taint"
              allowMultipleValues
              additionalFields={[
                {
                  id: 'effect',
                  label: 'Effects',
                  Component: TaintEffectsPicklist,
                  props: {
                    isAwsManagedMachinePool:
                      workerNodeGroup.type === NodeGroupTypes.MachinePool &&
                      workerNodeGroup.machinePoolType === NodeGroupTypes.AwsManagedMachinePool,
                  },
                },
              ]}
            />
          </CapiSpec>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  section: {
    margin: theme.spacing(4, 0),
  },
  spacer: {
    height: theme.spacing(1),
  },
}))
