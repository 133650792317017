import React from 'react'

import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'

import FormReviewTable from 'core/components/validatedForm/review-table'
import { NetworkBackendTypes } from '../form-components/NetworkBackendField'

const getFilteredNodesFormattedName = (nodes, filterList = []) =>
  nodes
    .filter((node) => filterList.includes(node.uuid))
    .map((node) => `${node.name} - ${node.primaryIp}`)

const calicoFields = ['calicoIpIpMode', 'calicoNatOutgoing', 'calicoBlockSize']
const BareOsClusterReviewTable = ({ wizardContext, columns }) => {
  useListAction(listNodes)
  const nodes = useSelector(nodesSelector)

  let reviewTableColumns = columns
  const data = {
    ...wizardContext,
    masterNodes: getFilteredNodesFormattedName(nodes, wizardContext.masterNodes),
    workerNodes: getFilteredNodesFormattedName(nodes, wizardContext.workerNodes),
  }
  if (wizardContext.networkPlugin !== NetworkBackendTypes.Calico) {
    reviewTableColumns = columns.filter((column) => !calicoFields.includes(column.id))
  }

  return <FormReviewTable data={data} columns={reviewTableColumns} />
}

export default BareOsClusterReviewTable
