import React from 'react'
import TextField from 'core/components/validatedForm/TextField'
import NodeInterfacesPicklist from 'app/plugins/infrastructure/components/nodes/NodeInterfacesPicklist'
import StorageClassesPicklist from 'k8s/components/storage/StorageClassesPicklist'
import DropdownField from 'core/components/validatedForm/DropdownField'
import { paramDisplayNames } from '../helpers'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import { Metal3Params } from '../model'

export default function Metal3ParamFields({ setFieldValue, values, showStorageClassField = true }) {
  return (
    <>
      <DropdownField
        DropdownComponent={NodeInterfacesPicklist}
        id={Metal3Params.Metal3DhcpInterface}
        name={Metal3Params.Metal3DhcpInterface}
        label={paramDisplayNames[Metal3Params.Metal3DhcpInterface]}
        clusterId={values?.clusterId}
        onChange={setFieldValue(Metal3Params.Metal3DhcpInterface)}
        value={values?.Metal3DhcpInterface}
        values={values}
        tooltip="This is the name of the PXE network interface on the cluster nodes
        (example: 'en0'). The ironic DNSmasq service listens on this interface for
        any DHCP requests."
        required
      />
      <KeyValuesField
        id={Metal3Params.Metal3DhcpRange}
        label={paramDisplayNames[Metal3Params.Metal3DhcpRange]}
        onChange={setFieldValue(Metal3Params.Metal3DhcpRange)}
        info="Pool of IP addresses that Metal3 can allocate an IP address from for a bare
          metal node. (example: 192.168.52.230, 192.168.52.250)"
        keyLabel="Start Address"
        valueLabel="End Address"
        allowMultipleValues={false}
        required
      />
      <TextField
        id={Metal3Params.Metal3DhcpGateway}
        label={paramDisplayNames[Metal3Params.Metal3DhcpGateway]}
        onChange={setFieldValue(Metal3Params.Metal3DhcpGateway)}
        info="Gateway IP address in the same subnet of the DHCP range used for PXE provisioning."
        required
      />
      <TextField
        id={Metal3Params.Metal3DnsServer}
        label={`${paramDisplayNames[Metal3Params.Metal3DnsServer]} (Optional)`}
        onChange={setFieldValue(Metal3Params.Metal3DnsServer)}
        info="IP address of the DNS server accessible from the PXE provisioning network
        (example: 8.8.8.8)"
      />
      <TextField
        id={Metal3Params.Metal3KernelURL}
        label={`${paramDisplayNames[Metal3Params.Metal3KernelURL]} (Optional)`}
        onChange={setFieldValue(Metal3Params.Metal3KernelURL)}
        info={`You may want to change this URL if its not feasible to use the default image
          from the Platform9 repository, such as in an air-gapped deployments.`}
      />
      <TextField
        id={Metal3Params.Metal3RamdiskURL}
        label={`${paramDisplayNames[Metal3Params.Metal3RamdiskURL]} (Optional)`}
        onChange={setFieldValue(Metal3Params.Metal3RamdiskURL)}
        info={`You may want to change this URL if its not feasible to use the default
          image from the Platform9 repository, such as in an air-gapped deployments.`}
      />
      <TextField
        id={Metal3Params.Metal3SshKey}
        label={`${paramDisplayNames[Metal3Params.Metal3SshKey]} (Optional)`}
        onChange={setFieldValue(Metal3Params.Metal3SshKey)}
        info="Used to troubleshoot the ironic python agent."
      />
      {showStorageClassField && (
        <DropdownField
          DropdownComponent={StorageClassesPicklist}
          id={Metal3Params.StorageClassName}
          label={`${paramDisplayNames[Metal3Params.StorageClassName]} (Optional)`}
          name={Metal3Params.StorageClassName}
          clusterId={values?.clusterId}
          onChange={setFieldValue(Metal3Params.StorageClassName)}
          value={values?.StorageClassName}
        />
      )}
    </>
  )
}
