import React, { useMemo } from 'react'
import { propEq } from 'ramda'
import { pathStrOr } from 'utils/fp'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listCloudProviderRegionDetails } from '../../cloudProviders/new-actions'
import { cloudProviderRegionDetailsSelector } from '../../cloudProviders/selectors'

interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
  resourceGroup: string
  vnetName: string
}

export default function AzureSubnetPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  resourceGroup,
  vnetName,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const items = useMemo(() => {
    const networks = pathStrOr([], '0.virtualNetworks', regionDetails)
    const groups = networks.filter(propEq('resourceGroup', resourceGroup))
    const group = groups.find((group) => group.name === vnetName)
    const subnets = pathStrOr([], 'properties.subnets', group)
    return subnets.map((x) => ({ label: x.name, value: x.name }))
  }, [regionDetails, regionDetails, vnetName])

  return <AsyncDropdown loading={loadingCloudProviderRegionDetails} items={items} {...rest} />
}
