import React from 'react'
import { ipv4CidrValidator, ipValidators } from '../../../form-components/validators'
import TextField from 'core/components/validatedForm/TextField'
import { CapiVpcTypes } from '../../../model'
import CapiAwsAvailabilityZoneField from './CapiAwsAvailiabilityZoneField'
import CapiSpec from '../CapiSpec'
import PicklistField from 'core/components/validatedForm/DropdownField'
import CapiVpcTypePicklist from './CapiVpcTypePicklist'
import Row from 'core/containers/Row'
import { mergeRight } from 'ramda'
import CapiAwsVpcPicklist from './CapiAwsVpcPicklist'
import CapiAwsInternetGatewayPicklist from './CapiAwsInternetGatewayPicklist'

interface Props {
  wizardContext: any
  setWizardContext: any
  isEksCluster: boolean
}

export default function CapiVpcConfig({ wizardContext, setWizardContext, isEksCluster }: Props) {
  return (
    <CapiSpec title="VPC Config">
      <Row minItemWidth="300" gap={24}>
        {/* VPC Type*/}
        <CapiVpcTypePicklist
          value={wizardContext.vpcType}
          awsAssumeRole={wizardContext?.awsAssumeRole}
          onChange={(value) => setWizardContext({ vpcType: value, subnets: [], azs: [], vpc: {} })}
          tooltip="PMK Managed option lets PMK create and manage the VPC for the Kubernetes Cluster. Self Managed option lets you use your existing VPC for the Kubernetes Cluster"
        />
      </Row>

      {/*VPC Tpe is  Pmk Managed */}
      {wizardContext.vpcType === CapiVpcTypes.PmkManaged && (
        <Row minItemWidth="300" gap={24}>
          <TextField
            id="vpcCidrBlock"
            label="VPC CIDR Block"
            value={wizardContext?.vpc?.cidrBlock}
            required
            validations={[ipv4CidrValidator]}
            onChange={(value) => setWizardContext({ vpc: { cidrBlock: value } })}
          />

          <CapiAwsAvailabilityZoneField
            isEksCluster={isEksCluster}
            wizardContext={wizardContext}
            setWizardContext={setWizardContext}
          />
        </Row>
      )}
      {/*VPC Tpe is  Self Managed */}
      {wizardContext.vpcType === CapiVpcTypes.SelfManaged && (
        <>
          <Row minItemWidth="300">
            <PicklistField
              DropdownComponent={CapiAwsVpcPicklist}
              id="vpcId"
              label="VPC"
              azs={wizardContext.azs}
              identityName={wizardContext.provider}
              roleArn={wizardContext.assumeRoleTargetArn}
              region={wizardContext?.region}
              onChange={(value) =>
                setWizardContext({ vpc: mergeRight(wizardContext?.vpc, { id: value }) })
              }
              required
              selectFirst
              enableSearch
            />
          </Row>
          <Row minItemWidth="300" gap={24}>
            <CapiAwsAvailabilityZoneField
              wizardContext={wizardContext}
              setWizardContext={setWizardContext}
              isEksCluster={isEksCluster}
            />

            <PicklistField
              DropdownComponent={CapiAwsInternetGatewayPicklist}
              id="internetGatewayId"
              label="Internet Gateway ID"
              identityName={wizardContext.provider}
              roleArn={wizardContext.assumeRoleTargetArn}
              region={wizardContext?.region}
              disabled={!wizardContext?.vpc?.id}
              vpcId={wizardContext?.vpc?.id}
              onChange={(value) =>
                setWizardContext({
                  vpc: mergeRight(wizardContext?.vpc, { internetGatewayId: value }),
                })
              }
              required
              selectFirst
            />
          </Row>
        </>
      )}
    </CapiSpec>
  )
}
