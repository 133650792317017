import React, { FunctionComponent, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import useReactRouter from 'use-react-router'
import ClusterHostChooser from './bareos/ClusterHostChooser'
import { allPass } from 'ramda'
import { customValidator } from 'core/utils/fieldValidators'
import Alert from 'core/components/Alert'
import { clusterIsHealthy, clusterNotBusy, isBareOsMultiMasterCluster } from './helpers'
import {
  checkNodesForClockDrift,
  clockDriftErrorMessage,
  inCluster,
  isMaster,
  isUnassignedNode,
  isConnected,
} from 'app/plugins/infrastructure/components/nodes/helpers'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'
import ModalForm from 'core/elements/modal/ModalForm'
import { routes } from 'core/utils/routes'
import { attachNodes, detachNodes, listClusters } from './newActions'
import { clustersSelector } from './selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import RadioFields from 'core/components/validatedForm/radio-fields'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: 5,
  },
  masterCount: {
    margin: theme.spacing(4, 0),
  },
  errorMessage: {
    padding: theme.spacing(1),
  },
  warning: {
    margin: theme.spacing(-2, 0, 2, 0),
  },
}))

// Quorum Messages
const zeroMasterMsg =
  'You cannot remove master node from a single master cluster.  ' +
  'If you wish to delete the cluster, please choose the ‘delete’ operation ' +
  'on the cluster on the infrastructure page instead.'
const oneMasterMsg =
  'Quorum Risk. A single master cluster is not recommended for production environments. ' +
  'An outage of the master will bring the cluster offline. Recommended for test environments only.'
const twoMastersMsg =
  'Quorum Risk. A two master cluster does not hold quorum. ' +
  'Losing 1 master will cause the Kubernetes cluster to not function.'
const threeMastersMsg = 'Quorum Achieved with a tolerance of 1.'
const fourMastersMsg =
  'Quorum Achieved with a tolerance of 1. ' +
  'Operating four masters can tolerate a loss of at  most 1 node as quorum is majority, 3 of 4.'
const fiveMastersMsg = 'Quorum Achieved with a tolerance of 2.'

interface IConstraint {
  startNum: number
  desiredNum: number
  relation: 'allow' | 'deny' | 'warn'
  message: string
}

// To eliminate excessive branching logic, an explicit state transition table is used.
export const scaleConstraints: IConstraint[] = [
  // shrink
  {
    startNum: 5,
    desiredNum: 4,
    relation: 'allow',
    message: fourMastersMsg,
  },
  {
    startNum: 4,
    desiredNum: 3,
    relation: 'allow',
    message: threeMastersMsg,
  },
  {
    startNum: 3,
    desiredNum: 2,
    relation: 'allow',
    message: twoMastersMsg,
  },
  {
    startNum: 2,
    desiredNum: 1,
    relation: 'allow',
    message: oneMasterMsg,
  },
  {
    startNum: 1,
    desiredNum: 0,
    relation: 'deny',
    message: zeroMasterMsg,
  },

  // grow
  {
    startNum: 0,
    desiredNum: 1,
    relation: 'allow',
    message: oneMasterMsg,
  },
  {
    startNum: 1,
    desiredNum: 2,
    relation: 'allow',
    message: twoMastersMsg,
  },
  {
    startNum: 2,
    desiredNum: 3,
    relation: 'allow',
    message: threeMastersMsg,
  },
  {
    startNum: 3,
    desiredNum: 4,
    relation: 'allow',
    message: fourMastersMsg,
  },
  {
    startNum: 4,
    desiredNum: 5,
    relation: 'allow',
    message: fiveMastersMsg,
  },
]

const defaultErrorMessage = 'You must have 1, 3 or 5 masters to hold quorum'

const scalingOptions = [
  { label: 'Add master nodes to the cluster', value: 'add' },
  { label: 'Remove master nodes from the cluster', value: 'remove' },
]

const ScaleMastersPage: FunctionComponent = () => {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const { id } = match.params

  const [scaleType, setScaleType] = useState(null)
  const [selectedNode, setSelectedNode] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const { loading: loadingClusters } = useListAction(listClusters)
  const { loading: loadingNodes } = useListAction(listNodes)
  const allNodes = useSelector(nodesSelector)
  const clusters = useSelector(clustersSelector)
  const cluster = clusters.find((x) => x.uuid === id)

  const { update: attach, updating: isAttaching, error: attachError } = useUpdateAction(attachNodes)
  const { update: detach, updating: isDetaching, error: detachError } = useUpdateAction(detachNodes)

  const isUpdating = isAttaching || isDetaching

  const handleAttach = (data: { nodes: string[] }) => {
    const nodes = data?.nodes.map((uuid) => ({ uuid, isMaster: true }))
    return attach({ cluster, nodes })
  }

  const handleDetach = (data: { nodes: string[] }) => {
    return detach({ cluster, nodes: data?.nodes })
  }

  const handleClose = () => {
    history.push(routes.cluster.managed.list.path())
  }

  const handleSubmit = useCallback(
    async (data: { nodes: string[] }) => {
      let updateSuccess = false
      if (scaleType === 'add') {
        const hasClockDrift = checkNodesForClockDrift(data?.nodes, allNodes)
        if (hasClockDrift) {
          setErrorMessage({ message: clockDriftErrorMessage })
          return
        }
        setErrorMessage(null)
        const { success } = await handleAttach(data)
        updateSuccess = success
      } else {
        const { success } = await handleDetach(data)
        updateSuccess = success
      }
      if (updateSuccess) {
        handleClose()
      }
    },
    [scaleType, handleAttach, handleDetach, allNodes, handleClose],
  )

  const numMasters = (cluster?.nodes || []).filter(isMaster).length
  const numToChange = scaleType === 'add' ? 1 : -1
  const totalMasters = numMasters + numToChange
  // Look up the transition in the state transition table.
  const transitionConstraint =
    scaleConstraints.find(
      (t) => t.startNum === numMasters && t.desiredNum === totalMasters,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ) || ({} as IConstraint)

  const clusterAndNodeStatusValidator = useCallback(() => {
    return customValidator(() => {
      return clusterIsHealthy(cluster) && clusterNotBusy(cluster)
    }, 'Unable to scale nodes. All nodes must be converged and healthy')
  }, [cluster, scaleType])()

  const masterNodeLengthValidator = useCallback(() => {
    return customValidator(() => {
      return transitionConstraint.relation === 'allow'
    }, defaultErrorMessage)
  }, [cluster, scaleType])()

  const bareOsValidator = useCallback(() => {
    return customValidator(() => {
      // BareOs clusters with single master node cannot scale without a virtial IP
      return isBareOsMultiMasterCluster(cluster)
    }, 'No Virtual IP Detected. To scale Masters a Virtual IP is required. Please recreate this cluster and provide a Virtual IP on the Network step')
  }, [cluster, scaleType])()

  return (
    <ModalForm
      route={routes.cluster.managed.qbert.scaleMasters}
      title="Scale Masters"
      loading={loadingClusters || loadingNodes}
      onSubmit={scaleType ? handleSubmit : null}
      submitTitle={`${scaleType === 'add' ? 'Add' : 'Remove'} masters`}
      submitting={isUpdating}
      error={attachError || detachError || errorMessage}
      onClose={handleClose}
    >
      <Text variant="subtitle1">Current Master Nodes: {numMasters}</Text>
      <br />
      <RadioFields
        id="scaleType"
        title="Scaling Options"
        value={scaleType}
        options={scalingOptions}
        onChange={(type) => setScaleType(type)}
      />
      <br />
      {!!scaleType && (
        <ClusterHostChooser
          id="nodes"
          selection="single"
          title={`Choose nodes to ${scaleType}`}
          filterFn={
            scaleType === 'add'
              ? allPass([isUnassignedNode, isConnected])
              : allPass([isMaster, inCluster(cluster?.uuid)])
          }
          validations={[clusterAndNodeStatusValidator, masterNodeLengthValidator, bareOsValidator]}
          onChange={(value) => setSelectedNode(value)}
          isSingleNodeCluster={false}
          showResourceRequirements={scaleType === 'add'}
          required
        />
      )}
      {selectedNode && (
        <Alert
          variant="warning"
          className={classes.warning}
          message={transitionConstraint.message}
        />
      )}
    </ModalForm>
  )
}

export default ScaleMastersPage
