import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React from 'react'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Badge from 'core/elements/badge/Badge'

import { capiInfoLink } from 'k8s/links'
import { routes } from 'core/utils/routes'
import ExternalLink from 'core/components/ExternalLink'

const ChevronIconButton = ({ link }) => {
  return (
    <SimpleLink src={link}>
      <FontAwesomeIcon solid size="sm">
        chevron-right
      </FontAwesomeIcon>
    </SimpleLink>
  )
}

interface Props {
  totalClusters: number
  totalCapiClusters: number
  totalLegacyClusters: number
  totalImportedClusters: number
}

export default function ClustersOverviewHeader({
  totalClusters,
  totalCapiClusters,
  totalLegacyClusters,
  totalImportedClusters,
}: Props) {
  const classes = useStyles()
  return (
    <header className={classes.headerBar}>
      <div className={classes.info}>
        <Text variant="caption1" className={classes.label}>
          Clusters Total
        </Text>
        <Text variant="h2" className={classes.value}>
          {totalClusters}
        </Text>
      </div>
      <div className={classes.info}>
        <div className={classes.labelDiv}>
          <div className={classes.capiLabel}>
            <Text variant="caption1">CAPI</Text>
            <Badge variant="primary" text="New" className={classes.badge} />
          </div>
          <ChevronIconButton link={routes.cluster.managed.list.path()} />
        </div>
        <div className={classes.capiValueDiv}>
          <Text variant="h2" className={classes.value}>
            {totalCapiClusters}
          </Text>
          <ExternalLink
            icon="external-link"
            iconPosition="right"
            textVariant="body2"
            url={capiInfoLink}
          >
            Learn about CAPI Clusters
          </ExternalLink>
        </div>
      </div>
      <div className={classes.info}>
        <div className={classes.labelDiv}>
          <Text variant="caption1" className={classes.label}>
            Legacy
          </Text>
          <ChevronIconButton link={routes.cluster.managed.list.path()} />
        </div>
        <Text variant="h2" className={classes.value}>
          {totalLegacyClusters}
        </Text>
      </div>
      <div className={classes.info}>
        <div className={classes.labelDiv}>
          <Text variant="caption1" className={classes.label}>
            Imported
          </Text>
          <ChevronIconButton link={routes.cluster.imported.list.path()} />
        </div>
        <Text variant="h2" className={classes.value}>
          {totalImportedClusters}
        </Text>
      </div>
    </header>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  headerBar: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(264px, 1fr))',
    height: '114px',
    margin: '7px 20px 64px 0px',
    backgroundColor: theme.components.card.background,
    width: '100%',
  },
  info: {
    display: 'grid',
    borderLeft: `1px solid ${theme.components.card.border}`,
    padding: theme.spacing(3, 1, 3, 2),
    marginRight: theme.spacing(2),
  },
  labelDiv: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
  },
  capiLabel: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: theme.spacing(2),
  },
  value: {
    fontWeight: 400,
  },
  badge: {
    padding: '2px 5px',
    display: 'flex',
    gap: '10px',
    '& .badgeText': {
      fontSize: '12px',
    },
  },
  capiValueDiv: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'end',
  },
  capiLinkText: {
    fontWeight: 400,
    lineHeight: '19px',
  },
}))
