import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AwsCloudProviderVerification from './aws/AwsCloudProviderVerification'
import AzureCloudProviderVerification from './azure/AzureCloudProviderVerification'
import { switchCase, emptyObj } from 'utils/fp'
import Progress from 'core/components/progress/Progress'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Text from 'core/elements/Text'
import { CloudProviders } from './model'
import WizardStep from 'core/components/wizard/WizardStep'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { cloudVerificationCalloutFields, renderVerificationCalloutFields } from './helpers'
import GoogleCloudProviderVerification from './google/GoogleCloudProviderVerification'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { isDecco } from 'core/utils/helpers'
import { RootState } from 'app/store'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { UserPreferences } from 'app/constants'

const useStyles = makeStyles((theme: Theme) => ({
  cpName: {
    paddingTop: theme.spacing(2),
  },
  form: {
    maxWidth: '800px',
    flexGrow: 1,
  },
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  history: any
}

// This step requires the cloud provider to have been created & have its ID
const AddCloudProviderVerificationStep = ({ history, wizardContext, setWizardContext }: Props) => {
  const classes = useStyles({})
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const isDeccoEnv = isDecco(features)
  const { prefs, fetchUserDefaults, updateUserDefaults } = useScopedPreferences('defaults')
  const cloudDefaults =
    prefs?.[UserPreferences.CloudProviders]?.[wizardContext.cloudProviderId] || emptyObj

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  const ActiveForm = switchCase({
    [CloudProviders.Aws]: AwsCloudProviderVerification,
    [CloudProviders.Azure]: AzureCloudProviderVerification,
    [CloudProviders.Gcp]: GoogleCloudProviderVerification,
  })(wizardContext.provider)
  const calloutFields = renderVerificationCalloutFields()
  return (
    <WizardStep
      stepId="step2"
      label="Set Cloud Provider Defaults"
      keepContentMounted={false}
      summaryFields={cloudDefaults}
      summaryCalloutFields={
        isDeccoEnv ? cloudVerificationCalloutFields(wizardContext.provider) : []
      }
      // summaryRenderLabels={calloutFields}
    >
      <Progress loading={!wizardContext.cloudProviderId}>
        <div className={classes.form}>
          <ValidatedForm
            classes={{ root: classes.validatedFormContainer }}
            initialValues={wizardContext}
            elevated={false}
          >
            <Text variant="subtitle1" className={classes.cpName}>
              {wizardContext.name}
            </Text>
            <ActiveForm
              wizardContext={wizardContext}
              setWizardContext={setWizardContext}
              updateUserDefaults={updateUserDefaults}
              cloudDefaults={cloudDefaults}
              showDefaultButtons={isDeccoEnv}
            />
          </ValidatedForm>
        </div>
      </Progress>
    </WizardStep>
  )
}

export default AddCloudProviderVerificationStep
