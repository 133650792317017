import PicklistField from 'core/components/validatedForm/DropdownField'
import React from 'react'

const defaultLabel = 'Master Node Instance Type'

const MasterNodeInstanceTypeField = ({
  dropdownComponent,
  values,
  onChange = undefined,
  label = defaultLabel,
  id = 'masterFlavor',
  ...rest
}) => (
  <PicklistField
    DropdownComponent={dropdownComponent}
    disabled={!(values.cloudProviderId && values.region)}
    id={id}
    label={label}
    cloudProviderId={values.cloudProviderId}
    cloudProviderRegionId={values.region}
    tooltip="Choose an instance type used by master nodes."
    onChange={onChange}
    enableSearch
    required
    {...rest}
  />
)

export default MasterNodeInstanceTypeField
