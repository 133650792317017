import React, { useMemo } from 'react'
import { pathStrOr } from 'utils/fp'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { cloudProviderRegionDetailsSelector } from '../../cloudProviders/selectors'
import { listCloudProviderRegionDetails } from '../../cloudProviders/new-actions'

interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
}

export default function AwsClusterSshKeyPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })
  const keypairs = useMemo(() => pathStrOr([], '0.keyPairs', regionDetails), [regionDetails])
  const items = useMemo(() => keypairs.map((x) => ({ label: x.KeyName, value: x.KeyName })), [
    keypairs,
  ])

  return <AsyncDropdown loading={loadingCloudProviderRegionDetails} items={items} {...rest} />
}
