import React, { useState, FC, useMemo, useCallback } from 'react'
import clsx from 'clsx'
import useReactRouter from 'use-react-router'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { getTaskContent } from './helpers'

import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import Card from 'core/elements/card'

import SimpleLink from 'core/components/SimpleLink'
import ProgressBar from 'core/components/progress/ProgressBar'
import ExternalLink from 'core/components/ExternalLink'
import PollingData from 'core/components/PollingData'
import NoContentMessage from 'core/components/NoContentMessage'
import NodeTasksModal from 'app/plugins/infrastructure/components/nodes/NodeTasksModal'
import generateTestId from 'utils/test-helpers'

const useStyles = makeStyles<Theme, {}>((theme) => ({
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableChooser: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 500px))',
    gap: theme.spacing(4),
  },

  nodeCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '22px 24px 24px 24px',
    gap: theme.spacing(3),
    overflow: 'auto',
  },
  nodeCardHeader: {
    padding: theme.spacing(0, 2),
    display: 'grid',
  },
  nodeIp: {
    margin: '4px 0 12px 0',
  },
  errorCard: {
    background: `${theme.components.alert.error.background} !important`,
    border: `1px solid ${theme.components.alert.error.border} !important`,
  },
  nodeCardBody: {
    boxSizing: 'border-box',
    padding: theme.spacing(1, 2, 2, 2),
    background: theme.components.table.hoverBackground,
    border: `1px solid ${theme.components.table.border}`,
    borderRadius: 4,

    height: 130,
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  cardBodyHeader: {
    display: 'grid',
    gap: 8,
    gridAutoRows: 'max-content',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  progressHeader: {
    padding: theme.spacing(1, 0.5),
  },
  endJustify: {
    justifySelf: 'end',
  },
}))

const tenSeconds = 1000 * 10
const oneMinute = 1000 * 60

export const NodeHealthWithTasksToggler: FC = () => {
  const { match, location } = useReactRouter()
  const clusterId: string = match.params.id
  const searchParams = new URLSearchParams(location.search)
  const linkedNodeUUID = searchParams.get('node') || null
  const [selectedNodeUuid, setSelectedNodeUuid] = useState(linkedNodeUUID)

  const handleViewSteps = (id) => () => {
    setSelectedNodeUuid(id)
  }

  const { message, loading: loadingNodes, reload: reloadNodes } = useListAction(listNodes)
  const { loading: loadingClusters, reload: reloadClusters } = useListAction(listClusters)
  const nodes = useSelector(nodesSelector)
  const clusters = useSelector(clustersSelector)

  const nodesInCluster = useMemo(() => {
    const cluster = clusters.find((cluster) => cluster.uuid === clusterId)
    if (cluster) {
      const clusterNodesUids = cluster.nodes.map(({ uuid }) => uuid)
      return nodes.filter((node) => clusterNodesUids.includes(node.uuid))
    }
    return []
  }, [clusters, nodes])

  const handleReload = useCallback(
    (ignoreCache, updateLoadingState) => {
      reloadClusters(ignoreCache, updateLoadingState)
      return reloadNodes(ignoreCache, updateLoadingState)
    },
    [reloadClusters, reloadNodes],
  )

  // Node stats change on every render cycle so need to not memoize this
  const selectedNode = nodes.find((node) => selectedNodeUuid === node.uuid)

  const classes = useStyles({})

  if (!nodesInCluster || !nodesInCluster.length) {
    return (
      <>
        <PollingData
          hidden
          loading={loadingNodes || loadingClusters}
          onReload={handleReload}
          refreshDuration={tenSeconds}
        />
        <NoContentMessage
          data-testid={generateTestId('wait', 'for', 'nodes')}
          message="Nothing yet, waiting for nodes..."
        />
      </>
    )
  }

  return (
    <>
      <HeaderPrimaryActionPortal>
        <PollingData
          loading={loadingNodes || loadingClusters}
          onReload={handleReload}
          refreshDuration={oneMinute}
        />
      </HeaderPrimaryActionPortal>
      <NodeTasksModal
        node={selectedNode}
        isOpen={!!selectedNodeUuid}
        onClose={() => setSelectedNodeUuid(null)}
      />
      <div className={classes.tableChooser}>
        {nodesInCluster?.map((node) => {
          const currNodeKubeStatusData = node?.combined?.resmgr?.extensions?.pf9_kube_status?.data
          const { allTasks, completedTasks, message, title, currentIdx } = getTaskContent(
            currNodeKubeStatusData,
          )

          const percentComplete = (completedTasks.length / allTasks.length) * 100
          const nodeNameAdditionalText = ` / ${node.isMaster ? 'Master' : 'Worker'}`
          return (
            <Card key={node.uuid} className={classes.nodeCard} withCustomBody>
              <header className={classes.nodeCardHeader}>
                <Tooltip message={node.name}>
                  <Text variant="body1" className={classes.ellipsis} component="h3">
                    <b>{node.name}</b>
                    {nodeNameAdditionalText}
                  </Text>
                </Tooltip>
                <div className={classes.nodeIp}>
                  <Tooltip message={node.primaryIp}>
                    <Text variant="body2" className={classes.ellipsis} component="p">
                      {node.primaryIp}
                    </Text>
                  </Tooltip>
                  {/* <NodeTaskStatus status={nodeState} /> */}
                </div>
                <ExternalLink url={node?.logs || ''} textVariant="body2">
                  View Logs
                </ExternalLink>
              </header>
              <section
                className={clsx(classes.nodeCardBody, { [classes.errorCard]: title === 'Failed' })}
              >
                <div
                  data-testid={generateTestId('progress', 'bar', 'status')}
                  className={classes.cardBodyHeader}
                >
                  <div className={clsx(classes.spaceBetween, classes.progressHeader)}>
                    <Text data-testid={generateTestId(title)} variant="subtitle2" component="p">
                      {title}
                    </Text>
                    <Text
                      // data-testid={generateTestId('progress', 'bar', 'status')}
                      variant="subtitle2"
                      component="p"
                    >
                      {currentIdx} / {allTasks.length}
                    </Text>
                  </div>
                  <ProgressBar
                    height={3}
                    width="100%"
                    percent={percentComplete}
                    color="primary"
                    showPercent={false}
                  />
                  {message && (
                    <Text
                      // data-testid={generateTestId('progress', 'bar', 'status')}
                      variant="body2"
                      component="p"
                    >
                      {message}
                    </Text>
                  )}
                </div>
                <SimpleLink onClick={handleViewSteps(node.uuid)} className={classes.endJustify}>
                  View all steps
                </SimpleLink>
              </section>
            </Card>
          )
        })}
      </div>
    </>
  )
}
