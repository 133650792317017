import { Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Text from 'core/elements/Text'
import CopyToClipboard from 'core/components/CopyToClipboard'
import { prop } from 'ramda'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { runPf9CliCommand } from '../clusters/constants'
import CodeBlock from 'core/components/CodeBlock'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import SubmitButton from 'core/components/buttons/SubmitButton'
import NumberedSteps from 'core/components/numbered-steps'
import SimpleLink from 'core/components/SimpleLink'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Progress from 'core/components/progress/Progress'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import { UserInfo } from './DownloadCliWalkthrough'
import { trackEvent } from 'utils/tracking'

const useStyles = makeStyles((theme: Theme) => ({
  downloadOvaWalkthrough: {
    '& .progress-root': {
      height: 'auto',
    },
    padding: theme.spacing(2, 6),
    margin: theme.spacing(2, 0),
    backgroundColor: hexToRgbaCss(theme.palette.primary.main, 0.1),
  },
  linkText: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  spaceAbove: {
    marginTop: theme.spacing(2),
  },
  downloadIcon: {
    marginLeft: theme.spacing(1),
  },
  downloadButton: {
    marginTop: theme.spacing(1),
  },
}))

const downloadOptions = [
  {
    label: 'VirtualBox OVA',
    link: 'https://pmkft-assets.s3.us-west-1.amazonaws.com/OVA_Images/Platform9_VirtualBox.ova',
  },
  {
    label: 'VMware OVA',
    link: 'https://pmkft-assets.s3.us-west-1.amazonaws.com/OVA_Images/Platform9_vmware.ova',
  },
]

interface InstallationStep {
  text: string
  command?: string
}

export const ovaInstallationSteps: InstallationStep[] = [
  { text: 'Download the OVA' },
  { text: 'Import and then start the OVA using VirtualBox, VMware Workstation or vSphere' },
  {
    text: 'Login using ubuntu/ubuntu and run',
    command: runPf9CliCommand,
  },
  { text: 'Return to Create Cluster to use the new node' },
]

const DownloadOvaWalkthrough = (): JSX.Element => {
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = useState(null)
  const selectSessionState = prop<string, SessionState>(sessionStoreKey)
  const session = useSelector(selectSessionState)
  const { getUserPrefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = getUserPrefs(session.username)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const tenant = useMemo(() => tenants.find((tenant) => tenant.id === currentTenant), [tenants])

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLinkClick = (label) => {
    handleMenuClose()
    trackEvent(`Add Node Download OVA - ${label}`)
  }

  return (
    <div className={classes.downloadOvaWalkthrough}>
      <Progress loading={loadingTenants} renderContentOnMount={!loadingTenants}>
        <Text variant="subtitle2">Download and start the OVA image</Text>
        <NumberedSteps
          step={1}
          description={
            <div>
              <Text>{ovaInstallationSteps[0].text}</Text>
              <SubmitButton className={classes.downloadButton} onClick={handleDownloadClick}>
                Download
                <FontAwesomeIcon className={classes.downloadIcon} size="sm" solid>
                  download
                </FontAwesomeIcon>
              </SubmitButton>
              <Menu
                id="download-options"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                {downloadOptions.map((option) => (
                  <SimpleLink src={option.link} key={option.label}>
                    <MenuItem onClick={() => handleLinkClick(option.label)}>
                      {option.label}
                    </MenuItem>
                  </SimpleLink>
                ))}
              </Menu>
            </div>
          }
        />
        <NumberedSteps step={2} description={<Text>{ovaInstallationSteps[1].text}</Text>} />
        <NumberedSteps
          step={3}
          description={
            <div>
              <div>
                <Text>{ovaInstallationSteps[2].text}</Text>
                <div className={classes.spaceAbove}>
                  <CopyToClipboard copyText={ovaInstallationSteps[2].command}>
                    <CodeBlock>{ovaInstallationSteps[2].command}</CodeBlock>
                  </CopyToClipboard>
                </div>
              </div>
              <div className={classes.spaceAbove}>
                <Text variant="body1">Here's your info for quick use:</Text>
                <UserInfo
                  classes={classes}
                  username={session?.username}
                  tenantName={tenant?.name}
                  currentRegion={currentRegion}
                />
              </div>
            </div>
          }
        />
        <NumberedSteps step={4} description={<Text>{ovaInstallationSteps[3].text}</Text>} />
      </Progress>
    </div>
  )
}

export default DownloadOvaWalkthrough
