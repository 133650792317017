import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Progress from 'core/components/progress/Progress'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import StatusConditionsCard from '../StatusConditionsCard'
import ClusterPropertiesCard from './ClusterPropertiesCard'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import Button from 'core/elements/button'
import YamlCard from '../YamlCard'
import { downloadYamlsZipFile, convertResourcesToYamls } from '../helpers'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateCapiCluster } from '../../actions'
import { updateCapiAwsCluster } from '../../../aws/capi/actions'
import { ICapiClusterSelector } from '../../model'
import { getCodeMirrorYamlValidations } from '../../helpers'
import {
  updateAwsClusterRoleIdentities,
  updateAwsClusterStaticIdentities,
} from '../../identities/actions'

interface CapiInfrastructurePageProps {
  cluster: ICapiClusterSelector
  resources: unknown[]
  loading: boolean
}
interface Yamls {
  [kind: string]: string
}

export default function CapiInfrastructurePage({
  cluster,
  resources,
  loading,
}: CapiInfrastructurePageProps) {
  const classes = useStyles()
  const [yamls, setYamls] = useState<Yamls>({})
  const { update: updateCluster, updating: updatingCluster } = useUpdateAction(updateCapiCluster)
  const { update: updateAwsCluster, updating: updatingAwsCluster } = useUpdateAction(
    updateCapiAwsCluster,
  )
  const { update: updateRoleIdentity, updating: updatingRoleIdentity } = useUpdateAction(
    updateAwsClusterRoleIdentities,
  )
  const { update: updateStaticIdentity, updating: updatingStaticIdentity } = useUpdateAction(
    updateAwsClusterStaticIdentities,
  )

  const isAwsCluster = cluster?.infrastructureType === CloudProviders.Aws

  useEffect(() => {
    setYamls(convertResourcesToYamls(resources))
  }, [resources])

  const downloadYamls = useCallback(() => {
    downloadYamlsZipFile(yamls, `${cluster?.name}-infrastructure`)
  }, [yamls])

  const updateFnMap = useMemo(
    () => ({
      Cluster: updateCluster,
      AWSCluster: updateAwsCluster,
      AWSClusterRoleIdentity: updateRoleIdentity,
      AWSClusterStaticIdentity: updateStaticIdentity,
    }),
    [],
  )

  const handleYamlUpdate = useCallback(
    async ({ kind, namespace, name, updatedYaml }) => {
      const updateFn = updateFnMap[kind]
      if (!updateFn) return
      return updateFn({
        namespace,
        name,
        body: updatedYaml,
      })
    },
    [updateFnMap],
  )

  const updating =
    updatingCluster || updatingAwsCluster || updatingRoleIdentity || updatingStaticIdentity

  return (
    <Progress loading={loading || updating} overlay>
      <div className={classes.infraPage}>
        <div className={classes.columns}>
          <StatusConditionsCard resourceType="Cluster" conditions={cluster?.status?.conditions} />
          {isAwsCluster && (
            <StatusConditionsCard
              resourceType="AWS Cluster"
              conditions={cluster?.infrastructure?.status?.conditions}
            />
          )}
        </div>
        <div className={classes.buttons}>
          {/* Todo: Add this button once the docs have been completed */}
          {/* <Button variant="secondary" icon="external-link">
            Read YAML Docs
          </Button> */}
          <Button variant="secondary" icon="download" onClick={downloadYamls}>
            Download YAMLs
          </Button>
        </div>
        <div className={classes.columns}>
          <ClusterPropertiesCard cluster={cluster} />
          <div className={classes.yamls}>
            {Object.entries(yamls).map(([kind, yaml]) => (
              <YamlCard
                key={kind}
                kind={kind}
                yaml={yaml}
                open={kind === 'Cluster'}
                updateYamlFn={handleYamlUpdate}
                yamlValidations={getCodeMirrorYamlValidations(kind)}
              />
            ))}
          </div>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  infraPage: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'right',
    padding: theme.spacing(3, 0, 1, 0),
    gap: theme.spacing(1),
    minWidth: `${626 * 2 + 16}px`,
    maxWidth: `${774 * 2 + 16}px`,
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(626px, 774px))',
    gridGap: theme.spacing(2),
  },
  yamls: {
    display: 'grid',
    gridTemplateColumns: 'minmax(626px, 774px)',
    gridAutoRows: 'max-content',
    gridGap: theme.spacing(2),
  },
}))
