import Card from 'core/elements/card'
import React from 'react'
import { ICapiClusterSelector } from '../../model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AlarmsListPage from 'k8s/components/alarms/AlarmsListPage'

export default function CapiClusterOverviewAlarms({ cluster }: { cluster: ICapiClusterSelector }) {
  const classes = useStyles()
  return (
    <Card title="Alarms" className={classes.container}>
      <AlarmsListPage clusterId={cluster.uuid} hideDocumentMeta />
    </Card>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumnStart: 2,
    gridColumnEnd: 4,
    margin: theme.spacing(1, 0, 0, 1),
  },
}))
