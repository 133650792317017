import { filter, pluck, pathOr } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { ExtensionsClass, Host as ResMgrHost } from 'api-client/resmgr.model'
import { emptyArr } from 'utils/fp'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { ICapiHostsSelector, Host } from './model'
import { calculateNodeUsages } from 'app/plugins/infrastructure/components/common/helpers'
import {
  getIpPreview,
  getNetworkInterfaces,
  resMgrCombinedHostsSelector,
} from 'app/plugins/infrastructure/components/common/selectors'
import { combineHost } from 'app/plugins/infrastructure/components/common/combineHosts'
import {
  HostTypes,
  ICombinedHostSelector,
} from 'app/plugins/infrastructure/components/common/model'
import { k8sNodesByClusterIdAndProviderIdSelector } from 'app/plugins/infrastructure/components/nodes/k8sNodes-selectors'
import { castFuzzyBool } from 'utils/misc'
import { createSelector } from 'reselect'
import { allKey, noneKey } from 'app/constants'
import { NodeState } from 'app/plugins/infrastructure/components/nodes/model'

const findK8sNodesByProviderId = (k8NodesById = [], hostProviderId) => {
  return hostProviderId
    ? k8NodesById[Object.keys(k8NodesById).find((id) => hostProviderId.includes(id))]
    : ''
}

const combinedHostsSelector = createSharedSelector(
  getDataSelector<DataKeys.CapiHosts>(DataKeys.CapiHosts),
  resMgrCombinedHostsSelector,
  k8sNodesByClusterIdAndProviderIdSelector,
  (hosts, resMgrHostsById, k8sNodesById) => {
    const hostsById = {}

    hosts.forEach((host) => {
      const hostId = host.metadata?.name
      const clusterId = host.spec?.pf9?.clusterID
      const providerId = host.spec?.kubelet?.providerID
      hostsById[hostId] = {} as any
      const resMgrHost = resMgrHostsById[hostId]
      hostsById[hostId][HostTypes.Resmgr] = resMgrHost
      hostsById[hostId][HostTypes.K8s] = findK8sNodesByProviderId(
        k8sNodesById[clusterId],
        providerId,
      )
    })

    return hostsById
  },
)

export const capiHostsSelector = createSharedSelector(
  getDataSelector<DataKeys.CapiHosts>(DataKeys.CapiHosts),
  combinedHostsSelector,
  (rawCapiHosts, combinedHostsObj): ICapiHostsSelector[] => {
    return rawCapiHosts.map((host: Host) => {
      const hostId = host.metadata.name
      const k8s = combinedHostsObj[hostId]?.[HostTypes.K8s]
      const resMgrHost = combinedHostsObj[hostId]?.[HostTypes.Resmgr]

      const usage = calculateNodeUsages([resMgrHost]) // expects array
      const combined = combineHost({
        [HostTypes.Resmgr]: resMgrHost?.resmgr,
      })
      const conditions = k8s?.status?.conditions
      return {
        ...host,
        hostId: host?.metadata?.name,
        clusterName: host?.spec?.pf9?.clusterName,
        clusterId: host?.spec?.pf9?.clusterID,
        uuid: host?.metadata?.name,
        name: host?.status.hostname,
        isMaster: host?.status?.clusterRole === 'master',
        isAuthorized: combined.isAuthorized,
        api_responding: combined.api_responding,
        // if hostagent is not responding, then the nodes info is outdated
        // set the status to disconnected manually
        status: combined?.responding ? host.status?.hostState : 'disconnected',
        combined,
        // qbert v3 link fails authorization so we have to use v1 link for logs
        // logs: `${getScopedQbertEndpoint(xqbertEndpoint)}/logs/${host.uuid}`,
        usage,
        nodeKubeVersion: host?.status?.nodelet?.version,
        roles: combined?.roles,
        operatingSystem: combined?.osInfo,
        networkInterfaces: combined?.networkInterfaces || {},
        cpuArchitecture: combined?.resmgr?.info?.arch,
        message: combined?.resmgr?.message,
        conditions: k8s?.status?.conditions,
        ready: conditions?.find((cond) => cond.type === 'Ready')?.status,
        unschedulable: k8s?.spec?.unschedulable,
        // resourceUtilization:
        isSpotInstance: castFuzzyBool(
          resMgrHost?.resmgr?.extensions?.node_metadata?.data?.isSpotInstance,
        ),
        assignedRoles: combined?.localizedRoles || [],
        ownerName: pathOr(null, ['metadata', 'labels', 'capi.pf9.io/ownerName'], host),
      }
    })
  },
)

export const capiHostsByOwnerNameSelector = createSharedSelector(
  capiHostsSelector,
  (capiHosts: ICapiHostsSelector[]) => {
    return capiHosts.reduce((accum, host) => {
      const ownerName = host.ownerName
      const currHosts = accum[ownerName] || []
      accum[ownerName] = [...currHosts, host]
      return accum
    }, {})
  },
)

export const hostSelectorForCapiCluster = createSharedSelector(
  capiHostsSelector,
  getDataSelector<DataKeys.ResMgrHosts>(DataKeys.ResMgrHosts),
  (capiHosts: ICapiHostsSelector[], resMgrHosts: ResMgrHost[]) => {
    const hostIds = pluck('hostId', capiHosts)
    const equalsAnyHostId = (host: ResMgrHost) => hostIds.includes(host.id)
    return filter(equalsAnyHostId, resMgrHosts).map((item) => {
      const extensions = item?.extensions as ExtensionsClass
      return {
        ...item,
        resourceType: HostTypes.Resmgr,
        ipPreview: getIpPreview(extensions?.ip_address?.data),
        networkInterfaces: getNetworkInterfaces(item),
        ovsBridges: extensions?.interfaces?.data.ovs_bridges || (emptyArr as any),
      }
    })
  },
)

export const hostUsageSelectorForCapiCluster = createSharedSelector(
  hostSelectorForCapiCluster,
  (hosts) => {
    const combinedHosts = hosts.map((item) => {
      const host = {
        [HostTypes.Resmgr]: item,
      }
      const combined = combineHost(host)
      return { ...host, ...combined }
    })
    return calculateNodeUsages(combinedHosts)
  },
)

export const makeParamsCapiHostsSelector = (
  defaultParams = {
    clusterId: null,
    state: null,
    role: null,
  },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(capiHostsSelector, selectParams, (hosts, params) => {
    const { clusterId, state, role } = params

    const filterByCluster = (host) => {
      if (clusterId === allKey) {
        return true
      } else if (clusterId === noneKey) {
        return !host.clusterId
      }
      return host.clusterId === clusterId
    }

    const filterByHostState = (host) => {
      return (
        state === allKey ||
        (state === NodeState.Authorized ? host.isAuthorized : !host.isAuthorized)
      )
    }

    const filterByHostRole = (host) => {
      if (role === allKey) {
        return true
      } else if (host.clusterId == null) {
        return false
      }
      return role === 'master' ? host.isMaster : !host.isMaster
    }

    return hosts.filter((host) => {
      return filterByCluster(host) && filterByHostState(host) && filterByHostRole(host)
    })
  })
}
