import React, { useMemo } from 'react'
import { compareVersions } from 'k8s/util/helpers'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { clusterAutoScalerInfo } from 'app/plugins/infrastructure/components/clusters/constants'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'

export const capiAutoScalerFieldId = 'enableCapiAutoScaler'

const CapiAutoScalerField = ({ wizardContext, setWizardContext, useToggleSwitch = false }) => {
  const isNewK8sVersion = useMemo(() => {
    const version = wizardContext.kubeRoleVersion || wizardContext.version
    if (!version) {
      return false
    }
    return compareVersions(version, '1.20') >= 0
  }, [wizardContext.kubeRoleVersion, wizardContext.version])

  return (
    <>
      {isNewK8sVersion ? (
        useToggleSwitch ? (
          <ToggleSwitchField
            id={capiAutoScalerFieldId}
            label="Cluster Autoscaler"
            onChange={(value) => setWizardContext({ [capiAutoScalerFieldId]: value })}
            info={clusterAutoScalerInfo}
            infoPlacement={{ align: topMiddle.align, offset: topMiddle.offset }}
          />
        ) : (
          <CheckboxField
            id={capiAutoScalerFieldId}
            label="Cluster Autoscaler"
            info={clusterAutoScalerInfo}
            value={wizardContext[capiAutoScalerFieldId]}
            onChange={(value) => setWizardContext({ [capiAutoScalerFieldId]: value })}
          />
        )
      ) : null}
    </>
  )
}

export default CapiAutoScalerField
