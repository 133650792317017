import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import React from 'react'
import { ControlPlaneStatus, InfrastructureStatus } from '../ClusterStatuses'

export default function StatusesCell({ item: cluster }: GridCellProps<any>) {
  return (
    <div>
      <InfrastructureStatus infrastructureReady={cluster.infrastructureReady} />
      <ControlPlaneStatus controlPlaneReady={cluster.controlPlaneReady} />
    </div>
  )
}
