import React from 'react'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { authNode } from './new-actions'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import useUpdateAction from 'core/hooks/useUpdateAction'

export default function NodeAuthDialog({ rows: [node], onClose }: GridDialogProps<INodesSelector>) {
  const { update: auth, updating: authorizing, error, reset } = useUpdateAction(authNode)

  const handleAuthNode = async () => {
    const { success } = await auth({ node })
    if (!success) {
      return
    }
    reset()
    onClose()
  }

  return (
    <ConfirmationDialog
      title="Authorize node"
      text={
        <>
          You are about to authorize the node {node?.name}.
          <br />
          Are you sure?
        </>
      }
      open
      loading={authorizing}
      error={error}
      onCancel={onClose}
      onConfirm={handleAuthNode}
    />
  )
}
