import React from 'react'
import TextField from 'core/components/validatedForm/TextField'
import { mergeRight } from 'ramda'

interface ApiFqdnFieldProps {
  setWizardContext?: (wizardContext: Record<string, any>) => void
  wizardContext?: Record<string, any>
  disabled?: boolean
  required?: boolean
}

export default function ApiFqdnField({
  setWizardContext,
  wizardContext,
  disabled = false,
  required = false,
}: ApiFqdnFieldProps) {
  return (
    <TextField
      id="externalDnsName"
      label="API FQDN"
      info="Fully Qualified Domain Name used to reference the cluster API.
    The API will be secured by including the FQDN in the API server certificate’s Subject Alt Names.
    Clusters in Public Cloud will automatically have the DNS records created and registered for the FQDN."
      value={wizardContext ? wizardContext?.route53?.apiFqdn : null}
      onChange={
        setWizardContext
          ? (apiFqdn) =>
              setWizardContext({ route53: mergeRight(wizardContext?.route53, { apiFqdn }) })
          : null
      }
      required={required}
      disabled={disabled}
    />
  )
}
