import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { intersection } from 'ramda'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'

// TODO figure out why using this throws an exception here only
const acceptedApiVersions = ['v1alpha2']

const { qbert } = ApiClient.getInstance()

export const getImportedClusters = async () => {
  Bugsnag.leaveBreadcrumb('Attempting to get imported clusters')
  await qbert.getImportedClusters()
}

export const getEcoInstallationYaml = async (clusterId) => {
  Bugsnag.leaveBreadcrumb('Attempting to get ECO installation YAML', { clusterId })
  return await qbert.getEcoInstallationYaml(clusterId)
}

export const clusterAgentActions = ActionsSet.make<DataKeys.ClusterAgents>({
  uniqueIdentifier: 'metadata.name',
  entityName: entityNamesByKey.ClusterAgents,
  cacheKey: DataKeys.ClusterAgents,
})

export const listClusterAgents = clusterAgentActions.add(
  new ListAction<DataKeys.ClusterAgents>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster agents')
    return await qbert.getClusterAgents()
  }),
)

export const importedClusterActions = ActionsSet.make<DataKeys.ImportedClusters>({
  uniqueIdentifier: 'uuid',
  entityName: entityNamesByKey.ImportedClusters,
  cacheKey: DataKeys.ImportedClusters,
})

export const listImportedClusters = importedClusterActions.add(
  new ListAction<DataKeys.ImportedClusters>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get imported clusters')
    try {
      const rawSunpikeApis = await qbert.getSunpikeApis()
      const sunpikeApis = rawSunpikeApis.versions?.map((version) => version.version)
      if (!intersection(sunpikeApis, acceptedApiVersions).length) {
        // If API is not supported yet, return an empty array
        return []
      }
    } catch (err) {
      return []
    }

    const settledClusters = await qbert.getImportedClusters()
    // Until non-imported clusters are completely moved over to sunpike API
    // filter them out (only return external clusters)
    return settledClusters?.filter((cluster) => cluster?.spec?.external)
  }).addDependency(DataKeys.ClusterAgents),
)

export const deleteImportedCluster = importedClusterActions.add(
  new DeleteAction<DataKeys.ImportedClusters, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to detach imported cluster', { clusterId })
    await qbert.deregisterExternalCluster(clusterId)
  }),
)
