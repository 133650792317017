import React from 'react'
import NodesListPage from './NodesListPage'
import { NodeListHeader } from './NodesListPageHeader'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

export default function NodesIndexPage() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <NodeListHeader />
      <NodesListPage />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gap: 16,
    gridTemplateRows: 'max-content 1fr',
  },
}))
