import React, { useMemo } from 'react'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'
import useListAction from 'core/hooks/useListAction'
import { propEq, always } from 'ramda'
import { useSelector } from 'react-redux'
import { listCloudProviders } from '../../../cloudProviders/new-actions'
import { cloudProvidersSelector } from '../../../cloudProviders/selectors'

interface CapiCloudProviderValue {
  uuid: string
  name: string
  projectId: string
}

interface CapiCloudProviderPicklistProps extends Omit<DropdownProps<string>, 'items' | 'onChange'> {
  type?: string
  id?: string
  onChange: (value: CapiCloudProviderValue) => void
}

export default function CapiCloudProviderPicklist({
  type,
  showAll = false,
  showNone = false,
  label = 'Cloud Provider',
  ...rest
}: CapiCloudProviderPicklistProps) {
  const { loading } = useListAction(listCloudProviders)
  const cloudProviders = useSelector(cloudProvidersSelector)

  const items = useMemo(
    () =>
      cloudProviders.filter(type ? propEq('type', type) : always(true)).map((provider) => ({
        key: provider.uuid,
        label: provider.name,
        value: { uuid: provider.uuid, name: provider.name, projectId: provider.projectId },
      })),
    [cloudProviders],
  )

  return (
    <Picklist
      {...rest}
      label={label}
      showAll={showAll}
      showNone={showNone}
      loading={loading}
      // @ts-ignore
      items={items}
    />
  )
}
