import React, { useCallback, useEffect, useMemo } from 'react'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import { ipValidators } from './validators'
import { NetworkStackTypes } from '../constants'
import Dropdown from 'core/elements/dropdown'
import Row from 'core/containers/Row'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { isNil, keys, mergeRight } from 'ramda'
import { CalicoCapiCnikeys } from 'k8s/components/infrastructure/clusters/aws/capi/CapiAwsNetworkBackendField'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  bulletList: {
    paddingInlineStart: 16,
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
}))

const calicoIpIPHelpText = {
  Always: 'Encapsulates POD traffic in IP-in-IP between nodes.',
  CrossSubnet: `Encapsulation when nodes span subnets and cross routers that may drop native POD 
     traffic, this is not required between nodes with L2 connectivity.`,
  Never: 'Disables IP in IP Encapsulation.',
}
const calicoBlockSizeTooltip = {
  [NetworkStackTypes.IPv6]: `Block size to use for the IPv6 POOL created at startup. Block size for 
    IPv6 should be in the range 116-128`,
  [NetworkStackTypes.IPv4]: `Block size determines how many Pod's can run per node vs total number
    of nodes per cluster. Example /22 enables 1024 IPs per node, and a maximum of 64 nodes. Block
    size must be greater than 20 and less than 32 and not conflict with the Contain CIDR`,
}

const calicoIpIpOptions = [
  { label: 'Always', value: 'Always' },
  { label: 'Cross Subnet', value: 'CrossSubnet' },
  { label: 'Never', value: 'Never' },
]
export enum CalicoDetectionTypes {
  FirstFound = 'first-found',
  KubernetesInternalIp = 'kubernetes-internal-ip',
  CanReach = 'can-reach',
  Interface = 'interface',
  SkipInterface = 'skip-interface',
}

const calicoDetectionOptions = [
  { label: 'First Found', value: CalicoDetectionTypes.FirstFound },
  {
    label: 'Kubernetes Internal IP',
    value: CalicoDetectionTypes.KubernetesInternalIp,
  },
  { label: 'Can Reach', value: CalicoDetectionTypes.CanReach },
  { label: 'Interface', value: CalicoDetectionTypes.Interface },
  { label: 'Skip Interface', value: CalicoDetectionTypes.SkipInterface },
]

const detectionMethodLabels = {
  'can-reach': 'IP or Domain Name',
  interface: 'IFace Name Regex List',
  'skip-interface': 'IFace Name Regex List',
}

export const calicoDefaults = {
  IPv4DetectionMethod: CalicoDetectionTypes.FirstFound,
  IPIPMode: 'Always',
  natOutgoing: true,
  IPv4BlkSize: 26,
  MTUSize: 1440,
}

const CalicoNetworkFields = ({
  values,
  setValues,
  useToggleSwitch = false,
  isCapiCluster = false,
}) => {
  const classes = useStyles()
  const minItemWidth = isCapiCluster ? '300' : '400'

  const ipIpModeFieldId = isCapiCluster ? CalicoCapiCnikeys.IPIPMode : 'calicoIpIpMode'
  const natOutgoingFieldId = isCapiCluster ? CalicoCapiCnikeys.natOutgoing : 'calicoNatOutgoing'

  const detectionMethodFieldId = isCapiCluster
    ? CalicoCapiCnikeys.IPv4DetectionMethod
    : 'calicoDetectionMethod'
  const detectionMethodValueId = 'calicoDetectionMethodValue'
  const blockSizeFieldId = isCapiCluster ? CalicoCapiCnikeys.IPv4BlkSize : 'calicoBlockSize'
  const mtuSizeFieldId = isCapiCluster ? CalicoCapiCnikeys.MTUSize : 'mtuSize'

  const ipIpModeTooltipInfo = isCapiCluster
    ? calicoIpIPHelpText[values?.calico?.[ipIpModeFieldId]]
    : calicoIpIPHelpText[values[ipIpModeFieldId]]

  const detectionMethod = isCapiCluster
    ? values?.calico?.[detectionMethodFieldId]
    : values[detectionMethodFieldId]

  const detectionMethodLabel =
    detectionMethodLabels[keys(detectionMethodLabels).find((key) => detectionMethod.includes(key))]

  const showDetectionMethodValueField =
    detectionMethod !== CalicoDetectionTypes.FirstFound &&
    detectionMethod !== CalicoDetectionTypes.KubernetesInternalIp

  const handleChange = (property) => (value) => {
    if (isCapiCluster) {
      return setValues({ calico: mergeRight(values.calico || {}, { [property]: value }) })
    }
    setValues({ [property]: value })
  }

  const handleDetectionMethodChanges = (property) => (value) => {
    switch (property) {
      case detectionMethodFieldId:
        isCapiCluster
          ? setValues({
              calico: mergeRight(values.calico || {}, {
                [detectionMethodFieldId]: value,
              }),
            })
          : setValues({ [detectionMethodFieldId]: value, [detectionMethodValueId]: '' })
        break

      case detectionMethodValueId:
        isCapiCluster
          ? setValues({
              // special handling required for Calico Capi Interface Detection Method
              calico: mergeRight(values.calico || {}, {
                [detectionMethodFieldId]: `${
                  values?.calico?.[detectionMethodFieldId].split('=')[0]
                }=${value}`,
              }),
            })
          : setValues({ [detectionMethodValueId]: value })
        break
    }
  }

  return (
    <>
      <Row minItemWidth={minItemWidth} gap={24}>
        <DropdownField
          DropdownComponent={Dropdown}
          id={ipIpModeFieldId}
          value={isCapiCluster ? values?.calico?.[ipIpModeFieldId] : values[ipIpModeFieldId]}
          onChange={handleChange(ipIpModeFieldId)}
          label="IP in IP Encapsulation Mode"
          items={calicoIpIpOptions}
          tooltip={ipIpModeTooltipInfo || ''}
          required
        />
        <DropdownField
          DropdownComponent={AsyncDropdown}
          id={detectionMethodFieldId}
          // value={
          //   isCapiCluster
          //     ? values?.calico?.[detectionMethodFieldId]
          //     : values[detectionMethodFieldId]
          // }
          onChange={handleDetectionMethodChanges(detectionMethodFieldId)}
          label="Interface Detection Method"
          items={calicoDetectionOptions}
          tooltip={
            <ul className={classes.bulletList}>
              <li>First found: First valid IP address on the first interface.</li>
              <li>Kubernetes Internal IP: Address assigned to Kubernetes node.</li>
              <li>Can Reach: Address used by the node to reach a particular IP or domain.</li>
              <li>Interface: Regex to include matching interfaces names.</li>
              <li>Skip Interface: Regex to exclude matching interfaces.</li>
            </ul>
          }
          selectFirst
          required
        />
        {showDetectionMethodValueField && (
          <TextField
            id={detectionMethodValueId}
            value={
              isCapiCluster
                ? values?.calico?.[detectionMethodFieldId]?.split('=')[1] || ''
                : values[detectionMethodValueId]
            }
            onChange={handleDetectionMethodChanges(detectionMethodValueId)}
            info={`Use the first valid IP address on the first enumerated interface (same logic as 
              first-found above) that does NOT match with any of the specified interface name 
              regexes. Regexes are separated by commas (e.g. eth.,enp0s.).`}
            label={detectionMethodLabel || ''}
            required
          />
        )}
      </Row>
      {useToggleSwitch ? (
        <ToggleSwitchField
          id={natOutgoingFieldId}
          value={isCapiCluster ? values?.calico?.[natOutgoingFieldId] : values[natOutgoingFieldId]}
          onChange={handleChange(natOutgoingFieldId)}
          label="NAT Outgoing"
          info="Packets destined outside the POD network will be SNAT'd using the node's IP."
        />
      ) : (
        <CheckboxField
          id={natOutgoingFieldId}
          value={isCapiCluster ? values?.calico?.[natOutgoingFieldId] : values[natOutgoingFieldId]}
          onChange={handleChange(natOutgoingFieldId)}
          label="NAT Outgoing"
          info="Packets destined outside the POD network will be SNAT'd using the node's IP."
        />
      )}
      <Row minItemWidth={minItemWidth} gap={24}>
        <TextField
          id={blockSizeFieldId}
          value={isCapiCluster ? values?.calico?.[blockSizeFieldId] : values[blockSizeFieldId]}
          onChange={handleChange(blockSizeFieldId)}
          label="Block Size"
          info={calicoBlockSizeTooltip?.[values.networkStack]}
          required
          type="number"
          validations={[
            ipValidators?.[values.networkStack]?.blockSizeValidator,
            ipValidators?.[values.networkStack]?.calicoBlockSizeCIDRValidator,
          ]}
        />
        <TextField
          id={mtuSizeFieldId}
          value={isCapiCluster ? values?.calico?.[mtuSizeFieldId] : values[mtuSizeFieldId]}
          onChange={handleChange(mtuSizeFieldId)}
          label="MTU Size"
          type="number"
          info="Maximum Transmission Unit (MTU) for the interface (in bytes)"
          required
        />
      </Row>
    </>
  )
}

export default CalicoNetworkFields
