import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import React, { useState } from 'react'
import EditNodeGroupModal from './EditNodeGroupModal'

// Temp solution for now. Remove this when Xan is finsihed adding the single item action
// in the grid

export default function EditNodeGroupButton({ nodeGroup }) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {showModal && (
        <EditNodeGroupModal open onClose={() => setShowModal(false)} nodeGroup={nodeGroup} />
      )}
      <FontAwesomeIcon onClick={() => setShowModal(true)}>pen-to-square</FontAwesomeIcon>
    </>
  )
}
