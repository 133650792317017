import React from 'react'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'

import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'

import { pathStrOr } from 'utils/fp'
import { uniq } from 'ramda'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface VipInterfaceChooserProps extends Omit<DropdownProps<string>, 'items'> {
  masterNodes: string[]
}

export default function VipInterfaceChooser({ masterNodes, ...rest }: VipInterfaceChooserProps) {
  const { loading } = useListAction(listNodes)
  const nodes = useSelector(nodesSelector)

  const masters = nodes.filter((node) => masterNodes && masterNodes.includes(node.uuid))

  const options = uniq(
    masters
      .map((node) => {
        const interfacesObj = pathStrOr([], 'combined.networkInterfaces', node)
        return Object.keys(interfacesObj).map((iface) => ({ value: iface, label: iface }))
      })
      .flat(),
  )

  return <AsyncDropdown {...rest} loading={loading} items={options} />
}
