import { getHealthStatusMessage } from '../clusters/ClusterStatusUtils'
import { routes } from 'core/utils/routes'
import uuid from 'uuid'
import { IEditImportedClusterPageTabs } from '../importedClusters/model'
import { CapiClusterPhases, CapiDetailsPageTabs } from '../clusters/capi/model'
import { isEmpty } from 'ramda'

export const getLegacyClusterIssues = (clusters) => {
  return clusters.reduce((accum, cluster) => {
    // If converging, ignore
    if (cluster?.connectionStatus === 'converging') {
      return accum
    }
    if (cluster?.healthStatus === 'unhealthy') {
      return [
        ...accum,
        {
          type: 'error',
          status: 'Unhealthy',
          message: getHealthStatusMessage(cluster),
          link: routes.cluster.managed.qbert.detail.path({ id: cluster.uuid }),
          resourceType: 'Cluster',
          resourceName: cluster?.name,
          id: uuid.v4(),
        },
      ]
    }
    return accum
  }, [])
}

export const getImportedClusterIssues = (importedClusters) => {
  return importedClusters.reduce((accum, cluster) => {
    const list = accum
    if (cluster?.status?.phase === 'Failing') {
      list.push({
        type: 'error',
        status: 'Failing',
        message: cluster?.status?.message,
        link: routes.cluster.imported.details.path({
          id: cluster.uuid,
        }),
        resourceType: 'Imported Cluster',
        resourceName: cluster?.name,
        id: uuid.v4(),
      })
    }
    if (!cluster?.ecoInstalled) {
      list.push({
        type: 'error',
        status: 'ECO Not Installed',
        message: 'ECO is required to create a secure connection from the Cluster to Platform9',
        link: routes.cluster.imported.edit.path({
          id: cluster?.uuid,
          tab: IEditImportedClusterPageTabs.ECO,
        }),
        resourceType: 'Imported Cluster',
        resourceName: cluster?.name,
        id: uuid.v4(),
      })
    }
    if (cluster?.ecoStatus === 'errored') {
      list.push({
        type: 'error',
        status: 'ECO Error',
        message: 'ECO on this cluster has encountered an error',
        link: routes.cluster.imported.details.path({
          id: cluster?.uuid,
        }),
        resourceType: 'Imported Cluster',
        resourceName: cluster?.name,
        id: uuid.v4(),
      })
    }
    if (cluster?.ecoStatus === 'offline') {
      list.push({
        type: 'warning',
        status: 'ECO Offline',
        message: 'ECO on this cluster is offline',
        link: routes.cluster.imported.details.path({
          id: cluster?.uuid,
        }),
        resourceType: 'Imported Cluster',
        resourceName: cluster?.name,
        id: uuid.v4(),
      })
    }
    return list
  }, [])
}

export const getCapiClusterIssues = (clusters) => {
  return clusters.reduce((accum, cluster) => {
    const infraNotProvisioned = cluster.phase !== CapiClusterPhases.Provisioned
    const infraNotReady = !cluster.infrastructureReady
    const controlPlaneNotReady = !cluster.controlPlaneReady

    if (cluster?.phase === 'Failed') {
      return [
        ...accum,
        {
          type: 'error',
          status: 'Failed',
          message: 'This cluster is in a failed state',
          link: routes.cluster.managed.list.path(),
          resourceType: 'CAPI Cluster',
          resourceName: cluster?.name,
          id: uuid.v4(),
        },
      ]
    } else if ((infraNotProvisioned || infraNotReady) && !isEmpty(cluster.infraErrors)) {
      return [
        ...accum,
        {
          type: 'error',
          status: cluster.infraErrors[0]?.reason,
          message: cluster.infraErrors[0]?.message,
          link: routes.cluster.managed.capi.details.path({
            id: cluster.uuid,
            tab: CapiDetailsPageTabs.Infrastructure,
          }),
          resourceType: 'CAPI Cluster',
          resourceName: cluster?.name,
          id: uuid.v4(),
        },
      ]
    } else if (controlPlaneNotReady && !isEmpty(cluster.controlPlaneErrors)) {
      return [
        ...accum,
        {
          type: 'error',
          status: cluster.controlPlaneErrors[0]?.reason,
          message: cluster.controlPlaneErrors[0]?.message,
          link: routes.cluster.managed.capi.details.path({
            id: cluster.uuid,
            tab: CapiDetailsPageTabs.ControlPlane,
          }),
          resourceType: 'CAPI Cluster',
          resourceName: cluster?.name,
          id: uuid.v4(),
        },
      ]
    }
    return accum
  }, [])
}

export const getNodeIssues = (nodes) => {
  return nodes.reduce((accum, node) => {
    const list = accum
    if (!node?.isAuthorized) {
      list.push({
        type: 'warning',
        status: 'Unauthorized',
        message: 'This node has not been authorized yet',
        link: routes.nodes.list.path(),
        resourceType: 'Node',
        resourceName: node?.name,
        id: uuid.v4(),
      })
    }
    if (node.status === 'disconnected') {
      const showLastResponse = node?.combined && node?.combined?.lastResponse
      list.push({
        type: 'error',
        status: showLastResponse
          ? `Disconnected since ${node?.combined?.lastResponse}`
          : 'Disconnected',
        message: 'This node is not connected to Platform9',
        link: routes.nodes.detail.path({ id: node?.uuid }),
        resourceType: 'Node',
        resourceName: node?.name,
        id: uuid.v4(),
      })
    }
    if (!['ok', 'disconnected', 'converging'].includes(node?.status)) {
      list.push({
        type: 'warning',
        status: 'Unhealthy',
        message: `This node is unhealthy with status ${node?.status}`,
        link: routes.nodes.detail.path({ id: node?.uuid }),
        resourceType: 'Node',
        resourceName: node?.name,
        id: uuid.v4(),
      })
    }
    return list
  }, [])
}
