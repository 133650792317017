import { partition, pathOr } from 'ramda'
import { Pf9KubeStatusData } from 'api-client/resmgr.model'
import { ClusterType, HardwareType, nodeHardwareRequirements } from '../clusters/bareos/constants'
import {
  ApiServerHealthStatus,
  ApiServerHealthStatusFields,
  ErrorMessageCodes,
  errorMessageLevel,
  INodesSelector,
} from './model'
import * as IpAddress from 'ip-address'
import { NetworkStackTypes } from '../clusters/constants'

export const clockDriftErrorMessage = 'Cannot attach node(s) with clock drift'

export const getErrorMessage = (node, msgLevel: errorMessageLevel, code: ErrorMessageCodes) => {
  const messages = pathOr(null, ['message', msgLevel], node)
  if (!messages || !Array.isArray(messages)) return null

  return messages.find((msg) => msg.code === code)?.message
}

export const hasClockDrift = (node) => !!getErrorMessage(node, 'warn', ErrorMessageCodes.timeDrift)

export const isUnauthorizedHost = (host) => !host?.roles?.includes('pf9-kube')

const isPrimaryNetwork = (primaryNetwork) => ([name, ip]) => ip === primaryNetwork

export const orderInterfaces = (
  networkInterfaces: Record<string, string>,
  primaryNetwork: string,
) => {
  return [].concat(
    ...partition(isPrimaryNetwork(primaryNetwork), Object.entries(networkInterfaces)),
  )
}

export const getNetworkingStackFromIp = (ipAddress = '') => {
  if (IpAddress.Address6.isValid(ipAddress)) {
    return NetworkStackTypes.IPv6
  }
  return NetworkStackTypes.IPv4
}

export const meetsHardwareRequirement = (
  value,
  clusterType: ClusterType,
  hardwareType: HardwareType,
) => {
  if (!value) return false
  return value >= nodeHardwareRequirements[clusterType][hardwareType]
}

/**
 * Checks whether or not any node in the nodes array have clock drift
 * @param nodes Array of nodes
 * @returns True if clock drift is detected in any of the nodes. False if none of the nodes have clock drift
 */
export const clockDriftDetectedInNodes = (nodes) => {
  return nodes && !!nodes.find((node) => hasClockDrift(node))
}

export const nodeApiServerHealthStatusFields: {
  [status in ApiServerHealthStatus]: ApiServerHealthStatusFields
} = {
  online: {
    label: 'Online',
    message: 'API server is responding on this node',
    clusterStatus: 'ok',
  },
  offline: {
    label: 'Offline',
    message: 'API server is not responding on this node',
    clusterStatus: 'fail',
  },
}

/**
 *
 * @param nodeUuids Array of node uuids
 * @param allNodes Array of all nodes
 * @returns True if clock drift is detected in any of the nodes. False if none of the nodes have clock drift
 */
export const checkNodesForClockDrift = (nodeUuids: String[], allNodes: INodesSelector[]) => {
  if (!nodeUuids || !allNodes) return false
  for (const uuid of nodeUuids) {
    const node = allNodes.find((node) => node.uuid === uuid)
    if (hasClockDrift(node)) {
      return true
    }
  }
  return false
}

const getPayload = (allTasks, completedTasks, title, message, currentIdx) => ({
  allTasks,
  completedTasks,
  title,
  message,
  currentIdx,
})

const getInstallPayload = (task, currentIdx, status, icon, isActive, isCompleted) => ({
  task,
  currentIdx,
  status,
  icon,
  isActive,
  isCompleted,
})

export const getNodeKubeStatusData = (node) =>
  node?.combined?.resmgr?.extensions?.pf9_kube_status?.data || ({} as Pf9KubeStatusData)

export const getTaskIdxFeatures = (allTasks, completedTasks, lastFailedTask) => {
  const currentStep = allTasks[completedTasks.length]
  const lastIdx = completedTasks.length
  const currentIdx = completedTasks.length + 1
  const failedIdx = allTasks.indexOf(lastFailedTask)

  // TODO get backend to update the last_failed_task to be null rather than 'None'
  const hasFailed = !!lastFailedTask && lastFailedTask !== 'None'

  return {
    currentStep,
    lastIdx,
    currentIdx,
    failedIdx,
    hasFailed,
  }
}

export const getTaskContent = (nodeKubeStatusData: Pf9KubeStatusData = {} as Pf9KubeStatusData) => {
  const allTasks = nodeKubeStatusData.all_tasks || []
  const completedTasks = nodeKubeStatusData.completed_tasks || []
  const lastFailedTask = nodeKubeStatusData.last_failed_task || null
  const { currentStep, lastIdx, currentIdx, failedIdx, hasFailed } = getTaskIdxFeatures(
    allTasks,
    completedTasks,
    lastFailedTask,
  )

  if (allTasks.length === 0) {
    return getPayload(allTasks, completedTasks, 'Waiting for node...', '', 0)
  } else if (failedIdx === -1 && allTasks.length === completedTasks.length) {
    return getPayload(allTasks, completedTasks, 'Completed', '', lastIdx)
  } else if (completedTasks.length < allTasks.length && !hasFailed) {
    return getPayload(allTasks, completedTasks, 'In progress...', currentStep, currentIdx)
  } else {
    return getPayload(allTasks, completedTasks, 'Failed', lastFailedTask, failedIdx + 1)
  }
}

export const getInstallTaskContent = ({
  task,
  completedTasks,
  nodeState,
  currentIdx,
  failedIdx,
}) => {
  if (currentIdx === failedIdx && nodeState !== 'retrying') {
    return getInstallPayload(task, currentIdx, 'error', 'times', true, false)
  }
  if (currentIdx === failedIdx && nodeState === 'retrying') {
    return getInstallPayload(task, currentIdx, 'error', 'sync', true, false)
  }
  if (currentIdx < completedTasks.length || currentIdx <= failedIdx) {
    return getInstallPayload(task, currentIdx, 'primary', 'check', false, true)
  }
  if (currentIdx === completedTasks.length && completedTasks.length > 0 && failedIdx === -1) {
    return getInstallPayload(task, currentIdx, 'primary', 'sync', true, false)
  }
  return getInstallPayload(task, currentIdx, 'default', '', false, false)
}

export const isConnected = (node: INodesSelector) => node.status === 'ok'
export const isUnassignedNode = (node: INodesSelector) => !node.clusterUuid
export const excludeNodes = (excludeList: string[] = []) => (node: INodesSelector) =>
  !excludeList.includes(node.uuid)
export const isMaster = (node: INodesSelector) => !!node.isMaster
export const isNotMaster = (node: INodesSelector) => !node.isMaster
export const inCluster = (clusterUuid: string) => (node: INodesSelector) =>
  node.clusterUuid === clusterUuid
export const networkingStackFilter = (networkStack) => (node: INodesSelector) => {
  return node.networkStack === networkStack
}
export const getAvailableSpace = (disk) => {
  if (!disk) return 0
  return disk.max - disk.current
}
