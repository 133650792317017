import React from 'react'

import CheckboxField from 'core/components/validatedForm/CheckboxField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { defaultEtcBackupPath } from 'app/constants'
import EtcdBackupParamFields from './param-fields/EtcdBackupParamFields'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'

export const etcdBackupDefaults = {
  useIntervalBackup: false,
  intervalBackupUnit: 'hours',
  useTimestampBackup: true,
  etcdStoragePath: defaultEtcBackupPath,
  etcdBackupIntervalMinutes: 30,
  etcdBackupIntervalHours: 4,
  etcdBackupTimestamp: '02:00',
  maxTimestampBackups: 3,
  maxIntervalBackups: 3,
}

export const oneClickEtcdBackupDefaults = {
  etcdStoragePath: defaultEtcBackupPath,
  etcdBackupTimestamp: '02:00',
  useTimestampBackup: true,
  maxTimestampBackups: 3,
}

const etcdBackupInfo = 'Enable automated etcd backups on this cluster'

const EtcdBackupFields = ({ wizardContext, setWizardContext, useToggleSwitch = false }) => {
  return useToggleSwitch ? (
    <ToggleSwitchField
      id="etcdBackup"
      label="ETCD Backup"
      onChange={(value) => setWizardContext({ etcdBackup: value })}
      info={etcdBackupInfo}
      infoPlacement={{ align: topMiddle.align, offset: topMiddle.offset }}
    />
  ) : (
    <CheckboxField id="etcdBackup" label="Enable ETCD Backup" info={etcdBackupInfo} />
  )
}

export const EdcdBackupAddonFields = ({
  values,
  setFieldValue,
  showHeader = true,
  useToggleSwitch = false,
}) => {
  return showHeader ? (
    <FormFieldSection
      title="ETCD Backup Configuration"
      info="ETCD Backup will backup your ETCD database at a set interval to the location provided."
    >
      <EtcdBackupParamFields
        values={values}
        setFieldValue={setFieldValue}
        useToggleSwitch={useToggleSwitch}
      />
    </FormFieldSection>
  ) : (
    <EtcdBackupParamFields
      values={values}
      setFieldValue={setFieldValue}
      useToggleSwitch={useToggleSwitch}
    />
  )
}

export default EtcdBackupFields
