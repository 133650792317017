import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { NodeGroupTypeLabels } from '../model'
import { AwsMachinePool, IAwsMachinePoolSelector } from './model'
import { Phase } from '../machine-deployment/model'
import { createSelector } from '@reduxjs/toolkit'
import { filter, pipe, find } from 'ramda'
import { MachinePool } from '../machine-pool/model'

export const awsMachinePoolsSelector: AppSelector<IAwsMachinePoolSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.AwsMachinePools>(DataKeys.AwsMachinePools),
  getDataSelector<DataKeys.MachinePools>(DataKeys.MachinePools),
  (machineTemplates, machinePools): IAwsMachinePoolSelector[] =>
    machineTemplates.map((mp: AwsMachinePool) => {
      const name = mp.metadata?.name
      // Resource with kind = MachinePool. AWSMachinePool is a type of MachinePool
      const machinePool = find(
        (machinePool: MachinePool) => machinePool.metadata?.name === name,
        machinePools,
      )

      return {
        ...mp,
        uuid: mp.metadata?.uid,
        name,
        namespace: mp.metadata?.namespace,
        clusterName: mp.metadata?.labels?.['cluster.x-k8s.io/cluster-name'],
        creationTimestamp: mp.metadata?.creationTimestamp,
        type: NodeGroupTypeLabels.AWSMachinePool,
        instanceType: mp.spec?.awsLaunchTemplate?.instanceType,
        awsLaunchTemplate: mp.spec?.awsLaunchTemplate,
        amiId: mp.spec?.awsLaunchTemplate?.ami?.id,
        sshKeyName: mp.spec?.awsLaunchTemplate?.sshKeyName,
        availabilityZones: mp.spec?.availabilityZones,
        minSize: mp.spec?.minSize,
        maxSize: mp.spec?.maxSize,
        mixedInstancesPolicy: mp.spec?.mixedInstancesPolicy,
        mixedInstances: !!mp.spec?.mixedInstancesPolicy,
        ready: mp.status?.ready,
        phase: mp.status?.ready ? Phase.Running : Phase.Failed,
        resources: [mp],
        refreshPreferences: mp.spec?.refreshPreferences,
        minHealthyPercentage: mp.spec?.refreshPreferences?.minHealthyPercentage,
        updateStrategy: mp.spec?.refreshPreferences?.strategy,
        numNodes: machinePool?.status?.readyReplicas,
      }
    }),
)

export const makeFilteredAwsMachinePoolsSelector = (
  defaultParams = {} as { clusterName: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(awsMachinePoolsSelector, selectParams, (machinePools, params) => {
    const { clusterName } = params
    const filterByClusterName = filter<IAwsMachinePoolSelector>((mp) => {
      return mp.clusterName === clusterName
    })
    return pipe<IAwsMachinePoolSelector[], IAwsMachinePoolSelector[]>(filterByClusterName)(
      machinePools,
    )
  })
}
