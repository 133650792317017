import Button from 'core/elements/button'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import React from 'react'

export default function EditYamlDialog({ onClose, enableEdit }) {
  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title="Enable YAML editing?"
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={enableEdit}>Yes, enable</Button>
        </>
      }
    >
      <Text variant="body2">
        Remember that altering the source code could result in permanent changes to how the cluster
        functions.
      </Text>
    </Modal>
  )
}
