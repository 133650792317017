import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'
import ExternalLink from 'core/components/ExternalLink'
import DownloadKubeConfigLink from 'app/plugins/infrastructure/components/clusters/DownloadKubeConfigLink'

export default function ClusterLinkCell({
  item: { links, usage },
}: GridCellProps<ClusterSelector>) {
  const hasGrafanaLink = !!usage && usage.grafanaLink
  if (!links && !hasGrafanaLink) {
    return null
  }
  const classes = useStyles()
  return (
    <div className={classes.linksCell}>
      {links.dashboard && (
        <ExternalLink
          className={`icon no-wrap-text`}
          url={links.dashboard}
          icon="external-link"
          iconPosition="left"
        >
          Dashboard
        </ExternalLink>
      )}
      {hasGrafanaLink && (
        <ExternalLink
          className={`icon no-wrap-text`}
          url={usage.grafanaLink}
          icon="external-link"
          iconPosition="left"
        >
          Grafana
        </ExternalLink>
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  linksCell: {
    display: 'grid',
    '& .icon': {
      alignItems: 'center',
      justifyContent: 'flex-start',
      display: 'flex',
      '& i': {
        fontSize: '12px',
        height: '11px',
        width: '14px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'inline-flex',
      },
    },
  },
}))
