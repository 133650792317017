import { ICapiAwsClusterSelector, CapiResourceKind } from '../aws/capi/model'
import { ClusterTypes } from '../model'
import { IMachinePoolSelector } from './machine-pool/model'
import { IAwsManagedControlPlaneSelector } from './control-plane/aws-managed-control-plane/model'
import { INodeletControlPlaneSelector } from './control-plane/nodelet-control-plane/model'
import { IMachineDeploymentSelector } from './machine-deployment/model'
import { IClusterUpgradeJobSelector } from './upgrade/model'

export enum AwsClusterTypes {
  AWS = 'aws',
  EKS = 'eks',
}

export enum CapiClusterPhases {
  Pending = 'Pending',
  Provisioning = 'Provisioning',
  Provisioned = 'Provisioned',
  Deleting = 'Deleting',
  Failed = 'Failed',
}

export enum CapiDetailsPageTabs {
  Overview = 'overview',
  Infrastructure = 'infrastructure',
  ControlPlane = 'control-plane',
  NodeGroups = 'node-groups',
  Addons = 'addOns',
}

export enum AwsClusterIdentityKinds {
  AWSClusterControllerIdentity = 'AWSClusterControllerIdentity',
  AWSClusterRoleIdentity = 'AWSClusterRoleIdentity',
  AWSClusterStaticIdentity = 'AWSClusterStaticIdentity',
}

export enum NodeGroupTypes {
  MachineDeployment = 'MachineDeployment',
  MachinePool = 'MachinePool',
  AwsMachinePool = 'AWSMachinePool',
  AwsManagedMachinePool = 'AWSManagedMachinePool',
  AwsMachineTemplate = 'AWSMachineTemplate',
}
export enum NodeGroupTypeLabels {
  MachineDeployment = 'Machine Deployment',
  MachinePool = 'Machine Pool',
  AWSMachinePool = 'AWS Machine Pool',
  AWSMachinTemplate = 'AWS Machine Template',
  AWSManagedMachinePool = 'AWS Managed Machine Pool',
  NodeletConfig = 'Nodelet Config',
  EKSConfig = 'EKS Config',
}

export enum ConfigTypes {
  NodeletConfig = 'NodeletConfig',
  NodeletConfigTemplate = 'NodeletConfigTemplate',
  EksConfig = 'EKSConfig',
  EksConfigTemplate = 'EKSConfigTemplate',
}

export enum ConfigTypeLabels {
  NodeletConfig = 'Nodelet Config',
  NodeletConfigTemplate = 'NodeletConfigTemplate',
  EksConfig = 'EKS Config',
  EksConfigTemplates = 'EKS Config Templates',
}
export interface ICapiClusterSelector extends CapiCluster {
  uuid: CapiCluster['metadata']['uid']
  name: CapiCluster['metadata']['name']
  namespace: CapiCluster['metadata']['namespace']
  creationTimestamp: CapiCluster['metadata']['creationTimestamp']
  podsCidrBlocks: CapiCluster['spec']['clusterNetwork']['pods']['cidrBlocks']
  servicesCidrBlocks: CapiCluster['spec']['clusterNetwork']['services']['cidrBlocks']
  phase: CapiCluster['status']['phase']
  controlPlaneReady: CapiCluster['status']['controlPlaneReady']
  infrastructureReady: CapiCluster['status']['infrastructureReady']
  controlPlaneEndpoint: string
  clusterType: ClusterTypes.Capi
  infrastructureType: string
  privileged: boolean
  allowWorkloadsOnMaster: boolean
  version: string
  infrastructureRef: CapiCluster['spec']['infrastructureRef']
  controlPlaneRef: CapiCluster['spec']['controlPlaneRef']
  infrastructure: ICapiAwsClusterSelector | IAwsManagedControlPlaneSelector
  controlPlane: INodeletControlPlaneSelector | IAwsManagedControlPlaneSelector
  vpcCidrBlock: string
  resources: any[]
  cloudProviderId: string
  region: string
  links: Links
  usage: Usage
  machineDeployments: IMachineDeploymentSelector[]
  machinePools: IMachinePoolSelector[]
  allNodeGroups: Array<IMachinePoolSelector | IMachineDeploymentSelector>
  canUpgrade: boolean
  upgradeJobs: IClusterUpgradeJobSelector[]
  recentUpgradeJob: IClusterUpgradeJobSelector
  currentUpgradeJob: IClusterUpgradeJobSelector
  upgrading: boolean
  upgradeFailed: boolean
  upgradeCompleted: boolean
  infraErrors: Condition[]
  controlPlaneErrors: Condition[]
}
export interface Links {
  dashboard: string
}
export interface Usage {
  grafanaLink: string
}
export interface ICapiResource {
  kind: CapiResourceKind
  status: Status
  spec: Spec
  metadata: Metadata
}

export interface GetCapiClustersResponse {
  apiVersion: string
  items: CapiCluster[]
  kind: string
  metadata: GetCapiClustersResponseMetadata
}

export interface CapiCluster extends ICapiResource {
  apiVersion: string
  kind: CapiResourceKind
  metadata: Metadata
  spec: Spec
  status: Status
}

export interface Metadata {
  creationTimestamp: string
  finalizers: string[]
  generation: number
  name: string
  namespace: string
  resourceVersion: string
  selfLink: string
  uid: string
}

interface Spec {
  clusterNetwork: ClusterNetwork
  controlPlaneEndpoint: ControlPlaneEndpoint
  controlPlaneRef: ControlPlaneRefClass
  infrastructureRef: ControlPlaneRefClass
}

interface ClusterNetwork {
  pods: Pods
  services: Pods
}

interface Pods {
  cidrBlocks: string[]
}

interface ControlPlaneEndpoint {
  host: string
  port: number
}

interface ControlPlaneRefClass {
  apiVersion: string
  kind: string
  name: string
  namespace: string
}

interface Status {
  conditions: Condition[]
  observedGeneration: number
  phase: string
  controlPlaneReady: boolean
  infrastructureReady: boolean
}

interface Condition {
  lastTransitionTime: string
  message?: string
  reason?: string
  severity?: string
  status: string
  type: string
}

export interface GetCapiClustersResponseMetadata {
  continue: string
  resourceVersion: string
  selfLink: string
}

export interface IAwsAmiSelector extends Ami {
  os: AmiSpec['os']
  imageId: AmiSpec['imageID']
  name: AmiMetadata['name']
  version: AmiSpec['kubernetesVersion']
}

export interface AwsAmis {
  kind: 'AWSAMIList'
  apiVersion: string
  items: Ami[]
}
export interface Ami {
  kind: string
  apiVersion: string
  metadata: AmiMetadata
  spec: AmiSpec
}

interface AmiMetadata {
  name: string
  creationTimestamp: string
}

interface AmiSpec {
  os: string
  region: string
  imageID: string
  kubernetesVersion: string
}
