import React, { useContext } from 'react'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { luigiInfo } from 'app/plugins/infrastructure/components/clusters/constants'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'
import { ValidatedFormContext } from 'core/components/validatedForm/ValidatedForm'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'

export default function NetworkPluginOperator({
  wizardContext,
  setWizardContext,
  disabled = false,
  useToggleSwitch = false,
}) {
  return (
    <>
      {useToggleSwitch ? (
        <ToggleSwitchField
          id="deployLuigiOperator"
          label="Network Plugin Operator"
          onChange={(value) => setWizardContext({ deployLuigiOperator: value })}
          info={luigiInfo}
          infoPlacement={{ align: topMiddle.align, offset: topMiddle.offset }}
          value={wizardContext.deployKubevirt ? true : !!wizardContext.deployLuigiOperator}
          disabled={disabled || wizardContext.deployKubevirt}
        />
      ) : (
        <CheckboxField
          id="deployLuigiOperator"
          label="Network Plugin Operator"
          info={luigiInfo}
          onChange={(value) => setWizardContext({ deployLuigiOperator: value })}
          value={wizardContext.deployKubevirt ? true : !!wizardContext.deployLuigiOperator}
          disabled={disabled || wizardContext.deployKubevirt}
        />
      )}
    </>
  )
}
