import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from 'core/elements/button'
import Card from 'core/elements/card'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import useReactRouter from 'use-react-router'
import { metal3Routes } from 'core/utils/routes/metal3Routes'

const imagePath = '/ui/images/icon-cloudproviders/bare-metal-default.svg'

export default function BareMetalCard() {
  const classes = useStyles()
  const { history } = useReactRouter()
  return (
    <Card
      title={
        <div className={classes.bareMetalCardHeader}>
          <div className={classes.iconContainer}>
            <img className={classes.icon} alt="Bare Metal" src={imagePath} />
          </div>
          <div>
            <Text className={classes.newText} variant="caption4">
              New
            </Text>
            <Text variant="subtitle2">Bare Metal</Text>
          </div>
        </div>
      }
      footer={
        <Button
          className={classes.button}
          variant="secondary"
          icon="external-link 1"
          onClick={() => history.push(metal3Routes.comingSoonMetal3.path())}
        >
          Learn More
        </Button>
      }
    >
      <Text variant="body2">
        Enable Metal3 and start deploying and managing bare metal hosts in your Kubernetes clusters
        now.
      </Text>
    </Card>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  bareMetalCardHeader: {
    padding: '16px 32px',
    display: 'flex',
  },
  newText: {
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  iconContainer: {
    width: '28px',
    height: '28px',
    margin: '0 16px 0 0',
    padding: '9.7px 10.6px 10.6px 10.7px',
    opacity: 0.5,
    backgroundColor: '#e6e6ea',
    borderRadius: '50px',
  },
  icon: {
    width: '100%',
    height: '100%',
    margin: '0 0 0px',
    padding: '0 0 6.7px',
    opacity: 0.5,
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}))
