import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Button from 'core/elements/button/Button'
import Theme from 'core/themes/model'
import React, { useState } from 'react'

export default function StatusCondViewToggleButtonGroup({ handleChange }) {
  const classes = useStyles()
  const [activeButtonId, setActiveButtonId] = useState('overview')
  const isActive = (id) => activeButtonId === id
  const handleButtonClick = (id) => {
    setActiveButtonId(id)
    handleChange && handleChange(id)
  }
  return (
    <div className={classes.buttonGroup}>
      <Button
        id="overview"
        size="small"
        variant="secondary"
        className={clsx(classes.button, isActive('overview') ? classes.activeButton : '')}
        onClick={() => handleButtonClick('overview')}
      >
        Overview
      </Button>
      <Button
        id="detailed"
        size="small"
        variant="secondary"
        className={clsx(classes.button, isActive('detailed') ? classes.activeButton : '')}
        onClick={() => handleButtonClick('detailed')}
      >
        Detailed Info
      </Button>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
  },
  button: {
    borderRadius: 'unset',
  },
  activeButton: {
    backgroundColor: theme.components.button.secondary.activeBackground,
    borderColor: theme.components.button.secondary.activeBorder,
    color: theme.components.button.secondary.activeColor,
    '& .button-icon, & .button-text': {
      color: theme.components.button.secondary.activeColor,
    },
  },
}))
