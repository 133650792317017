import React from 'react'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import DownloadCliPage from 'app/plugins/infrastructure/components/nodes/DownloadCliPage'
import DownloadHostAgentPage from 'app/plugins/infrastructure/components/nodes/download-host-agent-page'
import { routes } from 'core/utils/routes'
import DownloadOvaPage from 'app/plugins/infrastructure/components/nodes/download-ova-page'

const OnboardNewNodePage = () => (
  <Tabs route={routes.nodes.add}>
    <Tab value="ova" label="VM Template">
      <DownloadOvaPage />
    </Tab>
    <Tab value="cli" label="PF9 CLI">
      <DownloadCliPage />
    </Tab>
    <Tab value="advanced" label="Advanced">
      <DownloadHostAgentPage />
    </Tab>
  </Tabs>
)

export default OnboardNewNodePage
