import { listTablePrefs, TablePrefsParams } from 'app/constants'
import DocumentMeta from 'core/components/DocumentMeta'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import DataKeys from 'k8s/DataKeys'
import { both, pick } from 'ramda'
import React, { useState } from 'react'
import AddClusterButton from '../AddClusterButton'
import { createCidrRangesCell } from '../cluster-cells/CidrRangesCell'
import { listCapiClusters } from './actions'
import StatusesCell from './grid-cells/StatusesCell'
import { capiClustersSelector } from './selectors'
import getClusterBooleanCell from '../cluster-cells/getClusterBooleanCell'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import GridBadgesArrayCell from 'core/elements/grid/cells/GridBadgesArrayCell'
import ClusterNameCell from '../cluster-cells/ClusterNameCell'
import { CapiClusterPhases } from './model'
import PollingData from 'core/components/PollingData'
import CapiClusterDeleteDialog from './CapiClusterDeleteDialog'
import NodeGroupStatusesCell from '../cluster-cells/NodeGroupStatusesCell'
import { isAdmin } from '../../common/helpers'
import { canDeleteCapiCluster, canUpgradeCapiCluster } from '../helpers'
import { routes } from 'core/utils/routes'
import K8sVersionCell from '../cluster-cells/K8sVersionCell'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import useReactRouter from 'use-react-router'
import { downloadClusterYamls } from './details/helpers'
import KubeconfigDownloadModal from '../KubeconfigDownloadModal'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import getGridRedirectButton from 'core/elements/grid/helpers/getGridRedirectButton'

type ModelDataKey = DataKeys.CapiClusters
type SelectorModel = ArrayElement<ReturnType<typeof capiClustersSelector>>
type ActionParams = InferActionParams<typeof listCapiClusters>
type Params = ActionParams

export const clusterPhaseMap = {
  [CapiClusterPhases.Pending]: 'default',
  [CapiClusterPhases.Provisioning]: 'warning',
  [CapiClusterPhases.Provisioned]: 'success',
  [CapiClusterPhases.Deleting]: 'error',
  [CapiClusterPhases.Failed]: 'error',
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: ClusterNameCell,
  },
  {
    key: 'phase',
    label: 'Phase',
    CellComponent: createGridStatusCell({
      dataFn: (phase: string): StatusCellModel => {
        const variant = clusterPhaseMap[phase] || 'unknown'
        return { variant, label: phase }
      },
    }),
  },
  {
    key: 'status',
    label: 'Status',
    CellComponent: StatusesCell,
  },
  {
    key: 'infrastructureType',
    label: 'Infrastructure Type',
    formatFn: (value: string) => value?.toUpperCase(),
  },
  {
    key: 'controlPlane.version',
    label: 'K8s Version',
    CellComponent: K8sVersionCell,
  },
  {
    key: 'creationTimestamp',
    label: 'Created',
    CellComponent: DateAndTime,
  },
  {
    key: 'nodeGroupsStatus',
    label: 'Node Groups Status',
    CellComponent: NodeGroupStatusesCell,
  },
  {
    key: 'infrastructure.region',
    label: 'Region',
  },
  // {
  //   key: 'alerts',
  //   label: 'Active Alerts',
  // },
  {
    key: 'controlPlaneEndpoint',
    label: 'ControlPlane Endpoint',
    display: false,
  },
  {
    key: 'cidrRanges',
    label: 'CIDR Ranges',
    width: 'medium',
    CellComponent: createCidrRangesCell({
      dataFn: ({ vpcCidrBlock, podsCidrBlocks, servicesCidrBlocks }) => {
        return [
          { label: 'VPC CIDR', value: vpcCidrBlock },
          { label: 'Pods', value: podsCidrBlocks },
          { label: 'Services', value: servicesCidrBlocks },
        ]
      },
    }),
    display: false,
  },
  {
    key: 'controlPlane.cni.plugin',
    label: 'CNI',
    display: false,
  },
  {
    key: 'replicas',
    label: 'ControlPlane Replica Count',
    formatFn: ({ total, ready }) => (total || ready ? `${ready || 0}/${total || 0}` : ''),
    display: false,
  },
  {
    key: 'infrastructure.cloudProviderName',
    label: 'Cloud Provider',
    display: false,
  },
  {
    key: 'allowWorkloadsOnMaster',
    label: 'Master Workloads',
    display: false,
    CellComponent: getClusterBooleanCell({
      key: 'allowWorkloadsOnMaster',
      trueLabel: 'True',
      falseLabel: 'False',
    }),
  },
  {
    key: 'privileged',
    label: 'Privileged',
    display: false,
    CellComponent: getClusterBooleanCell({
      key: 'privileged',
      trueLabel: 'True',
      falseLabel: 'False',
    }),
  },
  {
    key: 'infrastructure.availabilityZones',
    label: 'Availability Zones',
    CellComponent: GridBadgesArrayCell,
    display: false,
  },
  {
    key: 'infrastructure.sshKey',
    label: 'SSH Key',
    display: false,
  },
]

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'CAPI Clusters',
  listTablePrefs,
)
const defaultParams: Params = {}
const searchTargets = ['name', 'uuid']
const oneSecond = 1000

export default function CapiClustersListPage() {
  const { history } = useReactRouter()
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { loading, reload } = useListAction(listCapiClusters, {
    params,
  })
  const data = useSelectorWithParams(capiClustersSelector, params)

  const [selectedCluster, setSelectedCluster] = useState<SelectorModel>(null)
  const [showDownloadKubeconfigModal, setShowDownloadKubeconfigModal] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Kubeconfig Download',
      icon: 'arrow-circle-down',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowDownloadKubeconfigModal(true)
      },
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      label: 'Cluster YAML File Download',
      icon: 'download',
      handleClick: (cluster) => downloadClusterYamls(cluster),
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => isAdmin() && canUpgradeCapiCluster([cluster]),
      label: 'Upgrade',
      icon: 'level-up',
      handleClick: (cluster) => {
        history.push(routes.cluster.managed.capi.upgrade.path({ id: cluster?.uuid }))
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      cond: (item) => canDeleteCapiCluster([item]),
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: both(isAdmin, canUpgradeCapiCluster),
      icon: 'level-up',
      label: 'Upgrade Cluster',
      BatchActionButton: getGridRedirectButton<SelectorModel>(({ uuid }) =>
        routes.cluster.managed.capi.upgrade.path({ id: uuid }),
      ),
    },
    {
      label: 'Kubeconfig Download',
      icon: 'arrow-circle-down',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowDownloadKubeconfigModal(true)
      },
      refreshAfterSuccess: false,
    },
    {
      label: 'Cluster YAML File Download',
      icon: 'download',
      handleAction: (clusters) => downloadClusterYamls(clusters[0] ?? null),
      refreshAfterSuccess: false,
    },
    {
      cond: (clusters) => {
        const cluster = clusters[0] ?? null
        if (!isAdmin()) return false
        return canUpgradeCapiCluster([cluster])
      },
      label: 'Upgrade',
      icon: 'level-up',
      handleAction: (clusters) => {
        history.push(routes.cluster.managed.capi.upgrade.path({ id: clusters[0]?.uuid }))
      },
      refreshAfterSuccess: true,
    },
    {
      cond: (clusters) => {
        const cluster = clusters[0] ?? null
        return canDeleteCapiCluster([cluster])
      },
      label: 'Delete',
      icon: 'trash',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="CAPI Clusters" />
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={oneSecond * 30} />
      {showDownloadKubeconfigModal && (
        <KubeconfigDownloadModal
          open={true}
          cluster={selectedCluster}
          onClose={() => setShowDownloadKubeconfigModal(false)}
        />
      )}
      {showDeleteDialog && (
        <CapiClusterDeleteDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedCluster]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.CapiClusters}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage="Loading clusters..."
        onRefresh={reload}
        data={data}
        columns={columns}
        addText="Add Cluster"
        AddButtonComponent={AddClusterButton}
        getParamsUpdater={getParamsUpdater}
        label="CAPI Clusters"
        showItemsCountInLabel
        batchActions={batchActions}
        rowMenuItems={rowMenuItems}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
