import React, { FC, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { bareOSSetupDocumentationLink } from 'k8s/links'
// import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { routes } from 'core/utils/routes'
import Theme from 'core/themes/model'
import { BareOsRequiredNodes, ClusterCreateTypes } from '../model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'

import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'

import { allPass } from 'ramda'
import InsufficientNodesNodesDialog from './InsufficientNodesDialog'
import ClusterRequirementsHeader from 'k8s/components/common/ClusterRequirementsHeader'
import BareOsAddOnBulletList from './BareOsAddOnBulletList'
import SelectClusterTypeSection from 'k8s/components/common/SelectClusterTypeSection'
import BareOsMinimumRequirements from './BareOsMinimumRequirements'
import Card from 'core/elements/card'
import { isUnassignedNode, isConnected } from 'app/plugins/infrastructure/components/nodes/helpers'

interface Props {
  onComplete: (route: string) => void
  provider: CloudProviders
}

const BareOSClusterRequirements: FC<Props> = ({ onComplete, provider }) => {
  const classes = useStyles({})
  const [createType, setCreateType] = useState('')
  const [availableNodes, setAvailableNodes] = useState(0)
  const [requiredNodes, setRequiredNodes] = useState(0)
  const [clusterType, setClusterType] = useState(ClusterCreateTypes.OneClick)

  const { loading } = useListAction(listNodes)
  const nodes = useSelector(nodesSelector)

  useEffect(() => {
    if (!loading) {
      const numNodes = nodes.filter(allPass([isConnected, isUnassignedNode])).length
      setAvailableNodes(numNodes)
    }
  }, [nodes])

  const handleClick = useCallback(
    (type: ClusterCreateTypes) => () => {
      setRequiredNodes(BareOsRequiredNodes[type])
      setCreateType(type)

      const hasAvailableNodes = availableNodes >= BareOsRequiredNodes[type]

      if (hasAvailableNodes) {
        onComplete(routes.cluster.add.bareOs[provider][type].path())
      }
    },
    [onComplete, provider, availableNodes],
  )

  return (
    <>
      {availableNodes < requiredNodes && (
        <InsufficientNodesNodesDialog
          createType={createType}
          availableNodes={availableNodes}
          requiredNodes={requiredNodes}
          setRequiredNodes={setRequiredNodes}
        />
      )}
      <Card className={classes.formCard} withCustomBody>
        <section className={classes.cardBody}>
          <div className={classes.requirementsContainer}>
            <ClusterRequirementsHeader
              provider={provider}
              link={bareOSSetupDocumentationLink}
              description={
                <>
                  Create a BareOS cluster using <b>Ubuntu</b>, <b>CentOS</b>, <b>RHEL</b> or{' '}
                  <b>Rocky</b> {provider} machines.
                </>
              }
            ></ClusterRequirementsHeader>
            <div className={classes.spacer} />
            <BareOsMinimumRequirements />
            <div className={classes.spacer} />
            <BareOsAddOnBulletList />
          </div>
          <hr className={classes.divider} />
          <SelectClusterTypeSection
            provider={provider}
            selected={clusterType}
            setClusterType={setClusterType}
            onComplete={handleClick}
          />
        </section>
      </Card>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  requirementsContainer: {
    margin: '48px 0 48px 48px',
  },
  formCard: {
    maxWidth: 1165,
  },
  cardBody: {
    display: 'grid',
    gap: theme.spacing(6),
    gridTemplateColumns: 'minmax(432px,632px) 1px minmax(332px,532px)',
    color: theme.palette.grey[700],
    padding: 0,
  },
  spacer: {
    height: theme.spacing(5),
  },

  divider: {
    height: '100%',
    background: theme.components.card.border,
    width: 1,
    border: 0,
    margin: 0,
    padding: 0,
  },
}))
export default BareOSClusterRequirements
