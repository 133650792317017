import Accordion from 'core/components/accordion/Accordion'
import FormReviewTable from 'core/components/validatedForm/review-table'
import React from 'react'

const columns = [
  { id: 'version', label: 'Kubernetes Version' },
  { id: 'replicas', label: 'Replica Count' },
  { id: 'instanceType', label: 'Instance Type' },
  { id: 'amiId', label: 'Operating System' },
  {
    id: 'publicIP',
    label: 'Make Master Nodes Public',
    render: (value) => (value ? 'True' : 'False'),
  },
  {
    id: 'allowWorkloadsOnMaster',
    label: 'Make Master Nodes Master + Worker',
    render: (value) => (value ? 'True' : 'False'),
  },
  {
    id: 'privileged',
    label: 'Privileged Container',
    render: (value) => (value ? 'True' : 'False'),
  },
]

export default function CapiPropertiesReview({ wizardContext, open }) {
  return (
    <Accordion open={open} id="properties" title="Properties">
      <FormReviewTable data={wizardContext} columns={columns} />
    </Accordion>
  )
}
