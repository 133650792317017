import { makeStyles } from '@material-ui/styles'
import Alert from 'core/components/Alert'
import Progress from 'core/components/progress/Progress'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { emptyObj, isNilOrEmpty, switchCase, ensureArray } from 'utils/fp'
import { partition, propOr } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { addonTypeToNameMap, getDisabledAddonObj, createEtcdBackupAddonObj } from '../helpers'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { listAddonVersions, listClusterAddons, updateClusterAddon } from '../new-actions'
import { addonVersionsSelector, clusterAddonsSelector } from '../selectors'
import ClusterAddonCard from './ClusterAddonCard'
import DisabledClusterAddonCard from './DisabledClusterAddonCard'
import useToggler from 'core/hooks/useToggler'
import DisableAddonDialog from './DisableAddonDialog'
import AddonConfigurationsModal from './AddonConfigurationsModal'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { compareVersions } from 'k8s/util/helpers'
import { decodeStr } from 'utils/misc'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import ModalForm from 'core/elements/modal/ModalForm'
import { IClusterAddon, ClusterAddonType } from '../model'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { ClusterTypes } from '../../model'

const alertTitle = `Note: Removing an add-on may impact the availability of your
  cluster and applications`

const alertMessage = `Cluster Add-ons are applications that are fully managed by Platform9.
  Add-ons may be added/removed at any stage of the cluster lifecycle.`

const bareOsAddons = [
  ClusterAddonType.CoreDns,
  ClusterAddonType.KubernetesDashboard,
  ClusterAddonType.MetricsServer,
  ClusterAddonType.MetalLb,
  ClusterAddonType.Monitoring,
  // ClusterAddonType.ProfileAgent,
  ClusterAddonType.Kubevirt,
  ClusterAddonType.Luigi,
  // ClusterAddonType.Metal3,
  ClusterAddonType.EtcdBackup,
]
const awsAddons = [
  ClusterAddonType.CoreDns,
  ClusterAddonType.KubernetesDashboard,
  ClusterAddonType.MetricsServer,
  ClusterAddonType.AwsAutoScaler,
  ClusterAddonType.Monitoring,
  // ClusterAddonType.ProfileAgent,
  ClusterAddonType.EtcdBackup,
]
const azureAddons = [
  ClusterAddonType.CoreDns,
  ClusterAddonType.KubernetesDashboard,
  ClusterAddonType.MetricsServer,
  ClusterAddonType.AzureAutoScaler,
  ClusterAddonType.Monitoring,
  // ClusterAddonType.ProfileAgent,
  ClusterAddonType.EtcdBackup,
]
const importedClusterAddons = [ClusterAddonType.Monitoring]
const nonAddonManagerAddons = [ClusterAddonType.EtcdBackup]
const capiClusterAddons = [
  ClusterAddonType.CoreDns,
  ClusterAddonType.Monitoring,
  // ClusterAddonType.ProfileAgent,
  ClusterAddonType.MetricsServer,
  ClusterAddonType.KubernetesDashboard,
  ClusterAddonType.CapiAutoScaler,
]

type FormAction = 'Edit' | 'Enable'

export default function ClusterAddonsPage({
  clusterId,
  cluster,
  loading,
  reloadClusters = undefined,
}) {
  const classes = useStyles()
  const { cloudProviderType, clusterType } = cluster || (emptyObj as CombinedClusterSelector)
  const { loading: loadingAddons, reload } = useListAction(listClusterAddons, {
    params: { clusterId },
  })
  const existingClusterAddons = useSelectorWithParams(clusterAddonsSelector, { clusterId })
  // TODO: Use useGetAction here once that's been created
  const { loading: loadingAddonVersions } = useListAction(listAddonVersions, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })

  const addonVersions = useSelectorWithParams(addonVersionsSelector, { clusterId })
  const currentAddonVersions = propOr(emptyObj, '0')(ensureArray(addonVersions))

  const [showDisableDialog, toggleDisableDialog] = useToggler()
  const [showConfigModal, toggleConfigModal] = useToggler()
  const [showFormModal, toggleFormModal] = useToggler()
  const [selectedAddon, setSelectedAddon] = useState(null)
  const [config, setConfig] = useState({ name: '', value: '' })
  const [formAction, setFormAction] = useState<FormAction>(null)
  const { update: updateAddon, updating, error } = useUpdateAction(updateClusterAddon)
  const isOlderVersionedCluster = useMemo(
    () => compareVersions(cluster?.kubeRoleVersion, '1.21') < 0,
    [cluster?.kubeRoleVersion],
  )

  useEffect(() => {
    // Refetch the addons to get the latest statuses
    reload(true)
  }, [])

  // List of all add-on types that can be enabled on a cluster
  const availableAddonTypes = useMemo(() => {
    if (!cloudProviderType) return []
    if (clusterType === ClusterTypes.Capi) return capiClusterAddons
    if (clusterType === ClusterTypes.Imported) return importedClusterAddons
    if (isOlderVersionedCluster) return nonAddonManagerAddons
    return switchCase({
      [CloudProviders.BareOS]: bareOsAddons,
      [CloudProviders.Aws]: awsAddons,
      [CloudProviders.Azure]: azureAddons,
    })(cloudProviderType)
  }, [cloudProviderType, clusterType, isOlderVersionedCluster])

  /**
   * Get a list of all the add-on objects. If the addon is enable, an addon object already
   * exists from the add-on manager. Create an add-on object for disabled add-ons and for add-ons
   * that are not managed by the add-on manager
   */
  const allAddons: IClusterAddon[] = useMemo(
    () =>
      availableAddonTypes.map((addonType) => {
        if (addonType === ClusterAddonType.EtcdBackup) {
          return createEtcdBackupAddonObj(cluster)
        }
        const addon = existingClusterAddons.find((addon) => addon.type === addonType)
        return addon ? addon : getDisabledAddonObj(addonType, cluster, currentAddonVersions)
      }),
    [
      availableAddonTypes,
      existingClusterAddons,
      currentAddonVersions,
      cluster?.etcdBackupEnabled,
      cluster?.tags,
    ],
  )

  const [configurableAddons, nonConfigurableAddons] = useMemo(
    () => partition((addon) => addon.isConfigurable, allAddons),
    [allAddons],
  )

  const [enabledNonConfigurableAddons, disabledNonConfigurableAddons] = useMemo(
    () => partition((addon) => addon.isEnabled, nonConfigurableAddons),
    [nonConfigurableAddons],
  )
  const [enabledConfigurableAddons, disabledConfigurableAddons] = useMemo(
    () => partition((addon) => addon.isEnabled, configurableAddons),
    [configurableAddons],
  )

  const renderAddonCards = useCallback(
    (addons) => {
      return isNilOrEmpty(addons) ? null : (
        <div className={classes.addonCards}>
          {addons.map((addon) => {
            return addon.isEnabled ? (
              <ClusterAddonCard
                key={addon.type}
                addon={addon}
                handleDisableClick={(addon) => {
                  setSelectedAddon(addon)
                  toggleDisableDialog()
                }}
                handleEditClick={(addon) => {
                  setSelectedAddon(addon)
                  setFormAction('Edit')
                  toggleFormModal()
                }}
                handleViewConfigClick={(name, value) => {
                  setConfig({ name, value })
                  toggleConfigModal()
                }}
                existingClusterAddons={existingClusterAddons}
                currentAddonVersions={currentAddonVersions}
              />
            ) : (
              <DisabledClusterAddonCard
                key={addon.type}
                addon={addon}
                handleEnableClick={(addon) => {
                  if (!addon.isConfigurable) return
                  setSelectedAddon(addon)
                  setFormAction('Enable')
                  toggleFormModal()
                }}
                existingClusterAddons={existingClusterAddons}
                currentAddonVersions={currentAddonVersions}
                cluster={cluster}
              />
            )
          })}
        </div>
      )
    },
    [cluster, currentAddonVersions, existingClusterAddons],
  )

  const updateAllAddons = useCallback(async () => {
    existingClusterAddons?.forEach((addon) => {
      // monitoring addon cannot be updated, must be reinstalled
      if (addon?.type === 'monitoring') return
      const name = addonTypeToNameMap[addon?.type]
      const latestAddonVersion = currentAddonVersions[name]
      if (!latestAddonVersion) return
      const needsUpdate = compareVersions(addon?.version, latestAddonVersion) < 0
      if (!needsUpdate) return
      updateAddon({ ...addon, version: latestAddonVersion })
    })
  }, [existingClusterAddons, currentAddonVersions])

  const reloadAddons = useCallback(() => {
    reload(true)
  }, [reload, reloadClusters])

  return (
    <Progress loading={loading || loadingAddons || loadingAddonVersions || updating}>
      {showDisableDialog && (
        <DisableAddonDialog
          addon={selectedAddon}
          open={true}
          onClose={toggleDisableDialog}
          cluster={cluster}
        />
      )}
      {showConfigModal && (
        <ModalForm open onClose={toggleConfigModal} error={error} title={config.name}>
          <CodeMirror
            id="config"
            label={config.name}
            value={decodeStr(config.value)}
            showSearchBar
            showCopyButton
          />
        </ModalForm>
      )}
      {showFormModal && (
        <AddonConfigurationsModal
          addon={selectedAddon}
          open={true}
          onClose={toggleFormModal}
          action={formAction}
          cluster={cluster}
          existingClusterAddons={existingClusterAddons}
          currentAddonVersions={currentAddonVersions}
        />
      )}
      <div className={classes.addonsPage}>
        <div className={classes.sideContent}>
          <Alert className={classes.warning} title={alertTitle} message={alertMessage} />
        </div>
        <div className={classes.addonsContainer}>
          <div className={classes.actionButtons}>
            <Button variant="tertiary" icon="sync" onClick={() => reloadAddons()}>
              Check for Updates
            </Button>
            <Button variant="cta" icon="arrow-alt-circle-up" onClick={updateAllAddons}>
              Update All
            </Button>
          </div>
          <div className={classes.cardsContainer}>
            <Text variant="subtitle2">Non-configurable</Text>
            {renderAddonCards(enabledNonConfigurableAddons)}
            {renderAddonCards(disabledNonConfigurableAddons)}
          </div>
          <div className={classes.cardsContainer}>
            <Text variant="subtitle2">Configurable</Text>
            {renderAddonCards(enabledConfigurableAddons)}
            {renderAddonCards(disabledConfigurableAddons)}
          </div>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  addonsPage: {
    display: 'grid',
    gridTemplateColumns: '234px 1fr',
    gridGap: theme.spacing(4),
  },
  sideContent: {
    display: 'grid',
  },
  addonsContainer: {
    display: 'grid',
    marginTop: theme.spacing(2),
  },
  cardsContainer: {
    display: 'grid',
    gridGap: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  addonCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 628px)',
    gridGap: theme.spacing(2),
  },
  warning: {
    height: '228px',
    marginTop: theme.spacing(2),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))
