import React from 'react'
import AwsAvailabilityZoneChooser from 'app/plugins/infrastructure/components/cloudProviders/aws/AwsAvailabilityZoneChooser'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'

const AwsAvailabilityZoneField = ({
  values,
  wizardContext,
  setWizardContext,
  allowMultiSelect,
  id = 'azs',
  value = undefined,
}) => (
  <AwsAvailabilityZoneChooser
    id={id}
    info="Select from the Availability Zones for the specified region"
    cloudProviderId={values.cloudProviderId}
    cloudProviderRegionId={values.region}
    onChange={(value) => setWizardContext({ [id]: allowMultiSelect ? value : [value] })}
    values={value || wizardContext[id]}
    type={CloudProviders.Aws}
    required
    allowMultiSelect={allowMultiSelect}
  />
)

export default AwsAvailabilityZoneField
