import Accordion from 'core/components/accordion/Accordion'
import FormReviewTable from 'core/components/validatedForm/review-table'
import Divider from 'core/elements/Divider'
import Theme from 'core/themes/model'
import React, { useMemo } from 'react'
import KeyValueReview from './KeyValueReview'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import { NodeGroupTypes } from '../../../capi/model'
import { objToKeyValueArr } from 'utils/fp'

const columns = [
  { id: 'type', label: 'Type' },
  { id: 'name', label: 'Name' },
  { id: 'wgVersion', label: 'Kubernetes Version' },
  { id: 'nodeCount', label: 'Node Count' },
  { id: 'instanceType', label: 'Instance Type' },
  {
    id: 'amiId',
    label: 'Operating System',
    hide: (values) => values.machinePoolType === NodeGroupTypes.AwsManagedMachinePool,
  },
  { id: 'publicIP', label: 'Make nodes public', render: (value) => (value ? 'True' : 'False') },
  {
    id: 'cas',
    label: 'Enable AutoScaling',
    insertDivider: true,
    render: (value) => (value === '-' ? 'False' : 'True'),
  },
  { id: 'minCasSize', label: 'Min Number of Workers', hide: (values) => !values.cas },
  { id: 'maxCasSize', label: 'Max Number of Workers', hide: (values) => !values.cas },
  {
    id: 'capacityType',
    label: 'Spot Instances',
    insertDivider: true,
    render: (value) => (value === 'spot' ? 'Enabled' : 'Disabled'),
    hide: (values) => values.machinePoolType !== NodeGroupTypes.AwsManagedMachinePool,
  },
  {
    id: 'spotMarketOptions',
    label: 'Spot Instances',
    insertDivider: true,
    render: (value) => (value ? 'Enabled' : 'Disabled'),
    hide: (values) => !values.spotMarketOptions,
  },
  {
    id: 'spotMarketOptions.maxPrice',
    label: 'Spot Instance Maximum Price',
    hide: (values) => !values.spotMarketOptions,
  },
]

export default function CapiWorkerNodeGroupReview({ wizardContext, open }) {
  const classes = useStyles()
  const workerNodeGroups = wizardContext?.workerNodeGroups
  if (!workerNodeGroups) return null

  const nodeUpdateStrategyOptions = useMemo(
    () =>
      wizardContext?.nodeUpdateStregy === 'count'
        ? [{ id: 'rollingUpdate.maxUnavailable', label: 'Max Unavailable Nodes - Count' }]
        : [{ id: 'rollingUpdate.maxUnavailable', label: 'Max Unavailable Nodes - Percentage' }],
    [wizardContext],
  )

  return workerNodeGroups.map((workerNodeGroup) => (
    <Accordion
      open={open}
      key={`workerNodeGroup-${workerNodeGroup.name}`}
      id={`workerNodeGroup-${workerNodeGroup.name}`}
      title={`Worker Node Group (${workerNodeGroup.name})`}
    >
      <>
        <FormReviewTable data={workerNodeGroup} columns={columns} />
        <Divider className={classes.divider} />
        {!!workerNodeGroup?.labels && (
          <>
            <Text variant="caption1" className={classes.title}>
              Labels
            </Text>
            <KeyValueReview
              items={objToKeyValueArr(workerNodeGroup?.labels) as Record<string, string>[]}
            />
            <Divider className={classes.divider} />
          </>
        )}
        {!!workerNodeGroup?.taints?.length && (
          <>
            <Text variant="caption1" className={classes.title}>
              Taints
            </Text>
            <KeyValueReview items={workerNodeGroup?.taints} additionalProperties={['effect']} />
            <Divider className={classes.divider} />
          </>
        )}

        <Text variant="caption1" className={classes.title}>
          Node Update Strategy
        </Text>
        <FormReviewTable data={workerNodeGroup} columns={nodeUpdateStrategyOptions} />
      </>
    </Accordion>
  ))
}

const useStyles = makeStyles<Theme>((theme) => ({
  divider: {
    width: 516,
    margin: theme.spacing(1, 0, 1, 0),
  },
  title: {
    marginLeft: theme.spacing(1),
  },
}))
