import { useAppSelector } from 'app/store'
import { SessionState } from 'core/session/sessionReducers'
import useReactRouter from 'use-react-router'
import DropdownButton from 'core/components/DropdownButton'
import { routes } from 'core/utils/routes'
import CreateButton from 'core/components/buttons/CreateButton'
import React from 'react'

const addClusterLinks = [
  { label: 'Import Cluster', link: routes.cluster.import.root.path() },
  { label: 'Create Cluster', link: routes.cluster.add.root.path() },
]

export default function AddClusterButton() {
  const { features } = useAppSelector<SessionState>((store) => store.session)
  const { history } = useReactRouter()
  const showImport = !!features?.experimental?.kplane
  if (showImport) {
    return <DropdownButton links={addClusterLinks} addText={'Add Cluster'} />
  }
  const handleAdd = () => {
    history.push(routes.cluster.add.root.path())
  }
  return <CreateButton onClick={handleAdd}>Create Cluster</CreateButton>
}
