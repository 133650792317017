import React from 'react'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { IClusterEditPageTabs } from './model'

export default function EnableMonitoringCard({ cluster }) {
  const classes = useStyles()
  return (
    <Card title="Alarms - Disabled" className={classes.enableMonitoringCard}>
      <Text variant="body2">
        Please edit this cluster and enable the monitoring add-on to continue.
      </Text>
      <SimpleLink
        src={routes.cluster.managed.qbert.edit.path({
          id: cluster.uuid,
          tab: IClusterEditPageTabs.Addons,
        })}
        textVariant="body2"
      >
        Edit Cluster
      </SimpleLink>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  enableMonitoringCard: {
    marginTop: theme.spacing(4),
  },
}))
