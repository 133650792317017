import React, { useCallback } from 'react'
import TextField from 'core/components/validatedForm/TextField'
import Row from 'core/containers/Row'
import { map, split, fromPairs, pipe, assocPath, dissocPath } from 'ramda'
import {
  Apiserver,
  ControllerManager,
  Scheduler,
  Spec as NodeletControlPlaneSpec,
} from '../capi/control-plane/nodelet-control-plane/model'
import { switchCase } from 'utils/fp'

type KubeComponentsKeys = Extract<
  keyof NodeletControlPlaneSpec,
  'apiserver' | 'scheduler' | 'controllerManager'
>
const defaultApiServer: Apiserver = {
  apiGroupsAndVersions: '',
  privileged: true,
  extraArgs: {},
}

const defaultScheduler: Scheduler = {
  extraArgs: {
    additionalProperties: '',
  },
}
const defaultControllerManager: ControllerManager = {
  extraArgs: {
    additionalProperties: '',
  },
}

const CustomApiFlagsFields = ({ wizardContext, setWizardContext, isCapiCluster = false }) => {
  const handleChange = useCallback(
    (property) => (value) => {
      setWizardContext({ [property]: value })
    },
    [setWizardContext],
  )
  const handleChangeForCapi = useCallback(
    (property: KubeComponentsKeys) => (value: string) => {
      if (typeof value !== 'string') return
      // --key1=val1, --key2=val2 to {key1:value1, key2:value2 }
      const extraArgs = pipe<string, string[], string[][], Record<string, string>>(
        split(','),
        map((val) =>
          // the backend needs to strip `--` from the args and needs them in format extraArgs : {key1:value1, key2:value2 }
          val?.split('=').map((str) => (str?.trim().startsWith('--') ? str?.trim().slice(2) : str)),
        ),
        // @ts-ignore
        fromPairs,
        // @ts-ignore
      )(value)
      setWizardContext({
        [property]: assocPath(['extraArgs'], extraArgs, getKubeComponents(property)),
      })
    },
    [setWizardContext, wizardContext],
  )

  const getKubeComponents = useCallback(
    (key: KubeComponentsKeys): Apiserver | Scheduler | ControllerManager => {
      const { apiserver } = wizardContext
      return switchCase({
        apiserver: apiserver || defaultApiServer,
        scheduler: defaultScheduler,
        controllerManager: defaultControllerManager,
      })(key)
    },
    [wizardContext],
  )

  return (
    <>
      <Row minItemWidth="300" gap={24}>
        <TextField
          onChange={
            isCapiCluster ? handleChangeForCapi('apiserver') : handleChange('apiServerFlags')
          }
          id="apiServerFlag"
          label="API Server Flags"
          info="Enter a comma separated list of supported kube-apiserver,example: --request-timeout=2m0s,--kubelet-timeout=20s"
        />
        <TextField
          onChange={
            isCapiCluster ? handleChangeForCapi('scheduler') : handleChange('schedulerFlags')
          }
          id="schedulerFlag"
          label="Scheduler Flags"
          info="Enter a comma separated list of supported kube-scheduler flags,example:--kube-api-burst=120,--log_file_max_size=3000"
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <TextField
          onChange={
            isCapiCluster
              ? handleChangeForCapi('controllerManager')
              : handleChange('controllerManagerFlags')
          }
          id="controllerManagerFlag"
          label="Controller Manager Flags"
          info="Enter a comma separated list of supported kube-controller-manager flags,example:--large-cluster-size-threshold=60,--concurrent-statefulset-syncs=10"
        />
      </Row>
    </>
  )
}
export default CustomApiFlagsFields
