import React, { useMemo } from 'react'
import { pathStrOr } from 'utils/fp'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listCloudProviderRegionDetails } from '../../cloudProviders/new-actions'
import { cloudProviderRegionDetailsSelector } from '../../cloudProviders/selectors'

// @todo fix these typings
interface Props {
  azs: unknown[]
  cloudProviderId: string
  cloudProviderRegionId: string
}

export default function AwsClusterVpcPicklist({
  azs,
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const options = useMemo(() => {
    const vpcs = pathStrOr([], '0.vpcs', regionDetails)

    // Filter out any VPC's that don't have valid subnets for all the AZ's the user
    // previously selected
    const hasAllAzs = (vpc) => {
      const azsInVpc = pathStrOr([], 'Subnets', vpc).map((x) => x.AvailabilityZone)
      return azs.every((az) => azsInVpc.includes(az))
    }
    const toOption = (vpc) => ({ label: `${vpc.VpcName}-${vpc.CidrBlock}`, value: vpc.VpcId })
    return vpcs.filter(hasAllAzs).map(toOption)
  }, [regionDetails])

  return <AsyncDropdown {...rest} loading={loadingCloudProviderRegionDetails} items={options} />
}
