import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { routes } from 'core/utils/routes'
import { awsPrerequisitesLink } from 'k8s/links'
import Theme from 'core/themes/model'
import { ClusterCreateTypes } from '../model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import AwsCloudProviderRequirementDialog from './AwsCloudProviderRequirementDialog'
import ClusterRequirementsHeader from 'k8s/components/common/ClusterRequirementsHeader'
import AwsMinimumPermissions from './AwsMinimumPermissions'
import SelectClusterTypeSection from 'k8s/components/common/SelectClusterTypeSection'
import Card from 'core/elements/card'
import { useSelector } from 'react-redux'
import useListAction from 'core/hooks/useListAction'
import { listCloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { cloudProvidersSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'

const AwsClusterRequirements = ({ onComplete, provider }) => {
  const classes = useStyles({})
  const [showDialog, setShowDialog] = useState(false)
  const { loading } = useListAction(listCloudProviders)
  const cloudProviders = useSelector(cloudProvidersSelector)
  const [clusterType, setClusterType] = useState(ClusterCreateTypes.Custom)

  const handleClick = useCallback(
    (type: ClusterCreateTypes) => () => {
      const hasAwsProvider = !!cloudProviders.some(
        (provider) => provider.type === CloudProviders.Aws,
      )
      if (!hasAwsProvider) {
        setShowDialog(true)
      } else {
        onComplete(routes.cluster.add.aws[type].path())
      }
    },
    [onComplete, cloudProviders],
  )
  return (
    <>
      {showDialog && (
        <AwsCloudProviderRequirementDialog showDialog={showDialog} setShowDialog={setShowDialog} />
      )}
      <Card className={classes.formCard} withCustomBody>
        <section className={classes.cardBody}>
          <div className={classes.requirementsContainer}>
            <ClusterRequirementsHeader
              provider={provider}
              link={awsPrerequisitesLink}
              description={
                <>
                  Build a Kubernetes Cluster using <b>AWS EC2</b> Instances
                </>
              }
            ></ClusterRequirementsHeader>
            <div className={classes.spacer} />
            <AwsMinimumPermissions />
          </div>
          <hr className={classes.divider} />
          <SelectClusterTypeSection
            provider={provider}
            selected={clusterType}
            setClusterType={setClusterType}
            onComplete={handleClick}
          />
        </section>
      </Card>
    </>
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  requirementsContainer: {
    margin: '48px 0 48px 48px',
  },
  formCard: {
    maxWidth: 1165,
  },
  cardBody: {
    display: 'grid',
    gap: theme.spacing(6),
    gridTemplateColumns: 'minmax(432px,632px) 1px minmax(432px,532px)',
    color: theme.palette.grey[700],
    padding: 0,
  },
  spacer: {
    height: theme.spacing(5),
  },
  divider: {
    height: '100%',
    background: theme.components.card.border,
    width: 1,
    border: 0,
    margin: 0,
    padding: 0,
  },
}))
export default AwsClusterRequirements
