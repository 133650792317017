import { switchCase } from 'utils/fp'
import { uncamelizeString } from 'utils/misc'
import { IClusterStatus } from '../../model'

export const getCapiClusterUpgradeStatus = (phase) => {
  const variant = switchCase(
    {
      Unknown: 'unknown',
      NotStarted: 'secondary',
      InProgress: 'primary',
      Completed: 'ok',
      Failed: 'error',
    },
    'unknown',
  )(phase)
  return { variant, label: uncamelizeString(phase) }
}

// Mapping as per backend
// Condition.Status | Condition.Severity | actual phase
// -----------------------------------------------------
// True             | *                  | Completed
// False            | Error              | Failed (permanent)
// False            | Warn               | InProgress but encountering a (transient) error
// False            | Info               | InProgress
// Unknown          | *                  | NotStarted

export const getControlPlaneStatus = (status, severity): IClusterStatus => {
  if (status === 'False' && severity === 'Info') return 'loading'
  if (status === 'False' && severity === 'Warn') return 'error'
  if (status === 'False' && severity === 'Error') return 'fail'
  if (status === 'True') return 'ok'
  return 'unknown'
}
