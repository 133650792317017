export enum ClusterAddonType {
  CoreDns = 'coredns',
  DnsAutoscaler = 'dns-autoscaler',
  KubernetesDashboard = 'kubernetes-dashboard',
  MetricsServer = 'metrics-server',
  MetalLb = 'metallb',
  AzureAutoScaler = 'cluster-auto-scaler-azure',
  AwsAutoScaler = 'cluster-auto-scaler-aws',
  CapiAutoScaler = 'cluster-auto-scaler-capi',
  Monitoring = 'monitoring',
  ProfileAgent = 'pf9-profile-agent',
  Kubevirt = 'kubevirt',
  Luigi = 'luigi',
  Metal3 = 'metal3',
  EtcdBackup = 'etcdBackup',
}

export enum CoreDnsParams {
  DnsMemoryLimit = 'dnsMemoryLimit',
  DnsDomain = 'dnsDomain',
  Base64EncAdditionalDnsConfig = 'base64EncAdditionalDnsConfig',
  Base64EncEntireDnsConfig = 'base64EncEntireDnsConfig',
  CoresPerReplica = 'CoresPerReplica',
  NodesPerReplica = 'NodesPerReplica',
  MinReplicas = 'MinReplicas',
  MaxReplicas = 'MaxReplicas',
  PollPeriodSecs = 'PollPeriodSecs',
}

export enum MetallbParams {
  MetallbIpRange = 'MetallbIpRange',
  Base64EncMetallbConfig = 'base64EncMetallbConfig',
}

export enum AwsAutoScalerParams {
  ClusterUUID = 'clusterUUID',
  ClusterRegion = 'clusterRegion',
  CPURequest = 'cpuRequest',
  CPULimit = 'cpuLimit',
  MemRequest = 'memRequest',
  MemLimit = 'memLimit',
  EnableAddonResizer = 'enableAddonResizer',
  CpuRequestLimit = 'cpuRequestLimit',
  CpuExtra = 'cpuExtra',
  MemRequestLimit = 'memRequestLimit',
  MemExtra = 'memExtra',
  PercentThreshold = 'percentThreshold',
  minClusterSize = 'minClusterSize',
}

export enum AzureAutoscalerParams {
  MinNumWorkers = 'minNumWorkers',
  MaxNumWorkers = 'maxNumWorkers',
}

export enum MonitoringParams {
  RetentionTime = 'retentionTime',
  StorageClassName = 'storageClassName',
  PvcSize = 'pvcSize',
  PvName = 'pvName',
}

export enum Metal3Params {
  Metal3DhcpInterface = 'Metal3DhcpInterface',
  Metal3DhcpRange = 'Metal3DhcpRange',
  Metal3DhcpGateway = 'Metal3DhcpGateway',
  Metal3DnsServer = 'Metal3DnsServer',
  Metal3KernelURL = 'Metal3KernelURL',
  Metal3RamdiskURL = 'Metal3RamdiskURL',
  Metal3SshKey = 'Metal3SshKey',
  StorageClassName = 'StorageClassName',
}

export enum EtcdBackupParams {
  StorageType = 'storageType',
  IsEtcdBackupEnabled = 'isEtcdBackupEnabled',
  LocalPath = 'localPath',
  DailyBackupTime = 'dailyBackupTime',
  MaxTimestampBackupCount = 'maxTimestampBackupCount',
  IntervalInMins = 'intervalInMins',
  IntervalInHours = 'intervalInHours',
  MaxIntervalBackupCount = 'maxIntervalBackupCount',
}

export interface IClusterAddon extends ClusterAddon {
  id: string
  name: string
  clusterId: string
  type: string
  params: any
  version: string
  healthy: boolean
  phase: string
  isConfigurable: boolean
  isEnabled: boolean
}

export interface ClusterAddons {
  kind: string
  apiVersion: string
  metadata: ClusterAddonsMetadata
  items: ClusterAddon[]
}

export interface ClusterAddon {
  metadata: ItemMetadata
  spec: Spec
  status: Status
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  deletionGracePeriodSeconds?: number
  deletionTimestamp?: string
  labels: Record<string, string>
  finalizers?: string[]
}

interface Spec {
  clusterID: string
  version: string
  type: string
  override: Override
  watch: boolean
}

interface Override {
  params?: AddonOverrideParam[]
}

export interface AddonOverrideParam {
  name: string
  value: string
}

interface Status {
  phase: string
  healthy?: boolean
  lastChecked: null
  message?: string
}

interface ClusterAddonsMetadata {
  resourceVersion: string
}
