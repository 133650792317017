import React, { useMemo } from 'react'
import { routes } from 'core/utils/routes'
import DeleteCloudProviderDialog from '../delete-cloud-provider-dialog'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { ArrayElement } from 'core/actions/Action'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { cloudProvidersSelector, makeCloudProvidersSelector } from '../selectors'
import { listCloudProviders } from '../new-actions'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import getGridDialogButton, {
  GridDialogProps,
} from 'core/elements/grid/helpers/getGridDialogButton'
import { pick, propEq } from 'ramda'
import { CloudProviders, CloudProviderRouteNames } from '../model'
import {
  cloudProvidersListTableColumns,
  deleteCloudProviderTooltipText,
} from '../list-table-helpers'
import UpdateCloudProviderPage from '../UpdateCloudProviderPage'
import { capiClustersSelector } from 'app/plugins/infrastructure/components/clusters/capi/selectors'

type SelectorModel = ArrayElement<ReturnType<typeof cloudProvidersSelector>>

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>(
  'AWS Cloud Providers',
  listTablePrefs,
)

const searchTargets = ['name', 'descriptiveType']

export const columns: GridViewColumn<SelectorModel>[] = cloudProvidersListTableColumns

const batchActions = [
  {
    cond: ([cloudProvider]) =>
      cloudProvider?.type !== 'local' && cloudProvider?.importedClusters?.length === 0,
    label: 'Delete',
    icon: 'trash-alt',
    BatchActionButton: getGridDialogButton<SelectorModel, GridDialogProps<SelectorModel>>(
      DeleteCloudProviderDialog,
      {},
      ([cloudProvider]) => ({
        tooltip:
          cloudProvider?.importedClusters?.length !== 0 ? deleteCloudProviderTooltipText : '',
      }),
    ),
  },
]

const awsCloudProvidersSelector = makeCloudProvidersSelector({ type: CloudProviders.Aws })

export default function AwsCloudProvidersListPage() {
  const { params, getParamsUpdater } = usePrefParams({})
  const { message, loading, reload } = useListAction(listCloudProviders)
  const data = useSelector(awsCloudProvidersSelector)
  const capiClusters = useSelector(capiClustersSelector)

  const cloudProviders = useMemo(() => {
    // This has to be done here due to capiClustersSelector cyclic dependancy with cloudProvidersSelector
    //TODO:CAPI Need to integrate capi cluster nodes + usage here to reflect correct collective clusters capacity
    return data.map((cloudProvider) => {
      const filteredCapiClusters = capiClusters.filter(
        propEq('cloudProviderId', cloudProvider.uuid),
      )
      const { clusters } = cloudProvider
      return {
        ...cloudProvider,
        clusters: [...clusters, ...filteredCapiClusters],
      }
    })
  }, [capiClusters, data])

  return (
    <>
      <DocumentMeta title="AWS Cloud Providers" />
      <UpdateCloudProviderPage />
      <ListContainer<DataKeys.CloudProviders, any>
        dataKey={DataKeys.CloudProviders}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={cloudProviders}
        columns={columns}
        addUrl={routes.cloudProviders.add.path({ provider: CloudProviderRouteNames.Aws })}
        addText="Add Cloud Provider"
        editUrl={(_, id) => routes.cloudProviders.aws.edit.path({ id })}
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        multiSelection={false}
        label="AWS Cloud Providers"
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
