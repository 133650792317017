import ModalForm from 'core/elements/modal/ModalForm'
import React, { useState, useMemo, useCallback } from 'react'
import CapiMachineDeploymentNodeUpdateStrategies from '../../aws/capi/CapiMachineDeploymentNodeUpdateStrategies'
import { isNilOrEmpty, switchCase } from 'utils/fp'
import CapiMachinePoolNodeUpdateStrategies from '../../aws/capi/CapiMachinePoolNodeUpdateStrategies'
import CapiAwsManagedMachinePoolNodeUpdateStrategies from '../../aws/capi/CapiAwsManagedMachinePoolNodeUpdateStrategies'
import useCapiResourceUpdate from '../../aws/capi/useCapiResourceUpdate'
import { CapiOperation } from '../../aws/capi/model'
import { NodeGroupTypes } from '../model'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import { listMachineDeployments } from '../machine-deployment/actions'
import { listAwsMachinePools } from '../aws-machine-pool/actions'
import { listAwsManagedMachinePools } from '../aws-managed-machine-pool/actions'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'

const getMachineDeploymentUpdateBody = (nodeGroup) => ({
  kind: NodeGroupTypes.MachineDeployment,
  metadata: nodeGroup.metadata,
  spec: {
    strategy: {
      rollingUpdate: nodeGroup.rollingUpdate,
    },
  },
})

const getAwsMachinePoolUpdateBody = (nodeGroup) => ({
  kind: NodeGroupTypes.AwsMachinePool,
  metadata: nodeGroup.metadata,
  spec: {
    refreshPreferences: nodeGroup.refreshPreferences,
  },
})

const getAwsManagedMachinePoolUpdateBody = (nodeGroup) => {
  const body = { ...nodeGroup }
  body.spec = { ...body.spec, updateConfig: nodeGroup.updateConfig }
  return body
}

const getUpdateBody = (kind, nodeGroup) => {
  return switchCase({
    [NodeGroupTypes.MachineDeployment]: getMachineDeploymentUpdateBody,
    [NodeGroupTypes.AwsMachinePool]: getAwsMachinePoolUpdateBody,
    [NodeGroupTypes.AwsManagedMachinePool]: getAwsManagedMachinePoolUpdateBody,
  })(kind)(nodeGroup)
}

const getCapiResourceUpdateOptions = (nodeGroupKind) => ({
  // We have to do a PUT request instead of a PATCH request to update AwsManagedMachinePool. This is
  // because the update fields for the resource is an object with either the maxUnavailable or
  // maxUnavailablePercentage key. If we want to remove a key, we have to do a PUT request to
  // replace the entire object
  operation:
    nodeGroupKind === NodeGroupTypes.AwsManagedMachinePool
      ? CapiOperation.Update
      : CapiOperation.PartialUpdate,
})

export default function EditNodeGroupModal({ nodeGroup: currentNodeGroup, open, onClose }) {
  const { name, kind } = currentNodeGroup
  const [nodeGroup, setNodeGroup] = useState(currentNodeGroup)
  const { execute, errors, updating } = useCapiResourceUpdate(getCapiResourceUpdateOptions(kind))
  const { reload: reloadMachineDeployments } = useListAction(listMachineDeployments)
  const { reload: reloadAwsMachinePools } = useListAction(listAwsMachinePools)
  const { reload: reloadAwsManagedMachinePools } = useListAction(listAwsManagedMachinePools)

  const handleOnChange = (fieldId) => (value) => {
    setNodeGroup({ ...nodeGroup, [fieldId]: value })
  }

  const NodeUpdateFields = useMemo(
    () =>
      switchCase(
        {
          [NodeGroupTypes.MachineDeployment]: CapiMachineDeploymentNodeUpdateStrategies,
          [NodeGroupTypes.AwsMachinePool]: CapiMachinePoolNodeUpdateStrategies,
          [NodeGroupTypes.AwsManagedMachinePool]: CapiAwsManagedMachinePoolNodeUpdateStrategies,
        },
        null,
      )(kind),
    [kind],
  )

  const refreshData = useCallback(() => {
    if (!nodeGroup || !nodeGroup.kind) return
    switchCase({
      [NodeGroupTypes.MachineDeployment]: reloadMachineDeployments,
      [NodeGroupTypes.AwsMachinePool]: reloadAwsMachinePools,
      [NodeGroupTypes.AwsManagedMachinePool]: reloadAwsManagedMachinePools,
    })(nodeGroup.kind)(true)
  }, [nodeGroup?.kind])

  const handleSubmit = async () => {
    const updatedResource = getUpdateBody(kind, nodeGroup)
    const { errors } = await execute([updatedResource])
    if (isEmpty(errors)) {
      refreshData()
      onClose()
    }
  }

  return (
    <ModalForm
      title="Edit"
      entityName={name}
      open={open}
      onClose={onClose}
      submitTitle="Save Changes"
      onSubmit={handleSubmit}
      submitting={updating}
      error={isNilOrEmpty(errors) ? null : errors[0]}
    >
      <FormFieldSection title="">
        {NodeUpdateFields && (
          <NodeUpdateFields
            workerNodeGroup={nodeGroup}
            onChange={handleOnChange}
            containerType="modal"
          />
        )}
      </FormFieldSection>
    </ModalForm>
  )
}
