import { Taint } from '../configs/model'
import { NodeGroupTypeLabels } from '../model'

export interface IAwsManagedMachinePoolSelector extends AwsManagedMachinePool {
  uuid: AwsManagedMachinePool['metadata']['uid']
  name: AwsManagedMachinePool['metadata']['name']
  type: NodeGroupTypeLabels
  namespace: AwsManagedMachinePool['metadata']['namespace']
  creationTimestamp: AwsManagedMachinePool['metadata']['creationTimestamp']
  ready: AwsManagedMachinePool['status']['ready']
  failureMessage: AwsManagedMachinePool['status']['failureMessage']
  availabilityZones: AwsManagedMachinePool['spec']['availabilityZones']
  instanceType: AwsManagedMachinePool['spec']['instanceType']
  scaling: AwsManagedMachinePool['spec']['scaling']
  capacityType: AwsManagedMachinePool['spec']['capacityType']
  updateConfig: AwsManagedMachinePool['spec']['updateConfig']
  amiType: AwsManagedMachinePool['spec']['amiType']
  amiVersion: AwsManagedMachinePool['spec']['amiVersion']
  phase: string
  resources: [AwsManagedMachinePool]
  clusterName: string
  numNodes: number
}

export interface AwsManagedMachinePoolList {
  apiVersion: string
  items: AwsManagedMachinePool[]
  kind: 'AWSManagedMachinePoolList'
}

export interface AwsManagedMachinePool {
  apiVersion: string
  kind: 'AWSManagedMachinePool'
  metadata: Metadata
  spec: Spec
  status: Status
}

export interface Metadata {
  annotations: Annotations
  creationTimestamp: string
  finalizers: string[]
  generation: number
  labels: Record<string, string>
  name: string
  namespace: string
  ownerReferences: OwnerReference[]
  resourceVersion: string
  uid: string
  deletionTimestamp: string
}

interface Annotations {
  'kubectl.kubernetes.io/last-applied-configuration': string
}

interface OwnerReference {
  apiVersion: string
  blockOwnerDeletion: boolean
  controller: boolean
  kind: string
  name: string
  uid: string
}

export interface Spec {
  availabilityZones: string[]
  amiType: string
  amiVersion: string
  capacityType: CapacityType
  diskSize?: number
  eksNodegroupName: string
  roleName: string
  instanceType: string
  scaling?: Scaling
  taints: Taint[]
  labels: Record<string, string>
  updateConfig: UpdateConfig
}
export enum CapacityType {
  onDemand = 'onDemand',
  spot = 'spot',
}

interface Scaling {
  maxSize: number
  minSize: number
}
export interface UpdateConfig {
  maxUnavailable: number
  // This is not a typo on the UI side. API misspelled it
  maxUnavailablePrecentage: number // min 1 and max 100
}

interface Status {
  conditions: Condition[]
  failureMessage: string
  ready: boolean
}

interface Condition {
  lastTransitionTime: string
  status: string
  type: string
}
