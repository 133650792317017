import React, { useContext } from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { ValidatedFormContext } from 'core/components/validatedForm/ValidatedForm'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import AzureAutoscalerParamFields from './param-fields/AzureAutoscalerParamFields'
import { awsAutoscalerInfo } from 'app/plugins/infrastructure/components/clusters/constants'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'

export const awsAutoScalingFieldId = 'enableCAS'

export default function AwsAutoScalingField({
  wizardContext,
  setWizardContext,
  disabled,
  useToggleSwitch = false,
}) {
  return useToggleSwitch ? (
    <ToggleSwitchField
      id={awsAutoScalingFieldId}
      label="Auto Scaling"
      onChange={(value) => setWizardContext({ [awsAutoScalingFieldId]: value })}
      info={awsAutoscalerInfo}
      infoPlacement={{ align: topMiddle.align, offset: topMiddle.offset }}
    />
  ) : (
    <CheckboxField
      id={awsAutoScalingFieldId}
      label="Enable Auto Scaling"
      info={awsAutoscalerInfo}
      disabled={disabled}
      value={wizardContext[awsAutoScalingFieldId]}
      onChange={(value) => setWizardContext({ [awsAutoScalingFieldId]: value })}
    />
  )
}

export const AwsAutoScalingAddonFields = ({ showHeader = true }) => {
  const { setFieldValue } = useContext(ValidatedFormContext) as any
  // We are using the minNumWorkers and maxNumWorkers fields from Azure
  // Autoscaler here because those are the params that was accepted for AWS Autoscaler
  // before the addon manager was created. If we take it out, the cluster create request will
  // fail.
  return showHeader ? (
    <FormFieldSection title={'Auto Scaling Configurations'}>
      <AzureAutoscalerParamFields setFieldValue={setFieldValue} />
    </FormFieldSection>
  ) : (
    <AzureAutoscalerParamFields setFieldValue={setFieldValue} />
  )
}
