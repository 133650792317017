import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import ExternalLink from 'core/components/ExternalLink'
import { getFieldsForCard, IDetailFields } from 'core/components/InfoPanel'
import { routes } from 'core/utils/routes'
import InfoCard from 'k8s/components/common/entity/info-card'
import React, { useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { castBoolToStr } from 'utils/misc'

import useListAction from 'core/hooks/useListAction'
import { useDispatch, useSelector } from 'react-redux'
import { listK8sNodes, listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'

import { orderInterfaces } from './helpers'
import { INodesSelector } from './model'
import { DetailRow } from './NodeDetailsPage'
import { capiHostsSelector } from '../clusters/capi/details/overview/hosts/selectors'
import { eksCapik8sNodesSelector } from './k8sNodes-selectors'
import DocumentMeta from 'core/components/DocumentMeta'
import { clientActions } from 'core/client/clientReducers'
import Progress from 'core/components/progress/Progress'

const renderLink = (url, text) => <ExternalLink url={url}>{text}</ExternalLink>

const k8sNodeDetailsFields: Array<IDetailFields<INodesSelector>> = [
  {
    id: 'name',
    title: 'Name',
    required: true,
  },
  {
    id: 'uuid',
    title: 'Unique ID',
    required: true,
  },
  {
    id: 'nodeKubeVersion',
    title: 'Actual Kube Role Version',
  },
  {
    id: 'clusterName',
    title: 'Cluster Name',
    render: (value, { clusterId }) =>
      renderLink(routes.cluster.managed.capi.details.path({ id: clusterId }), value),
  },
]

const miscFields = [
  {
    id: 'cpuArchitecture',
    title: 'CPU Architecture',
  },
  {
    id: 'operatingSystem',
    title: 'Operating System',
  },
]

const useStyles = makeStyles<Theme>((theme) => ({
  nodeInfo: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: theme.spacing(2),
    alignItems: 'start',
    justifyItems: 'start',
  },
  miscNodeInfo: {
    display: 'grid',
    gridTemplateRows: 'max-content max-content',
    gridTemplateColumns: 'max-content',
    gridGap: theme.spacing(2),
  },
}))

const getNodeDetails = (node) => getFieldsForCard(k8sNodeDetailsFields, node)
const getMiscNodeDetails = (node) => getFieldsForCard(miscFields, node)

const EksNodeInfo = () => {
  const { match } = useReactRouter()
  const classes = useStyles({})
  const dispatch = useDispatch()
  const id = match.params.id

  const { loading } = useListAction(listK8sNodes)
  const nodes = useSelector(eksCapik8sNodesSelector)
  const selectedNode = nodes.find((x) => x.uuid === match.params.id)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: selectedNode?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [selectedNode?.name, id])

  const details = getNodeDetails(selectedNode)
  const miscDetails = getMiscNodeDetails(selectedNode)

  if (!selectedNode || loading) {
    return <Progress loading message="Loading EKS Nodes..." />
  }

  return (
    <>
      <DocumentMeta title={`EKS Node - ${selectedNode?.name}`} breadcrumbs />
      <div className={classes.nodeInfo}>
        <InfoCard title="Kubernetes Node Details" items={details} />
        <div className={classes.miscNodeInfo}>
          <InfoCard title="Misc" items={miscDetails} />
        </div>
      </div>
    </>
  )
}

export default EksNodeInfo
