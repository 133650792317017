import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Alert from 'core/components/Alert'
import { getFieldsForCard } from 'core/components/InfoPanel'
import { ecoFields } from './GeneralPage'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { codeMirrorOptions } from 'app/constants'
import { getEcoInstallationYaml } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import Progress from 'core/components/progress/Progress'
import InfoCard from 'k8s/components/common/entity/info-card'
import Text from 'core/elements/Text'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridGap: 16,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  card: {
    width: 550,
  },
  yamlContainer: {
    position: 'relative',
    maxWidth: '100%',
    overflow: 'hidden',

    '& .CodeMirror-actionsBar': {
      position: 'absolute',
      top: 8,
      right: 32,
      zIndex: 4,
    },
  },
  fullWidth: {
    width: '100% !important',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  alertText: {
    display: 'grid',
    gridGap: 16,
  },
}))

const ecoStats = (cluster) => getFieldsForCard(ecoFields, cluster)

const EcoInstallationPage = ({ cluster }) => {
  const classes = useStyles({})
  const [ecoYaml, setEcoYaml] = useState('')
  const [loading, setLoading] = useState(true)

  const ecoStatus = ecoStats(cluster)
  useEffect(() => {
    if (!cluster.uuid) {
      return
    }
    const getYaml = async () => {
      try {
        const yaml = await getEcoInstallationYaml(cluster.uuid)
        setEcoYaml(yaml)
      } catch (err) {
        const errorMessage =
          err?.response?.data?.error || err?.response?.data || 'Error fetching YAML'
        setEcoYaml(errorMessage)
      } finally {
        setLoading(false)
      }
    }
    getYaml()
  }, [cluster])

  return (
    <div className={classes.root}>
      <Alert variant="primary" title={`External Cluster Operator Installation`}>
        <div className={classes.alertText}>
          <Text variant="body2">
            The External Cluster Operator is used to enable secure connectivity between external
            clusters and the Platform9 SaaS Management Plane. Download the YAML displayed below and
            run <code>kubectl apply -f {`<path_to_yaml>`}</code> to install the ECO agent.
          </Text>
          <Text variant="body2">Once installed the ECO Status will change to running.</Text>
        </div>
      </Alert>
      <div className={classes.card}>
        <InfoCard title="ECO Status" items={ecoStatus} />
      </div>
      <Progress
        loading={loading}
        message={`Loading YAML...`}
        overlay={false}
        className={classes.overflowHidden}
      >
        <ValidatedForm classes={{ root: classes.yamlContainer }} elevated={false}>
          <CodeMirror
            className={classes.fullWidth}
            id="kubeconfigYaml"
            label="External Cluster Operator Installation YAML"
            options={codeMirrorOptions}
            value={ecoYaml}
            showCopyButton
            showDownloadButton
            downloadFileName={`${cluster.name}.yaml`}
          />
        </ValidatedForm>
      </Progress>
    </div>
  )
}

export default EcoInstallationPage
