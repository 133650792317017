import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import ListContainer from 'core/containers/ListContainer'
import Button from 'core/elements/button'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import Theme from 'core/themes/model'
import { pick, pluck } from 'ramda'
import React, { useMemo } from 'react'
import generateTestId from 'utils/test-helpers'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import useListAction from 'core/hooks/useListAction'
import { listRepositories } from 'k8s/components/app-catalog/repositories/new-actions'
import { useSelector } from 'react-redux'
import {
  repositoriesSelector,
  repositoriesConnectedToClustersSelector,
} from 'k8s/components/app-catalog/repositories/selectors'
import Progress from 'core/components/progress/Progress'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import getGridRedirectButton from 'core/elements/grid/helpers/getGridRedirectButton'
import NoRepositoriesMessage from 'k8s/components/app-catalog/repositories/components/NoRepositoriesMessage'

const useStyles = makeStyles<Theme>((theme) => ({
  dialogContent: {
    backgroundColor: theme.components.table.background,

    '& .grid-container': {
      border: 0,
    },

    '& .progress-root': {
      height: 'auto',
    },
  },
  message: {
    margin: theme.spacing(4, 2),
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.components.table.border}`,
  },
}))

type ModelDataKey = DataKeys.Repositories
type SelectorModel = ArrayElement<ReturnType<typeof repositoriesSelector>>

const defaultParams = {
  repositories: [],
}

const usePrefParams = createUsePrefParamsHook<typeof defaultParams & TablePrefsParams>(
  'Unconnected Repositories',
  listTablePrefs,
)

const searchTargets = ['name']

export const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Name' },
  { key: 'type', label: 'Type' },
]

const batchActions: GridBatchActionSpec<SelectorModel>[] = [
  {
    icon: 'edit',
    label: 'Edit',
    BatchActionButton: getGridRedirectButton<SelectorModel>(({ name }) =>
      routes.repositories.edit.path({ id: name }),
    ),
  },
]

const ClusterReposListDialog = ({ clusterId, onClose }) => {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { loading, message, reload } = useListAction(listRepositories)
  const allRepositories = useSelector(repositoriesSelector)
  const repositoriesConnectedToClustersMap = useSelector(repositoriesConnectedToClustersSelector)
  const namesOfReposConnectedToCluster = pluck<any, string>(
    'name',
    repositoriesConnectedToClustersMap[clusterId] || [],
  )

  const reposAvailableToCluster = useMemo(
    () => allRepositories.filter((repo) => !namesOfReposConnectedToCluster.includes(repo.name)),
    [allRepositories, namesOfReposConnectedToCluster],
  )

  const hasRepositories = useMemo(() => !loading && allRepositories.length > 0, [
    allRepositories,
    loading,
  ])

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
      data-testid={generateTestId('cluster', 'repositories')}
    >
      <div className={classes.dialogContent}>
        <Progress loading={loading} message={message}>
          {!hasRepositories && (
            <NoRepositoriesMessage className={classes.message} showAddRepositoryLink={false} />
          )}
          {hasRepositories && (
            <ListContainer<ModelDataKey, SelectorModel>
              dataKey={DataKeys.Repositories}
              searchTargets={searchTargets}
              uniqueIdentifier="name"
              loading={loading}
              loadingMessage={message}
              onRefresh={reload}
              data={reposAvailableToCluster}
              columns={columns}
              getParamsUpdater={getParamsUpdater}
              batchActions={batchActions}
              label="Available Repositories"
              {...pick(listTablePrefs, params)}
            />
          )}
          <div className={classes.actions}>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            {!hasRepositories && (
              <Button
                onClick={() => history.push(routes.repositories.add.path())}
                data-testid={generateTestId('add', 'repository')}
              >
                Add Repository
              </Button>
            )}
          </div>
        </Progress>
      </div>
    </Dialog>
  )
}

export default ClusterReposListDialog
