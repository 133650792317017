import React, { useMemo } from 'react'
import { compareVersions } from 'k8s/util/helpers'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { metricsServerInfo } from 'app/plugins/infrastructure/components/clusters/constants'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'

export const metricsServerFieldId = 'enableMetricsServer'

const MetricsServer = ({ wizardContext, setWizardContext, useToggleSwitch = false }) => {
  const isNewK8sVersion = useMemo(() => {
    const version = wizardContext.kubeRoleVersion || wizardContext.version
    if (!version) {
      return false
    }
    return compareVersions(version, '1.20') >= 0
  }, [wizardContext.kubeRoleVersion, wizardContext.version])

  return (
    <>
      {isNewK8sVersion ? (
        useToggleSwitch ? (
          <ToggleSwitchField
            id={metricsServerFieldId}
            label="Metrics Server"
            onChange={(value) => setWizardContext({ [metricsServerFieldId]: value })}
            info={metricsServerInfo}
            infoPlacement={{ align: topMiddle.align, offset: topMiddle.offset }}
          />
        ) : (
          <CheckboxField
            id={metricsServerFieldId}
            label="Metrics Server"
            info={metricsServerInfo}
            value={wizardContext[metricsServerFieldId]}
            onChange={(value) => setWizardContext({ [metricsServerFieldId]: value })}
          />
        )
      ) : null}
    </>
  )
}

export default MetricsServer
